<template>
  <div
    class="fixed top-0 left-0 bottom-0 flex flex-col items-start w-60 p-7 border-r border-grey-300"
  >
    <nuxt-link class="inline-block h-12 mb-8" to="/admin">
      <Logo class="h-12" />
    </nuxt-link>
    <h2 class="mb-12 text-xl">
      Admin
    </h2>
    <div class="flex flex-col items-start">
      <AdminSideBarLink label="Submissions" to="/admin/submissions" />
      <AdminSideBarLink
        v-if="role === 'ADMIN' || role === 'SUPER_ADMIN'"
        label="Coupons"
        to="/admin/coupons"
      />
      <AdminSideBarLink
        v-if="role === 'ADMIN' || role === 'SUPER_ADMIN'"
        label="Users"
        to="/admin/users"
      />
    </div>
    <div class="w-full mt-auto">
      <span class="inline-block w-full truncate" v-text="email" />
      <BaseButton
        class="btn text-teal-200 hover:underline"
        @click="$router.push({ path: '/log-out?redirect=/admin' })"
      >
        Log out
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AdminSideBarLink from '~/components/AdminSideBarLink';
import BaseButton from '~/components/BaseButton';
import Logo from '~/components/Logo';

export default {
  name: 'AdminSideBar',
  components: {
    AdminSideBarLink,
    BaseButton,
    Logo,
  },
  computed: {
    ...mapGetters(['email', 'role']),
  },
};
</script>
