<template>
  <div
    class="p-6 md:p-8 xl:p-10 border-l-2 border-r-2 border-b-2 border-grey-300"
  >
    <PaymentMethod value="zip">
      <img
        :src="require('~/assets/img/payment-gateways/zip.png')"
        style="height: 30px"
        alt="Zip - Own it now, pay later"
      />
    </PaymentMethod>
    <FormSection :class="{ hidden: !active }">
      <FormRow class="mt-4">
        <div class="flex items-center text-charcoal-100">
          <!-- <BaseGlyph id="exclamation" /> -->
          <span class="text-lg">
            After clicking “Complete order”, you will be redirected to Zip.
          </span>
        </div>
      </FormRow>
      <FormRow>
        <BaseAlert v-if="paymentError" class="w-full mt-2" type="error">
          {{ paymentError }}
        </BaseAlert>
      </FormRow>
    </FormSection>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import BaseAlert from '~/components/BaseAlert';
import FormRow from '~/components/FormRow';
import FormSection from '~/components/FormSection';
import PaymentMethod from '~/components/PaymentMethod';

import SUBMIT_WILL_MUTATION from '~/graphql/mutations/SubmitWill';
import CREATE_ZIP_CHECKOUT from '~/graphql/mutations/CreateZipCheckout';
import { subscription, user, will } from '~/mixins/apollo';
import { formatError } from '~/utilities';

export default {
  components: {
    BaseAlert,
    FormRow,
    FormSection,
    PaymentMethod,
  },
  mixins: [subscription, user, will],
  props: {
    error: { type: String, default: '' },
    addPartnersWill: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters('checkout', [
      'checkoutAction',
      'couponCode',
      'paymentMethod',
      'paymentLoading',
      'paymentError',
      'paymentSuccess',
      'paymentProcessing',
      'finalSetupPrice',
    ]),
    active() {
      return this.paymentMethod === 'zip';
    },
  },
  async mounted() {
    switch (this.$route.query.result) {
      case 'success':
        this.setPaymentSuccess(true);
        this.setPaymentProcessing(false);
        break;
      case 'cancelled':
        this.setPaymentMethod('zip');
        this.setPaymentError('Your payment was cancelled.');
        this.setPaymentProcessing(false);
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '❌ Payment Failed',
          props: {
            error: 'Zip payment cancelled by customer',
          },
        });
        break;
      case 'declined':
        this.setPaymentMethod('zip');
        this.setPaymentError('Your payment was declined.');
        this.setPaymentProcessing(false);
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '❌ Payment Failed',
          props: {
            error: 'Zip payment declined',
          },
        });
        break;
      case 'approved':
        try {
          this.setPaymentProcessing(true);
          this.setPaymentMethod('zip');
          this.zipMoneyApproved();
          await this.zipMoneyProcess();
        } catch (e) {
          this.setPaymentError(e.message);
        }
        break;
      default:
        this.setPaymentProcessing(false);
    }

    this.$nuxt.$on('checkout', async () => {
      if (this.paymentMethod === 'zip') {
        this.setPaymentLoading(true);
        await this.zipMoneyCheckout();
      }
    });
  },
  methods: {
    ...mapActions('checkout', [
      'setPaymentLoading',
      'setPaymentError',
      'setPaymentSuccess',
      'setPaymentProcessing',
      'setPaymentMethod',
      'submitWill',
      'zipMoneyApproved',
    ]),
    async zipMoneyCheckout() {
      const willProduct = this.addPartnersWill ? 'WILL_X2' : 'WILL';

      try {
        const { data } = await this.$apollo.mutate({
          mutation: CREATE_ZIP_CHECKOUT,
          variables: {
            userId: this.will.userId,
            coupon: this.couponCode,
            products: [willProduct],
          },
        });

        if (window) {
          window.location.href = data.createZipCheckout.checkout.redirectUri;
        }
      } catch (e) {
        this.setPaymentError(e.message);
      }
    },
    async zipMoneyProcess() {
      if (this.checkoutAction === 'submit') {
        const willProduct = this.addPartnersWill ? 'WILL_X2' : 'WILL';
        const hasProfessionalExecutor = [
          'professional',
          'friendsFamilyAndProfessional',
        ].includes(this.willMeta.executors_option);

        await this.$apollo
          .mutate({
            mutation: SUBMIT_WILL_MUTATION,
            variables: {
              id: this.willId,
              zipCheckoutId: this.$route.query.checkoutId,
              coupon: this.couponCode || '',
              products: [willProduct],
            },
          })
          .then(({ data, errors }) => {
            if (data.submitWill && data.submitWill.success) {
              this.setWillStatus(data.submitWill.will.status);

              this.$nuxt.$emit('sendTrackingEvent', {
                event: '📄 Submit Will',
                props: {
                  ...(this.couponCode && { coupon_code: this.couponCode }),
                  price: this.finalSetupPrice,
                  gateway: 'Zip',
                },
                integrations: {
                  Intercom: false,
                  'Facebook Pixel': false,
                },
              });

              this.$nuxt.$emit('sendTrackingAttributes', {
                ...(this.couponCode && { coupon_code: this.couponCode }),
                price: this.finalSetupPrice,
                has_professional_executor: hasProfessionalExecutor,
              });

              if (hasProfessionalExecutor) {
                this.$nuxt.$emit('sendTrackingEvent', {
                  event: 'Professional Executor Appointed',
                });
              }

              this.setPaymentSuccess(true);
              this.setPaymentProcessing(false);

              if (this.addPartnersWill) {
                this.willMeta.partner_bundle = 'true';
                this.updateWillMeta();
              }

              this.$router.push({
                path: this.$route.path,
                query: {
                  success: true,
                },
              });
            } else {
              this.$nuxt.$emit('unlockForm');
              this.setPaymentError(formatError(errors[0].message));
              this.setPaymentProcessing(false);
            }
          })
          .catch((e) => {
            this.$nuxt.$emit('unlockForm');
            this.setPaymentError(formatError(e.message));
            this.setPaymentProcessing(false);
          });
      } else if (this.checkoutAction === 'renew') {
        await this.reactivateSubscription(null, this.$route.query.checkoutId)
          .then(({ data, errors }) => {
            this.setPaymentSuccess(true);
            this.setPaymentProcessing(false);

            this.$router.push({
              path: this.$route.path,
              query: {
                success: true,
              },
            });
          })
          .catch((e) => {
            this.$nuxt.$emit('unlockForm');
            this.setPaymentError(formatError(e.message));
            this.setPaymentProcessing(false);
          });
      }
    },
  },
};
</script>
