<template>
  <ModuleStep :index="2" prev-step="/get-started">
    <Form
      :hide-submit="!isComplete"
      submit-arrow
      :auto-submit="true"
      @submit="done"
    >
      <FormSection>
        <template #heading>
          Do you live in Australia?
        </template>
        <Tip>
          Safewill is available for people living in Australia who are over the
          age of 18.
        </Tip>
        <LargeRadioButtons
          id="is_resident"
          v-model="is_resident"
          :options="[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]"
        />
      </FormSection>
      <FormSection v-if="is_resident === false" class="problem">
        <template #heading>
          We've got a problem!
        </template>
        <p>
          Unfortunately, Safewill is currently not available for anyone living
          outside Australia or under the age of 18.
        </p>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapFields } from 'vuex-map-fields';

import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import LargeRadioButtons from '~/components/LargeRadioButtons';
import ModuleStep from '~/components/ModuleStep';
import Tip from '~/components/Tip';

export default {
  name: 'GetStartedAustralianResident',
  components: {
    Form,
    FormSection,
    LargeRadioButtons,
    ModuleStep,
    Tip,
  },
  computed: {
    ...mapFields('signup', ['meta.is_resident']),
    isComplete() {
      return !!this.is_resident;
    },
  },
  methods: {
    done() {
      this.$router.push({
        path: '/get-started/age',
        query: this.$route.query,
      });
    },
  },
};
</script>
