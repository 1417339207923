<template>
  <AuthPanel>
    <template #heading>
      <Squiggle>Two Factor</Squiggle> <br />Authentication.
    </template>
    <template #form>
      <Form
        v-slot="{ loading }"
        :mutation="LOGIN_MUTATION"
        :variables="variables"
        custom-error="Invalid 2fa code."
        submit-arrow
        submit-label="Log In"
        submit-wide
        auto-focus
        @done="done"
      >
        <!-- 2FA Code -->
        <FormRow>
          <TextInput
            id="email"
            v-model="twoFactorCode"
            :disabled="loading"
            label="Code"
            placeholder="000000"
            rules="required"
            type="text"
          />
        </FormRow>
      </Form>
    </template>
  </AuthPanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import GET_WILL_QUERY from '~/graphql/queries/GetWill';
import LOGIN_MUTATION from '~/graphql/mutations/Login';

import AuthPanel from '~/components/AuthPanel';
import Form from '~/components/Form';
import FormRow from '~/components/FormRow';
import Squiggle from '~/components/Squiggle';
import TextInput from '~/components/TextInput';

import { isIE11 } from '~/utilities';

export default {
  name: 'LogIn',
  middleware: ['notAuthenticated'],
  components: {
    AuthPanel,
    Form,
    FormRow,
    Squiggle,
    TextInput,
  },
  data() {
    return {
      LOGIN_MUTATION,
      twoFactorCode: '',
    };
  },
  computed: {
    ...mapGetters(['email', 'password']),
    variables() {
      return {
        email: this.email,
        password: this.password,
        twoFactorCode: this.twoFactorCode,
      };
    },
  },
  mounted() {
    this.$analytics.reset();

    if (!this.password || !this.email) {
      if (isIE11()) {
        window.location.href = '/log-in';
      } else {
        this.$router.push({
          path: '/log-in',
        });
      }
    }
  },
  methods: {
    ...mapActions([
      'setToken',
      'setPassword',
      'setTwoFactorEnabled',
      'setWillId',
      'setWillStatus',
    ]),
    ...mapActions('signup', ['resetSignup']),
    async done({
      data: {
        login: { token, user },
      },
    }) {
      await this.$apolloHelpers
        .onLogin(token)
        .then(() => {
          this.setToken(token);
          this.setPassword(null);
          this.setTwoFactorEnabled(true);
          this.$ff.refreshFeatureFlags();
        })
        .catch((e) => {
          console.error(e.message);
        });

      await this.$apollo
        .query({
          query: GET_WILL_QUERY,
        })
        .then(({ data }) => {
          if (data.getWill && data.getWill.will) {
            this.setWillId(data.getWill.will.id);
            this.setWillStatus(data.getWill.will.status);
          }
        })
        .catch((e) => {
          console.error(e.message);
        });

      if (isIE11()) {
        window.location.href = '/';
      } else {
        this.$router.push({
          path: '/',
        });
      }
    },
  },
};
</script>
