<template>
  <BaseModal v-if="showNotifyCharityConsentModal" modal-wide hide-close>
    <div
      class="flex justify-center items-center w-10 h-10 rounded-full mb-4 bg-ice-300"
    >
      <BaseIcon id="charity" />
    </div>
    <h4 class="mb-4 text-3xl">Notifying Charities</h4>
    <div class="text-xl text-charcoal-200">
      <p>
        By including a charity in your will you are making them a part of your
        legacy. These charities would love to know about your contribution to
        their cause. With your permission, we'll share your contact details with
        them so they can thank you directly.
      </p>
    </div>
    <div class="flex items-baseline mt-8">
      <BaseButton class="mr-6 btn-xl btn-slate" @click="savePreference(true)">
        <div class="flex items-center">
          <span class="text-white">Okay</span>
        </div>
      </BaseButton>
      <BaseButton class="text-xl text-grey-600" @click="savePreference(false)">
        No thanks
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import UPDATE_WILL_NOTIFY_CHARITY_MUTATION from '~/graphql/mutations/UpdateNotifyCharityOfWill';

import BaseButton from '~/components/BaseButton';
import BaseModal from '~/components/BaseModal';
import BaseIcon from '~/components/BaseIcon';

import { will } from '~/mixins/apollo';

export default {
  name: 'NotifyCharitiesConsentModal',
  components: {
    BaseButton,
    BaseModal,
    BaseIcon,
  },
  mixins: [will],
  computed: {
    ...mapGetters('ui', ['showNotifyCharityConsentModal']),
  },
  watch: {
    willMeta() {
      this.checkConditionsAndDisplayModal();
    },
    will() {
      this.checkConditionsAndDisplayModal();
    },
  },
  methods: {
    ...mapActions('ui', ['setShowNotifyCharityConsentModal']),
    async savePreference(preference) {
      await this.updateNotifyCharityOfWill(preference);
      this.setShowNotifyCharityConsentModal(false);
    },
    async updateNotifyCharityOfWill(value) {
      const { data } = await this.$apollo.mutate({
        mutation: UPDATE_WILL_NOTIFY_CHARITY_MUTATION,
        variables: {
          userId: this.userId,
          value,
        },
      });
      if (
        data.updateNotifyCharityOfWill &&
        data.updateNotifyCharityOfWill.success
      ) {
        await this.refetchWill();
      }
    },
    checkConditionsAndDisplayModal() {
      if (
        this.role === 'CONSUMER' &&
        !['IN_PROGRESS', 'ARCHIVED'].includes(this.will.status) &&
        this.willMeta &&
        (this.willMeta.charity_in_estate || this.willMeta.charity_in_gifts) &&
        this.willMeta.notify_charities === null
      ) {
        this.setShowNotifyCharityConsentModal(true);
      }
    },
  },
};
</script>
