<template>
  <div
    v-on-dismiss="{
      watch: showMobileNav,
      callback: dismiss,
    }"
    class="fixed z-40 top-0 right-0 bottom-0 transform w-72 h-screen bg-white shadow-lg font-medium text-lg  transform transition-all duration-500 ease-in-out"
    :class="mobileNavClass"
  >
    <div class="flex justify-end items-center h-18 md:h-28 px-6">
      <button type="button" @click="dismiss">
        <MenuDisc icon="close" />
      </button>
    </div>
    <MenuItem to="/" icon="home" text="Overview" vertical @click="dismiss" />
    <MenuItem
      to="/dashboard"
      icon="feather-alt"
      text="Your Will"
      vertical
      @click="dismiss"
    />
    <MenuItem
      v-if="showPoa"
      :to="hasPoa ? '/poa/dashboard' : '/poa'"
      icon="shield"
      text="Power of Attorney"
      vertical
      @click="dismiss"
    />
    <client-only>
      <MenuItem
        v-if="willStatus != 'IN_PROGRESS' && willStatus != 'FLAGGED'"
        id="invite"
        to="/dashboard/invite"
        icon="refer"
        text="Invite"
        vertical
        @click="dismiss"
      />
      <MenuItem
        v-if="willStatus === 'APPROVED'"
        id="upload"
        to="/dashboard/upload-will"
        icon="upload"
        text="Uploads"
        vertical
        @click="dismiss"
      />
    </client-only>
    <MenuItem
      to="/dashboard/account-settings"
      text="Account"
      icon="user"
      vertical
      @click="dismiss"
    >
      <WarningBadge
        v-if="willStatus === 'APPROVED' && hasExpiredSubscription"
      />
    </MenuItem>
    <MenuItem
      to="/log-out"
      icon="logout"
      text="Log Out"
      vertical
      @click="dismiss"
    />
  </div>
</template>

<script>
import VDismiss from 'vue-dismiss';
import { queries } from '@/modules/apollo-queries/poa-status';
import { mapActions, mapGetters } from 'vuex';

import MenuDisc from '~/components/MenuDisc';
import MenuItem from '~/components/MenuItem';
import WarningBadge from '~/components/WarningBadge';

import { subscription, will } from '~/mixins/apollo';

export default {
  name: 'MobileNav',
  components: {
    MenuDisc,
    MenuItem,
    WarningBadge,
  },
  mixins: [VDismiss, subscription, will],
  apollo: {
    poaStatus: {
      ...queries.getPoaStatus(),
      variables() {
        return {
          id: this.poaId,
        };
      },
    },
  },
  data() {
    return {
      poaStatus: null,
    };
  },
  computed: {
    ...mapGetters('ui', ['showMobileNav']),
    ...mapGetters(['verified', 'willStatus']),
    ...mapGetters(['poaId']),
    hasPoa() {
      return this.poaStatus !== null;
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    showPoa() {
      return this.$ff.powerOfAttorney() || this.hasPoa;
    },
    mobileNavClass() {
      return !this.showMobileNav
        ? 'opacity-0 translate-x-full duration-300'
        : '';
    },
  },
  methods: {
    ...mapActions('ui', ['setShowMobileNav']),
    dismiss() {
      this.setShowMobileNav(false);
    },
  },
};
</script>
