<template>
  <a
    class="link inline-flex items-center font-medium"
    :href="href"
    target="_blank"
  >
    <span class="inline-block mr-1">
      <slot />
    </span>
    <BaseGlyph id="link" />
  </a>
</template>

<script>
import BaseGlyph from '~/components/BaseGlyph';

export default {
  name: 'ExternalLink',
  components: {
    BaseGlyph,
  },
  props: {
    href: {
      required: true,
      type: String,
    },
  },
};
</script>
