<template>
  <ModuleStep :index="5" prev-step="/about-yourself/address">
    <Form
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      :auto-submit="isComplete && !willMeta.has_partner"
      submit-arrow
      @done="done"
    >
      <FormSection class="step-1">
        <template #heading> Do you have a partner? </template>
        <Tip>
          <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
          You have a partner if you’re married or have
          <GlossaryTooltip term="De Facto/Domestic relationship">
            a de facto/domestic relationship.
          </GlossaryTooltip>
        </Tip>
        <LargeRadioButtons
          id="hasPartner"
          v-model="willMeta.has_partner"
          :options="[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]"
        />
        <Notice headline="Partner discount" icon="tag">
          <p>
            Add your partner to get 50% off their Will. Once you’ve submitted
            your Will, confirm your partner’s email address and invite them to
            complete theirs when they’re ready.
          </p>
        </Notice>
      </FormSection>
      <p v-if="willMeta.has_partner" class="text-lg mb-6">
        Once you've written your Will, we'll send a discount code for your
        partner to write theirs for 50% off.
      </p>
      <FormSection
        v-if="willMeta.has_partner"
        class="step-2"
        tip="We’ll ask you to add or select people at relevant places in your will. Their details can be edited on the People, Pets & Charities page."
      >
        <template #heading> My partner is... </template>
        <PersonSelector
          v-model="selectedPartners"
          :loading="loading"
          :max="1"
          :people="filteredPeople"
          type="partner"
        />
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import GlossaryTooltip from '~/components/GlossaryTooltip';
import LargeRadioButtons from '~/components/LargeRadioButtons';
import ModuleStep from '~/components/ModuleStep';
import Notice from '~/components/Notice';
import PersonSelector from '~/components/PersonSelector';
import Tip from '~/components/Tip';

import { metaArrayToObject } from '~/utilities';

import { beneficiaries, people, user, will } from '~/mixins/apollo';

export default {
  name: 'AboutYourselfPartner',
  components: {
    Form,
    FormSection,
    GlossaryTooltip,
    LargeRadioButtons,
    ModuleStep,
    Notice,
    PersonSelector,
    Tip,
  },
  mixins: [beneficiaries, people, user, will],
  data() {
    return {
      selectedPartners: [],
    };
  },
  computed: {
    isComplete() {
      return !!(
        (this.willMeta && this.willMeta.has_partner === false) ||
        this.selectedPartners.length
      );
    },
    filteredPeople() {
      return this.people.filter((person) => {
        return person.category === 'none' || person.category === 'partner';
      });
    },
  },
  watch: {
    partners(partners) {
      if (partners.length && !this.selectedPartners.length) {
        this.selectedPartners = partners.map((partner) => partner.id);
      }
    },
  },
  methods: {
    async done() {
      let partnerUpdated = false;

      await Promise.all(
        this.people.map((person) => {
          const isPartner = person.category === 'partner';
          const isSelected = this.selectedPartners.includes(person.id);

          if (this.willMeta.has_partner && isSelected) {
            if (!isPartner) {
              partnerUpdated = true;

              this.$nuxt.$emit('sendTrackingAttributes', {
                partner_name: metaArrayToObject(person.meta).full_name,
              });

              return this.updatePerson(person, {
                category: 'partner',
              });
            }
          } else if (isPartner) {
            partnerUpdated = true;

            this.$nuxt.$emit('sendTrackingAttributes', {
              partner_name: null,
            });

            return this.updatePerson(person, {
              category: 'none',
            });
          }
        })
      );

      if (
        this.willMeta.estate_split === 'partner' &&
        (this.willMeta.has_partner === false || partnerUpdated)
      ) {
        this.willMeta.estate_split = null;

        await this.removeAllBeneficiaries();
        await this.updateWillMeta();
      }

      this.$router.push({
        path: '/about-yourself/phone',
      });
    },
  },
};
</script>
