<template>
  <div>
    <form ref="fileform" class="text-center" :style="styles">
      <span class="drop-files" v-text="label"> </span>
    </form>
  </div>
</template>

<script>
export default {
  name: 'DropFileBox',
  data() {
    return {
      files: [],
    };
  },
  computed: {
    label() {
      return this.files.length === 0
        ? '+ Drop files here to add'
        : this.files.length === 1
        ? '1 file added'
        : this.files.length + ' files added';
    },
    styles() {
      return {
        background: '#EEEEEE',
        'border-color': '#cccccc',
        'border-style': 'dashed',
        'border-width': '3px',
        'border-radius': '4px',
        'line-height': '400px',
      };
    },
  },
  mounted() {
    [
      'drag',
      'dragstart',
      'dragend',
      'dragover',
      'dragenter',
      'dragleave',
      'drop',
    ].forEach(
      function(evt) {
        this.$refs.fileform.addEventListener(
          evt,
          function(e) {
            e.preventDefault();
            e.stopPropagation();
            if (evt === 'dragover') {
              e.dataTransfer.effectAllowed = 'copyMove';
              e.dataTransfer.dropEffect = 'copy';
              e.target.style.background = 'gray';
            }
            if (evt === 'dragleave' || evt === 'drop') {
              e.target.style.background = '#ccc';
            }
          },
          false
        );
      }.bind(this)
    );

    this.$refs.fileform.addEventListener(
      'drop',
      function(e) {
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
          const type = e.dataTransfer.files[i].type;
          if (type.includes('image/') || type.includes('/pdf')) {
            this.files.push(e.dataTransfer.files[i]);
          }
        }
        this.$emit('passFilesToParent', this.files);
      }.bind(this)
    );
  },
  methods: {
    addFiles(files) {
      for (let i = 0; i < files.length; i++) {
        this.files.push(files[i]);
      }
    },
    clearFiles() {
      this.files = [];
    },
  },
};
</script>
