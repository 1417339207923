<template>
  <BasePanel
    v-if="showPetPanel"
    class="pet-panel"
    title="Adding a new pet"
    @close="close"
  >
    <Form
      v-slot="{ loading }"
      auto-focus
      :mutation="mutation"
      :variables="variables"
      submit-label="Save Pet"
      @update="update"
      @done="close"
    >
      <FormSection>
        <FormRow>
          <TextInput
            id="pet_name"
            v-model="name"
            :disabled="loading"
            label="Pet's Name"
            placeholder="e.g. Popcorn"
            rules="required"
          />
        </FormRow>
        <FormRow>
          <TextInput
            id="pet_type"
            v-model="type"
            :disabled="loading"
            label="Type of Animal"
            placeholder="e.g. Cockatiel"
            rules="required"
          />
        </FormRow>
      </FormSection>
    </Form>
  </BasePanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import BasePanel from '~/components/BasePanel';
import Form from '~/components/Form';
import FormRow from '~/components/FormRow';
import FormSection from '~/components/FormSection';
import TextInput from '~/components/TextInput';

import { pets, user } from '~/mixins/apollo';
import { objectToMetaArray } from '~/utilities';

export default {
  name: 'PetPanel',
  components: {
    BasePanel,
    Form,
    FormRow,
    FormSection,
    TextInput,
  },
  mixins: [pets, user],
  computed: {
    ...mapFields('pet', ['fields.name', 'fields.type']),
    ...mapGetters('pet', ['id', 'fields', 'showPetPanel']),
    mutation() {
      return this.id ? this.UPDATE_PET_MUTATION : this.ADD_PET_MUTATION;
    },
    variables() {
      const variables = {
        meta: objectToMetaArray(this.fields),
        willId: this.willId,
      };

      if (this.id) {
        variables.id = this.id;
      }

      return variables;
    },
  },
  methods: {
    ...mapActions('pet', ['setShowPetPanel']),
    close() {
      this.setShowPetPanel(false);
    },
    update(store, { data: { addPet } }) {
      if (addPet) {
        const data = store.readQuery(this.getPetsQuery);
        data.getPets.pets.push(addPet.pet);

        store.writeQuery({
          ...this.getPetsQuery,
          data,
        });
      }
    },
  },
};
</script>
