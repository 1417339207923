<template>
  <ModuleStep :index="2" prev-step="/assets">
    <Form
      v-slot="{ loading }"
      auto-focus
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      submit-arrow
      :submit-label="buttonText"
      @done="onDone"
    >
      <FormSection>
        <template #heading>
          Add notes about your assets and debts
        </template>
        <Tip>
          This is optional and the notes are only here to help guide your
          executor. If there is any information about your assets or liabilities
          you want your executor to know, you can add it here. If you would like
          to leave any assets to specific people, you can include them in the
          gifts module.
        </Tip>
        <TextArea
          id="asset_notes"
          v-model="willMeta.asset_note"
          :disabled="loading"
          maxlength="2500"
          placeholder="Optional"
        />
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import ModuleStep from '~/components/ModuleStep';
import TextArea from '~/components/TextArea';
import Tip from '~/components/Tip';

import { user, will } from '~/mixins/apollo';

export default {
  name: 'AssetNotes',
  components: {
    Form,
    FormSection,
    ModuleStep,
    TextArea,
    Tip,
  },
  mixins: [user, will],
  computed: {
    buttonText() {
      return this.willMeta.asset_note ? 'Next' : 'Skip';
    },
  },
  methods: {
    onDone() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✅ Module 6 (Assets)',
      });
      this.$router.push({
        path: '/dashboard',
      });
    },
  },
};
</script>
