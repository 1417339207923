<template>
  <ModuleStep :index="1" prev-step="/dashboard" :show-help="true">
    <Form
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      :auto-submit="!willMeta.has_gifts"
      submit-arrow
      @done="done"
    >
      <FormSection class="step-1">
        <template #heading>
          <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
          Would you like to leave a
          <GlossaryTooltip term="Gift"> gift </GlossaryTooltip>? 🎁
        </template>
        <p class="mb-8 sm:mb-12 text-2xl">
          Gifts are a special part of your Will. You can gift anything you own,
          whether it's a possession or an amount of money.
        </p>
        <Tip>
          Gifts are specific items you'd like to give to specific people or
          charities. Anything you don't gift will automatically go toward your
          estate.
        </Tip>
        <LargeRadioButtons
          id="has_gifts"
          v-model="willMeta.has_gifts"
          :options="[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]"
        />
      </FormSection>
      <FormSection v-if="willMeta.has_gifts" class="step-2">
        <template #heading>
          What would you like to gift?
        </template>
        <Tip>
          If you would like to gift a single item among multiple beneficiaries,
          you can enter the proportion of the item that each beneficiary should
          receive as a separate gift. For example, if you would like your
          “record collection” to be split equally between Person A and Person B,
          you would leave a gift of 50% of your record collection to Person A,
          and another gift of 50% of your record collection to Person B (Just
          make sure that it adds to 100%)!
        </Tip>
        <MetaSlot
          v-for="(gift, index) in gifts"
          :key="index"
          v-slot="{ giftMeta, personMeta, charityMeta }"
          :gift-meta="gift.meta"
          :person-meta="gift.person && gift.person.meta"
          :charity-meta="gift.charity && gift.charity.meta"
        >
          <DataCard
            :title="
              giftMeta.type === 'money'
                ? `$${giftMeta.amount}`
                : giftMeta.description
            "
            :description="personMeta.full_name || charityMeta.name"
            :icon="giftMeta.type === 'money' ? 'wallet' : 'gift'"
            edit
            remove
            type="gift"
            @edit="editGift(gift)"
            @remove="removeGift(gift)"
          >
            <BaseAlert v-if="giftMeta.note" compact type="confirm">
              <div>
                <h6>
                  You wrote:
                </h6>
                <p class="font-normal" v-text="giftMeta.note" />
              </div>
            </BaseAlert>
          </DataCard>
        </MetaSlot>
        <BaseButton
          class="btn-xl btn-white w-full add-gift"
          focusable
          @click="addGift"
        >
          + Add a gift
        </BaseButton>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapActions } from 'vuex';

import BaseAlert from '~/components/BaseAlert';
import BaseButton from '~/components/BaseButton';
import DataCard from '~/components/DataCard';
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import GlossaryTooltip from '~/components/GlossaryTooltip';
import LargeRadioButtons from '~/components/LargeRadioButtons';
import MetaSlot from '~/components/MetaSlot';
import ModuleStep from '~/components/ModuleStep';
import Tip from '~/components/Tip';

import { gifts, user, will } from '~/mixins/apollo';

export default {
  name: 'GiftsAdd',
  components: {
    BaseAlert,
    BaseButton,
    DataCard,
    Form,
    FormSection,
    GlossaryTooltip,
    LargeRadioButtons,
    MetaSlot,
    ModuleStep,
    Tip,
  },
  mixins: [gifts, user, will],
  computed: {
    isComplete() {
      return !!(this.willMeta.has_gifts === false || this.gifts.length);
    },
  },
  methods: {
    ...mapActions('gift', ['addGift', 'editGift']),
    async done() {
      if (this.willMeta.has_gifts === false && this.gifts.length) {
        await this.removeAllGifts();
      }

      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✅ Module 5 (Gifts)',
      });

      const noCharity = !this.gifts.find((g) => g.charity !== null);

      this.$nuxt.$emit('sendTrackingAttributes', {
        has_charity_gift: !noCharity,
      });

      if (noCharity) {
        this.$router.push({
          path: '/gifts/include-charity',
        });
      } else {
        this.willMeta.charity_in_gifts = 'true';
        await this.updateWillMeta();
        this.$router.push({
          path: '/dashboard',
        });
      }
    },
  },
};
</script>
