var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleStep',{attrs:{"index":3,"prev-step":"/guardians/primary"}},[_c('Form',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"hide-submit":!_vm.isComplete,"submit-arrow":""},on:{"done":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('FormSection',{staticClass:"step-1",scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        Would you like to appoint a backup guardian for your children?\n      ")]},proxy:true}],null,true)},[_vm._v(" "),_c('p',[_vm._v("\n        A backup guardian will take parental responsibility of your children\n        if your primary guardian is unable or unwilling to act.\n      ")]),_vm._v(" "),_c('Tip',[_vm._v("\n        If you have someone in mind who could act if your primary guardian is\n        unable to, it may be a good idea to add this extra layer of\n        protection.\n      ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"hasBackupGuardians","options":[
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          } ]},model:{value:(_vm.willMeta.has_backup_guardian),callback:function ($$v) {_vm.$set(_vm.willMeta, "has_backup_guardian", $$v)},expression:"willMeta.has_backup_guardian"}})],1),_vm._v(" "),(_vm.willMeta.has_backup_guardian)?_c('FormSection',{staticClass:"step-2",attrs:{"tip":"We’ll ask you to add or select people at relevant places in your Will. Their details can be edited on the People, Pets & Charities page."},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        Who would you like to appoint as backup guardian?\n      ")]},proxy:true}],null,true)},[_vm._v(" "),_c('Tip',[_vm._v("\n        Guardians must be over the age of 18.\n      ")]),_vm._v(" "),_c('PersonSelector',{attrs:{"loading":loading,"max":1,"people":_vm.filteredPeople,"type":"guardian"},model:{value:(_vm.selectedBackupGuardian),callback:function ($$v) {_vm.selectedBackupGuardian=$$v},expression:"selectedBackupGuardian"}})],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }