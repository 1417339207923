<template>
  <transition appear :css="false" @enter="enter" @leave="leave">
    <div class="fixed inset-0 z-30 px-5 bg-grey-100 overflow-y-auto">
      <TopBar :title="title">
        <BaseButton id="testClick" class="ml-auto" focusable @click="close">
          <BaseIcon id="close" />
        </BaseButton>
      </TopBar>
      <div class="w-full max-w-3xl py-16 md:py-20 mt-20 md:mt-28 mx-auto">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import anime from 'animejs';

import BaseButton from '~/components/BaseButton';
import BaseIcon from '~/components/BaseIcon';
import TopBar from '~/components/TopBar';

export default {
  name: 'BaseWizard',
  components: {
    BaseButton,
    BaseIcon,
    TopBar,
  },
  props: {
    dataType: {
      type: String,
      required: true,
    },
  },
  computed: {
    title() {
      return `Adding a ${this.dataType.toLowerCase()}`;
    },
  },
  mounted() {
    window.addEventListener('keydown', this.keyHandler);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyHandler);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    enter(el, complete) {
      anime({
        targets: el,
        opacity: [0, 1],
        duration: 300,
        easing: 'easeInOutQuad',
        complete,
      });
    },
    leave(el, complete) {
      anime({
        targets: el,
        opacity: [1, 0],
        duration: 300,
        easing: 'easeInOutQuad',
        complete,
      });
    },
    keyHandler(e) {
      switch (e.keyCode) {
        case 27: // ESC
          e.preventDefault();
          this.close();
          break;
        default:
          break;
      }
    },
  },
};
</script>
