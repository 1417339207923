var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleStep',{attrs:{"index":0,"prev-step":_vm.previous,"show-help":true}},[_c('Form',{attrs:{"hide-submit":!_vm.isComplete,"submit-arrow":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('FormSection',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        Would you like to appoint a financial decision maker?\n      ")]},proxy:true}],null,true)},[_vm._v(" "),_c('p',{staticClass:"mb-4"},[_vm._v("\n        Appoint people you trust to act on your behalf as financial decision\n        makers. This is known as creating an Enduring Power of Attorney under\n        Australian Law.\n      ")]),_vm._v(" "),_c('p',{staticClass:"mb-4"},[_vm._v("\n        Your financial decision maker will be able to act on your behalf for\n        any financial purpose, which may include:\n      ")]),_vm._v(" "),_c('ul',{staticClass:"list-disc ml-6 mb-4"},[_c('li',[_vm._v("Managing your bank accounts and investments")]),_vm._v(" "),_c('li',[_vm._v("Paying bills")]),_vm._v(" "),_c('li',[_vm._v("Submitting tax returns")]),_vm._v(" "),_c('li',[_vm._v("Buying and selling real estate")]),_vm._v(" "),_c('li',[_vm._v("Collecting rent")])]),_vm._v(" "),_c('p',{staticClass:"mb-4"},[_vm._v("\n        Please note that your financial decision maker won’t be able to act on\n        your behalf as director of a company, or trustee of a trust.\n      ")]),_vm._v(" "),_c('Tip',[_vm._v("\n        We’ll ask you about any limits or exclusions you might want to add to\n        their powers later.\n      ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"has_attorney","options":[
          {
            label: "Yes, I'd like to appoint financial decision makers",
            value: true,
          },
          {
            label: "No, I don't want to appoint any financial decision makers",
            value: false,
          } ]},on:{"change":function($event){return _vm.onSubmit()}},model:{value:(_vm.poaFinancialMeta.has_attorney),callback:function ($$v) {_vm.$set(_vm.poaFinancialMeta, "has_attorney", $$v)},expression:"poaFinancialMeta.has_attorney"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }