<template>
  <div class="window">
    <nuxt />
  </div>
</template>

<script>
export default {
  name: 'ModernLayout',
};
</script>

<style lang="scss">
.window {
  padding-top: var(--header-height);
}
</style>
