<template>
  <button
    class="opacity-0 group-hover:opacity-100 m-0 p-0 border-none bg-transparent text-red-300 underline"
    @click="showArchiveCouponModal"
  >
    Archive
  </button>
</template>

<script>
import dialog from '~/mixins/message-box';
import { coupons } from '~/mixins/apollo';

export default {
  mixins: [coupons, dialog],
  props: {
    coupon: {
      type: Object,
      required: true,
    },
  },
  methods: {
    showArchiveCouponModal() {
      this.$confirm(
        {
          title: `Archive ${this.coupon.code.toUpperCase()}?`,
          message: 'Are you sure you want to archive this coupon?',
        },
        (action) => {
          if (action) {
            this.archiveCoupon(this.coupon);
          }
        }
      );
    },
  },
};
</script>
