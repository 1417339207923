<template>
  <ModuleStep :index="0" :prev-step="previous" :show-help="true">
    <Form
      v-slot="{ loading }"
      :hide-submit="!isComplete"
      submit-arrow
      @submit.prevent="onSubmit"
    >
      <FormSection>
        <template #heading>
          Would you like to appoint a medical decision maker?
        </template>
        <p class="mb-4">
          Appoint people you trust to act on your behalf as healthcare decision
          makers. This is known as creating
          <template v-if="['nsw'].includes(userState)">
            an Enduring Guardian under New South Wales law.
          </template>
          <template v-else-if="['act'].includes(userState)">
            an Enduring Power of Attorney for Healthcare under Australian
            Capital Territory law.
          </template>
          <template v-else-if="['vic'].includes(userState)">
            an Appointment of Medical Treatment Decision Maker under Victorian
            law.
          </template>
          <template v-else-if="['nt'].includes(userState)">
            an Advance Personal Plan for Healthcare under Northern Territory
            law.
          </template>
          <template v-else-if="['sa'].includes(userState)">
            an Advance Care Directive under South Australian law.
          </template>
          <template v-else-if="['tas'].includes(userState)">
            an Enduring Guardian under Tasmanian law.
          </template>
          <template v-else-if="['wa'].includes(userState)">
            an Enduring Guardian under Western Australian law.
          </template>
          <template v-else-if="['qld'].includes(userState)">
            a medical Power of Attorney under Queensland law.
          </template>
        </p>
        <template v-if="['vic'].includes(userState)">
          <p class="mb-4">
            Your healthcare decision maker will be able to act on your behalf
            for medical or health purposes, for example, they will be able to
            decide what medical treatment you receive.
          </p>
        </template>
        <template v-else>
          <p class="mb-4">
            Your healthcare decision maker will be able to act on your behalf
            for medical or health purposes, for example:
          </p>
          <ul class="list-disc ml-6 mb-4">
            <li>
              Deciding what medical treatment you receive
            </li>
            <li>
              Deciding where you live and the types of personal services you
              receive
            </li>
          </ul>
        </template>
        <Tip>
          We’ll ask you about any limits or exclusions you might want to add to
          their powers later.
        </Tip>
        <LargeRadioButtons
          id="hasAttorney"
          v-model="poaMedicalMeta.has_attorney"
          :options="[
            {
              label: `Yes, I'd like to appoint a healthcare decision maker`,
              value: true,
            },
            {
              label: `No, I don't want to appoint a healthcare decision maker`,
              value: false,
            },
          ]"
          @change="onSubmit()"
        />
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';
import { queries, mutations } from '@/modules/apollo-queries/poa-meta';
import { prevStep, nextStep } from '@/modules/poa-flow/medical/index';
import Tip from '~/components/Tip';
import Form from '~/components/Form';
import ModuleStep from '~/components/ModuleStep';
import FormSection from '~/components/FormSection';
import LargeRadioButtons from '~/components/LargeRadioButtons';

import { user, will } from '~/mixins/apollo';

export default {
  name: 'PoaMedicalAttorneyIndex',
  components: {
    Tip,
    Form,
    ModuleStep,
    FormSection,
    LargeRadioButtons,
  },
  mixins: [user, will],
  apollo: {
    poaMedicalMeta: {
      ...queries.poaMedicalMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
  },
  data() {
    return {
      poaMedicalMeta: {
        has_attorney: undefined,
      },
    };
  },
  computed: {
    ...mapGetters(['poaId']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
        poaMedicalMeta: this.poaMedicalMeta,
      });
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    isComplete() {
      return (
        this.poaMedicalMeta &&
        (this.poaMedicalMeta.has_attorney === true ||
          this.poaMedicalMeta.has_attorney === false)
      );
    },
  },
  methods: {
    async onSubmit() {
      await this.$apollo.mutate(
        mutations.updatePoaMeta(this.poaId, {
          key: 'has_attorney',
          value: this.poaMedicalMeta.has_attorney ? 'true' : 'false',
          category: 'MEDICAL',
        })
      );

      if (!this.poaMedicalMeta.has_attorney) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '✅ Module POA medical skipped',
        });
      }

      const path = this.next;
      await this.$router.push({ path });
    },
  },
  head() {
    return {
      title: 'Medical decision maker',
    };
  },
};
</script>
