<template>
  <div class="pb-8 last-child:pb-0 sm:pb-16 sm:last-child:pb-0 text-2xl">
    <div v-if="$slots.heading" class="flex items-center mb-8">
      <h3 class="flex-grow font-medium md:font-normal text-3xl md:text-4xl">
        <slot name="heading" />
      </h3>
      <Tooltip v-if="tip" class="ml-4" :content="tip">
        <div
          class="flex justify-center items-center w-5 h-5 rounded-full bg-grey-500 text-grey-200"
        >
          <BaseGlyph id="question-mark" />
        </div>
      </Tooltip>
    </div>
    <slot />
  </div>
</template>

<script>
import BaseGlyph from '~/components/BaseGlyph';
import Tooltip from '~/components/Tooltip';

export default {
  name: 'FormSection',
  components: {
    BaseGlyph,
    Tooltip,
  },
  props: {
    tip: {
      default: null,
      type: String,
    },
  },
};
</script>
