<template>
  <nuxt-link
    class="inline-block p-3 rounded my-1 -ml-3 leading-none"
    :class="classes"
    :to="to"
    v-text="label"
  />
</template>

<script>
export default {
  name: 'AdminSideBarLink',
  props: {
    label: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
  },
  computed: {
    active() {
      return this.$route.path.includes(this.to);
    },
    classes() {
      return {
        'bg-teal-100 text-teal-300': this.active,
      };
    },
  },
};
</script>
