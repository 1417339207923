<template>
  <BaseModal v-if="showSubscriptionExpiredModal" @close="close">
    <div
      class="flex justify-center items-center w-10 h-10 rounded-full mb-4 bg-red-300 text-white"
    >
      <BaseGlyph id="exclamation" />
    </div>
    <h4 class="max-w-xs mb-4 text-3xl text-red-300">
      Your subscription has expired
    </h4>
    <div class="mb-4 text-xl">
      <p>
        Unlock your account to make updates to your Will. You can still view and
        download your existing Will if no updates are required.
      </p>
    </div>
    <Form
      submit-arrow
      submit-label="Unlock Account"
      submit-wide
      @submit="submit"
    />
  </BaseModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { renewSubscriptionHandler } from '@/mixins/subscriptions';
import subscription from '@/mixins/apollo/subscription';

import BaseGlyph from '~/components/BaseGlyph';
import BaseModal from '~/components/BaseModal';
import Form from '~/components/Form';

export default {
  name: 'SubscriptionExpiredModal',
  components: {
    BaseGlyph,
    BaseModal,
    Form,
  },
  mixins: [subscription, renewSubscriptionHandler],
  computed: {
    ...mapGetters('ui', ['showSubscriptionExpiredModal']),
    variables() {
      return {
        id: this.willId,
      };
    },
  },
  methods: {
    ...mapActions('ui', ['setShowSubscriptionExpiredModal']),
    close() {
      this.setShowSubscriptionExpiredModal(false);
    },
    submit() {
      this.close();
      this.handleRenewSubscription();
    },
  },
};
</script>
