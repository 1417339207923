<template>
  <ModuleStep :index="4" :prev-step="prevStepUrl">
    <Form
      v-if="currentBeneficiary"
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      :auto-submit="
        isComplete && currentBeneficiary.meta.estate_backup_split !== 'custom'
      "
      submit-arrow
      @done="done"
    >
      <FormSection class="step-1">
        <template #heading>
          If {{ currentBeneficiary.person.meta.full_name }} dies before you, who
          would you like to inherit their share of your estate?
        </template>
        <Tip>
          If your backups also die before you, their share will be passed to
          their children (if they have any) or equally divided amongst your
          other beneficiaries.
        </Tip>
        <EstateBackupSelector
          v-model="currentBeneficiary.meta.estate_backup_split"
        />
      </FormSection>
      <FormSection
        v-if="currentBeneficiary.meta.estate_backup_split === 'custom'"
        class="step-2"
      >
        <template #heading>
          Who should inherit {{ currentBeneficiary.person.meta.full_name }}’s
          share?
        </template>
        <p class="mb-8">
          You can select multiple backup beneficiaries. You will be able to
          select their proportions in the next step.
        </p>
        <PersonSelector
          v-model="selectedBeneficiaryIDs"
          label
          :loading="loading"
          :people="validPeople"
        />
        <h4 class="mb-4 text-2xl">
          Charities and Not-for-Profits
        </h4>
        <CharitySuggester
          v-if="showSuggestedCharities"
          v-model="selectedSuggestedCharities"
          :loading="loading"
        />
        <CharitySelector v-model="selectedBeneficiaryIDs" :loading="loading" />
        <BaseButton
          class="btn-xl btn-white w-full add-charity"
          :disabled="loading"
          focusable
          @click="addCharity"
        >
          + Add a charity
        </BaseButton>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapActions } from 'vuex';
import { metaArrayToObject } from '~/utilities';
import { isSameCharity } from '~/utilities/charity';

import BaseButton from '~/components/BaseButton';
import CharitySelector from '~/components/CharitySelector';
import CharitySuggester from '~/components/CharitySuggester';
import EstateBackupSelector from '~/components/EstateBackupSelector';
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import ModuleStep from '~/components/ModuleStep';
import PersonSelector from '~/components/PersonSelector';
import Tip from '~/components/Tip';

import { beneficiaries, charities, people, user, will } from '~/mixins/apollo';

export default {
  key(route) {
    return route.fullPath;
  },
  components: {
    BaseButton,
    CharitySelector,
    CharitySuggester,
    EstateBackupSelector,
    Form,
    FormSection,
    ModuleStep,
    PersonSelector,
    Tip,
  },
  mixins: [beneficiaries, charities, people, user, will],
  data() {
    return {
      selectedBeneficiaryIDs: [],
      selectedSuggestedCharities: [],
    };
  },
  computed: {
    showSuggestedCharities() {
      const hasReferral = !!this.willMeta.referral_charity;
      const hasAddedSuggestedCharity = this.charities.some((charity) => {
        const charityMeta = metaArrayToObject(charity.meta);
        return !!charityMeta.suggestionLocation;
      });
      return !hasReferral && !hasAddedSuggestedCharity;
    },
    currentBeneficiary() {
      return this.beneficiaries.find((b) => b.id === this.$route.params.id);
    },
    currentIndex() {
      return this.backupQueue.findIndex((b) => b.id === this.$route.params.id);
    },
    prevStepUrl() {
      const beneficiary = this.backupQueue[this.currentIndex - 1];
      return beneficiary
        ? `/estate/backup-beneficiaries/${beneficiary.id}`
        : '/estate/beneficiaries';
    },
    isComplete() {
      return !!(
        this.currentBeneficiary.meta.estate_backup_split &&
        (this.currentBeneficiary.meta.estate_backup_split !== 'custom' ||
          this.selectedBeneficiaryIDs.length ||
          this.selectedSuggestedCharities.length)
      );
    },
    validPeople() {
      return this.people.filter(
        (person) =>
          person.id !==
          (this.currentBeneficiary && this.currentBeneficiary.person.id)
      );
    },
  },
  watch: {
    beneficiaries(beneficiaries) {
      if (!this.currentBeneficiary) return;

      if (
        this.currentBeneficiary.meta.estate_backup_split === 'custom' &&
        this.beneficiaries.length &&
        !this.selectedBeneficiaryIDs.length
      ) {
        const people = this.people
          .filter((person) => {
            return this.beneficiaries.find(
              (beneficiary) =>
                beneficiary.person &&
                beneficiary.person.id === person.id &&
                beneficiary.person.id !== this.currentBeneficiary.person.id
            );
          })
          .map((person) => person.id);

        const charities = this.charities
          .filter((charity) => {
            return this.beneficiaries.find(
              (beneficiary) =>
                beneficiary.charity && beneficiary.charity.id === charity.id
            );
          })
          .map((charity) => charity.id);

        this.selectedBeneficiaryIDs = people.concat(charities);
      }
    },
  },
  methods: {
    ...mapActions('charity', ['addCharity']),
    metaArrayToObject,
    async done() {
      let availableDistribution = 100;
      let defaultDistribution;

      if (this.selectedSuggestedCharities.length) {
        await Promise.all(
          this.selectedSuggestedCharities.map((charity) => {
            return this.addPartnerCharity(charity);
          })
        );
        await this.refetchCharities();
        this.charities.forEach((charity) => {
          const charityMeta = metaArrayToObject(charity.meta);
          const exists = this.selectedSuggestedCharities.find(
            (selectedSuggestedCharity) => {
              return isSameCharity(selectedSuggestedCharity, charityMeta);
            }
          );
          if (exists) {
            this.selectedBeneficiaryIDs.push(charity.id);
          }
        });
      }

      // Save estate backup split meta data.
      await this.updateBeneficiary(
        this.currentBeneficiary,
        this.currentBeneficiary.distribution
      );

      switch (this.currentBeneficiary.meta.estate_backup_split) {
        case 'remaining':
        case 'children':
          for (const charity of this.charities) {
            await this.removeBackupBeneficiaryByCharity(
              this.currentBeneficiary,
              charity
            );
          }

          for (const person of this.people) {
            if (!this.isChild(person) && !this.isPartner(person)) {
              await this.removeBackupBeneficiaryByPerson(
                this.currentBeneficiary,
                person
              );
            }
          }

          break;
        case 'custom': {
          const existingBeneficiaries = this.currentBeneficiary.backup
            ? this.currentBeneficiary.backup.filter((beneficiary) => {
                return this.selectedBeneficiaryIDs.find((id) => {
                  return (
                    (beneficiary.charity && beneficiary.charity.id === id) ||
                    (beneficiary.person && beneficiary.person.id === id)
                  );
                });
              })
            : [];

          existingBeneficiaries.forEach((beneficiary) => {
            availableDistribution -= parseFloat(beneficiary.distribution);
          });

          defaultDistribution =
            availableDistribution /
            (this.selectedBeneficiaryIDs.length - existingBeneficiaries.length);

          for (const person of this.people) {
            const beneficiary = this.getBackupBeneficiaryByPerson(
              this.currentBeneficiary,
              person
            );
            const isSelected = this.selectedBeneficiaryIDs.includes(person.id);

            if (isSelected) {
              if (!beneficiary) {
                await this.addBackupBeneficiary(
                  this.currentBeneficiary,
                  person,
                  defaultDistribution
                );
              }
            } else if (beneficiary) {
              await this.removeBackupBeneficiary(beneficiary);
            }
          }

          for (const charity of this.charities) {
            const beneficiary = this.getBackupBeneficiaryByCharity(
              this.currentBeneficiary,
              charity
            );
            const isSelected = this.selectedBeneficiaryIDs.includes(charity.id);

            if (isSelected) {
              if (!beneficiary) {
                await this.addBackupBeneficiary(
                  this.currentBeneficiary,
                  charity,
                  defaultDistribution
                );
              }
            } else if (beneficiary) {
              await this.removeBackupBeneficiary(beneficiary);
            }
          }

          break;
        }
        default:
          break;
      }

      if (this.currentBeneficiary.meta.estate_backup_split === 'custom') {
        this.$router.push({
          path: `/estate/backup-distribution/${
            this.backupQueue[this.currentIndex].id
          }`,
        });
      } else if (this.currentIndex < this.backupQueue.length - 1) {
        this.$router.push({
          path: `/estate/backup-beneficiaries/${
            this.backupQueue[this.currentIndex + 1].id
          }`,
        });
      } else {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '✅ Module 4 (Estate)',
        });

        this.$router.push({
          path: '/dashboard',
        });
      }
    },
  },
};
</script>
