var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleStep',{attrs:{"index":1,"prev-step":"/dashboard"}},[_c('Form',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"hide-submit":!_vm.isComplete,"submit-arrow":""},on:{"done":_vm.done}},[_c('FormSection',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        Would you consider leaving a gift to a charity or not-for-profit?\n      ")]},proxy:true}])},[_vm._v(" "),_c('p',[_vm._v("\n        Many of our users choose to leave a gift to charity as part of their\n        Will. You can gift either a sum of money, or property.\n      ")]),_vm._v(" "),_c('LargeRadioButtons',{staticClass:"mt-8",attrs:{"id":"has_gifts","options":[
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          } ]},model:{value:(_vm.willMeta.charity_in_gifts),callback:function ($$v) {_vm.$set(_vm.willMeta, "charity_in_gifts", $$v)},expression:"willMeta.charity_in_gifts"}})],1),_vm._v(" "),(_vm.willMeta.charity_in_gifts)?_c('FormSection',[_c('p',{staticClass:"mb-4"},[_vm._v("Gifts")]),_vm._v(" "),_vm._l((_vm.gifts),function(gift,index){return _c('MetaSlot',{key:index,attrs:{"gift-meta":gift.meta,"person-meta":gift.person && gift.person.meta,"charity-meta":gift.charity && gift.charity.meta},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var giftMeta = ref.giftMeta;
        var personMeta = ref.personMeta;
        var charityMeta = ref.charityMeta;
return [_c('DataCard',{attrs:{"title":giftMeta.type === 'money'
              ? ("$" + (giftMeta.amount))
              : giftMeta.description,"description":personMeta.full_name || charityMeta.name,"icon":giftMeta.type === 'money' ? 'wallet' : 'gift',"edit":"","remove":"","type":"gift"},on:{"edit":function($event){return _vm.editGift(gift)},"remove":function($event){return _vm.removeGift(gift)}}},[(giftMeta.note)?_c('BaseAlert',{attrs:{"compact":"","type":"confirm"}},[_c('div',[_c('h6',[_vm._v("You wrote:")]),_vm._v(" "),_c('p',{staticClass:"font-normal",domProps:{"textContent":_vm._s(giftMeta.note)}})])]):_vm._e()],1)]}}],null,true)})}),_vm._v(" "),_c('BaseButton',{staticClass:"btn-xl btn-white w-full add-gift",attrs:{"focusable":""},on:{"click":_vm.addGift}},[_vm._v("\n        + Add a gift\n      ")])],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }