var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleStep',{attrs:{"index":1,"prev-step":"/dashboard","show-help":true}},[_c('Form',{attrs:{"auto-focus":"","mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"hide-submit":!_vm.isComplete,"submit-arrow":""},on:{"done":_vm.onDone},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('h1',{staticClass:"font-medium md:font-normal leading-tight text-4xl md:text-5xl lg:text-5xl mb-6"},[_vm._v("\n      Let's start to write\n      "),_c('Squiggle',[_vm._v("your Will.")])],1),_vm._v(" "),_c('FormSection',{staticClass:"step-1",scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("What's your full name?")]},proxy:true}],null,true)},[_vm._v(" "),_c('FormRow',[_c('TextInput',{attrs:{"id":"name_first","disabled":loading,"placeholder":"Your first name","rules":"required"},model:{value:(_vm.willMeta.name_first),callback:function ($$v) {_vm.$set(_vm.willMeta, "name_first", $$v)},expression:"willMeta.name_first"}})],1),_vm._v(" "),_c('FormRow',[_c('TextInput',{attrs:{"id":"name_middle","disabled":loading,"placeholder":"Your middle name (optional)"},model:{value:(_vm.willMeta.name_middle),callback:function ($$v) {_vm.$set(_vm.willMeta, "name_middle", $$v)},expression:"willMeta.name_middle"}})],1),_vm._v(" "),_c('FormRow',[_c('TextInput',{attrs:{"id":"name_last","disabled":loading,"placeholder":"Your last name","rules":"required"},model:{value:(_vm.willMeta.name_last),callback:function ($$v) {_vm.$set(_vm.willMeta, "name_last", $$v)},expression:"willMeta.name_last"}})],1)],1),_vm._v(" "),_c('FormSection',{staticClass:"step-2",scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("Have you ever been known by another name?")]},proxy:true}],null,true)},[_vm._v(" "),_c('Tip',[_vm._v("\n        e.g. A maiden name or if you’ve legally changed your name before.\n      ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"has_alt_name","options":[
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          } ]},model:{value:(_vm.willMeta.has_alt_name),callback:function ($$v) {_vm.$set(_vm.willMeta, "has_alt_name", $$v)},expression:"willMeta.has_alt_name"}})],1),_vm._v(" "),(_vm.willMeta.has_alt_name)?_c('FormSection',{staticClass:"step-3",scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("What was your other name?")]},proxy:true}],null,true)},[_vm._v(" "),_c('FormRow',[_c('TextInput',{attrs:{"id":"alt_name_first","disabled":loading,"placeholder":"Your first name","rules":"required"},model:{value:(_vm.willMeta.alt_name_first),callback:function ($$v) {_vm.$set(_vm.willMeta, "alt_name_first", $$v)},expression:"willMeta.alt_name_first"}})],1),_vm._v(" "),_c('FormRow',[_c('TextInput',{attrs:{"id":"alt_name_middle","disabled":loading,"placeholder":"Your middle name (optional)"},model:{value:(_vm.willMeta.alt_name_middle),callback:function ($$v) {_vm.$set(_vm.willMeta, "alt_name_middle", $$v)},expression:"willMeta.alt_name_middle"}})],1),_vm._v(" "),_c('FormRow',[_c('TextInput',{attrs:{"id":"alt_name_last","disabled":loading,"placeholder":"Your last name","rules":"required"},model:{value:(_vm.willMeta.alt_name_last),callback:function ($$v) {_vm.$set(_vm.willMeta, "alt_name_last", $$v)},expression:"willMeta.alt_name_last"}})],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }