<template>
  <div
    class="flex items-center cursor-pointer"
    @click="setPaymentMethod(value)"
  >
    <svg
      class="svg relative inline-block mr-2"
      preserveAspectRatio="xMidYMid meet"
      height="30px"
      width="30px"
      viewBox="0 0 30 30"
    >
      <circle
        class="outline"
        cx="15"
        cy="15"
        r="13"
        fill="#EDF0EF"
        stroke="#EDF0EF"
        stroke-width="2"
      />
      <circle class="core" cx="15" cy="15" r="8" :fill="fillColor" />
    </svg>
    <slot />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('checkout', ['paymentMethod']),
    fillColor() {
      return this.paymentMethod === this.value ? '#00DABF' : '#EDF0EF';
    },
  },
  methods: {
    ...mapMutations('checkout', ['setPaymentMethod']),
  },
};
</script>
