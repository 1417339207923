<template>
  <ModuleStep :index="5" :prev-step="prevStepUrl">
    <Form
      v-if="validBeneficiaries"
      :hide-submit="!isComplete"
      submit-arrow
      @submit="submit"
    >
      <FormSection v-if="currentBeneficiary">
        <template #heading>
          If {{ currentBeneficiary.person.meta.full_name }} dies before you, how
          would you like to divide their share?
        </template>
        <Tip>
          Proportions must add up to 100%
        </Tip>
        <BeneficiaryInputs
          v-model="distributions"
          :beneficiaries="validBeneficiaries"
          @valid="checkValid"
        />
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import ModuleStep from '~/components/ModuleStep';
import BeneficiaryInputs from '~/components/BeneficiaryInputs';
import Tip from '~/components/Tip';

import { beneficiaries, user, will } from '~/mixins/apollo';

export default {
  key(route) {
    return route.fullPath;
  },
  components: {
    Form,
    FormSection,
    ModuleStep,
    BeneficiaryInputs,
    Tip,
  },
  mixins: [beneficiaries, user, will],
  data() {
    return {
      distributions: [],
      valid: false,
    };
  },
  computed: {
    currentBeneficiary() {
      return this.beneficiaries.find((b) => b.id === this.$route.params.id);
    },
    currentIndex() {
      return this.backupQueue.findIndex((b) => b.id === this.$route.params.id);
    },
    prevStepUrl() {
      const beneficiary = this.backupQueue[this.currentIndex];
      return beneficiary
        ? `/estate/backup-beneficiaries/${beneficiary.id}`
        : '/estate/beneficiaries';
    },
    isComplete() {
      return !!this.valid;
    },
    validBeneficiaries() {
      return (
        this.currentBeneficiary &&
        this.currentBeneficiary.backup.filter((beneficiary) => {
          if (beneficiary.person && this.currentBeneficiary) {
            return beneficiary.person.id !== this.currentBeneficiary.person.id;
          }
          return true;
        })
      );
    },
  },
  methods: {
    checkValid(valid) {
      this.valid = valid;
    },
    async submit() {
      await Promise.all(
        this.currentBeneficiary.backup.map((beneficiary, i) => {
          return this.updateBackupBeneficiary(
            beneficiary,
            this.distributions[i]
          );
        })
      );
      if (this.currentIndex < this.backupQueue.length - 1) {
        this.$router.push({
          path: `/estate/backup-beneficiaries/${
            this.backupQueue[this.currentIndex + 1].id
          }`,
        });
      } else {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '✅ Module 4 (Estate)',
        });
        this.$router.push({
          path: '/dashboard',
        });
      }
    },
  },
};
</script>
