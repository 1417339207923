<template>
  <div
    class="relative inline-flex flex-col items-start mt-16 lg:w-70 p-6 lg:px-10 lg:py-12 bg-white shadow-md xl:shadow-lg text-base text-grey-200 select-none cursor-default"
  >
    <!-- Masthead -->
    <div class="h-4 lg:h-6 mb-4 lg:mb-8" style="max-width: 20px;">
      <Logo class="h-4 lg:h-6" />
    </div>
    <!-- Text -->
    <div class="mb-4 lg:mb-8">
      <svg
        class="w-full"
        viewBox="0 0 202 196"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          ref="lines"
          class="stroke-current"
          fill="none"
          stroke-width="5"
          stroke-linecap="round"
        >
          <path d="M3 2.5h36.702" />
          <path d="M3 32.5h196.022" />
          <path d="M3 45.5h196.022" />
          <path d="M3 58.5h196.022" />
          <path d="M3 71.5h196.022" />
          <path d="M3 84.5h196.022" />
          <path d="M3 97.5h196.022" />
          <path d="M3 127.5h100.01" />
          <path d="M3 154.5h196.022" />
          <path d="M3 167.5h196.022" />
          <path d="M3 180.5h196.022" />
          <path d="M3 193.5h86.001" />
        </g>
      </svg>
    </div>
    <!-- Signature -->
    <div class="mb-1 lg:mb-3 text-teal-200">
      <svg class="w-1/3" viewBox="0 0 73 23" xmlns="http://www.w3.org/2000/svg">
        <path
          ref="signature"
          class="stroke-current"
          d="M1.445 14.263C6.69 10.617 11.751 6.69 17.178 3.327c1.389-.86 4.696-3.302 4.605-1.667-.148 2.66-3.345 4.14-4.864 6.325-1.915 2.755-3.314 5.874-5.427 8.48-.476.586-.125-1.664.364-2.238 1.252-1.468 2.866-2.643 4.567-3.546a5.254 5.254 0 0 1 3.139-.542c.703.1 1.163 1.044-.405 4.003-1.076 2.031-2.453 3.899-3.885 5.695-.468.588-1.432 2.071-1.766 1.397-1.558-3.146 7.547-10.82 8.798-9.821 1.876 1.5.312 5.935 4.008 5.546 4.345-.458 9.063-4.652 12.869-2.498l1.52-.023c4.503 2.548-1.118 3.398 7.426 2.472 1.969-.213 6.942-2.508 9.536-2.07 4.705.792 9.46 4.924 14.22.306"
          fill="none"
          stroke-width="2"
        />
      </svg>
    </div>
    <!-- Magnifying Glass -->
    <div
      ref="lens"
      class="absolute top-0 left-0 w-10 lg:w-20 h-10 lg:h-20 mt-16 lg:mt-24 -ml-5 lg:-ml-10 rounded-full whitespace-no-wrap"
    >
      <div class="flex items-center h-full rounded-full">
        <span
          ref="text"
          class="inline-block ml-20 font-serif text-small text-grey-300 bg-white"
        >
          The last Will and testament of&nbsp;
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import anime from 'animejs';

import Logo from '~/components/Logo';

export default {
  name: 'BaseWill',
  components: {
    Logo,
  },
  // props: {
  //   action: {
  //     type: String,
  //     required: true
  //   }
  // },
  data() {
    return {
      review: null,
      sign: null,
      show: null,
    };
  },
  mounted() {
    this.show = anime.timeline({
      autoplay: true,
    });

    this.show
      .add({
        targets: this.$el,
        translateY: {
          value: [90, 0],
          duration: 850,
          easing: 'easeOutQuart',
        },
        opacity: {
          value: [0, 1],
          duration: 250,
          easing: 'easeInOutQuad',
        },
        translateZ: 0,
      })
      .add(
        {
          targets: this.$refs.lines.children,
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 650,
          easing: 'easeInOutQuad',
          delay: anime.stagger(40),
        },
        '-=800'
      );

    this.sign = anime.timeline({ autoplay: true });

    this.sign.add({
      targets: this.$refs.signature,
      strokeDashoffset: [anime.setDashoffset, 0],
      duration: 1000,
      easing: 'easeInOutQuad',
    });

    this.review = anime.timeline({
      autoplay: true,
    });

    // this.review
    //   .add({
    //     targets: this.$refs.lens,
    //     translateX: [-50, 0],
    //     translateY: [30, 0],
    //     translateZ: 0,
    //     rotate: [-20, 0],
    //     opacity: {
    //       value: [0, 1],
    //       duration: 250,
    //       easing: 'easeInOutQuad'
    //     },
    //     transformOrigin: 'bottom right',
    //     easing: 'easeOutCubic',
    //     duration: 650
    //   })
    //   .add({
    //     targets: this.$refs.text,
    //     opacity: [0, 1],
    //     duration: 1
    //   })
    //   .add({
    //     targets: this.$refs.lens,
    //     translateX: [0, `${this.$el.offsetWidth}px`],
    //     translateZ: 0,
    //     duration: 3000,
    //     easing: 'easeInOutQuad'
    //   })
    //   .add({
    //     targets: this.$refs.text,
    //     translateX: '-100%',
    //     translateZ: 0,
    //     duration: 3000,
    //     easing: 'easeInOutQuad'
    //   }, '-=3000')
  },
};
</script>
