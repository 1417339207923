<template>
  <ModuleStep :index="1" prev-step="/dashboard">
    <Form
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      submit-arrow
      @done="done"
    >
      <FormSection>
        <template #heading>
          Would you consider leaving a gift to a charity or not-for-profit?
        </template>
        <p>
          Many of our users choose to leave a gift to charity as part of their
          Will. You can gift either a sum of money, or property.
        </p>
        <LargeRadioButtons
          id="has_gifts"
          v-model="willMeta.charity_in_gifts"
          class="mt-8"
          :options="[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]"
        />
      </FormSection>
      <FormSection v-if="willMeta.charity_in_gifts">
        <p class="mb-4">Gifts</p>
        <MetaSlot
          v-for="(gift, index) in gifts"
          :key="index"
          v-slot="{ giftMeta, personMeta, charityMeta }"
          :gift-meta="gift.meta"
          :person-meta="gift.person && gift.person.meta"
          :charity-meta="gift.charity && gift.charity.meta"
        >
          <DataCard
            :title="
              giftMeta.type === 'money'
                ? `$${giftMeta.amount}`
                : giftMeta.description
            "
            :description="personMeta.full_name || charityMeta.name"
            :icon="giftMeta.type === 'money' ? 'wallet' : 'gift'"
            edit
            remove
            type="gift"
            @edit="editGift(gift)"
            @remove="removeGift(gift)"
          >
            <BaseAlert v-if="giftMeta.note" compact type="confirm">
              <div>
                <h6>You wrote:</h6>
                <p class="font-normal" v-text="giftMeta.note" />
              </div>
            </BaseAlert>
          </DataCard>
        </MetaSlot>
        <BaseButton
          class="btn-xl btn-white w-full add-gift"
          focusable
          @click="addGift"
        >
          + Add a gift
        </BaseButton>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapActions } from 'vuex';

import BaseAlert from '~/components/BaseAlert';
import BaseButton from '~/components/BaseButton';
import DataCard from '~/components/DataCard';
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import LargeRadioButtons from '~/components/LargeRadioButtons';
import MetaSlot from '~/components/MetaSlot';
import ModuleStep from '~/components/ModuleStep';

import { gifts, user, will } from '~/mixins/apollo';

export default {
  name: 'GiftsAdd',
  components: {
    BaseAlert,
    BaseButton,
    DataCard,
    Form,
    FormSection,
    LargeRadioButtons,
    MetaSlot,
    ModuleStep,
  },
  mixins: [gifts, user, will],
  computed: {
    isComplete() {
      return !!(
        this.gifts.find((g) => g.charity !== null) ||
        !this.willMeta.charity_in_gifts
      );
    },
  },
  methods: {
    ...mapActions('gift', ['addGift', 'editGift']),
    async done() {
      if (this.willMeta.charity_in_gifts) {
        this.willMeta.has_gifts = true;
        await this.updateWillMeta();
      }
      if (this.willMeta.has_gifts === false && this.gifts.length) {
        await this.removeAllGifts();
      }

      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✅ Module 5 (Gifts)',
      });
      this.$nuxt.$emit('sendTrackingAttributes', {
        has_charity_gift:
          this.willMeta.charity_in_gifts || this.willMeta.charity_in_estate,
      });

      this.$router.push({
        path: '/dashboard',
      });
    },
  },
};
</script>
