<template>
  <Module title="Medical POA" :total-steps="10">
    <nuxt-child />
  </Module>
</template>
<script>
import Module from '~/components/Module';
export default {
  name: 'MedicalPoa',
  layout: 'module',
  middleware: ['authenticated'],
  components: {
    Module,
  },
};
</script>
