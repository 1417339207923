<template>
  <div>
    <slot />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Module',
  props: {
    title: {
      type: String,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.setTitle(this.title);
    this.setTotalSteps(this.totalSteps);
  },
  methods: {
    ...mapActions('modules', ['setTitle', 'setTotalSteps']),
  },
};
</script>
