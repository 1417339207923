<template>
  <div class="flex first-child:mt-0 last-child:mb-0" :class="classes.wrapper">
    <div
      class="content-box flex-shrink-0 w-px mr-4 border border-grey-300 bg-grey-300"
    />
    <div class="flex-grow" :class="classes.content">
      <Chip label="Tip" />
      <p class="text-base" :class="classes.text">
        <slot />
      </p>
    </div>
  </div>
</template>

<script>
import Chip from '~/components/Chip';

export default {
  name: 'Tip',
  components: {
    Chip,
  },
  props: {
    large: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    classes() {
      return {
        content: {
          'md:flex md:items-center': this.large,
        },
        text: {
          'mt-3 md:mt-0 md:ml-3 md:text-xl': this.large,
          'mt-3 opacity-75 text-charcoal-200': !this.large,
        },
        wrapper: {
          'my-10': this.large,
          'mt-6 mb-10': !this.large,
        },
      };
    },
  },
};
</script>
