<template>
  <Module title="About Yourself" :total-steps="5">
    <nuxt-child />
  </Module>
</template>

<script>
import Module from '~/components/Module';

export default {
  name: 'AboutYourself',
  layout: 'module',
  middleware: ['authenticated'],
  components: {
    Module,
  },
  fetch({ redirect, route }) {
    if (route.path === '/about-yourself') redirect(301, '/about-yourself/name');
  },
};
</script>
