<template>
  <ModuleStep :index="2" :prev-step="previous" :show-help="true">
    <Form submit-arrow :hide-submit="!isComplete" @submit.prevent="onSubmit">
      <FormSection>
        <template #heading>
          Would you like your financial decision makers to act jointly or
          severally?
        </template>
        <p class="mb-4">
          Jointly means that all decision makers must agree and act unanimously.
        </p>
        <p class="mb-4">
          Severally means that your decision makers can act unanimously, but
          they can also act separately.
        </p>
        <template v-if="['vic', 'qld', 'act'].includes(userState)">
          <p class="mb-4">
            For either option, if one attorney loses their capacity to act, the
            appointment of the other attorneys will not be affected.
          </p>
          <Tip>
            <p class="mb-3">Each choice has pros and cons.</p>
            <p class="mb-3">
              Jointly ensures that you get more thorough decisions agreed on by
              all decision makers (although please note that the appointment
              will be terminated if any of your attorneys are unable to act).
            </p>
            <p class="mb-3">
              Severally gives them more flexibility but decisions may not
              involve everyone (e.g. if one person travels a lot and won’t be
              able to participate in every decision).
            </p>
          </Tip>
        </template>
        <template v-else-if="['tas'].includes(userState)">
          <Tip>
            <p class="mb-3">Each choice has pros and cons.</p>
            <p class="mb-3">
              Jointly ensures that you get more thorough decisions agreed on by
              all decision makers (although please note that the appointment
              will be terminated if any of your attorneys are unable to act).
            </p>
            <p class="mb-3">
              Severally gives them more flexibility but decisions may not
              involve everyone (e.g. if one person travels a lot and won’t be
              able to participate in every decision).
            </p>
          </Tip>
        </template>
        <template v-else-if="['nsw', 'wa', 'sa', 'nt'].includes(userState)">
          <Tip>
            <p class="mb-3">
              Each choice has pros and cons. Jointly ensures that you get more
              thorough decisions agreed on by all decision makers, whilst
              severally gives them more flexibility (e.g. if one person travels
              a lot and won’t be able to participate in every decision).
            </p>
          </Tip>
        </template>
        <FormRow class="flex-wrap">
          <LargeRadioButtons
            id="financial_attorney_decisions"
            v-model="poaFinancialMeta.attorney_decisions"
            :options="[
              {
                label: `Jointly — all decision makers must agree`,
                value: 'jointly',
              },
              {
                label: `Severally — decision makers can also act on their own`,
                value: 'jointly and severally',
              },
            ]"
          />
        </FormRow>
      </FormSection>
      <FormSection v-if="eligibleForOtherDecision">
        <template #heading>
          If one of your joint decision makers dies, loses capacity or otherwise
          cannot act:
        </template>
        <Tip>
          For example, if one decision maker becomes unwell or dies, and is no
          longer capable of acting on your behalf.
        </Tip>
        <FormRow class="flex-wrap">
          <LargeRadioButtons
            id="financial_attorney_decisions_other"
            v-model="poaFinancialMeta.attorney_decisions_other"
            :options="[
              {
                label: `My other decision maker(s) can act without them`,
                value: 'other',
              },
              {
                label: `None of my other decision makers can act`,
                value: 'none',
              },
            ]"
          />
        </FormRow>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';
import { queries, mutations } from '@/modules/apollo-queries/poa-meta';
import { prevStep, nextStep } from '@/modules/poa-flow/financial/index';
import Tip from '~/components/Tip';
import Form from '~/components/Form';
import FormRow from '~/components/FormRow';
import LargeRadioButtons from '~/components/LargeRadioButtons';
import ModuleStep from '~/components/ModuleStep';
import FormSection from '~/components/FormSection';

import { user, will } from '~/mixins/apollo';

export default {
  name: 'PoaFinancialPrimaryDecisions',
  components: {
    Tip,
    Form,
    FormRow,
    LargeRadioButtons,
    ModuleStep,
    FormSection,
  },
  mixins: [user, will],
  apollo: {
    poaFinancialMeta: {
      ...queries.poaFinancialMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
  },
  data() {
    return {
      poaFinancialMeta: {
        attorney_decisions: undefined,
        attorney_decisions_other: undefined,
      },
    };
  },
  computed: {
    ...mapGetters(['poaId']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
        addressState: this.userState,
      });
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    isComplete() {
      const completedMainQuestion =
        this.poaFinancialMeta && this.poaFinancialMeta.attorney_decisions;
      const completedOtherQuestion =
        !this.eligibleForOtherDecision ||
        this.poaFinancialMeta.attorney_decisions_other;
      return completedMainQuestion && completedOtherQuestion;
    },
    eligibleForOtherDecision() {
      return (
        ['nsw', 'sa', 'nt'].includes(this.userState) &&
        this.poaFinancialMeta.attorney_decisions === 'jointly'
      );
    },
  },
  methods: {
    async onSubmit() {
      await this.$apollo.mutate(
        mutations.updatePoaMetas(this.poaId, [
          {
            key: 'attorney_decisions',
            value: this.poaFinancialMeta.attorney_decisions,
            category: 'FINANCIAL',
          },
          {
            key: 'attorney_decisions_other',
            value: this.poaFinancialMeta.attorney_decisions_other,
            category: 'FINANCIAL',
          },
        ])
      );

      const path = this.next;
      await this.$router.push({ path });
    },
  },
  head() {
    return {
      title: 'Primary financial decision makers',
    };
  },
};
</script>
