<template>
  <BasePanel v-if="showGlossaryPanel" title="Glossary" @close="close">
    <div
      v-for="(term, index) in sortedTerms"
      :key="index"
      :class="{ 'mt-12': index > 0 }"
    >
      <h4 class="mb-4 text-2xl leading-none" v-text="term" />
      <p class="text-charcoal-200 opacity-75" v-text="terms[term]" />
    </div>
  </BasePanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import BasePanel from '~/components/BasePanel';

export default {
  name: 'GlossaryPanel',
  components: {
    BasePanel,
  },
  computed: {
    ...mapGetters('glossary', ['showGlossaryPanel', 'terms']),
    sortedTerms() {
      return Object.keys(this.terms).sort();
    },
  },
  methods: {
    ...mapActions('glossary', ['setShowGlossaryPanel']),
    close() {
      this.setShowGlossaryPanel(false);
    },
  },
};
</script>
