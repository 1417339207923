<template>
  <ModuleStep :index="6" prev-step="/guardians/pet-guardians">
    <Form
      v-slot="{ loading }"
      auto-focus
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      submit-arrow
      :submit-label="submitLabel"
      @done="onDone"
    >
      <FormSection>
        <template #heading>
          Would you like to leave a pet care fund?
        </template>
        <Tip>
          Pet care funds can cover things like pet food and other costs to care
          for your pet. Executors will typically do their best to make this
          happen, but it won’t be legally binding.
        </Tip>
        <MoneyInput
          id="pet_care_fund"
          v-model="willMeta.pet_care_fund"
          :disabled="loading"
          rules="max_value:999999999"
        />
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import ModuleStep from '~/components/ModuleStep';
import MoneyInput from '~/components/MoneyInput';
import Tip from '~/components/Tip';

import { user, will } from '~/mixins/apollo';

export default {
  name: 'GuardiansPetCareFund',
  components: {
    MoneyInput,
    Form,
    FormSection,
    ModuleStep,
    Tip,
  },
  mixins: [user, will],
  computed: {
    submitLabel() {
      return this.willMeta.pet_care_fund ? 'Next' : 'Skip';
    },
  },
  methods: {
    onDone() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✅ Module 2 (Guardians)',
      });
      this.$router.push({
        path: '/dashboard',
      });
    },
  },
};
</script>
