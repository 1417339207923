<template>
  <ModuleStep :prev-step="previous" :index="2" :show-help="true">
    <Form
      v-slot="{ loading }"
      submit-arrow
      :hide-submit="!isComplete"
      @submit.prevent="onSubmit"
    >
      <FormSection v-if="askAttorneyDecisions">
        <template #heading>
          Would you like your healthcare decision makers to act jointly or
          severally?
        </template>
        <p class="mb-4">
          Jointly means that all decision makers must agree and act unanimously.
        </p>
        <p class="mb-4">
          Severally means that your decision makers can act unanimously, but
          they can also act separately.
        </p>
        <p v-if="['qld', 'act', 'sa'].includes(userState)" class="mb-4">
          For either option, if one attorney loses their capacity to act, the
          appointment of the other attorneys will not be affected.
        </p>
        <Tip>
          Each choice has pros and cons. Jointly ensures that you get more
          thorough decisions agreed on by all decision makers, whilst severally
          gives them more flexibility (e.g. if one person travels a lot and
          won’t be able to participate in every decision).
        </Tip>
        <FormRow class="flex-wrap">
          <LargeRadioButtons
            id="medical_attorney_decisions"
            v-model="poaMedicalMeta.attorney_decisions"
            :options="[
              {
                label: `Jointly — all decision makers must agree`,
                value: 'jointly',
              },
              {
                label: `Severally — decision makers can also act on their own`,
                value: 'jointly and severally',
              },
            ]"
          />
        </FormRow>
      </FormSection>
      <FormSection v-if="askAttorneyDecisionsOther">
        <template #heading>
          If one of your joint decision makers dies, loses capacity or otherwise
          cannot act:...
        </template>
        <Tip>
          For example, if one decision maker becomes unwell or dies, and is no
          longer capable of acting on your behalf.
        </Tip>
        <FormRow class="flex-wrap">
          <LargeRadioButtons
            id="medical_attorney_decisions_other"
            v-model="poaMedicalMeta.attorney_decisions_other"
            :options="[
              {
                label: `My other decision maker(s) can act without them`,
                value: 'other',
              },
              {
                label: `None of my other decision makers can act`,
                value: 'none',
              },
            ]"
          />
        </FormRow>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';
import { queries, mutations } from '@/modules/apollo-queries/poa-meta';
import { prevStep, nextStep } from '@/modules/poa-flow/medical/index';
import Tip from '~/components/Tip';
import Form from '~/components/Form';
import FormRow from '~/components/FormRow';
import LargeRadioButtons from '~/components/LargeRadioButtons';
import ModuleStep from '~/components/ModuleStep';
import FormSection from '~/components/FormSection';

import { user, will } from '~/mixins/apollo';

export default {
  name: 'PoaMedicalPrimaryDecisions',
  components: {
    Form,
    FormRow,
    LargeRadioButtons,
    ModuleStep,
    FormSection,
    Tip,
  },
  mixins: [user, will],
  apollo: {
    poaMedicalMeta: {
      ...queries.poaMedicalMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
  },
  data() {
    return {
      poaMedicalMeta: {
        attorney_decisions: undefined,
        attorney_decisions_other: undefined,
        has_substitute: undefined,
      },
    };
  },
  computed: {
    ...mapGetters(['poaId']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
        poaMedicalMeta: this.poaMedicalMeta,
        addressState: this.userState,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
        addressState: this.userState,
      });
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    isComplete() {
      const completedMainQuestion =
        !this.askAttorneyDecisions || this.poaMedicalMeta.attorney_decisions;
      const completedOtherQuestion =
        !this.askAttorneyDecisionsOther ||
        this.poaMedicalMeta.attorney_decisions_other;
      return completedMainQuestion && completedOtherQuestion;
    },
    askAttorneyDecisions() {
      return ['nsw', 'qld', 'act', 'sa', 'nt'].includes(this.userState);
    },
    askAttorneyDecisionsOther() {
      return (
        ['tas', 'wa'].includes(this.userState) ||
        (['nsw', 'nt', 'tas'].includes(this.userState) &&
          this.poaMedicalMeta.attorney_decisions === 'jointly')
      );
    },
  },
  methods: {
    async onSubmit() {
      await this.$apollo.mutate(
        mutations.updatePoaMetas(this.poaId, [
          {
            key: 'attorney_decisions',
            value: this.poaMedicalMeta.attorney_decisions,
            category: 'MEDICAL',
          },
          {
            key: 'attorney_decisions_other',
            value: this.poaMedicalMeta.attorney_decisions_other,
            category: 'MEDICAL',
          },
        ])
      );

      const path = this.next;
      this.$router.push({ path });
    },
  },
  head() {
    return {
      title: 'Primary medical decision makers',
    };
  },
};
</script>
