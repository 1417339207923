<template>
  <div
    v-if="poaCompleteAndApproved"
    class="flex flex-row md:flex-col lg:flex-row transition-all"
  >
    <BaseButton
      v-if="showUpdatePoaButton"
      class="btn btn-xl btn-focus font-medium w-full md:w-auto transition-all relative btn-slate text-white m-1"
      :class="downloadButtonClasses"
      @click="$emit('unlockPoa')"
    >
      <span :class="hideButtonText">
        Update POA
      </span>
      <div
        v-if="disableDownloadButton"
        class="absolute inset-0 flex justify-center items-center transition-all opacity-100"
      >
        <LoadingDots />
      </div>
    </BaseButton>
    <a
      v-if="showFinancial"
      class="btn btn-xl btn-focus font-medium w-full md:w-auto transition-all relative whitespace-no-wrap btn-teal text-white m-1"
      :class="downloadButtonClasses"
      :href="poaDocumentLink('financial')"
      target="_blank"
    >
      <BaseIcon id="download" classes="mr-2" />
      <div
        v-if="disableDownloadButton"
        class="absolute inset-0 flex justify-center items-center bg-teal-200 transition-all"
      >
        <LoadingDots />
      </div>
      Financial <span class="hidden sm:inline">&nbsp;POA</span>
    </a>
    <a
      v-if="showMedical"
      class="btn btn-xl btn-focus font-medium w-full md:w-auto transition-all relative whitespace-no-wrap btn-teal text-white m-1"
      :href="poaDocumentLink('medical')"
      :class="downloadButtonClasses"
      target="_blank"
    >
      <BaseIcon id="download" classes="mr-2" />
      <div
        v-if="disableDownloadButton"
        class="absolute inset-0 flex justify-center items-center bg-teal-200 transition-all"
      >
        <LoadingDots />
      </div>
      Medical <span class="hidden sm:inline">&nbsp;POA</span>
    </a>
  </div>
  <Tooltip v-else class="flex-grow md:flex-grow-0" :content="tooltip">
    <BaseButton
      v-if="displaySubmitButton"
      class="btn btn-xl btn-focus font-medium w-full md:w-auto transition-all relative"
      :disabled="!complete"
      :class="btnClasses"
      :loading="loading"
      @click="$emit('click')"
    >
      Submit your POA
      <div
        :class="loadingClasses"
        class="absolute inset-0 flex justify-center items-center bg-teal-200 transition-all"
      >
        <LoadingDots />
      </div>
    </BaseButton>
  </Tooltip>
</template>

<script>
import BaseButton from '~/components/BaseButton';
import LoadingDots from '~/components/LoadingDots';
import Tooltip from '~/components/Tooltip';
import BaseIcon from '~/components/BaseIcon';

export default {
  name: 'PoaDownloadComboButton',
  components: {
    BaseButton,
    BaseIcon,
    LoadingDots,
    Tooltip,
  },
  props: {
    complete: {
      type: Boolean,
      required: true,
    },
    approved: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    showMedical: {
      type: Boolean,
      required: true,
    },
    showFinancial: {
      type: Boolean,
      required: true,
    },
    showUpdatePoaButton: {
      type: Boolean,
      required: true,
    },
    disableDownloadButton: {
      type: Boolean,
      required: true,
    },
    displaySubmitButton: {
      type: Boolean,
      required: true,
    },
    userId: {
      type: String,
      default: () => undefined,
      required: false,
    },
  },
  computed: {
    poaCompleteAndApproved() {
      return this.complete && this.approved;
    },
    tooltip() {
      return !this.complete ? 'You must complete all modules' : '';
    },
    btnClasses() {
      return this.complete
        ? 'text-white' + (this.approved ? ' btn-teal' : ' btn-slate')
        : '';
    },
    loadingClasses() {
      return this.loading ? 'opacity-100' : 'opacity-0';
    },
    downloadButtonClasses() {
      return this.disableDownloadButton
        ? ' cursor-wait pointer-events-none'
        : '';
    },
    hideButtonText() {
      return this.disableDownloadButton ? 'opacity-0' : 'opacity-100';
    },
  },
  methods: {
    poaDocumentLink(type) {
      const baseUrl = `${process.env.SAFEWILL_BACKEND_API}/documents/poa/${type}`;
      if (this.userId) {
        return baseUrl + '/' + this.userId;
      }

      return baseUrl;
    },
  },
};
</script>
