<template>
  <div>
    <GlobalHeader />
    <nuxt />
    <GlossaryPanel />
  </div>
</template>

<script>
import GlobalHeader from '~/components/GlobalHeader';
import GlossaryPanel from '~/components/GlossaryPanel';

export default {
  name: 'MinimalLayout',
  components: { GlobalHeader, GlossaryPanel },
};
</script>
