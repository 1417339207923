<template>
  <div class="w-full min-h-screen overflow-hidden">
    <TopBar :title="title">
      <div class="absolute lg:static left-0 right-0 bottom-0 lg:w-56 h-2">
        <ProgressBar />
      </div>
      <template v-if="token">
        <div class="z-10 ml-auto font-medium text-lg">
          <Dropdown>
            <template #toggle>
              <MenuDisc />
            </template>
            <template #items>
              <MenuItem to="/" icon="home" text="Overview" vertical />
              <MenuItem
                to="/dashboard"
                icon="feather-alt"
                text="Your Will"
                vertical
              />
              <MenuItem
                to=""
                icon="book"
                text="Show Glossary"
                vertical
                @click="showGlossary"
              />
              <MenuItem to="/log-out" icon="logout" text="Log Out" vertical />
            </template>
          </Dropdown>
        </div>
      </template>
      <template v-else-if="$mq !== 'base'">
        <div
          class="hidden md:block ml-auto mr-10 opacity-30 font-medium text-lg leading-none text-charcoal-200"
        >
          Already started a will?
        </div>
        <LogInButton class="ml-auto md:ml-0" />
      </template>
    </TopBar>
    <div
      class="container gutters py-20 md:py-28 mx-auto"
      :class="{ 'mt-16': $nuxt.isOffline }"
    >
      <div class="py-10 md:py-16">
        <nuxt :nuxt-child-key="$route.fullPath" />
      </div>
    </div>
    <CharityPanel />
    <PersonPanel />
    <PetPanel />
    <UserPanel />
    <GiftWizard />
    <GlossaryPanel />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import CharityPanel from '~/components/CharityPanel';
import Dropdown from '~/components/Dropdown';
import MenuItem from '~/components/MenuItem';
import GiftWizard from '~/components/GiftWizard';
import GlossaryPanel from '~/components/GlossaryPanel';
import LogInButton from '~/components/LogInButton';
import MenuDisc from '~/components/MenuDisc';
import PersonPanel from '~/components/PersonPanel';
import PetPanel from '~/components/PetPanel';
import UserPanel from '~/components/UserPanel';
import ProgressBar from '~/components/ProgressBar';
import TopBar from '~/components/TopBar';

import tracking from '~/mixins/tracking';

export default {
  name: 'ModuleLayout',
  components: {
    Dropdown,
    CharityPanel,
    MenuItem,
    GiftWizard,
    GlossaryPanel,
    LogInButton,
    MenuDisc,
    PersonPanel,
    PetPanel,
    UserPanel,
    ProgressBar,
    TopBar,
  },
  mixins: [tracking],
  computed: {
    ...mapGetters(['token']),
    ...mapGetters('modules', ['title']),
  },
  methods: {
    ...mapActions('glossary', ['setShowGlossaryPanel']),
    showGlossary() {
      this.setShowGlossaryPanel(true);
    },
  },
};
</script>
