var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleStep',{attrs:{"index":2,"prev-step":"/get-started"}},[_c('Form',{attrs:{"hide-submit":!_vm.isComplete,"submit-arrow":"","auto-submit":true},on:{"submit":_vm.done}},[_c('FormSection',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        Do you live in Australia?\n      ")]},proxy:true}])},[_vm._v(" "),_c('Tip',[_vm._v("\n        Safewill is available for people living in Australia who are over the\n        age of 18.\n      ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"is_resident","options":[
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          } ]},model:{value:(_vm.is_resident),callback:function ($$v) {_vm.is_resident=$$v},expression:"is_resident"}})],1),_vm._v(" "),(_vm.is_resident === false)?_c('FormSection',{staticClass:"problem",scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        We've got a problem!\n      ")]},proxy:true}],null,false,1734632292)},[_vm._v(" "),_c('p',[_vm._v("\n        Unfortunately, Safewill is currently not available for anyone living\n        outside Australia or under the age of 18.\n      ")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }