<template>
  <ModuleStep :index="3" prev-step="/estate/beneficiaries">
    <Form :hide-submit="!isComplete" submit-arrow @submit="submit">
      <FormSection>
        <template #heading>
          How much should each beneficiary receive?
        </template>
        <Tip>
          Proportions must add up to 100%
        </Tip>
        <BeneficiaryInputs
          v-model="distributions"
          :beneficiaries="beneficiaries"
          @valid="checkValid"
        />
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import ModuleStep from '~/components/ModuleStep';
import BeneficiaryInputs from '~/components/BeneficiaryInputs';
import Tip from '~/components/Tip';

import { beneficiaries, user } from '~/mixins/apollo';

export default {
  name: 'EstateDistribution',
  components: {
    Form,
    FormSection,
    ModuleStep,
    BeneficiaryInputs,
    Tip,
  },
  mixins: [beneficiaries, user],
  data() {
    return {
      distributions: [],
      valid: false,
    };
  },
  computed: {
    isComplete() {
      return !!this.valid;
    },
  },
  methods: {
    checkValid(valid) {
      this.valid = valid;
    },
    async submit() {
      await Promise.all(
        this.beneficiaries.map((beneficiary, i) => {
          return this.updateBeneficiary(beneficiary, this.distributions[i]);
        })
      );

      const hasPeople = this.beneficiaries.find((b) => b.person !== null);

      if (hasPeople) {
        this.$router.push({
          path: `/estate/backup-beneficiaries/${this.backupQueue[0].id}`,
        });
      } else {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '✅ Module 4 (Estate)',
        });

        this.$router.push({
          path: '/dashboard',
        });
      }
    },
  },
};
</script>
