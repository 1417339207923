<template>
  <ModuleStep :index="1" prev-step="/dashboard" :show-help="true">
    <Form
      v-slot="{ loading }"
      auto-focus
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      submit-arrow
      @done="onDone"
    >
      <h1
        class="font-medium md:font-normal leading-tight text-4xl md:text-5xl lg:text-5xl mb-6"
      >
        Let's start to write
        <Squiggle>your Will.</Squiggle>
      </h1>
      <FormSection class="step-1">
        <template #heading>What's your full name?</template>
        <FormRow>
          <TextInput
            id="name_first"
            v-model="willMeta.name_first"
            :disabled="loading"
            placeholder="Your first name"
            rules="required"
          />
        </FormRow>
        <FormRow>
          <TextInput
            id="name_middle"
            v-model="willMeta.name_middle"
            :disabled="loading"
            placeholder="Your middle name (optional)"
          />
        </FormRow>
        <FormRow>
          <TextInput
            id="name_last"
            v-model="willMeta.name_last"
            :disabled="loading"
            placeholder="Your last name"
            rules="required"
          />
        </FormRow>
      </FormSection>
      <FormSection class="step-2">
        <template #heading>Have you ever been known by another name?</template>
        <Tip>
          e.g. A maiden name or if you’ve legally changed your name before.
        </Tip>
        <LargeRadioButtons
          id="has_alt_name"
          v-model="willMeta.has_alt_name"
          :options="[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]"
        />
      </FormSection>
      <FormSection v-if="willMeta.has_alt_name" class="step-3">
        <template #heading>What was your other name?</template>
        <FormRow>
          <TextInput
            id="alt_name_first"
            v-model="willMeta.alt_name_first"
            :disabled="loading"
            placeholder="Your first name"
            rules="required"
          />
        </FormRow>
        <FormRow>
          <TextInput
            id="alt_name_middle"
            v-model="willMeta.alt_name_middle"
            :disabled="loading"
            placeholder="Your middle name (optional)"
          />
        </FormRow>
        <FormRow>
          <TextInput
            id="alt_name_last"
            v-model="willMeta.alt_name_last"
            :disabled="loading"
            placeholder="Your last name"
            rules="required"
          />
        </FormRow>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import Form from '~/components/Form';
import FormRow from '~/components/FormRow';
import FormSection from '~/components/FormSection';
import ModuleStep from '~/components/ModuleStep';
import TextInput from '~/components/TextInput';
import Squiggle from '~/components/Squiggle';
import Tip from '~/components/Tip';
import LargeRadioButtons from '~/components/LargeRadioButtons';

import { user, will } from '~/mixins/apollo';

export default {
  name: 'AboutYourselfName',
  components: {
    Form,
    FormRow,
    FormSection,
    ModuleStep,
    TextInput,
    Squiggle,
    Tip,
    LargeRadioButtons,
  },
  mixins: [user, will],
  computed: {
    isComplete() {
      return !!(
        this.willMeta.name_first &&
        this.willMeta.name_last &&
        (this.willMeta.has_alt_name === false ||
          (this.willMeta.alt_name_first && this.willMeta.alt_name_last))
      );
    },
  },
  methods: {
    onDone() {
      const willMeta = this.willMeta;

      this.$nuxt.$emit('sendTrackingAttributes', {
        name: `${willMeta.name_first} ${willMeta.name_middle} ${willMeta.name_last}`,
      });

      if (willMeta.has_alt_name) {
        this.$nuxt.$emit('sendTrackingAttributes', {
          alternative_name: `${willMeta.alt_name_first} ${willMeta.alt_name_middle} ${willMeta.alt_name_last}`,
        });
      }

      this.$router.push({
        path: '/about-yourself/date-of-birth',
      });
    },
  },
};
</script>
