<template>
  <BasePanel
    v-if="showCharityPanel"
    class="charity-panel"
    title="Adding a new charity"
    @close="close"
  >
    <div v-if="!showAddCustomCharity">
      <div
        class="py-8 px-10 -mx-10 -mt-10 md:-mt-12 mb-6 bg-ice-100 text-base text-slate-300"
      >
        <div
          class="mb-2 bg-ice-300 rounded-full w-12 h-12 flex p-2 justify-center items-center"
        >
          <BaseIcon id="charity" />
        </div>
        Browse by filters or search for a charity to support the causes you’re
        passionate about
      </div>
      <div class="mb-6">
        <button
          v-for="category in charityCategories"
          :key="`featuredCharities_${category}`"
          type="button"
          class="border-2 border-solid border-slate-300 rounded-full px-3 mr-2 mb-2 leading-relaxed"
          :class="categoryIsSelected(category)"
          @click="selectCategory(category)"
        >
          {{ category }}
        </button>
      </div>
      <FormRow class="mb-6">
        <TextInput
          id="searchTerm"
          v-model="searchTerm"
          placeholder="Search for a charity"
        />
      </FormRow>
      <div class="pb-44">
        <CharityCard
          v-for="(charityData, slot) in filteredCharities"
          :key="`filteredCharities${slot}`"
          :charity-key="'charityKey_' + charityData.key"
          :selected="selectedCharityKeys.includes(charityData.key)"
          :charity-data="charityData"
          :hide-description="true"
          small
          @onChange="onChange(charityData.key)"
        />
        <div v-if="!selectedCharityKeys.length">
          <p class="pt-8 text-lg mb-4">
            Can't find the charity you're looking for?
          </p>
          <BaseButton
            class="bg-teal-400 btn-xl"
            focusable
            type="button"
            :loading="savingCharity"
            @click="toggleShowAddCustomCharity"
          >
            <div class="flex items-center">
              <span class="inline-block text-white">
                Add Charity Details
              </span>
            </div>
          </BaseButton>
        </div>
      </div>
      <div
        v-if="selectedCharityKeys.length"
        class="fixed bottom-0 right-0 bg-white w-full px-6 md:px-10 py-4 md:py-6"
      >
        <BaseButton
          class="btn-slate btn-xl"
          focusable
          type="button"
          :loading="savingCharity"
          @click="saveCharities"
        >
          <div class="flex items-center">
            <span class="inline-block text-white">
              Save Charity
            </span>
          </div>
        </BaseButton>
      </div>
    </div>
    <div v-else>
      <AddCustomCharity @successfullyAddedCharity="successfullyAddedCharity" />
    </div>
  </BasePanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { featuredCharities, referralCharities } from '~/modules/charityData';
import { localCharityData } from '~/utilities/charity';

import AddCustomCharity from '~/components/AddCustomCharity';
import BaseButton from '~/components/BaseButton';
import BasePanel from '~/components/BasePanel';
import BaseIcon from '~/components/BaseIcon';
import CharityCard from '~/components/CharityCard';
import FormRow from '~/components/FormRow';
import TextInput from '~/components/TextInput';

import { charities, will, user } from '~/mixins/apollo';

export default {
  name: 'CharityPanel',
  components: {
    AddCustomCharity,
    BaseButton,
    BasePanel,
    BaseIcon,
    CharityCard,
    FormRow,
    TextInput,
  },
  mixins: [charities, will, user],
  data() {
    return {
      savingCharity: false,
      searchTerm: '',
      selectedCategory: 'All charities',
      selectedCharityKeys: [],
      showAddCustomCharity: false,
    };
  },
  computed: {
    ...mapFields('charity', ['fields.name', 'fields.address']),
    ...mapGetters('charity', ['id', 'fields', 'showCharityPanel']),
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    featuredCharityKeys() {
      const charityKeys = Object.keys(featuredCharities);
      const keys = [
        ...new Set(
          charityKeys.flatMap((charityKey) => {
            return featuredCharities[charityKey];
          })
        ),
      ];
      return keys;
    },
    charityCategories() {
      const charityKeys = Object.keys(referralCharities);
      const categories = [
        ...new Set(
          charityKeys.flatMap((charityKey) => {
            return referralCharities[charityKey].categories &&
              referralCharities[charityKey].categories.length
              ? referralCharities[charityKey].categories
              : [];
          })
        ),
      ];
      return ['All charities', ...categories.sort()];
    },
    charityArray() {
      const charityKeys = Object.keys(referralCharities);
      const featuredCharityArray = [];
      const unfeaturedCharityArray = [];
      charityKeys.forEach((charityKey) => {
        // safewill test charity for e2e tests
        if (charityKey !== 'swt') {
          const charityData = {
            ...localCharityData(this.userState, charityKey, referralCharities),
            key: charityKey,
          };
          if (this.featuredCharityKeys.includes(charityKey)) {
            featuredCharityArray.push(charityData);
          } else {
            unfeaturedCharityArray.push(charityData);
          }
        }
      });
      featuredCharityArray.sort((a, b) => {
        return a.name > b.name;
      });
      unfeaturedCharityArray.sort((a, b) => {
        return a.name > b.name;
      });
      return featuredCharityArray.concat(unfeaturedCharityArray);
    },
    filteredCharities() {
      const hasCategory = this.selectedCategory !== 'All charities';
      const hasSearchTerm = !!this.searchTerm;
      return this.charityArray.filter((charity) => {
        return (
          (hasCategory
            ? charity.categories?.includes(this.selectedCategory)
            : true) &&
          (hasSearchTerm
            ? charity.name.toUpperCase().includes(this.searchTerm.toUpperCase())
            : true)
        );
      });
    },
  },
  methods: {
    ...mapActions('charity', ['setShowCharityPanel']),
    successfullyAddedCharity() {
      this.close();
    },
    toggleShowAddCustomCharity() {
      this.showAddCustomCharity = !this.showAddCustomCharity;
    },
    onChange(charityKey) {
      const index = this.selectedCharityKeys.indexOf(charityKey);
      if (index > -1) {
        this.selectedCharityKeys.splice(index, 1);
      } else {
        this.selectedCharityKeys.push(charityKey);
      }
    },
    async saveCharities() {
      if (this.selectedCharityKeys.length) {
        this.savingCharity = true;
        const addedCharities = [];
        await Promise.all(
          this.selectedCharityKeys.map((charityKey) => {
            const charityData = {
              ...localCharityData(
                this.userState,
                charityKey,
                referralCharities
              ),
              searchLocation: window.location.pathname,
              searchFeature: this.featuredCharityKeys.includes(charityKey),
            };
            addedCharities.push(charityData);
            return this.addPartnerCharity(charityData);
          })
        );
        await this.refetchCharities();
        this.savingCharity = false;
        this.$nuxt.$emit('addCharities', addedCharities);
        this.close();
      }
    },
    categoryIsSelected(category) {
      return this.selectedCategory === category
        ? 'bg-slate-300 text-white'
        : 'bg-white text-slate-300';
    },
    close() {
      this.searchTerm = '';
      this.selectedCategory = 'All charities';
      this.selectedCharityKeys = [];
      this.showAddCustomCharity = false;
      this.setShowCharityPanel(false);
    },
    selectCategory(category) {
      this.selectedCategory = category;
    },
    primaryText() {
      return !this.hideName ? this.charityMeta.name : null;
    },
    secondaryText() {
      if (this.hideDescription) {
        return null;
      }
      return this.partnerCharity
        ? this.charityMeta.description
        : this.charityMeta.address;
    },
    tooltipText() {
      return this.showTooltip ? this.charityMeta.description : null;
    },
    logo() {
      return (
        this.charityMeta.logo ||
        referralCharities[this.charityMeta.referral].logo
      );
    },
    classes() {
      return {
        card: {
          'border-2 border-teal-200': this.selected,
          'border-2 border-white': !this.selected,
        },
      };
    },
  },
};
</script>
