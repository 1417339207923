<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 92.845" class="logo">
    <title>Safewill</title>
    <g :fill="mark" stroke-width=".44814">
      <path
        d="m22.134 20.315c-7.7618 7.7246-7.7618 20.203 0 27.928l10.213 10.139 2.7852 2.748 5.5336-5.5332-12.998-12.924-0.22273-0.25992c-4.4567-4.6795-4.3452-11.995 0.22273-16.564 4.6795-4.679 12.33-4.679 17.009 0l7.6874 7.6506 5.4964-5.5708-7.6874-7.6502-0.29712-0.25992c-7.7246-7.3907-20.091-7.3163-27.742 0.29711z"
      />
      <path
        d="m39.069 31.678-5.5336 5.5708 12.998 12.924 0.22317 0.25992c4.4563 4.679 4.3452 11.995-0.22317 16.563-4.679 4.6795-12.33 4.6795-17.009 0l-7.6874-7.6502-5.5336 5.5708 7.6874 7.6502 0.29712 0.25992c7.7618 7.4279 20.092 7.3163 27.779-0.25992 7.7618-7.7246 7.7618-20.203 0-27.928z"
      />
      <path
        d="m0 36.878v19.089c0 20.389 16.601 36.878 37.101 36.878 20.5 0 37.1-16.489 37.1-36.878v-19.089c0-20.389-16.6-36.878-37.1-36.878-20.5 0-37.101 16.489-37.101 36.878zm66.366 0v19.089c0 16.043-13.11 29.042-29.265 29.042-16.155 0-29.265-12.998-29.265-29.042v-19.089c0-16.044 13.11-29.042 29.265-29.042 16.192 0 29.265 12.998 29.265 29.042z"
      />
    </g>
    <g :fill="text" stroke-width=".44814">
      <path
        d="m115.5 62.021c-5.5336 0-9.1362-3.1939-9.6188-8.542l-0.0372-0.33431h-6.796l0.0367 0.4087c0.52029 9.0986 6.7965 14.781 16.415 14.781 9.6556 0 15.858-4.9394 15.858-12.553 0-9.2102-7.5762-11.104-14.261-12.776-5.1993-1.2996-9.6928-2.4137-9.6928-6.6849 0-3.3794 2.9707-5.4592 7.7246-5.4592 4.9022 0 8.1705 2.5996 8.5787 6.7965l0.0372 0.33431h6.7965l-0.0372-0.4087c-0.55703-7.9105-6.6105-13.035-15.375-13.035-8.6903 0-14.744 4.8278-14.744 11.736 0 8.8758 7.5018 10.881 14.112 12.664 5.2365 1.4112 9.7304 2.6368 9.7304 6.8704 0.0367 5.608-6.0906 6.2022-8.7275 6.2022z"
      />
      <path
        d="m181.05 31.01h2.7112v-5.7563h-3.1195c-6.2766 0-10.361 3.9741-10.361 10.101v1.4484h-6.2022v5.7935h6.2022v25.217h6.5733v-25.217h9.1358v-5.7935h-9.1358v-1.2624c0-2.7856 1.5967-4.5311 4.1964-4.5311z"
      />
      <path
        d="m239.95 46.125 6.6477 21.689h5.9419l9.6188-31.01h-6.6849l-5.9047 21.54-6.4994-21.54h-6.0906l-6.5361 21.577-6.0163-21.577h-6.6105l9.6184 31.01h5.9423z"
      />
      <path d="m272.56 36.803h-6.5733v31.01h6.5733z" />
      <path
        d="m272.85 28.485c0-2.0054-1.6339-3.6026-3.6021-3.6026-2.0054 0-3.6026 1.6344-3.6026 3.6026v0.11159c0 2.0054 1.6344 3.6021 3.6026 3.6021 2.0054 0 3.6021-1.6339 3.6021-3.6021z"
      />
      <path d="m286.22 25.254h-6.5733v42.597h6.5733z" />
      <path d="m300 25.254h-6.5733v42.597h6.5733z" />
      <path
        d="m216.33 53.367c0.0744-0.55748 0.11158-1.5228 0.11158-2.3397 0-7.3163-4.5679-14.744-14.744-14.744-4.5307 0-8.356 1.5967-11.067 4.679-2.5253 2.8228-3.8992 6.7965-3.8992 11.142 0 4.308 1.4856 8.3188 4.1964 11.29 2.8968 3.1939 6.8337 4.865 11.402 4.865 4.4563 0 8.2444-1.4484 10.844-3.9364l-4.308-4.308c-1.374 1.6339-3.6021 2.5253-6.5361 2.5253-4.865 0-8.4676-3.5282-9.099-8.8763h23.063zm-22.84-4.6795c0.96574-4.5311 3.7881-6.9448 8.2448-6.9448 4.4935 0 7.5762 2.6368 8.0589 6.9448z"
      />
      <path
        d="m153.6 48.985h-6.3134c-7.3907 0-12.144 3.8625-12.144 9.8044 0 5.6824 4.2708 9.5077 10.659 9.5077 4.0852 0 7.6134-1.6716 9.4701-4.3452v3.8992h6.5738v-19.943c0-6.9448-5.3481-11.587-13.333-11.587-4.4939 0-8.3932 1.7455-10.807 4.5307l4.1596 4.1596c1.1141-1.931 3.6021-3.2683 6.4617-3.2683 4.308 0 6.9448 2.2286 6.9448 5.8307 0 1.1885-0.92854 1.4112-1.6711 1.4112zm1.6711 5.1993c0 5.4964-3.1195 8.7647-8.356 8.7647-3.1567 0-5.2737-1.7083-5.2737-4.2708 0-2.934 2.4141-4.865 6.165-4.865h7.4274v0.37106z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    mark: {
      default: '#67D7C0',
      type: String,
    },
    text: {
      default: '#10132F',
      type: String,
    },
  },
};
</script>
<style lang="scss">
.logo {
  display: block;
  height: var(--logo-height);
}
</style>
