<template>
  <transition appear :css="false" @enter="enter" @leave="leave">
    <div class="fixed inset-0 z-40">
      <div
        ref="background"
        class="absolute inset-0"
        :class="{ 'bg-shade-300': !hideOverlay }"
        @click="close"
      />
      <div
        class="relative z-10 flex justify-center items-start h-screen px-4 py-20 md:py-40 overflow-y-auto scrolling-touch"
      >
        <div
          ref="card"
          v-on-dismiss="{
            callback: close,
          }"
          class="w-full p-8 md:p-12 bg-white"
          :class="{ 'max-w-3xl': modalWide, 'max-w-2xl': !modalWide }"
        >
          <div class="relative">
            <slot />
            <div v-if="!hideClose" class="absolute top-0 right-0">
              <BaseButton class="text-black" @click="close">
                <BaseGlyph id="close" />
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import anime from 'animejs';
import VDismiss from 'vue-dismiss';

import BaseButton from '~/components/BaseButton';
import BaseGlyph from '~/components/BaseGlyph';

export default {
  name: 'BaseModal',
  components: {
    BaseButton,
    BaseGlyph,
  },
  mixins: [VDismiss],
  props: {
    hideClose: {
      default: false,
      type: Boolean,
    },
    hideOverlay: {
      default: false,
      type: Boolean,
    },
    title: {
      default: null,
      type: String,
    },
    modalWide: {
      default: false,
      type: Boolean,
    },
  },
  mounted() {
    window.addEventListener('keydown', this.keyHandler);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyHandler);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    enter(el, complete) {
      const timeline = anime.timeline({ complete });

      timeline
        .add({
          targets: this.$refs.card,
          translateY: [50, 0],
          translateZ: 0,
          opacity: [0, 1],
          duration: 400,
          easing: 'easeOutQuart',
        })
        .add(
          {
            targets: this.$refs.background,
            opacity: [0, 1],
            duration: 150,
            easing: 'linear',
          },
          0
        );
    },
    leave(el, complete) {
      const timeline = anime.timeline({
        complete: () => {
          this.$emit('done');
          complete();
        },
      });

      timeline
        .add({
          targets: this.$refs.card,
          translateY: [0, 30],
          translateZ: 0,
          opacity: [1, 0],
          duration: 350,
          easing: 'easeOutCubic',
        })
        .add(
          {
            targets: this.$refs.background,
            opacity: [1, 0],
            duration: 250,
            easing: 'linear',
          },
          50
        );
    },
    keyHandler(e) {
      switch (e.keyCode) {
        case 27: // ESC
          e.preventDefault();
          this.close();
          break;
        default:
          break;
      }
    },
  },
};
</script>
