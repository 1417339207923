<template>
  <ModuleStep :index="4" prev-step="/executors/primary">
    <Form
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      :auto-submit="!willMeta.has_backup_executor"
      submit-arrow
      @done="done"
    >
      <FormSection class="step-1">
        <template #heading>
          Would you like to appoint a backup executor?
        </template>
        <Tip v-if="willMeta.executors_option === 'professional'">
          If Safewill decides to step aside in the best interests of your
          beneficiaries or estate, you can appoint a backup executor to act as
          executor
        </Tip>
        <Tip v-else>
          In the event that your primary executors are unable or unwilling to
          act, you can select up to two people to replace them as executor of
          your estate
        </Tip>
        <LargeRadioButtons
          id="hasBackupExecutor"
          v-model="willMeta.has_backup_executor"
          :options="[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]"
        />
      </FormSection>
      <FormSection
        v-if="willMeta.has_backup_executor"
        class="step-2"
        tip="We’ll ask you to add or select people at relevant places in your will. Their details can be edited on the People, Pets & Charities page."
      >
        <template #heading>
          Who should be your backup executor?
        </template>
        <PersonSelector
          v-model="selectedBackupExecutors"
          :loading="loading"
          :max="2"
          :people="filteredPeople"
          type="executor"
        />
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import LargeRadioButtons from '~/components/LargeRadioButtons';
import ModuleStep from '~/components/ModuleStep';
import PersonSelector from '~/components/PersonSelector';
import Tip from '~/components/Tip';

import { executors, people, user, will } from '~/mixins/apollo';
import { metaArrayToObject } from '~/utilities';

export default {
  name: 'ExecutorsBackup',
  components: {
    Form,
    FormSection,
    LargeRadioButtons,
    ModuleStep,
    PersonSelector,
    Tip,
  },
  mixins: [executors, people, user, will],
  data() {
    return {
      selectedBackupExecutors: [],
    };
  },
  computed: {
    isComplete() {
      return (
        this.willMeta.has_backup_executor === false ||
        !!this.selectedBackupExecutors.length
      );
    },
    filteredPeople() {
      return this.people.filter((person) => {
        const meta = metaArrayToObject(person.meta);
        const personIsAlreadySelected = this.selectedBackupExecutors.includes(
          person.id
        );
        return (
          personIsAlreadySelected ||
          (meta.is_over_18 &&
            meta.address_country === 'Australia' &&
            !this.isPrimaryExecutor(person))
        );
      });
    },
  },
  watch: {
    backupExecutors(executors) {
      if (executors && !this.selectedBackupExecutors.length) {
        this.selectedBackupExecutors = this.people
          .filter((person) => {
            return executors.find(
              (executor) => person.id === executor.person.id
            );
          })
          .map((person) => person.id);
      }
    },
  },
  methods: {
    async done() {
      await Promise.all(
        this.people.map((person) => {
          const isBackupExecutor = this.isBackupExecutor(person);
          const isSelected = this.selectedBackupExecutors.includes(person.id);

          if (this.willMeta.has_backup_executor && isSelected) {
            if (!isBackupExecutor) {
              return this.addExecutor(person, 'backup');
            }
          } else if (isBackupExecutor) {
            return this.removeExecutor(person);
          }
        })
      );

      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✅ Module 3 (Executors)',
      });

      this.$router.push({
        path: '/dashboard',
      });
    },
  },
};
</script>
