var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"person mb-8 last-child:mb-0"},[(_vm.label)?_c('div',{staticClass:"flex items-center mb-4"},[_c('h4',{staticClass:"flex-grow text-2xl"},[_vm._v("\n      People\n    ")]),_vm._v(" "),_c('Tooltip',{staticClass:"ml-4",attrs:{"content":"We’ll ask you to add or select people at relevant places in your Will. Their details can be edited on the People, Pets & Charities page."}},[_c('div',{staticClass:"flex justify-center items-center w-5 h-5 rounded-full bg-grey-500 text-grey-200"},[_c('BaseGlyph',{attrs:{"id":"question-mark"}})],1)])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"relative"},_vm._l((_vm.people),function(person,index){return _c('MetaSlot',{key:index,attrs:{"meta":person.meta},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var meta = ref.meta;
return [_c('div',{staticClass:"mb-4"},[_c('LocalScope',{attrs:{"id":("person_" + (person.id) + "__" + _vm.id),"quick-edit":index >= _vm.quickEditIndex,"selected":_vm.isSelected[index]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var id = ref.id;
var quickEdit = ref.quickEdit;
var selected = ref.selected;
return [_c('label',{staticClass:"group block rounded cursor-pointer select-none",class:_vm.classes.label[index],attrs:{"for":id}},[_c('BaseCard',{class:[
                _vm.classes.card[index],
                { 'rounded-bl-none rounded-br-none': quickEdit } ],attrs:{"inline":"","primary-text":meta.full_name,"secondary-text":_vm.buildIdentifier(meta)},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"relative inline-flex justify-center items-center cursor-pointer",class:_vm.classes.checkbox[index]},[_c('input',{staticClass:"btn absolute top-0 left-0 w-full h-full p-0",class:_vm.classes.input[index],attrs:{"id":id,"name":id,"type":"checkbox"},domProps:{"checked":selected},on:{"change":function($event){return _vm.onChange($event.target.checked, person)}}}),_vm._v(" "),(selected)?_c('span',{staticClass:"text-lg"},[_vm._v("\n                      Unselect\n                    ")]):_c('span',{staticClass:"text-lg"},[_vm._v("\n                      Select\n                    ")])]),_vm._v(" "),_c('div',{staticClass:"relative inline-flex justify-center items-center cursor-pointer mt-2"},[_c('BaseButton',{staticClass:"btn btn-md transition-duration-0 transition-all",class:_vm.classes.edit[index],on:{"click":function($event){return _vm.onEditPerson(person)}}},[_vm._v("\n                      Edit\n                    ")])],1)])]},proxy:true}],null,true)})],1)]}}],null,true)})],1)]}}],null,true)})}),1),_vm._v(" "),_c('BaseButton',{staticClass:"add-person btn-xl btn-white w-full",attrs:{"disabled":_vm.loading,"focusable":""},on:{"click":_vm.showAddPersonPanel}},[_vm._v("\n    + Add a person\n  ")]),_vm._v(" "),(_vm.showingGenericPersonPanel)?_c('Portal',{attrs:{"to":"sidePanel"}},[_c('GenericPersonPanel',{attrs:{"existing-person":_vm.personBeingEdited,"identifier-requirements":_vm.identifierRequirements,"type":_vm.type},on:{"close":_vm.closePersonPanel}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }