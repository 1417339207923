<template>
  <div>
    <AdminSideBar v-if="authenticated" />
    <div :class="authenticated ? 'max-w-admin min-w-admin ml-60' : null">
      <div
        :class="
          authenticated
            ? 'gutters py-16'
            : 'flex justify-center items-center min-w-screen min-h-screen'
        "
      >
        <nuxt />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AdminSideBar from '~/components/AdminSideBar';

export default {
  name: 'AdminLayout',
  components: {
    AdminSideBar,
  },
  computed: {
    ...mapGetters(['role', 'token']),
    authenticated() {
      return (
        this.token && ['MODERATOR', 'ADMIN', 'SUPER_ADMIN'].includes(this.role)
      );
    },
  },
};
</script>
