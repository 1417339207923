<template>
  <div>
    <Form
      class="inset-0 z-40 pt-0 overflow-y-auto"
      :class="classes"
      hide-submit
      @submit.prevent="$nuxt.$emit('checkout')"
    >
      <div
        class="container gutters flex flex-col lg:flex-row min-h-full mx-auto"
      >
        <div class="md:flex-grow mb-0 lg:pb-16 lg:pr-15">
          <div class="flex items-center h-16">
            <BaseButton class="text-black" focusable @click="close">
              <BaseIcon id="arrow-left" />
            </BaseButton>
          </div>
          <nuxt-link to="/" class="inline-block h-8 mb-6 md:mt-8">
            <Logo wordmark class="h-8" />
          </nuxt-link>
          <h2 class="mb-6 text-6xl md:text-8xl leading-tight">
            <template v-if="paymentSuccess">
              <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
              <Squiggle class="checkout-success">Thank you.</Squiggle>
            </template>
            <template v-else-if="checkoutAction === 'submit'">
              {{ firstName }}, let's<br /><Squiggle>finish</Squiggle> your Will
            </template>
            <template v-else-if="checkoutAction === 'renew'">
              <Squiggle>Unlock</Squiggle> your account
            </template>
            <template v-else-if="checkoutAction === 'update'">
              <Squiggle>Update</Squiggle> your credit card
            </template>
          </h2>
          <p class="max-w-3xl text-xl">
            <template v-if="checkoutAction === 'submit'">
              Your Will is checked by our legal advisors here in Australia
              before we finalise it for you to print and sign. We’ll be in touch
              if anything needs fixing.
            </template>
            <template v-else-if="paymentSuccess">
              Your will is being checked by our Wills team. Thanks for being a
              part of Australia’s smartest way to write a will.
            </template>
            <template v-else-if="checkoutAction === 'renew'">
              Unlock your account and make unlimited changes to your Will by
              checking out below. Your unlocking fee includes a full year of
              free and unlimited updates.
            </template>
            <template v-else>
              Update your credit card details to keep your subscription active.
              This grants you access to unlimited free updates to your Will, and
              to digital storage of your Will document.
            </template>
          </p>
          <div v-if="paymentSuccess" style="max-width: 600px;">
            <div class="flex items-center text-charcoal-100 my-6">
              <BaseIcon id="info" />
              <span class="text-lg ml-2">
                We’ll be in touch in the next 3 business days.
              </span>
            </div>
            <BaseCard
              v-if="!invitedByPartner && !willMeta.partner_bundle"
              class="mb-4 last-child:mb-0 border-2 border-grey-200 rounded"
              badge="Give 50% off"
              badge-color="bg-slate-100"
              primary-text="Partner Discount"
              secondary-text="Invite your partner to get 50% off the price of their will."
            >
              <SentInvite
                v-for="invite in invites"
                :key="invite.id"
                :invite="invite"
                type="PARTNER"
              />
              <SendInvite
                v-if="!hasInvitedPartner"
                :invites="invites"
                type="PARTNER"
                :prefill="partnerEmail"
              />
            </BaseCard>
            <BaseCard
              v-if="showPoa"
              class="mb-4 last-child:mb-0 border-2 border-grey-200 rounded"
              badge="RECOMMENDED"
              badge-color="bg-green-300"
              primary-text="Add your Powers of Attorney"
              secondary-text="Whilst your Will protects your family and your assets should you pass, your Powers of Attorney allow you to appoint someone to protect your living wishes."
            >
              <BaseButton
                class="btn relative btn btn-lg btn-slate text-white text-center text-base w-full"
                to="/poa"
              >
                Purchase for $50
              </BaseButton>
            </BaseCard>
            <BaseCard
              class="mb-4 last-child:mb-0 border-2 border-grey-200 rounded"
              badge="Give 20% off"
              badge-color="bg-teal-200"
              primary-text="Friends and Family Discount"
              secondary-text="Invite friends and family to get 20% off the price of their will. You can invite as many people as you like. Earn $10 for each person that completes a will, up to $50."
            >
              <SentInvite
                v-for="invite in invites"
                :key="invite.id"
                :invite="invite"
                type="DEFAULT"
              />
              <h4
                v-if="friends.length"
                class="text-lg text-black leading-normal"
              >
                Invite people in your will via email
              </h4>
              <SendInvite
                v-for="friend in friends"
                :key="friend"
                :invite="friend"
                :prefill="friend"
              />
              <h4 class="mt-4 text-lg text-black leading-normal">
                Invite via email
              </h4>
              <SendInvite :invites="invites" />
              <div class="relative my-6">
                <div
                  class="relative text-grey-300 mx-auto w-12 text-center bg-white mt-2 text-xl bold z-10 px-2"
                >
                  OR
                </div>
                <div
                  class="border-t-2 border-grey-300 absolute w-full mt-4 top-0 z-0"
                />
              </div>
              <h4 class="text-lg text-black leading-normal">
                Share your personal link
              </h4>
              <LinkInvite :link="inviteLink" />
              <Tip>
                You can also manage and send invites from the invite page on
                your dashboard.
              </Tip>
            </BaseCard>
          </div>
          <CheckoutSummary
            v-if="!paymentSuccess"
            :pay-partners-will="payPartnersWill"
            class="mt-8 lg:hidden"
            @remove:partnersWill="payPartnersWill = false"
          />
          <div class="pt-10">
            <div v-if="paymentSuccess">
              <BaseButton class="btn-slate btn-xl" focusable @click="close">
                <div class="flex items-center">
                  <span
                    class="inline-block mr-auto text-white"
                    :class="classes.buttonText"
                  >
                    Go to dashboard
                  </span>
                  <span class="ml-4">
                    <BaseIcon id="arrow-right" />
                  </span>
                </div>
              </BaseButton>
              <div class="mt-4 opacity-50 font-medium">
                <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
                or
                <nuxt-link to="/log-out" class="underline hover:no-underline">
                  log out
                </nuxt-link>
              </div>
              <div class="mt-10">
                <p class="mr-2 mb-2">
                  If you had a good experience, leave us a review:
                </p>
                <ReviewCollector />
              </div>
            </div>
            <div v-else>
              <div
                v-if="
                  (willMeta.has_partner || has_partner) &&
                    !payPartnersWill &&
                    checkoutAction === 'submit'
                "
                class="mb-6 lg:mb-8"
              >
                <BaseButton
                  class="w-full bg-teal-100 rounded-none p-7 lg:p-9"
                  @click="payPartnersWill = true"
                >
                  <div class="flex items-center">
                    <div class="bg-slate-100 text-white p-3 mr-8">
                      <div class="rotate-45">
                        <BaseIcon id="close" classes="w-4 h-4" />
                      </div>
                    </div>
                    <div class="flex flex-wrap flex-grow">
                      <div class="flex justify-between w-full text-2xl mb-1">
                        <h3>Partner's Will</h3>
                        <div>$80</div>
                      </div>
                      <div class="text-charcoal-100">
                        <p>
                          Bundle with your own Will and save
                          <span>$80</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </BaseButton>
              </div>
              <PaymentStripe :add-partners-will="addPartnersWill" />
              <PaymentZip v-if="showZip" :add-partners-will="addPartnersWill" />
              <PaymentAfterpay
                v-if="showPayLater"
                :add-partners-will="addPartnersWill"
              />
              <FormSection>
                <FormRow>
                  <div class="w-full hidden lg:block">
                    <BaseButton
                      class="btn btn-xl btn-slate w-full pt-0 mt-2 mb-4 font-medium"
                      :loading="paymentLoading"
                      focusable
                      type="submit"
                    >
                      <div class="flex items-center text-white">
                        <span
                          class="inline-block mr-auto"
                          v-text="buttonText"
                        />
                        <span class="ml-4">
                          <BaseIcon id="arrow-right" />
                        </span>
                      </div>
                    </BaseButton>
                    <p class="max-w-2xl ml-auto text-charcoal-100 text-right">
                      <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
                      By continuing you confirm that you have
                      <GlossaryTooltip term="Testamentary capacity">
                        testamentary
                      </GlossaryTooltip>
                      and physical capacity, and are not writing the Will under
                      <GlossaryTooltip term="Duress">
                        duress
                      </GlossaryTooltip>
                      .
                    </p>
                  </div>
                </FormRow>
              </FormSection>
            </div>
            <div
              v-if="checkoutAction === 'submit'"
              class="pt-10 hidden lg:block"
            >
              <CheckoutFaq :faq="faq" classes="pt-10 hidden lg:block" />
            </div>
          </div>
        </div>
        <div
          :class="paymentSuccess ? null : 'lg:border-l-2'"
          class="relative w-full lg:max-w-xl flex-shrink-0 pt-8 lg:pt-0 pb-20 lg:py-32 border-grey-300"
        >
          <div
            v-if="paymentSuccess"
            class="absolute inset-0 -mx-6 md:ml-0 md:-mr-15 bg-grey-100"
          />
          <div class="relative z-10 lg:mt-20 lg:pl-15">
            <template v-if="paymentSuccess">
              <h3 class="mb-8 text-3xl">
                What happens next
              </h3>
              <FeatureBlock class="mb-10" heading="We review" icon="glasses">
                <p>
                  Our team will make sure everything checks out. If it doesn’t,
                  we’ll be in touch to fix anything up before we send you your
                  Will.
                </p>
              </FeatureBlock>
              <FeatureBlock class="mb-10" heading="You sign" icon="signature">
                <p>
                  Once it’s been reviewed, we’ll email your Will to you to print
                  off and sign. You’ll need two people to act as a witness when
                  you sign.
                </p>
              </FeatureBlock>
              <FeatureBlock heading="Update anytime" icon="edit">
                <p>
                  Update and resubmit your Will for review anytime and anywhere
                  for ${{ defaultSubscriptionPrice }}
                  a year. Your first year of updates is free, and you can opt
                  out any time before
                  {{ renewalDate }} without being charged.
                </p>
              </FeatureBlock>
            </template>
            <template v-else>
              <CheckoutSummary
                :pay-partners-will="payPartnersWill"
                class="hidden md:block md:fixed md:pr-12"
                style="width: 350px;"
                @remove:partnersWill="payPartnersWill = false"
              />
              <div class="lg:hidden">
                <BaseButton
                  class="btn btn-xl btn-slate lg:hidden w-full mt-2 mb-4 font-medium"
                  :loading="paymentLoading"
                  focusable
                  type="submit"
                >
                  <div class="flex items-center text-white">
                    <span class="inline-block mr-auto" v-text="buttonText" />
                    <span class="ml-4">
                      <BaseIcon id="arrow-right" />
                    </span>
                  </div>
                </BaseButton>
                <p class="max-w-2xl ml-auto text-charcoal-100 md:text-right">
                  <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
                  By continuing you confirm that you have
                  <GlossaryTooltip term="Testamentary capacity">
                    testamentary
                  </GlossaryTooltip>
                  and physical capacity, and are not writing the Will under
                  <GlossaryTooltip term="Duress"> duress </GlossaryTooltip>.
                </p>
                <div v-if="checkoutAction === 'submit'" class="pt-10">
                  <CheckoutFaq :faq="faq" classes="pt-10" />
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </Form>

    <div
      v-if="paymentProcessing"
      class="absolute inset-0 flex justify-center items-center text-teal-200"
    >
      <LoadingDots />
    </div>
    <NotifyCharitiesConsentModal />
  </div>
</template>

<script>
import upperFirst from 'lodash/upperFirst';
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import { queries } from '@/modules/apollo-queries/poa-status';

import LoadingDots from '~/components/LoadingDots';
import BaseButton from '~/components/BaseButton';
import BaseIcon from '~/components/BaseIcon';
import CheckoutFaq from '~/components/CheckoutFaq';
import FeatureBlock from '~/components/FeatureBlock';
import Form from '~/components/Form';
import FormRow from '~/components/FormRow';
import FormSection from '~/components/FormSection';
import GlossaryTooltip from '~/components/GlossaryTooltip';
import Logo from '~/components/Logo';
import Squiggle from '~/components/Squiggle';
import ReviewCollector from '~/components/ReviewCollector';
import CheckoutSummary from '~/components/CheckoutSummary';
import PaymentStripe from '~/components/PaymentStripe';
import PaymentAfterpay from '~/components/PaymentAfterpay';
import PaymentZip from '~/components/PaymentZip';
import BaseCard from '~/components/BaseCard';
import SendInvite from '~/components/SendInvite';
import SentInvite from '~/components/SentInvite';
import LinkInvite from '~/components/LinkInvite';
import Tip from '~/components/Tip';
import NotifyCharitiesConsentModal from '~/components/NotifyCharitiesConsentModal';

import { subscription, user, will, invites, people } from '~/mixins/apollo';
import tracking from '~/mixins/tracking';
import { metaArrayToObject } from '~/utilities';

import CURRENT_USER_QUERY from '~/graphql/queries/CurrentUser.gql';

export default {
  key(route) {
    return route.fullPath;
  },
  components: {
    BaseButton,
    BaseIcon,
    CheckoutFaq,
    CheckoutSummary,
    FeatureBlock,
    Form,
    FormRow,
    FormSection,
    GlossaryTooltip,
    Logo,
    Squiggle,
    ReviewCollector,
    PaymentAfterpay,
    PaymentStripe,
    PaymentZip,
    LoadingDots,
    BaseCard,
    SendInvite,
    LinkInvite,
    SentInvite,
    Tip,
    NotifyCharitiesConsentModal,
  },
  middleware: ['authenticated'],
  mixins: [subscription, user, will, tracking, invites, people],
  data() {
    return {
      payPartnersWill: false,
      faq: [
        {
          question: 'What happens next?',
          answer:
            'Once your Will has been submitted, our experts here in Australia will check it within 3 business days and email you your Will for printing and signing.',
        },
        {
          question: 'What if I need to change something?',
          answer:
            'After your first Will has been checked, you can log in with your email and password to update your Will easily at any time. Should you do so, we’ll review your updates and send you a new copy of your Will.',
        },
      ],
    };
  },
  apollo: {
    poaStatus: {
      ...queries.getPoaStatus(),
      variables() {
        return {
          id: this.poaId,
        };
      },
    },
  },
  computed: {
    ...mapGetters('checkout', [
      'couponCode',
      'checkoutAction',
      'defaultSetupPrice',
      'defaultSubscriptionPrice',
      'finalSetupPrice',
      'invitedByPartner',
      'paymentMethod',
      'paymentProcessing',
      'paymentLoading',
      'paymentSuccess',
      'paymentError',
    ]),
    ...mapFields('signup', ['meta.has_partner']),
    hasPoa() {
      return this.poaStatus !== null;
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    showPoa() {
      return this.$ff.powerOfAttorney() && !this.hasPoa;
    },
    buttonText() {
      return this.checkoutAction === 'update'
        ? 'Update your card'
        : 'Complete Order';
    },
    classes() {
      return {
        hidden: this.paymentProcessing,
      };
    },
    renewalDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      return this.$formatDate(date, 'mmm d, yyyy');
    },
    firstName() {
      return this.willMeta && upperFirst(this.willMeta.name_first);
    },
    inviteLink() {
      const code = (this.publicInvite && this.publicInvite.code) || '';
      return `https://safewill.com/i/${code}`;
    },
    hasInvitedPartner() {
      return !!this.invites.find((i) => i.type === 'PARTNER');
    },
    partnerEmail() {
      const meta = this.partners[0] && metaArrayToObject(this.partners[0].meta);
      return (meta && meta.email) || '';
    },
    friends() {
      const emails = this.people.filter((p) => {
        if (p.category !== 'partner' && !this.minors.includes(p)) {
          const meta = metaArrayToObject(p.meta);
          if (meta && meta.email) {
            return !this.invites.find((i) => i.email === meta.email);
          }
        }
      });
      return emails.map((p) => {
        const meta = metaArrayToObject(p.meta);
        if (meta.email) return meta.email;
      });
    },
    totalSaved() {
      const accepted = this.invites.filter((i) => i.purchased);
      return Math.min(accepted.length * 10, 50);
    },
    addPartnersWill() {
      return (
        (this.willMeta.has_partner || this.has_partner) &&
        this.payPartnersWill &&
        this.checkoutAction === 'submit'
      );
    },
    showPayLater() {
      return this.checkoutAction !== 'update' && this.finalSetupPrice >= 40;
    },
    showZip() {
      return this.$ff.showZip() && this.showPayLater;
    },
  },
  async mounted() {
    if (!this.checkoutAction && this.hasExpiredSubscription) {
      await this.renewSubscription();
    } else if (!this.checkoutAction || this.checkoutAction === 'submit') {
      await this.submitWill();
    }

    if (this.$route.query.success) {
      this.setPaymentSuccess(true);
      this.setPaymentLoading(false);
    } else if (this.$route.query.result === 'approved') {
      this.setPaymentProcessing(true);
    } else {
      this.setPaymentProcessing(false);
    }
    try {
      this.$nuxt.$emit('toggleCouponInputLoader', true);
      const { data } = await this.$apollo.query({
        query: CURRENT_USER_QUERY,
      });
      const latestReferralCoupon =
        data?.currentUser?.user?.latest_referral_coupon;
      if (latestReferralCoupon) {
        this.$nuxt.$emit('applyCoupon', latestReferralCoupon);
      } else {
        this.$nuxt.$emit('applyCoupon', null);
      }
    } catch (error) {
      this.$nuxt.$emit('toggleCouponInputLoader', false);
      this.$nuxt.$emit('sendTrackingEvent', {
        event: '❌ Failed to get referral coupon',
      });
    }
  },
  layout: 'minimal',
  methods: {
    ...mapActions(['setWillStatus']),
    ...mapActions('checkout', [
      'submitWill',
      'renewSubscription',
      'zipMoneyApproved',
      'setCouponCode',
      'setPaymentProcessing',
      'setPaymentLoading',
      'setPaymentSuccess',
      'setPaymentError',
    ]),
    close() {
      if (this.checkoutAction === 'submit' && !this.paymentSuccess) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '🚫 Cancelled Checkout',
        });
      }

      this.$router.replace({ path: '/' });
    },
  },
  head: {
    script: [
      {
        src: 'https://widget.reviews.io/badge-modern/dist.js',
      },
    ],
  },
};
</script>
