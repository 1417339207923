var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleStep',{attrs:{"prev-step":_vm.previous,"index":2,"show-help":true}},[_c('Form',{attrs:{"submit-arrow":"","hide-submit":!_vm.isComplete},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [(_vm.askAttorneyDecisions)?_c('FormSection',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        Would you like your healthcare decision makers to act jointly or\n        severally?\n      ")]},proxy:true}],null,true)},[_vm._v(" "),_c('p',{staticClass:"mb-4"},[_vm._v("\n        Jointly means that all decision makers must agree and act unanimously.\n      ")]),_vm._v(" "),_c('p',{staticClass:"mb-4"},[_vm._v("\n        Severally means that your decision makers can act unanimously, but\n        they can also act separately.\n      ")]),_vm._v(" "),(['qld', 'act', 'sa'].includes(_vm.userState))?_c('p',{staticClass:"mb-4"},[_vm._v("\n        For either option, if one attorney loses their capacity to act, the\n        appointment of the other attorneys will not be affected.\n      ")]):_vm._e(),_vm._v(" "),_c('Tip',[_vm._v("\n        Each choice has pros and cons. Jointly ensures that you get more\n        thorough decisions agreed on by all decision makers, whilst severally\n        gives them more flexibility (e.g. if one person travels a lot and\n        won’t be able to participate in every decision).\n      ")]),_vm._v(" "),_c('FormRow',{staticClass:"flex-wrap"},[_c('LargeRadioButtons',{attrs:{"id":"medical_attorney_decisions","options":[
            {
              label: "Jointly — all decision makers must agree",
              value: 'jointly',
            },
            {
              label: "Severally — decision makers can also act on their own",
              value: 'jointly and severally',
            } ]},model:{value:(_vm.poaMedicalMeta.attorney_decisions),callback:function ($$v) {_vm.$set(_vm.poaMedicalMeta, "attorney_decisions", $$v)},expression:"poaMedicalMeta.attorney_decisions"}})],1)],1):_vm._e(),_vm._v(" "),(_vm.askAttorneyDecisionsOther)?_c('FormSection',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        If one of your joint decision makers dies, loses capacity or otherwise\n        cannot act:...\n      ")]},proxy:true}],null,true)},[_vm._v(" "),_c('Tip',[_vm._v("\n        For example, if one decision maker becomes unwell or dies, and is no\n        longer capable of acting on your behalf.\n      ")]),_vm._v(" "),_c('FormRow',{staticClass:"flex-wrap"},[_c('LargeRadioButtons',{attrs:{"id":"medical_attorney_decisions_other","options":[
            {
              label: "My other decision maker(s) can act without them",
              value: 'other',
            },
            {
              label: "None of my other decision makers can act",
              value: 'none',
            } ]},model:{value:(_vm.poaMedicalMeta.attorney_decisions_other),callback:function ($$v) {_vm.$set(_vm.poaMedicalMeta, "attorney_decisions_other", $$v)},expression:"poaMedicalMeta.attorney_decisions_other"}})],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }