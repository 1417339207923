<template>
  <ModuleStep v-if="willMeta" :index="2" prev-step="/estate">
    <Form
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      :auto-submit="willMeta.estate_split && willMeta.estate_split !== 'custom'"
      submit-arrow
      @done="done"
    >
      <FormSection v-if="hasFamily" class="step-1">
        <template #heading>Who would you like to inherit your estate?</template>
        <Tip>
          In the next step you will also be able to choose backups in case any
          of your beneficiaries pass away before you.
        </Tip>
        <EstateSelector v-model="willMeta.estate_split" />
      </FormSection>
      <FormSection v-if="willMeta.estate_split === 'custom'" class="step-2">
        <template #heading>Who should inherit your estate?</template>
        <p class="mb-6">
          Add any number of people or not-for-profit organisations. You can
          decide the proportions in the next step.
        </p>
        <PersonSelector
          v-model="selectedBeneficiaryIDs"
          label
          :loading="loading"
          :people="people"
        />
        <h4 class="mb-4 text-2xl">
          Charities and Not-for-Profits
        </h4>
        <CharitySuggester
          v-if="showSuggestedCharities"
          v-model="selectedSuggestedCharities"
          :loading="loading"
        />
        <CharitySelector v-model="selectedBeneficiaryIDs" :loading="loading" />
        <BaseButton
          class="btn-xl btn-white w-full add-charity"
          :disabled="loading"
          focusable
          @click="addCharity"
        >
          + Add a charity
        </BaseButton>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapActions } from 'vuex';
import { metaArrayToObject } from '~/utilities';
import { isSameCharity } from '~/utilities/charity';

import BaseButton from '~/components/BaseButton';
import CharitySelector from '~/components/CharitySelector';
import CharitySuggester from '~/components/CharitySuggester';
import EstateSelector from '~/components/EstateSelector';
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import ModuleStep from '~/components/ModuleStep';
import PersonSelector from '~/components/PersonSelector';
import Tip from '~/components/Tip';

import { beneficiaries, charities, people, user, will } from '~/mixins/apollo';

export default {
  name: 'EstateBeneficiaries',
  components: {
    BaseButton,
    CharitySelector,
    CharitySuggester,
    EstateSelector,
    Form,
    FormSection,
    ModuleStep,
    PersonSelector,
    Tip,
  },
  mixins: [beneficiaries, charities, people, user, will],
  data() {
    return {
      selectedBeneficiaryIDs: [],
      selectedSuggestedCharities: [],
    };
  },
  computed: {
    showSuggestedCharities() {
      const hasReferral = !!this.willMeta.referral_charity;
      const hasAddedSuggestedCharity = this.charities.some((charity) => {
        const charityMeta = metaArrayToObject(charity.meta);
        return !!charityMeta.suggestionLocation;
      });
      return !hasReferral && !hasAddedSuggestedCharity;
    },
    isComplete() {
      return !!(
        this.willMeta.estate_split &&
        (this.willMeta.estate_split !== 'custom' ||
          this.selectedBeneficiaryIDs.length ||
          this.selectedSuggestedCharities.length)
      );
    },
    hasFamily() {
      return !!(this.partners.length || this.children.length);
    },
  },
  watch: {
    beneficiaries() {
      if (
        this.willMeta.estate_split === 'custom' &&
        this.beneficiaries.length &&
        !this.selectedBeneficiaryIDs.length
      ) {
        const peopleIDs = this.people
          .filter((person) => {
            return this.beneficiaries.find(
              (beneficiary) =>
                beneficiary.person && beneficiary.person.id === person.id
            );
          })
          .map((person) => person.id);

        const charityIDs = this.charities
          .filter((charity) => {
            return this.beneficiaries.find(
              (beneficiary) =>
                beneficiary.charity && beneficiary.charity.id === charity.id
            );
          })
          .map((charity) => charity.id);

        this.selectedBeneficiaryIDs = peopleIDs.concat(charityIDs);
      }
    },
    willMeta(newValue) {
      if (newValue && !this.hasFamily) {
        this.willMeta.estate_split = 'custom';
      }
    },
  },
  mounted() {
    this.willMeta.estate_backup_index = 0;
    this.updateWillMeta();
  },
  methods: {
    ...mapActions('charity', ['addCharity']),
    async done() {
      let availableDistribution = 100;
      let defaultDistribution;

      if (this.selectedSuggestedCharities.length) {
        await Promise.all(
          this.selectedSuggestedCharities.map((charity) => {
            return this.addPartnerCharity(charity);
          })
        );
        await this.refetchCharities();
        this.charities.forEach((charity) => {
          const charityMeta = metaArrayToObject(charity.meta);
          const exists = this.selectedSuggestedCharities.find(
            (selectedSuggestedCharity) => {
              return isSameCharity(selectedSuggestedCharity, charityMeta);
            }
          );
          if (exists) {
            this.selectedBeneficiaryIDs.push(charity.id);
          }
        });
      }

      switch (this.willMeta.estate_split) {
        case 'partner':
        case 'children':
          for (const charity of this.charities) {
            await this.removeBeneficiaryByCharity(charity);
          }

          for (const person of this.people) {
            if (!this.isChild(person) && !this.isPartner(person)) {
              await this.removeBeneficiaryByPerson(person);
            }
          }

          switch (this.willMeta.estate_split) {
            case 'partner':
              defaultDistribution = availableDistribution;

              for (const child of this.children) {
                await this.removeBeneficiaryByPerson(child);
              }

              for (const partner of this.partners) {
                const beneficiary = this.getBeneficiaryByPerson(partner);

                if (beneficiary) {
                  await this.updateBeneficiary(
                    beneficiary,
                    defaultDistribution
                  );
                } else {
                  await this.addBeneficiary(partner, defaultDistribution);
                }
              }

              break;
            case 'children':
              defaultDistribution =
                availableDistribution / this.children.length;

              for (const partner of this.partners) {
                await this.removeBeneficiaryByPerson(partner);
              }

              for (const child of this.children) {
                const beneficiary = this.getBeneficiaryByPerson(child);

                if (beneficiary) {
                  await this.updateBeneficiary(
                    beneficiary,
                    defaultDistribution
                  );
                } else {
                  await this.addBeneficiary(child, defaultDistribution);
                }
              }

              break;
            default:
              break;
          }

          break;
        case 'custom': {
          const existingBeneficiaries = this.beneficiaries.filter(
            (beneficiary) => {
              return this.selectedBeneficiaryIDs.find((id) => {
                return (
                  (beneficiary.charity && beneficiary.charity.id === id) ||
                  (beneficiary.person && beneficiary.person.id === id)
                );
              });
            }
          );

          existingBeneficiaries.forEach((beneficiary) => {
            availableDistribution -= parseFloat(beneficiary.distribution);
          });

          defaultDistribution =
            availableDistribution /
            (this.selectedBeneficiaryIDs.length - existingBeneficiaries.length);

          for (const person of this.people) {
            const beneficiary = this.getBeneficiaryByPerson(person);
            const isSelected = this.selectedBeneficiaryIDs.includes(person.id);

            if (isSelected) {
              if (!beneficiary) {
                await this.addBeneficiary(person, defaultDistribution);
              }
            } else if (beneficiary) {
              await this.removeBeneficiary(beneficiary);
            }
          }

          for (const charity of this.charities) {
            const beneficiary = this.getBeneficiaryByCharity(charity);
            const isSelected = this.selectedBeneficiaryIDs.includes(charity.id);

            if (isSelected) {
              if (!beneficiary) {
                await this.addBeneficiary(charity, defaultDistribution);
              }
            } else if (beneficiary) {
              await this.removeBeneficiary(beneficiary);
            }
          }

          break;
        }
        default:
          break;
      }

      const noCharity =
        this.willMeta.estate_split !== 'custom' ||
        !this.charities.find((charity) =>
          this.selectedBeneficiaryIDs.includes(charity.id)
        );

      if (noCharity) {
        return this.$router.push({
          path: '/estate/include-charity',
        });
      } else {
        this.willMeta.charity_in_estate = 'true';
        await this.updateWillMeta();
        this.$nuxt.$emit('sendTrackingAttributes', {
          has_charity_gift: !noCharity,
        });

        await this.refetchBeneficiaries();

        const currentPrimaryTotal = this.beneficiaries.reduce(
          (total, beneficiary) => {
            return total + Number(beneficiary.distribution);
          },
          0
        );
        const mustRedistribute =
          this.willMeta.estate_split === 'custom' ||
          currentPrimaryTotal !== defaultDistribution;

        this.$router.push({
          path: mustRedistribute
            ? '/estate/distribution'
            : `/estate/backup-beneficiaries/${this.backupQueue[0].id}`,
        });
      }
    },
  },
};
</script>
