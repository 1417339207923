<template>
  <BaseWizard
    v-if="showGiftWizard"
    class="gift-wizard"
    :hide-submit="!complete"
    data-type="Gift"
    @close="close"
  >
    <Form
      v-slot="{ loading }"
      :hide-submit="!complete"
      submit-label="Save Gift"
      @submit.prevent="submit"
    >
      <FormSection class="step-1">
        <template #heading>
          What type of gift is this?
        </template>
        <LargeRadioButtons
          id="gift_type"
          v-model="type"
          :options="[
            {
              icon: 'gift',
              label: 'A possession',
              value: 'possession',
            },
            {
              icon: 'wallet',
              label: 'Some money',
              value: 'money',
            },
          ]"
        />
      </FormSection>
      <FormSection v-if="type === 'possession'" class="step-2">
        <template #heading>
          Describe your gift
        </template>
        <Tip>
          Be specific with what you are gifting so that the possession can be
          easily identified. If your gift is too vague, such as ‘my ring’, it
          may be hard for your executor to make the correct gift and it may
          fail. You may also choose to gift an amount of money.
        </Tip>
        <TextInput
          id="gift_description"
          v-model="description"
          placeholder="record collection"
        />
      </FormSection>
      <FormSection v-else-if="type === 'money'" class="step-2">
        <template #heading>
          How much money are you giving?
        </template>
        <Tip>
          One person or not-for-profit can be listed per gift. You can add as
          many gifts as you like.
        </Tip>
        <MoneyInput id="gift_amount" v-model="amount" :max="9999999999" />
      </FormSection>
      <FormSection v-if="type" class="step-3">
        <template #heading>
          Who are you leaving this to?
        </template>
        <Tip v-if="type === 'possession'">
          Gifts to underage beneficiaries will only vest when they reach the age
          of 18. Until that time, they will be held on trust by your executor.
        </Tip>
        <Tip v-else-if="type === 'money'">
          Gifts to underage beneficiaries will only vest when they reach the age
          of 18. Until that time, they will be held on trust by your executor,
          or may be used for the maintenance of that underage beneficiary.
        </Tip>
        <PersonSelector
          v-model="recipients"
          label
          :loading="loading"
          :max="1"
          :people="people"
        />
        <h4 class="mb-4 text-2xl">
          Charities and Not-for-Profits
        </h4>
        <CharitySuggester
          v-if="showSuggestedCharities"
          v-model="recipients"
          :loading="loading"
          :selected-suggested-charities="recipients"
          :max="1"
        />
        <CharitySelector v-model="recipients" :loading="loading" :max="1" />
        <BaseButton
          class="btn-xl btn-white w-full add-charity"
          :disabled="loading"
          focusable
          @click="addCharity"
        >
          + Add a charity
        </BaseButton>
      </FormSection>
      <FormSection v-if="type" class="step-4">
        <template #heading>
          Notes about this gift
        </template>
        <Tip>
          Leave a quick note about this gift.
        </Tip>
        <TextArea
          id="gift_note"
          v-model="note"
          placeholder="Optional"
          maxlength="2500"
        />
      </FormSection>
    </Form>
  </BaseWizard>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import BaseButton from '~/components/BaseButton';
import BaseWizard from '~/components/BaseWizard';
import CharitySelector from '~/components/CharitySelector';
import CharitySuggester from '~/components/CharitySuggester';
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import LargeRadioButtons from '~/components/LargeRadioButtons';
import PersonSelector from '~/components/PersonSelector';
import MoneyInput from '~/components/MoneyInput';
import TextInput from '~/components/TextInput';
import TextArea from '~/components/TextArea';
import Tip from '~/components/Tip';

import { charities, gifts, people, user, will } from '~/mixins/apollo';
import { objectToMetaArray, metaArrayToObject } from '~/utilities';

export default {
  name: 'GiftWizard',
  components: {
    BaseButton,
    BaseWizard,
    CharitySelector,
    CharitySuggester,
    Form,
    FormSection,
    LargeRadioButtons,
    MoneyInput,
    PersonSelector,
    TextInput,
    TextArea,
    Tip,
  },
  mixins: [charities, gifts, people, user, will],
  data() {
    return {
      recipients: [],
      selectedSuggestedCharities: [],
    };
  },
  computed: {
    ...mapFields('gift', [
      'fields.type',
      'fields.description',
      'fields.amount',
      'fields.note',
    ]),
    ...mapGetters('gift', [
      'id',
      'fields',
      'showGiftWizard',
      'person',
      'charity',
    ]),
    mutation() {
      return this.id ? this.UPDATE_GIFT_MUTATION : this.ADD_GIFT_MUTATION;
    },
    showSuggestedCharities() {
      const hasReferral = !!this.willMeta.referral_charity;
      const hasAddedSuggestedCharity = this.charities.some((charity) => {
        const charityMeta = metaArrayToObject(charity.meta);
        return !!charityMeta.suggestionLocation;
      });
      return !hasReferral && !hasAddedSuggestedCharity;
    },
    variables() {
      const variables = {
        meta: objectToMetaArray(this.fields),
        person: null,
        charity: null,
        willId: this.willId,
      };

      if (this.id) {
        variables.id = this.id;
      } else {
        variables.willId = this.willId;
      }

      const recipient = this.recipients[0];

      if (recipient) {
        if (this.isPerson(recipient)) {
          variables.person = recipient;
        } else if (this.isCharity(recipient)) {
          variables.charity = recipient;
        }
      }

      return variables;
    },
    complete() {
      return (
        this.type && (this.amount || this.description) && this.recipients.length
      );
    },
  },
  methods: {
    ...mapActions('charity', ['addCharity']),
    ...mapActions('gift', ['setShowGiftWizard']),
    ...mapActions('person', ['addPerson']),
    async submit() {
      const noRecipientID = !this.variables.person && !this.variables.charity;
      if (noRecipientID) {
        const newRecipientABN = this.recipients[0].abn;
        await this.addPartnerCharity(this.recipients[0]);
        await this.refetchCharities();
        const newRecipient = this.charities.find((charity) => {
          const charityMeta = metaArrayToObject(charity.meta);
          return charityMeta.abn === newRecipientABN;
        });
        this.recipients = [newRecipient.id];
      }
      await this.$apollo.mutate({
        mutation: this.mutation,
        variables: this.variables,
        update: (store, res) => {
          this.update(store, res);
          this.close();
        },
      });
    },
    close() {
      this.setShowGiftWizard(false);
    },
    update(store, { data: { addGift } }) {
      if (addGift) {
        const data = store.readQuery(this.getGiftsQuery);
        data.getGifts.gifts.push(addGift.gift);

        store.writeQuery({
          ...this.getGiftsQuery,
          data,
        });
      }
    },
  },
};
</script>
