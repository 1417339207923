<template>
  <tr
    class="group border-t border-grey-300 bg-white hover:bg-grey-100 cursor-pointer transition-bg transition-100"
    @click="onClick"
  >
    <slot />
  </tr>
</template>

<script>
export default {
  name: 'AdminDataTableRow',
  props: {
    to: {
      default: null,
      type: String,
    },
  },
  methods: {
    onClick() {
      if (this.to) {
        this.$router.push({
          path: this.to,
        });
      }
    },
  },
};
</script>
