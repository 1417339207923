<template>
  <div class="relative">
    <div
      class="group flex flex-col items-start w-full h-96 p-8 rounded-lg bg-white shadow-2xl transition-card transition-250"
      :class="classes.card"
    >
      <Tooltip class="relative w-12 h-12 mb-4" :content="tooltip">
        <div v-if="issues">
          <div
            class="absolute flex justify-center items-center w-full h-full rounded-full bg-orange-300 text-white"
          >
            <BaseIcon id="alert" />
          </div>
        </div>
        <div v-else>
          <div
            class="absolute flex justify-center items-center w-full h-full rounded-full font-medium text-2xl text-teal-200"
            v-text="number"
          />
          <ProgressRing
            class="absolute w-full h-full"
            :progress="adjustedProgress"
          />
        </div>
      </Tooltip>
      <div class="w-full">
        <h2 class="mb-3 text-2xl md:text-3xl leading-none" v-text="title" />
        <BaseButton
          v-if="complete && editable"
          class="link-alt"
          @click="edit"
          @focus.native="$emit('focus')"
        >
          Edit
        </BaseButton>
        <p v-else-if="!complete" class="text-grey-600" v-text="description" />
      </div>
      <div
        v-if="complete || status === 'APPROVED'"
        class="flex flex-col flex-grow justify-end align-start w-full text-charcoal-100"
      >
        <slot v-if="!loading" />
      </div>
      <template v-else>
        <nuxt-link
          :to="to"
          no-prefetch
          class="group-hover:flex btn btn-lg btn-teal w-full mt-auto font-medium"
          :class="classes.button"
          @focus.native="$emit('focus')"
          v-text="buttonText"
        />
        <span
          class="group-hover:hidden inline-block w-full mt-auto text-right text-grey-400"
          :class="classes.link"
          v-text="`${duration.join('-')} mins`"
        />
      </template>
    </div>
    <div
      v-if="loading"
      class="absolute z-10 inset-0 flex justify-center items-center text-teal-200"
    >
      <LoadingDots animation="slide" />
    </div>
  </div>
</template>

<script>
import BaseButton from '~/components/BaseButton';
import BaseIcon from '~/components/BaseIcon';
import LoadingDots from '~/components/LoadingDots';
import ProgressRing from '~/components/ProgressRing';
import Tooltip from '~/components/Tooltip';

export default {
  name: 'ModuleCard',
  components: {
    BaseButton,
    BaseIcon,
    LoadingDots,
    ProgressRing,
    Tooltip,
  },
  props: {
    description: {
      type: String,
      required: true,
    },
    duration: {
      type: Array,
      required: true,
    },
    issues: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    number: {
      type: Number,
      required: true,
    },
    progress: {
      type: Number,
      required: true,
    },
    ready: {
      default: false,
      type: Boolean,
    },
    title: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    adjustedProgress() {
      return this.ready && this.progress === 0 ? 0.05 : this.progress;
    },
    buttonText() {
      return this.continue ? 'Continue' : 'Start';
    },
    classes() {
      return {
        button: {
          hidden: !this.continue,
        },
        card: {
          'opacity-50 scale-98': this.loading,
        },
        link: {
          hidden: this.continue,
        },
      };
    },
    complete() {
      return this.progress === 1;
    },
    continue() {
      return this.ready || this.progress;
    },
    tooltip() {
      let tooltip = 'This card is partially complete.';

      switch (this.status) {
        case 'IN_PROGRESS':
          if (this.complete) {
            tooltip =
              'This card is complete. You can make changes once your submission has been checked.';
          }
          break;
        case 'AWAITING_APPROVAL':
          tooltip =
            'This card is complete. You can make changes once your document is reviewed.';
          break;
        case 'FLAGGED':
          if (this.issues) {
            tooltip = 'There card needs attention.';
          } else {
            tooltip =
              'This card is complete. You can make changes once your submission has been checked.';
          }
          break;
        case 'APPROVED':
          tooltip = 'Unlock your document to make changes to this card.';
          break;
        default:
          break;
      }

      return tooltip;
    },
  },
  methods: {
    edit() {
      this.$router.push({
        path: this.to,
      });
    },
  },
};
</script>
