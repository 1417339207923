<template>
  <div v-if="masquerading" class="relative z-10 bg-red-300">
    <div class="container gutters mx-auto py-3">
      <div class="flex items-center justify-between flex-wrap">
        <div class="flex items-center">
          <span class="flex p-2 rounded-lg text-white">
            <BaseGlyph id="exclamation" />
          </span>
          <p class="ml-3 font-medium text-white">
            You are masquerading as {{ email }}
          </p>
        </div>
        <div
          class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto"
        >
          <div class="rounded-md shadow-sm">
            <BaseButton
              id="exitMock"
              class="flex items-center justify-center w-full px-4 py-2 text-sm leading-5 font-medium rounded-md bg-white focus:outline-none focus:shadow-outline transition ease-in-out duration-150 mr-6 text-red-300"
              @click="exit"
            >
              Exit
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import BaseGlyph from '~/components/BaseGlyph';
import BaseButton from '~/components/BaseButton';
import CURRENT_USER_QUERY from '~/graphql/queries/CurrentUser.gql';

export default {
  components: {
    BaseGlyph,
    BaseButton,
  },
  computed: {
    ...mapGetters(['masquerading', 'email']),
    ...mapMutations([
      'setMasquerading',
      'setEmail',
      'setUserId',
      'setVerified',
    ]),
  },
  methods: {
    ...mapMutations(['setPoaId']),
    async exit() {
      try {
        await this.$apollo.query({
          query: CURRENT_USER_QUERY,
        });
        this.setPoaId(null);
        this.$router.push({ path: '/admin' });
      } catch (e) {
        console.error(e.message);
      }
    },
  },
};
</script>
