<template>
  <div class="flex min-h-screen">
    <div class="md:w-3/6 justify-center py-4 px-4">
      <a href="/">
        <Logo
          wordmark
          icon-color="#000"
          text-color="#000"
          class="h-12 lg:ml-8 mt-8"
        />
      </a>
      <div class="relative max-w-3xl mx-auto">
        <div v-if="screen !== 'callback'">
          <Form class="mt-10" hide-submit submit-label="Next" @submit="next">
            <FormSection>
              <h1 class="mb-6 text-6xl">
                Getting
                <TextMarker
                  class="text-teal-100"
                  stroke-width="9"
                  type="circle-stroke"
                >
                  started
                </TextMarker>
              </h1>
              <p class="mr-10 text-xl">
                We’ll start with a few basic questions to set up your profile.
                It only takes a minute.
              </p>
              <br />
              <div class="flex items-center">
                <BaseIcon
                  id="message"
                  classes="stroke-0 absolute left-0 w-10 h-auto"
                />
                <div>
                  <h4 class="ml-14 text-xl">Live support</h4>
                  <p class="ml-14 mr-20 text-base text-charcoal-200">
                    Live chat and phone support is at hand should you need any
                    assistance.
                  </p>
                </div>
              </div>
              <br />
              <div class="flex items-center">
                <BaseIcon
                  id="glasses"
                  classes="stroke-0 absolute left-0 w-10 h-auto"
                />
                <div>
                  <h1 class="ml-14 text-xl">Legal expertise</h1>
                  <p class="ml-14 mr-20 text-base text-charcoal-200">
                    Safewill is Australia’s only online will writing service
                    where your will is reviewed by legal advisors.
                  </p>
                </div>
              </div>
              <br />
              <div class="flex items-center">
                <div class="flex items-center">
                  <BaseIcon
                    id="card"
                    classes="stroke-0 absolute left-0 w-10 h-auto flex items-center"
                  />
                  <div v-if="isPlan">
                    <h1 class="ml-14 text-xl">Exclusive Discount</h1>
                    <p class="ml-14 mr-20 text-base text-charcoal-200">
                      As a valued Plan International Australia supporter, write
                      your free Will today.
                    </p>
                  </div>
                  <div v-else>
                    <h1 class="ml-14 text-xl">Pay when you're ready</h1>
                    <p class="ml-14 mr-20 text-base text-charcoal-200">
                      We’ll only ask for payment once you’re ready to submit
                      your will to our legal team for review.
                    </p>
                  </div>
                </div>
              </div>
            </FormSection>
          </Form>
          <div class="flex items-center">
            <BaseButton
              class="flex items-center justify-center w-full mt-14 bg-slate-100 btn-xl text-left text-navy-100"
              @click="next"
            >
              <div class="flex w-full items-center">
                <span class="w-full text-white text-xl">
                  Next
                </span>
                <BaseIcon id="arrow-right" classes="text-green-300" />
              </div>
            </BaseButton>
          </div>
          <br />
          <div class="flex items-center">
            <BaseButton
              class="flex items-center justify-center w-full btn-xl bg-teal-100 text-left text-navy-100"
              @click="callback"
            >
              <div class="flex w-full items-center">
                <span class="w-full text-slate-100 text-xl">
                  Speak to our team instead
                </span>
                <BaseIcon id="arrow-right" classes="text-green-300" />
              </div>
            </BaseButton>
          </div>
        </div>
        <div v-else>
          <button
            class="mt-10 mb-6 w-full flex items-center text-grey-600"
            @click="back"
          >
            <BaseIcon id="arrow-left" classes="mr-2 text-green-300" />
            Back
          </button>
          <h1 class="mb-6 text-5xl">
            Write your Will over the
            <TextMarker
              class="text-teal-100"
              stroke-width="9"
              type="circle-stroke"
            >
              phone
            </TextMarker>
          </h1>
          <p class="mb-6 mr-8 text-lg">
            Want some help writing your Will? Our team of experts can help you
            fill out your Will via phone or chat, at no extra cost!
          </p>
          <p class="mb-6 mr-8 text-lg">
            We are open 7 days a week so call us on
            <a href="tel:1800103310" class="link link-dark whitespace-no-wrap">
              1800 10 33 10
            </a>
            or
            <button
              type="button"
              class="link link-dark whitespace-no-wrap"
              @click="showChat"
            >
              start a chat with our team
            </button>
            now.
          </p>
        </div>
      </div>
    </div>
    <div
      class="hidden md:flex bg-cover md:w-3/6 bg-center"
      :style="background ? `backgroundImage: url(${background})` : null"
    >
      <div class="text-lg pl-14 pb-12 flex overlay-gradient w-full">
        <div class="flex justify-end flex-col">
          <div class="flex items-center">
            <span class="flex relative h-3 w-3">
              <span
                class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-200 opacity-75"
              ></span>
              <span
                class="relative inline-flex rounded-full h-3 w-3 bg-green-200"
              ></span>
            </span>
            <span class="text-xl text-green-100 font-medium mt-2 ml-3 mb-3">
              We’re online
            </span>
          </div>
          <span class="w-8/12 text-left text-white">
            Our customer care team is online and ready to answer your questions.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import cookies from 'js-cookie';

import { showChat } from '@/modules/chat';
import { referralCodesToCharities } from '@/modules/referralCodes';
import { referralCharities } from '@/modules/charityData';

import BaseIcon from '~/components/BaseIcon';
import BaseButton from '~/components/BaseButton';
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import Logo from '~/components/Logo';
import TextMarker from '~/components/TextMarker';

import tracking from '~/mixins/tracking';

export default {
  name: 'GetStartedIntro',
  components: {
    Form,
    FormSection,
    TextMarker,
    BaseIcon,
    BaseButton,
    Logo,
  },
  mixins: [tracking],
  data() {
    return {
      background: null,
      screen: 'start',
    };
  },
  computed: {
    ...mapFields('signup', ['meta.has_partner']),
    isPlan() {
      const coupon = cookies.get('coupon');
      return coupon && coupon.includes('PLAN');
    },
  },
  created() {
    this.$root.setLayout('minimal');
  },
  mounted() {
    this.setBackground();
  },
  methods: {
    back() {
      this.screen = 'start';
    },
    callback() {
      this.screen = 'callback';
    },
    next() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: '🏁 Get Started',
      });
      this.$router.push({
        path: '/get-started/resident',
        query: this.$route.query,
      });
    },
    scheduleCallback() {
      this.$router.push({
        path: '/get-started/callback',
      });
    },
    setBackground() {
      const referral = cookies.get('referral_charity')
        ? cookies.get('referral_charity').toLowerCase()
        : null;
      const charityKeys = referralCodesToCharities[referral];
      const charityBackground = charityKeys?.find((charityKey) => {
        return !!referralCharities[charityKey].background;
      });
      if (charityBackground) {
        this.background = referralCharities[charityBackground].background;
      } else {
        this.background = require('~/assets/img/pilot-kid.png');
      }
    },
    showChat() {
      showChat();
    },
  },
};
</script>
