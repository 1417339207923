<template>
  <table class="w-full text-left text-slate-100">
    <tbody>
      <tr
        v-for="(row, index) in rows"
        :key="index"
        :class="index % 2 ? 'bg-white' : 'bg-grey-100'"
      >
        <td class="w-48 h-8 px-2 opacity-50">
          <div v-text="row[0]" />
        </td>
        <td class="h-8 px-2" v-text="row[1]" />
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'AdminSimpleTable',
  props: {
    rows: {
      // [['', ''], ...]
      type: Array,
      required: true,
    },
  },
};
</script>
