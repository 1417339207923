<template>
  <ModuleStep :index="1" prev-step="/dashboard" :show-help="true">
    <Form
      submit-arrow
      @submit="$router.push({ path: '/estate/beneficiaries' })"
    >
      <FormSection>
        <template #heading>
          Your Estate
        </template>
        <p>
          <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
          Your
          <GlossaryTooltip term="Estate / Residual estate">
            estate
          </GlossaryTooltip>
          is everything that you own, apart from any specific gifts you leave
          (which you will be asked about in the next section)
        </p>
        <Tip>
          Although you are able to split your estate however you wish, you
          should keep in mind that financial dependents (such as your spouse or
          kids) may have a claim to part of your estate, and excluding them may
          result in conflict.
        </Tip>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import GlossaryTooltip from '~/components/GlossaryTooltip';
import ModuleStep from '~/components/ModuleStep';
import Tip from '~/components/Tip';

export default {
  name: 'EstateIntro',
  components: {
    Form,
    FormSection,
    GlossaryTooltip,
    ModuleStep,
    Tip,
  },
};
</script>
