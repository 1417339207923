<template>
  <div>
    <label class="input-label">
      {{ meta.full_name }}
    </label>
    <div v-if="meta" class="relative">
      <div
        class="w-full max-w-full h-20 px-6 py-6 border-2 border-grey-200 rounded text-xl text-charcoal-200 whitespace-no-wrap overflow-hidden"
      >
        {{ meta.email }}
      </div>
      <div
        class="pt-4 md:pt-0 md:absolute md:top-0 md:right-0 md:bottom-0 flex items-center md:pr-6"
      >
        <div class="flex text-teal-200">
          <span class="text-charcoal-100 mr-1">Completed</span>
          <BaseIcon id="tick" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '~/components/BaseIcon';

export default {
  name: 'ExecutorNotified',
  components: {
    BaseIcon,
  },
  props: {
    meta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      error: '',
      success: '',
      loading: false,
    };
  },
};
</script>
