<template>
  <div class="px-8 border-b border-grey-300 last-child:border-b-0">
    <div ref="wrapper" class="relative w-full">
      <div ref="header" class="flex items-center py-4">
        <div
          class="inline-flex items-center mr-auto cursor-pointer"
          @click="toggle"
        >
          <span class="inline-block mr-4 text-teal-200">
            <BaseGlyph :id="expanded ? 'angle-up' : 'angle-down'" />
          </span>
          <h5 class="font-medium text-black" v-text="title" />
        </div>
        <AdminFlagCheckbox
          v-model="flagged"
          :will-id="willId"
          :module-name="moduleName"
          :disabled="disabled"
          @change="$emit('change', flagged)"
        />
      </div>
      <transition :css="false" @enter="expand" @leave="collapse">
        <div v-if="expanded" class="w-full pb-8" :class="{ absolute: !done }">
          <slot />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import anime from 'animejs';

import AdminFlagCheckbox from '~/components/AdminFlagCheckbox';
import BaseGlyph from '~/components/BaseGlyph';

const DURATION = 350;

export default {
  name: 'AdminModuleSummary',
  components: {
    AdminFlagCheckbox,
    BaseGlyph,
  },
  model: {
    event: 'change',
  },
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      default: '',
      type: [String, Boolean],
    },
    moduleName: {
      required: true,
      type: String,
    },
    willId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      done: true,
      expanded: true,
      flagged: this.value,
    };
  },
  watch: {
    value(newValue) {
      if (!newValue) this.flagged = false;
    },
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
    cancel() {
      anime.remove(this.$el);
    },
    expand(el, complete) {
      this.cancel();

      anime({
        targets: this.$refs.wrapper,
        height: this.getHeight(this.$refs.header) + this.getHeight(el),
        duration: DURATION,
        easing: 'cubicBezier(0.4, 0, 0.2, 1)',
        complete: () => {
          this.done = true;
          this.$refs.wrapper.style.height = null;
          complete();
        },
      });
    },
    collapse(el, complete) {
      this.cancel();

      anime({
        targets: this.$refs.wrapper,
        height: this.getHeight(this.$refs.header),
        duration: DURATION,
        easing: 'cubicBezier(0.4, 0, 0.2, 1)',
        begin: () => {
          this.done = false;
        },
        complete: () => {
          this.$refs.wrapper.style.height = null;
          complete();
        },
      });
    },
    getHeight(el) {
      return el.getBoundingClientRect().height;
    },
  },
};
</script>
