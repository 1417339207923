<template>
  <ModuleStep :index="2" prev-step="/executors">
    <Form v-slot="{ loading }" hide-submit>
      <FormSection>
        <template #heading>
          Who should be your Executor?
        </template>
        <Tip>
          Being an executor is a serious responsibility. Select an option below
          to learn more, or start a live chat if you need help.
        </Tip>
        <div>
          <ExpandingCard
            v-for="(option, index) in executorOptions"
            :key="index"
            :open="option.value === willMeta.executors_option"
            class="mb-5"
            @click="willMeta.executors_option = option.value"
          >
            <template #title>
              {{ option.label }}
            </template>
            <div class="flex items-center my-6">
              <BaseIcon id="info" />
              <span
                class="ml-3 font-bold text-sm tracking-wide uppercase text-slate-100"
              >
                You should know
              </span>
            </div>
            <div class="text-lg">
              <template v-if="option.value === 'friendsFamily'">
                <p class="mb-8">
                  Being an executor is a serious responsibility that requires a
                  lot of time and effort. If you’re thinking about choosing
                  friends or family, you’ll need to choose people who would be:
                </p>
                <ul class="unordered-list">
                  <li>
                    Trusted by you
                  </li>
                  <li>
                    Familiar with legal and financial paperwork
                  </li>
                  <li>
                    Less likely to be influenced by family conflicts and
                    disputes
                  </li>
                  <li>
                    Willing and available to help when the time comes
                  </li>
                </ul>
              </template>
              <template v-else-if="option.value === 'professional'">
                <p class="mb-8">
                  Safewill Professional Executors are trained professionals who
                  can execute your will if you aren’t comfortable with giving
                  the responsibility to a friend or family member, or don’t know
                  anyone personally who would be suitable or available to handle
                  the financial and legal actions involved.
                </p>
                <p>
                  Choose Safewill to be your executor and you’ll rest easy
                  knowing that it’s all taken care of by one of Australia’s
                  leading estate planning service. You’ll be choosing an
                  objective third-party who is already prepared for the work
                  required to act as your Executor.
                </p>
              </template>
              <template
                v-else-if="option.value === 'friendsFamilyAndProfessional'"
              >
                <p class="mb-8">
                  Choose this option if you’d primarily like Safewill
                  Professional Executors to carry out your Will, but would still
                  like the input of a friend or family member to act as a
                  primary contact.
                </p>
                <p>
                  Safewill’s Professional Executors will carry out your Will as
                  instructed and work together with the primary contact if
                  required. This also means that your contact will be named as a
                  back-up executor, so they can take over if it is in the best
                  interests of your estate for them to do so.
                </p>
              </template>
            </div>
            <ReviewsSummaryCard
              v-if="option.value !== 'friendsFamily'"
              class="mt-8"
            />
            <BaseButton
              class="btn-xl btn-slate w-full mt-5"
              :loading="isLoading"
              @click="onSubmit"
            >
              <div class="flex justify-between items-center">
                <span class="inline-block text-white">Select</span>
                <span class="ml-4">
                  <BaseIcon id="arrow-right" />
                </span>
              </div>
            </BaseButton>
          </ExpandingCard>
        </div>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import BaseButton from '~/components/BaseButton';
import BaseIcon from '~/components/BaseIcon';
import ExpandingCard from '~/components/ExpandingCard';
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import ModuleStep from '~/components/ModuleStep';
import ReviewsSummaryCard from '~/components/ReviewsSummaryCard';
import Tip from '~/components/Tip';

import { executors, people, user, will } from '~/mixins/apollo';

export default {
  name: 'ExecutorsOptions',
  components: {
    BaseButton,
    BaseIcon,
    ExpandingCard,
    Form,
    FormSection,
    ModuleStep,
    ReviewsSummaryCard,
    Tip,
  },
  mixins: [executors, people, user, will],
  data() {
    return {
      executorOptions: [
        {
          label: 'Friends & Family',
          value: 'friendsFamily',
        },
        {
          label: 'Safewill Professional Executors',
          value: 'professional',
        },
        {
          label: 'Safewill Professional Executors with Friends & Family',
          value: 'friendsFamilyAndProfessional',
        },
      ],
      isLoading: false,
    };
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;

      let path;
      this.willMeta.professional_executor_terms_check = false;
      this.willMeta.professional_executor_payment_check = false;

      switch (this.willMeta.executors_option) {
        case 'friendsFamily':
          path = '/executors/primary';

          break;
        case 'professional':
          path = '/executors/professional-terms';
          this.willMeta.has_backup_executor = false;

          this.$nuxt.$emit('sendTrackingEvent', {
            event: '👨‍🎓 Safewill Professional Executor',
            props: { type: 'Professional' },
          });

          await Promise.all(
            this.people.map((person) => {
              if (
                this.isPrimaryExecutor(person) ||
                this.isBackupExecutor(person)
              ) {
                return this.removeExecutor(person);
              }
            })
          );

          break;
        case 'friendsFamilyAndProfessional':
          path = '/executors/primary-contact';
          this.willMeta.has_backup_executor = false;

          this.$nuxt.$emit('sendTrackingEvent', {
            event: '👨‍🎓 Safewill Professional Executor',
            props: { type: 'Professional with Friends & Family' },
          });

          await Promise.all(
            this.people.map((person) => {
              if (this.isBackupExecutor(person)) {
                return this.removeExecutor(person);
              }
            })
          );

          break;
        default:
          break;
      }

      await this.updateWillMeta();

      this.$router.push({ path });
    },
  },
};
</script>
