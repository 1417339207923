<template>
  <nuxt-link to="/log-in" class="inline-flex items-center text-teal-200">
    <span class="mr-3 font-medium text-lg leading-none text-black">
      Log In
    </span>
    <BaseIcon id="login" />
  </nuxt-link>
</template>

<script>
import BaseIcon from '~/components/BaseIcon';

export default {
  name: 'LogInButton',
  components: {
    BaseIcon,
  },
};
</script>
