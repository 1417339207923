<template>
  <ModuleStep :index="2" prev-step="/poa/about-yourself">
    <Form
      v-slot="{ loading }"
      auto-focus
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      submit-arrow
      @done="done"
    >
      <FormSection>
        <template #heading>
          What's your current residential address?
        </template>
        <FormRow>
          <TextInput
            id="address_street"
            v-model="willMeta.address_street"
            :disabled="loading"
            placeholder="Street Address"
          />
        </FormRow>
        <FormRow>
          <TextInput
            id="address_suburb"
            v-model="willMeta.address_suburb"
            :disabled="loading"
            placeholder="Suburb"
          />
        </FormRow>
        <FormRow>
          <SelectInput
            id="address_state"
            v-model="willMeta.address_state"
            class="w-full mb-2 md:w-1/2 md:mr-2 md:mb-0"
            :disabled="loading"
            placeholder="State"
            :options="australianStates"
            searchable
          />
          <TextInput
            id="address_postcode"
            v-model="willMeta.address_postcode"
            class="w-full md:w-1/2"
            :disabled="loading"
            placeholder="Postcode"
          />
        </FormRow>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';

import Form from '~/components/Form';
import FormRow from '~/components/FormRow';
import FormSection from '~/components/FormSection';
import ModuleStep from '~/components/ModuleStep';
import SelectInput from '~/components/SelectInput';
import TextInput from '~/components/TextInput';

import { getUserFullAddress } from '~/utilities';
import { beneficiaries, user, will } from '~/mixins/apollo';

export default {
  name: 'AboutYourselfAddress',
  components: {
    Form,
    FormRow,
    FormSection,
    ModuleStep,
    SelectInput,
    TextInput,
  },
  mixins: [beneficiaries, user, will],
  computed: {
    ...mapGetters('ui', ['australianStates']),
    isComplete() {
      return !!(
        this.willMeta.address_street &&
        this.willMeta.address_suburb &&
        this.willMeta.address_state &&
        this.willMeta.address_postcode
      );
    },
  },
  methods: {
    done() {
      const willMeta = this.willMeta;
      this.$nuxt.$emit('sendTrackingAttributes', {
        address: getUserFullAddress(willMeta),
      });

      this.$router.push({
        path: '/poa/about-yourself/date-of-birth',
      });
    },
  },
};
</script>
