<template>
  <span>
    <span v-if="!['APPROVED', 'FLAGGED'].includes(status)">
      is
    </span>
    <Tooltip class="relative" :content="tooltip">
      <span class="relative inline-block">
        <span class="relative z-10" v-text="text" />
        <span
          class="absolute top-100 left-0 right-0 inline-block mt-1 overflow-hidden"
          :class="status === 'FLAGGED' ? 'text-orange-200' : 'text-teal-100'"
        >
          <svg
            class="w-full"
            height="6"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMin"
          >
            <path
              class="stroke-current"
              d="M0 3h9999"
              fill="none"
              stroke-width="6"
              :stroke-dasharray="status === 'FLAGGED' ? '12,8' : null"
            />
          </svg>
        </span>
      </span>
    </Tooltip>
  </span>
</template>

<script>
import Tooltip from '~/components/Tooltip';

export default {
  name: 'GenericStatusText',
  components: {
    Tooltip,
  },
  props: {
    product: {
      default: 'Will',
      type: String,
    },
    ready: {
      default: false,
      type: Boolean,
    },
    status: {
      required: true,
      type: String,
    },
  },
  computed: {
    dashed() {
      return this.status === 'FLAGGED';
    },
    text() {
      let text = '';
      switch (this.status) {
        case 'IN_PROGRESS':
          text = this.ready ? 'ready to submit' : 'in progress';
          break;
        case 'AWAITING_APPROVAL':
          text = 'being reviewed';
          break;
        case 'FLAGGED':
          text = 'needs attention';
          break;
        case 'APPROVED':
          text = 'is complete';
          break;
        default:
          break;
      }
      return text;
    },
    tooltip() {
      let tooltip = '';
      switch (this.status) {
        case 'IN_PROGRESS':
          tooltip = this.ready
            ? `When you’re ready submit your ${this.product}, and our legal advisers ${this.product} review your application within 3 business days.`
            : `Once you’ve completed all the cards below, you’ll be able to submit your ${this.product} to our legal advisors to review.`;
          break;
        case 'AWAITING_APPROVAL':
          tooltip = `Our team is reviewing your ${this.product}. You’ll receive an email once the ${this.product} is approved for signing.`;
          break;
        case 'FLAGGED':
          tooltip = `One or more cards below need your attention before you can submit for review again.`;
          break;
        case 'APPROVED':
          tooltip = `Your ${this.product} is ready to download, print and sign.`;
          break;
        default:
          break;
      }
      return tooltip;
    },
  },
};
</script>
