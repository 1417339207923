<template>
  <span
    class="inline-flex justify-center items-center h-8 px-4 rounded-full whitespace-no-wrap shadow-chip"
    :class="classes"
    v-text="label"
  />
</template>

<script>
export default {
  name: 'AdminStatusChip',
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    classes() {
      let classes;

      switch (this.status) {
        case 'IN_PROGRESS':
          classes = 'bg-grey-300 text-slate-100';
          break;
        case 'AWAITING_APPROVAL':
          classes = 'bg-brown-100 text-brown-200';
          break;
        case 'APPROVED':
          classes = 'bg-teal-100 text-teal-300';
          break;
        case 'FLAGGED':
          classes = 'bg-cerise-100 text-cerise-200';
          break;
        case 'ARCHIVED':
          classes = 'bg-grey-100 text-charcoal-100';
          break;
        default:
          break;
      }

      return classes;
    },
    label() {
      let label;

      switch (this.status) {
        case 'IN_PROGRESS':
          label = 'In Progress';
          break;
        case 'AWAITING_APPROVAL':
          label = 'Awaiting Review';
          break;
        case 'APPROVED':
          label = 'Approved';
          break;
        case 'FLAGGED':
          label = 'Flagged';
          break;
        case 'ARCHIVED':
          label = 'Archived';
          break;
        default:
          break;
      }

      return label;
    },
  },
};
</script>
