<template>
  <div>
    <Form hide-submit @submit.prevent="submitPayment">
      <div
        class="container gutters flex flex-col lg:flex-row min-h-full mx-auto"
      >
        <div class="md:flex-grow mb-0 lg:pb-16 lg:pr-15">
          <div class="flex items-center h-16">
            <BaseButton class="text-black" focusable @click="close">
              <BaseIcon id="arrow-left" />
            </BaseButton>
          </div>
          <nuxt-link to="/" class="inline-block h-8 mb-6 md:mt-8">
            <Logo wordmark class="h-8" />
          </nuxt-link>
          <h2 class="mb-6 text-6xl md:text-8xl leading-tight">
            Add your <Squiggle>POA</Squiggle>
          </h2>
          <div class="max-w-3xl text-xl">
            Now that you’ve submitted your Will for review, appoint your Powers
            of Attorney to choose someone to look after your living wishes.
          </div>

          <div
            v-if="['tas'].includes(userState)"
            class="flex-col bg-grey-200 items-baseline p-8 my-4 rounded font-medium text-base"
          >
            <span
              class="inline-flex justify-center items-center h-6 px-3 rounded-full font-bold text-sm text-white leading-none tracking-widest uppercase select-none bg-teal-300"
            >
              TASMANIAN RESIDENTS
            </span>
            <div class="flex mt-4">
              <BaseIcon
                id="info-alt"
                classes="flex-shrink-0 mr-4 mt-1 text-teal-300"
              />
              <div class="font-normal text-charcoal-500">
                <p>
                  Please note that in Tasmania, your Power of Attorney must be
                  registered before it can be legally valid and enforceable. The
                  current fee to register a financial Power of Attorney with the
                  Land Titles Office is $149.32. The current fee to register a
                  medical Power of Attorney with Service Tasmania is $74.25.
                  These fees are in addition to the below Safewill fee.
                </p>
              </div>
            </div>
          </div>
          <GenericCheckoutSummary
            v-if="!priceLoading"
            :checkout-items="checkoutItems"
            :total-price="checkoutTotalPrice"
            product-type="poa"
            class="mt-8 lg:hidden"
          />
          <div class="pt-10">
            <div>
              <GenericPaymentStripe ref="genericPaymentStripe" />

              <BaseAlert v-if="paymentError" class="w-full mt-2" type="error">
                {{ paymentError }}
              </BaseAlert>

              <FormSection>
                <FormRow>
                  <div class="w-full hidden lg:block">
                    <BaseButton
                      class="btn btn-xl btn-slate w-full pt-0 mt-2 mb-4 font-medium"
                      :loading="paymentLoading || paymentProcessing"
                      focusable
                      type="submit"
                    >
                      <div class="flex items-center text-white">
                        <span
                          class="inline-block mr-auto"
                          v-text="submitButtonText"
                        />
                        <span class="ml-4">
                          <BaseIcon id="arrow-right" />
                        </span>
                      </div>
                    </BaseButton>
                  </div>
                </FormRow>
              </FormSection>
              <CheckoutFaq :faq="faq" classes="pt-10 hidden lg:block" />
            </div>
          </div>
        </div>
        <div
          class="relative w-full lg:max-w-xl flex-shrink-0 pt-8 lg:pt-0 pb-20 lg:py-32 border-grey-300"
        >
          <div class="relative z-10 lg:mt-20 lg:pl-15">
            <template>
              <GenericCheckoutSummary
                v-if="!priceLoading"
                :checkout-items="checkoutItems"
                :total-price="checkoutTotalPrice"
                product-type="poa"
                class="hidden md:block md:fixed md:pr-12"
                style="width: 350px;"
              />
              <div class="lg:hidden">
                <BaseButton
                  class="btn btn-xl btn-slate lg:hidden w-full mt-2 mb-4 font-medium"
                  :loading="paymentLoading || paymentProcessing"
                  focusable
                  type="submit"
                >
                  <div class="flex items-center text-white">
                    <span
                      class="inline-block mr-auto"
                      v-text="submitButtonText"
                    />
                    <span class="ml-4">
                      <BaseIcon id="arrow-right" />
                    </span>
                  </div>
                </BaseButton>
                <p class="max-w-2xl ml-auto text-charcoal-100 md:text-right">
                  <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
                  By continuing you confirm that you have
                  <GlossaryTooltip term="Testamentary capacity">
                    testamentary
                  </GlossaryTooltip>
                  and physical capacity, and are not writing the Will under
                  <GlossaryTooltip term="Duress"> duress </GlossaryTooltip>.
                </p>
                <CheckoutFaq :faq="faq" classes="pt-10" />
              </div>
            </template>
          </div>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import upperFirst from 'lodash/upperFirst';
import { mapActions, mapGetters } from 'vuex';

import BaseAlert from '~/components/BaseAlert';
import BaseButton from '~/components/BaseButton';
import BaseIcon from '~/components/BaseIcon';
import CheckoutFaq from '~/components/CheckoutFaq';
import Form from '~/components/Form';
import FormRow from '~/components/FormRow';
import FormSection from '~/components/FormSection';
import GenericCheckoutSummary from '~/components/GenericCheckoutSummary';
import GenericPaymentStripe from '~/components/GenericPaymentStripe';
import GlossaryTooltip from '~/components/GlossaryTooltip';
import Logo from '~/components/Logo';
import Squiggle from '~/components/Squiggle';
import { formatError } from '~/utilities';

import GET_POA_PRICE from '~/graphql/queries/GetPoaPrice';
import CREATE_STRIPE_INVOICE_FOR_POA from '~/graphql/mutations/CreateStripeInvoiceForPoa';
import GET_POA_STATUS from '~/graphql/queries/GetPoaStatus';

import { subscription, user, will, error } from '~/mixins/apollo';
import tracking from '~/mixins/tracking';

function calculateFinalPoaPrice(poaPriceFromBackend) {
  if (!poaPriceFromBackend) {
    return null;
  }
  const currentTime = Date.now();
  if (
    poaPriceFromBackend.discount &&
    poaPriceFromBackend.discount.expiresAt > currentTime
  ) {
    return poaPriceFromBackend.discount.price;
  }
  return poaPriceFromBackend.fullPrice;
}

export default {
  name: 'PoaCheckout',
  apollo: {
    poaPrice: {
      prefetch: false,
      fetchPolicy: 'no-cache',
      query: GET_POA_PRICE,
      error,
    },
  },
  key(route) {
    return route.fullPath;
  },
  components: {
    BaseButton,
    BaseIcon,
    CheckoutFaq,
    Form,
    FormRow,
    FormSection,
    GenericCheckoutSummary,
    GenericPaymentStripe,
    GlossaryTooltip,
    Logo,
    Squiggle,
    BaseAlert,
  },
  middleware: ['authenticated'],
  mixins: [subscription, user, will, tracking],
  data() {
    return {
      poaPrice: null,
      paymentError: null,
      faq: [
        {
          question: 'What happens next?',
          answer:
            'Once you’ve paid for your Powers of Attorney, you can complete it anytime (just like you did with your Will). As always, our live chat and phone team are here during extended business hours, should you need a hand.',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('checkout', ['paymentProcessing', 'paymentLoading']),
    ...mapGetters(['poaId', 'masquerading', 'userId']),
    priceLoading() {
      return this.checkoutTotalPrice === null;
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    checkoutItems() {
      return [
        {
          name: 'Power Of Attorney',
          price: calculateFinalPoaPrice(this.poaPrice),
          icon: 'shield',
        },
      ];
    },
    checkoutTotalPrice() {
      return calculateFinalPoaPrice(this.poaPrice);
    },
    submitButtonText() {
      return 'Complete Order';
    },
    firstName() {
      return this.willMeta && upperFirst(this.willMeta.name_first);
    },
  },
  methods: {
    ...mapActions('checkout', [
      'setPaymentLoading',
      'setPaymentProcessing',
      'setPaymentSuccess',
    ]),
    close() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: '🚫 Cancelled POA Checkout',
      });
      this.$router.replace({ path: '/poa' });
    },
    async submitPayment() {
      this.setPaymentLoading(true);
      this.setPaymentProcessing(true);

      try {
        const stripeToken = await this.$refs.genericPaymentStripe.getStripeToken();

        if (stripeToken) {
          this.setPaymentSuccess(true);
          const result = await this.$apollo.mutate({
            mutation: CREATE_STRIPE_INVOICE_FOR_POA,
            variables: {
              cardToken: stripeToken.id,
              ...(this.masquerading && { userId: this.userId }),
            },
            refetchQueries: [
              {
                query: GET_POA_STATUS,
                variables: { id: this.poaId },
              },
            ],
            awaitRefetchQueries: true,
          });

          if (result.data.createStripeInvoiceForPoa.success) {
            this.$nuxt.$emit('sendTrackingEvent', {
              event: '📄 POA purchased',
            });

            this.$router.push({
              path: '/poa/dashboard',
            });

            this.paymentError = null;
          }
        }
      } catch (error) {
        const errorMessage = formatError(error.message);

        this.$nuxt.$emit('sendTrackingEvent', {
          event: '❌ POA Payment Failed',
          props: {
            error: errorMessage,
          },
        });

        this.paymentError = errorMessage;
        this.setPaymentLoading(false);
        this.setPaymentProcessing(false);
        this.$refs.genericPaymentStripe.disabledStripeElements(false);
      }
    },
  },
};
</script>
