var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleStep',{attrs:{"index":2,"prev-step":"/guardians/your-children"}},[_c('Form',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"hide-submit":!_vm.isComplete,"auto-submit":!_vm.willMeta.has_primary_guardian,"submit-arrow":""},on:{"done":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('FormSection',{staticClass:"step-1",scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        Would you like to appoint a\n        "),_c('GlossaryTooltip',{attrs:{"term":"Guardian"}},[_vm._v("\n          guardian\n        ")]),_vm._v("\n        for your children?\n      ")]},proxy:true}],null,true)},[_vm._v(" "),_c('p',[_vm._v("\n        A guardian is the person who becomes responsible for your child if you\n        were to pass away.\n      ")]),_vm._v(" "),_c('Tip',[_vm._v("\n        Normally, the surviving parent will be the legal guardian of their\n        child. If both parents die, and no guardian has been nominated in a\n        Will, there may be disagreement as to who looks after the children and\n        this may need to be settled in Court.\n      ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"hasPrimaryGuardians","options":[
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          } ]},model:{value:(_vm.willMeta.has_primary_guardian),callback:function ($$v) {_vm.$set(_vm.willMeta, "has_primary_guardian", $$v)},expression:"willMeta.has_primary_guardian"}})],1),_vm._v(" "),(_vm.willMeta.has_primary_guardian)?_c('FormSection',{staticClass:"step-2",attrs:{"tip":"We’ll ask you to add or select people at relevant places in your Will. Their details can be edited on the People, Pets & Charities page."},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        Who should be the primary guardian?\n      ")]},proxy:true}],null,true)},[_vm._v(" "),_c('p',[_vm._v("\n        Your primary guardian will take responsibility for your underage child\n        if all others with parental responsibility are unable to.\n      ")]),_vm._v(" "),_c('Tip',[_vm._v("\n        You shouldn’t appoint the child’s other parent, as they would\n        typically already have parental responsibility.\n        "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('ExternalLink',{attrs:{"href":"https://safewill.com/blog/how-do-i-choose-a-guardian"}},[_vm._v("\n          How do I choose a guardian?\n        ")])],1),_vm._v(" "),_c('PersonSelector',{attrs:{"loading":loading,"max":1,"people":_vm.filteredPeople,"type":"guardian"},model:{value:(_vm.selectedPrimaryGuardian),callback:function ($$v) {_vm.selectedPrimaryGuardian=$$v},expression:"selectedPrimaryGuardian"}})],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }