<template>
  <div class="relative" @click="toggle">
    <button type="button" :class="active ? 'btn-active-filter' : null">
      <slot name="toggle" />
    </button>
    <div
      v-on-dismiss="{
        watch: active,
        callback: dismiss,
      }"
      class="absolute right-0 flex flex-col z-50 rounded bg-white shadow-md transform transition-all duration-300 ease-in-out font-medium text-lg"
      :class="classes"
    >
      <slot name="items" />
    </div>
  </div>
</template>

<script>
import VDismiss from 'vue-dismiss';

export default {
  name: 'Dropdown',
  mixins: [VDismiss],
  props: {
    direction: {
      default: 'down',
      type: String,
      validator(value) {
        return ['up', 'down'].includes(value);
      },
    },
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    classes() {
      return {
        'top-100 mt-4': this.direction === 'down',
        'bottom-100 mb-4': this.direction === 'up',
        'opacity-0 pointer-events-none translate-y-negative-1':
          !this.active && this.direction === 'down',
        'opacity-0 pointer-events-none translate-y-positive-1':
          !this.active && this.direction === 'up',
      };
    },
  },
  methods: {
    dismiss() {
      this.active = false;
    },
    toggle() {
      this.active = !this.active;
    },
  },
};
</script>
