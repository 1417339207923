<template>
  <div
    class="h-full lg:rounded-full overflow-hidden bg-grey-200 translate-none"
  >
    <div
      ref="progress"
      class="relative h-full lg:rounded-full bg-teal-200 transition-transform transition-500 transition-fast-in-slow-out"
      :style="{ transform }"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProgressBar',
  computed: {
    ...mapGetters('modules', ['currentStep', 'totalSteps']),
    progress() {
      return Math.min(
        this.currentStep / this.totalSteps,
        this.totalSteps > 1 ? 0.95 : 0.55
      );
    },
    transform() {
      return `translate3d(${(this.progress - 1) * 100}%, 0, 0)`;
    },
  },
};
</script>
