<template>
  <ModuleStep :index="4" prev-step="/get-started/age">
    <Form
      v-slot="{ loading }"
      auto-focus
      :hide-submit="!isComplete"
      submit-arrow
      @submit="done"
    >
      <h1
        class="font-medium md:font-normal leading-tight text-4xl md:text-5xl lg:text-5xl mb-6"
      >
        Excellent, now let's quickly set up your account.
      </h1>
      <FormSection>
        <template #heading> What should we call you? </template>
        <Tip>
          We take privacy seriously. All of the data we collect is encrypted for
          safety.
        </Tip>
        <FormRow>
          <TextInput
            id="name_first"
            v-model="name_first"
            :disabled="loading"
            placeholder="Your first name"
          />
        </FormRow>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapFields } from 'vuex-map-fields';

import Form from '~/components/Form';
import FormRow from '~/components/FormRow';
import FormSection from '~/components/FormSection';
import ModuleStep from '~/components/ModuleStep';
import TextInput from '~/components/TextInput';
import Tip from '~/components/Tip';
import { user } from '~/mixins/apollo';

export default {
  name: 'GetStartedName',
  components: {
    Form,
    FormRow,
    FormSection,
    ModuleStep,
    TextInput,
    Tip,
  },
  mixins: [user],
  computed: {
    ...mapFields('signup', [
      'meta.name_first',
      'meta.name_middle',
      'meta.name_last',
    ]),
    isComplete() {
      return !!this.name_first;
    },
  },
  methods: {
    done() {
      this.$nuxt.$emit('sendTrackingAttributes', {
        prefered_name: `${this.name_first}`,
      });

      this.$router.push({
        path: '/get-started/email',
        query: this.$route.query,
      });
    },
  },
};
</script>
