<template>
  <div class="form">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Form',
};
</script>
<style lang="scss">
$this: '.form';
.form {
  --focus-outline: none;
  --focus-outline-active: 0.5rem solid #e2f7f2;
  --input-padding: var(--input-padding-top) var(--input-padding-x)
    var(--input-padding-bottom);
  .headline {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }
  p {
    margin: 2rem var(--input-padding-x);
  }
  &__fieldset {
    margin-bottom: 1rem;
    &.grid {
      margin-bottom: 0;
    }
  }
  &__control {
    position: relative;
    margin: 0 #{calc(var(--input-padding-x) * -1)};
    &:focus-within {
      --focus-outline: var(--focus-outline-active);
      --input-border: var(--input-border-focus);
    }
    &--inline {
      display: flex;
      align-items: center;
      input {
        display: inline-block;
        flex-shrink: 0;
        visibility: hidden;
        margin-right: 1.75rem;
        width: 1.75rem;
      }
    }
  }
  &__field--touched:invalid {
    border-color: var(--red);
    & + .form__label {
      color: var(--red);
    }
  }
  &__select {
    appearance: auto;
    outline: var(--focus-outline);
    font-size: var(--input-font-size);
    display: block;
    background: var(--white);
    border: 1px solid var(--input-border);
    padding: var(--input-padding);
    border-radius: var(--input-radius);
    width: 100%;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
  }
  &__input-text {
    outline: var(--focus-outline);
    font-size: var(--input-font-size);
    line-height: var(--input-lineheight);
    display: block;
    background: var(--white);
    border: 1px solid var(--input-border);
    padding: var(--input-padding);
    border-radius: var(--input-radius);
    width: 100%;
    &::placeholder {
      opacity: 0 !important;
    }
  }
  &__label {
    cursor: pointer;
    @at-root [required] + &::after {
      color: var(--red);
      content: ' *';
    }
    @at-root :is(#{$this}__select, #{$this}__input-text) + & {
      --outline-offset: 10px;
      background: rgba(var(--water), 0);
      border-radius: var(--input-radius) var(--input-radius) 0 0;
      color: rgb(var(--ebony), 0.5);
      display: block;
      position: absolute;
      font-size: var(--input-font-size);
      top: var(--input-padding-top);
      left: #{calc(var(--input-padding-x) - var(--outline-offset))};
      padding: 0 var(--outline-offset);
      transition: var(--transition);
      transform-origin: top left;
    }
    @at-root :is(:focus:not(#{$this}__input-radio, #{$this}__input-checkbox), #{$this}__input-text:not(:placeholder-shown), #{$this}__select:valid)
        + & {
      background: rgba(var(--water), 1);
      color: rgb(var(--ebony), 0.75);
      transform: translatey(#{calc(-2 * var(--input-padding-top) - 1px)})
        scale(0.75);
      line-height: 1;
    }
  }
  &__input-radio,
  &__input-checkbox {
    margin-left: var(--input-padding-x);
    & + #{$this}__label {
      margin-right: var(--input-padding-x);
    }
  }
  &__input-radio {
    outline: var(--focus-outline);
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    background: #fff;
    border-radius: 999px;
    border: 1px solid var(--input-border);
    &:checked {
      background: radial-gradient(var(--grass), var(--grass) 45%, #fff 50%);
    }
  }
  &__input-checkbox {
    + #{$this}__label::before,
    + #{$this}__label::after {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      transform: translatey(-50%);
      left: #{calc(var(--input-padding-x) - 0.5rem)};
      width: 2.5rem;
      height: 2.5rem;
      border-radius: var(--input-radius);
      border: 2px solid var(--checkbox-border);
    }
    + #{$this}__label::before {
      background-color: #fff;
      @include background-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M6.5 9.086 4 6.586 2.586 8 6.5 11.914 13.414 5 12 3.586z" fill="#ccc"/></svg>'
      );
    }
    + #{$this}__label::after {
      border-color: var(--checkbox-border-focus);
      outline: var(--focus-outline-active);
      background-color: #fff;
      opacity: 0;
      transition: var(--transition);
      @include background-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M6.5 9.086 4 6.586 2.586 8 6.5 11.914 13.414 5 12 3.586z" fill="#123"/></svg>'
      );
    }
    &:hover + #{$this}__label::after {
      opacity: #{calc(2 / 3)};
    }
    &:hover:checked + #{$this}__label::after,
    &:checked + #{$this}__label::after {
      opacity: 1;
    }
  }
}
</style>
