<template>
  <ModuleStep :index="5" :prev-step="previous" :show-help="true">
    <Form
      v-slot="{ loading }"
      :hide-submit="!isComplete"
      submit-arrow
      @submit.prevent="onSubmit"
    >
      <FormSection>
        <template #heading>
          Would you like to appoint
          {{ canHaveMultipleSubstitutes ? 'substitute' : 'a substitute' }}?
        </template>
        <p v-if="['nsw'].includes(userState)" class="mb-4">
          In New South Wales, your substitute decision maker will only be
          granted power if all of your original decision makers are unable to
          act.
        </p>
        <p v-else-if="['vic'].includes(userState)" class="mb-4">
          In Victoria, your substitute decision maker will take over at any time
          your primary decision maker is unavailable.
        </p>
        <p v-else-if="['qld'].includes(userState)" class="mb-4">
          In Queensland, your substitute decision maker will only be granted
          power if all of your original decision makers are unable to act.
        </p>
        <p v-else-if="['act'].includes(userState)" class="mb-4">
          In ACT your substitute decision makers will only be granted power if
          all of your original decision makers are unable to act.
        </p>
        <p v-else-if="['wa'].includes(userState)" class="mb-4">
          In Western Australia your substitute decision maker will replace any
          of your original decision makers that are unable to act.
        </p>
        <p v-else-if="['tas'].includes(userState)" class="mb-4">
          In Tasmania your substitute decision makers will replace any of your
          original decision makers that are unable to act.
        </p>
        <p v-else-if="['nt'].includes(userState)" class="mb-4">
          In the Northern Territory your substitute decision makers will only be
          granted power if all of your original decision makers are unable to
          act.
        </p>
        <LargeRadioButtons
          id="has_substitute"
          v-model="poaMedicalMeta.has_substitute"
          :options="[
            {
              label: `Yes, I'd like to appoint ${
                canHaveMultipleSubstitutes
                  ? 'substitute decision makers'
                  : 'a substitute decision maker'
              }`,
              value: true,
            },
            {
              label: `No, I don't want to appoint  ${
                canHaveMultipleSubstitutes
                  ? 'any substitute decision makers'
                  : 'a substitute decision maker'
              }`,
              value: false,
            },
          ]"
          @change="onSubmit()"
        />
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  queries as metaQueries,
  mutations as metaMutations,
} from '@/modules/apollo-queries/poa-meta';
import { queries as attorneysQueries } from '@/modules/apollo-queries/poa-attorneys';
import { prevStep, nextStep } from '@/modules/poa-flow/medical/index';
import Form from '~/components/Form';
import ModuleStep from '~/components/ModuleStep';
import FormSection from '~/components/FormSection';
import LargeRadioButtons from '~/components/LargeRadioButtons';

import { user, will } from '~/mixins/apollo';

export default {
  name: 'PoaMedicalSubstitutes',
  components: {
    Form,
    ModuleStep,
    FormSection,
    LargeRadioButtons,
  },
  mixins: [user, will],
  apollo: {
    attorneys: {
      ...attorneysQueries.getAttorneys({ power: 'MEDICAL' }),
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
    poaMedicalMeta: {
      ...metaQueries.poaMedicalMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
  },
  data() {
    return {
      attorneys: undefined,
      selectedPrimaryAttorneyPersonIDs: [],
      poaMedicalMeta: {
        has_substitute: undefined,
      },
    };
  },
  computed: {
    ...mapGetters(['poaId']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
        addressState: this.userState,
        numPrimaryAttorneysSelected: this.selectedPrimaryAttorneyPersonIDs
          .length,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
        addressState: this.userState,
        poaMedicalMeta: this.poaMedicalMeta,
        numPrimaryAttorneysSelected: this.selectedPrimaryAttorneyPersonIDs
          .length,
      });
    },
    canHaveMultipleSubstitutes() {
      return ['act'].includes(this.userState);
    },
    primaryAttorneyPeopleIDs() {
      return this.attorneys
        ?.filter((attorney) => attorney.role === 'PRIMARY')
        ?.map((attorney) => attorney.person.id);
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    isComplete() {
      return (
        this.poaMedicalMeta &&
        (this.poaMedicalMeta.has_substitute === true ||
          this.poaMedicalMeta.has_substitute === false)
      );
    },
  },
  watch: {
    attorneys(attorneys) {
      if (attorneys.length) {
        this.selectedPrimaryAttorneyPersonIDs = attorneys
          .filter((attorney) => attorney.role === 'PRIMARY')
          .map((attorney) => attorney.person.id);
      }
    },
  },
  methods: {
    async onSubmit() {
      await this.$apollo.mutate(
        metaMutations.updatePoaMeta(this.poaId, {
          key: 'has_substitute',
          value: this.poaMedicalMeta.has_substitute ? 'true' : 'false',
          category: 'MEDICAL',
        })
      );
      const path = this.next;
      this.$router.push({ path });
    },
  },
  head() {
    return {
      title: 'Medical decision making substitutes',
    };
  },
};
</script>
