<template>
  <ModuleStep :index="6" prev-step="/get-started/email">
    <Form
      auto-focus
      :mutation="ADD_USER_MUTATION"
      :variables="variables"
      :hide-submit="!isComplete"
      submit-arrow
      @done="done"
    >
      <FormSection>
        <template #heading> Choose a password </template>
        <FormRow>
          <PasswordInput
            id="password"
            v-model="password"
            placeholder="Password"
          />
        </FormRow>
        <FormRow>
          <PasswordValidator
            v-model="validPassword"
            :input="password"
            :exclude="[
              meta.name_first,
              meta.name_middle,
              meta.name_last,
              email,
            ]"
          />
        </FormRow>
        <FormRow>
          <CheckboxInput
            id="terms"
            v-model="terms"
            class="mt-4 md:mt-6"
            required
          >
            <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
            By checking this box, you confirm that you have read and accept our
            <ExternalLink href="https://safewill.com/terms">
              terms of use
            </ExternalLink>
            and
            <ExternalLink href="https://safewill.com/privacy">
              privacy policy.
            </ExternalLink>
          </CheckboxInput>
        </FormRow>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import cookies from 'js-cookie';

import { referralCodesToCharities } from '@/modules/referralCodes';

import ADD_USER_MUTATION from '~/graphql/mutations/AddUser';
import GET_WILL_QUERY from '~/graphql/queries/GetWill';
import GET_PRICE_QUERY from '~/graphql/queries/GetPrice';
import LOGIN_MUTATION from '~/graphql/mutations/Login';

import CheckboxInput from '~/components/CheckboxInput';
import ExternalLink from '~/components/ExternalLink';
import Form from '~/components/Form';
import FormRow from '~/components/FormRow';
import FormSection from '~/components/FormSection';
import ModuleStep from '~/components/ModuleStep';
import PasswordInput from '~/components/PasswordInput';
import PasswordValidator from '~/components/PasswordValidator';

import { objectToMetaArray } from '~/utilities';
import { sanitizeSignUpRedirect } from '~/utilities/allowed-redirects';
import { charities } from '~/mixins/apollo';

export default {
  name: 'GetStartedPassword',
  components: {
    CheckboxInput,
    ExternalLink,
    Form,
    FormRow,
    FormSection,
    ModuleStep,
    PasswordInput,
    PasswordValidator,
  },
  mixins: [charities],
  data() {
    return {
      ADD_USER_MUTATION,
      password: '',
      terms: false,
      validPassword: false,
    };
  },
  computed: {
    ...mapGetters(['willId']),
    ...mapFields('signup', [
      'email',
      'meta',
      'meta.referral_charity',
      'meta.has_partner',
      'coupon',
    ]),
    variables() {
      return {
        email: this.email,
        password: this.password,
        meta: objectToMetaArray(this.meta),
      };
    },
    isComplete() {
      return !!(this.password && this.validPassword && this.terms);
    },
  },
  mounted() {
    this.has_partner = cookies.get('has_partner');
    this.referral_charity = cookies.get('referral_charity');
  },
  methods: {
    ...mapActions([
      'setEmail',
      'setRole',
      'setToken',
      'setUserId',
      'setVerified',
      'setWillId',
      'setWillStatus',
    ]),
    ...mapActions('signup', ['resetSignup']),
    async done() {
      const { token, user } = await this.$apollo
        .mutate({
          mutation: LOGIN_MUTATION,
          variables: {
            email: this.email,
            password: this.password,
          },
        })
        .then(({ data }) => data && data.login)
        .catch((e) => {
          console.error(e.message);
        });

      await this.$apolloHelpers
        .onLogin(token)
        .then(() => {
          this.setEmail(user.email);
          this.setRole(user.role);
          this.setToken(token);
          this.setUserId(user.id);
          this.setVerified(user.verified);
          this.$ff.refreshFeatureFlags();
        })
        .catch((e) => {
          console.error(e.message);
        });

      await this.$apollo
        .query({
          query: GET_WILL_QUERY,
        })
        .then(async ({ data }) => {
          if (data.getWill.will) {
            this.setWillId(data.getWill.will.id);
            this.setWillStatus(data.getWill.will.status);

            const charityKeys =
              this.referral_charity &&
              referralCodesToCharities[this.referral_charity];
            if (charityKeys) await this.addPartnerCharities(charityKeys);

            const coupon = cookies.get('coupon');
            if (coupon) {
              await this.$apollo.query({
                query: GET_PRICE_QUERY,
                variables: {
                  code: coupon,
                  willId: data.getWill.will.id,
                  products: ['WILL'],
                },
              });

              this.$nuxt.$emit('sendTrackingAttributes', {
                coupon_code: coupon,
              });
            }

            if (this.has_partner) {
              this.$nuxt.$emit('sendTrackingAttributes', {
                has_partner: true,
              });
            }
          }
        })
        .catch((e) => {
          console.error(e.message);
        });

      this.resetSignup();
      this.email = '';

      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✅ Sign Up Complete',
        props: {},
        integrations: {
          Amplitude: false,
          Intercom: false,
          'Facebook Pixel': false,
          'Facebook Pixel Server Side': false,
          'Personas Facebook Custom Audiences': false,
        },
      });

      const signupRedirect = sanitizeSignUpRedirect(
        String(cookies.get('signup_redirect'))
      );
      cookies.remove('signup_redirect');

      if (signupRedirect) {
        this.$router.push({
          path: signupRedirect,
        });
      } else {
        this.$router.push({
          path: '/dashboard',
          query: { welcome: 'true' },
        });
      }
    },
  },
};
</script>
