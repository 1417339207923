<template>
  <component
    :is="to ? 'nuxt-link' : 'button'"
    :to="to"
    class="btn relative button-test"
    :class="classes"
    :style="styles"
    :type="type"
    :disabled="disabled || loading"
    @click="click"
  >
    <transition-group
      class="w-full"
      :css="false"
      tag="div"
      @enter="enter"
      @leave="leave"
    >
      <div
        v-if="loading"
        :key="1"
        class="absolute inset-0 flex justify-center items-center"
      >
        <LoadingDots />
      </div>
      <div v-else :key="2">
        <slot />
      </div>
    </transition-group>
  </component>
</template>

<script>
import anime from 'animejs';

import LoadingDots from '~/components/LoadingDots';

export default {
  name: 'BaseButton',
  components: {
    LoadingDots,
  },
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    focusable: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    to: {
      default: null,
      type: String,
    },
    type: {
      default: 'button',
      type: String,
    },
  },
  data() {
    return {
      width: 0,
    };
  },
  computed: {
    classes() {
      return {
        'btn-disabled': this.disabled && !this.loading,
        'btn-focus': this.focusable,
        'cursor-wait': this.loading,
      };
    },
    styles() {
      return {
        width: this.loading ? `${this.width}px` : null,
      };
    },
  },
  watch: {
    loading(isLoading) {
      this.width = isLoading ? this.$el.getBoundingClientRect().width : 0;
    },
  },
  methods: {
    click() {
      if (!this.to && !this.loading) {
        this.$emit('click');
      }
    },
    enter(el, complete) {
      anime.remove(el);

      anime({
        targets: el,
        opacity: 1,
        duration: 150,
        easing: 'easeInOutQuad',
        complete,
      });
    },
    leave(el, complete) {
      anime.remove(el);

      anime({
        targets: el,
        opacity: 0,
        duration: 150,
        easing: 'easeInOutQuad',
        complete,
      });
    },
  },
};
</script>
