<template>
  <span class="relative z-10 inline-block leading-none">
    <slot />
    <span
      ref="svg"
      class="absolute z-under"
      :class="`text-${colour}`"
      style="top: 0.78em; left: 0em; right: 0.1em;"
    >
      <svg
        class="w-full h-full"
        viewBox="0 0 357 37"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMin"
      >
        <path
          class="stroke-current"
          d="M1 8.722c5.587-.708 11.163-2.051 16.761-2.124 92.707-1.195 239.922-1.931 328.633 11.504 23.065 3.494-46.8 2.586-70.178 4.766-22.734 2.12-45.416 5.421-68.124 8.132"
          fill="none"
          stroke-width="10"
        />
      </svg>
    </span>
  </span>
</template>

<script>
import anime from 'animejs';

export default {
  props: {
    colour: {
      default: 'teal-100',
      type: String,
    },
    delay: {
      default: 500,
      type: Number,
    },
    duration: {
      default: 600,
      type: Number,
    },
    easing: {
      default: 'easeInOutQuad',
      type: String,
    },
  },
  mounted() {
    anime({
      targets: this.$refs.svg.querySelector('path'),
      strokeDashoffset: [anime.setDashoffset, 0],
      duration: this.duration,
      delay: this.delay,
      easing: this.easing,
    });
  },
};
</script>
