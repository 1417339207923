<template>
  <Form
    v-slot="{ loading }"
    auto-focus
    :mutation="mutation"
    :variables="variables"
    submit-label="Save Charity"
    @update="update"
    @done="close"
  >
    <FormSection>
      <FormRow>
        <TextInput
          id="charity_name"
          v-model="name"
          :disabled="loading"
          label="Charity's Name"
          placeholder="e.g. Oxfam Australia"
          rules="required"
        />
      </FormRow>
      <FormRow>
        <TextInput
          id="charity_address"
          v-model="address"
          :disabled="loading"
          label="Charity's Address"
          placeholder="e.g. 123 City Rd, Sydney, NSW 2000"
          rules="required"
        />
      </FormRow>
      <Tip>
        <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
        This should be the full legal name of the charity. We recommend checking
        the charity's bequests page if you're unsure.
      </Tip>
    </FormSection>
  </Form>
</template>

<script>
import Form from '~/components/Form';
import FormRow from '~/components/FormRow';
import FormSection from '~/components/FormSection';
import TextInput from '~/components/TextInput';
import Tip from '~/components/Tip';

import { charities, user } from '~/mixins/apollo';
import { objectToMetaArray } from '~/utilities';

export default {
  name: 'AddCustomCharity',
  components: {
    Form,
    FormRow,
    FormSection,
    TextInput,
    Tip,
  },
  mixins: [charities, user],
  data() {
    return {
      name: '',
      address: '',
    };
  },
  computed: {
    mutation() {
      return this.ADD_CHARITY_MUTATION;
    },
    variables() {
      const variables = {
        meta: objectToMetaArray({ name: this.name, address: this.address }),
      };
      variables.willId = this.willId;
      return variables;
    },
  },
  methods: {
    close() {
      this.$emit('successfullyAddedCharity');
    },
    update(store, { data: { addCharity } }) {
      if (addCharity) {
        const data = store.readQuery(this.getCharitiesQuery);
        data.getCharities.charities.push(addCharity.charity);
        store.writeQuery({
          ...this.getCharitiesQuery,
          data,
        });
        this.$nuxt.$emit('addCharity', addCharity.charity);
      }
    },
  },
};
</script>
