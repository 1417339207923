<template>
  <div
    class="p-6 md:p-8 xl:p-10 border-l-2 border-r-2 border-b-2 rounded-b border-grey-300"
  >
    <PaymentMethod value="afterpay">
      <img
        :src="require('~/assets/img/payment-gateways/afterpay-logo.svg')"
        style="height: 30px"
        alt="Afterpay"
      />
      <span class="ml-3">in 4 payments of ${{ afterpayPayments }}</span>
      <button
        class="btn text-teal-200 underline hover:no-underline ml-2"
        type="button"
        @click="displayAfterpayLightbox"
      >
        Info
      </button>
    </PaymentMethod>
    <div
      class="flex fixed inset-0 z-50 p-2 md:p-10 justify-center items-center"
      :class="showAfterpayLightbox ? '' : 'hidden'"
      style="backgroundColor: rgba(0,0,0,.6)"
    >
      <div class="relative bg-white" style="maxWidth: 700px">
        <button
          class="absolute text-3xl py-2 px-6 top-0 right-0"
          type="button"
          @click="hideAfterpayLightbox"
        >
          x
        </button>
        <a
          href="https://www.afterpay.com/terms/"
          target="_blank"
          rel="noopener"
        >
          <img
            :src="
              require('~/assets/img/payment-gateways/afterpay-lightbox.jpg')
            "
            alt="Shop now. Pay later. Always interest free."
            class="max-w-full"
          />
        </a>
      </div>
    </div>
    <FormSection :class="{ hidden: !active }">
      <FormRow class="mt-4">
        <div class="flex items-center text-charcoal-100">
          <!-- <BaseGlyph id="exclamation" /> -->
          <span class="text-lg">
            After clicking “Complete order”, you will be redirected to Afterpay.
          </span>
        </div>
      </FormRow>
      <FormRow>
        <BaseAlert v-if="paymentError" class="w-full mt-2" type="error">
          {{ paymentError }}
        </BaseAlert>
      </FormRow>
    </FormSection>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import BaseAlert from '~/components/BaseAlert';
import FormRow from '~/components/FormRow';
import FormSection from '~/components/FormSection';
import PaymentMethod from '~/components/PaymentMethod';

import SUBMIT_WILL_MUTATION from '~/graphql/mutations/SubmitWill';
import CREATE_AFTERPAY_CHECKOUT from '~/graphql/mutations/CreateAfterpayCheckout';
import PROCESS_AFTERPAY_CHECKOUT from '~/graphql/mutations/ProcessAfterpayCheckout';

import { subscription, user, will } from '~/mixins/apollo';
import { formatError } from '~/utilities';

export default {
  components: {
    BaseAlert,
    FormRow,
    FormSection,
    PaymentMethod,
  },
  mixins: [subscription, user, will],
  props: {
    error: { type: String, default: '' },
    addPartnersWill: {
      type: Boolean,
    },
  },
  data() {
    return {
      showAfterpayLightbox: false,
    };
  },
  computed: {
    ...mapGetters('checkout', [
      'checkoutAction',
      'couponCode',
      'paymentMethod',
      'paymentLoading',
      'paymentError',
      'paymentSuccess',
      'paymentProcessing',
      'finalSetupPrice',
    ]),
    active() {
      return this.paymentMethod === 'afterpay';
    },
    afterpayPayments() {
      return (this.finalSetupPrice / 4).toFixed(2);
    },
  },
  async mounted() {
    const paymentStatus = this.$route.query.status;

    switch (paymentStatus && paymentStatus.toLowerCase()) {
      case 'cancelled':
        this.setPaymentMethod('afterpay');
        this.setPaymentError('Your payment was cancelled.');
        this.setPaymentProcessing(false);
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '❌ Payment Failed',
          props: {
            error: 'Afterpay payment cancelled by customer',
          },
        });
        break;
      case 'success':
        try {
          this.setPaymentProcessing(true);
          this.setPaymentMethod('afterpay');
          this.afterpayApproved();
          await this.afterpayProcess();
        } catch (e) {
          this.setPaymentError(e.message);
        }
        break;
      default:
        this.setPaymentProcessing(false);
    }

    this.$nuxt.$on('checkout', async () => {
      if (this.paymentMethod === 'afterpay') {
        this.setPaymentLoading(true);
        await this.afterpayCheckout();
      }
    });
  },
  methods: {
    ...mapActions('checkout', [
      'setPaymentLoading',
      'setPaymentError',
      'setPaymentSuccess',
      'setPaymentProcessing',
      'setPaymentMethod',
      'submitWill',
      'afterpayApproved',
    ]),
    displayAfterpayLightbox() {
      this.showAfterpayLightbox = true;
    },
    hideAfterpayLightbox() {
      this.showAfterpayLightbox = false;
    },
    declinedPayment() {
      this.setPaymentMethod('afterpay');
      this.setPaymentError('Your payment was declined.');
      this.setPaymentProcessing(false);
      this.setPaymentLoading(false);
      this.$nuxt.$emit('sendTrackingEvent', {
        event: '❌ Payment Failed',
        props: {
          error: 'Afterpay payment declined',
        },
      });
    },
    async successfulPayment(payment) {
      const [product] = payment.items;
      const payedProduct = (product && product.sku) || 'WILL';

      await this.$apollo
        .mutate({
          mutation: SUBMIT_WILL_MUTATION,
          variables: {
            id: this.willId,
            afterpayPaymentId: payment.id,
            afterpayPaymentToken: payment.token,
            coupon: this.couponCode || '',
            products: [payedProduct],
          },
        })
        .then(({ data, errors }) => {
          if (data.submitWill && data.submitWill.success) {
            this.setWillStatus(data.submitWill.will.status);
            const hasProfessionalExecutor = [
              'professional',
              'friendsFamilyAndProfessional',
            ].includes(this.willMeta.executors_option);

            this.$nuxt.$emit('sendTrackingEvent', {
              event: '📄 Submit Will',
              props: {
                ...(this.couponCode && { coupon_code: this.couponCode }),
                price: this.finalSetupPrice,
                gateway: 'AfterPay',
              },
              integrations: {
                Amplitude: false,
                Intercom: false,
                'Facebook Pixel': false,
                'Facebook Pixel Server Side': false,
                'Personas Facebook Custom Audiences': false,
              },
            });

            this.$nuxt.$emit('sendTrackingAttributes', {
              ...(this.couponCode && { coupon_code: this.couponCode }),
              price: this.finalSetupPrice,
              has_professional_executor: hasProfessionalExecutor,
            });

            if (hasProfessionalExecutor) {
              this.$nuxt.$emit('sendTrackingEvent', {
                event: 'Professional Executor Appointed',
              });
            }

            this.setPaymentSuccess(true);
            this.setPaymentProcessing(false);
            this.setPaymentLoading(false);

            if (this.addPartnersWill) {
              this.willMeta.partner_bundle = 'true';
              this.updateWillMeta();
            }

            this.$router.push({
              path: this.$route.path,
            });
          } else {
            this.$nuxt.$emit('unlockForm');
            this.setPaymentError(formatError(errors[0].message));
            this.setPaymentProcessing(false);
            this.setPaymentLoading(false);
          }
        })
        .catch((e) => {
          this.$nuxt.$emit('unlockForm');
          this.setPaymentError(formatError(e.message));
          this.setPaymentProcessing(false);
          this.setPaymentLoading(false);
        });
    },
    async afterpayCheckout() {
      const willProduct = this.addPartnersWill ? 'WILL_X2' : 'WILL';

      try {
        const { data } = await this.$apollo.mutate({
          mutation: CREATE_AFTERPAY_CHECKOUT,
          variables: {
            userId: this.will.userId,
            coupon: this.couponCode,
            products: [willProduct],
          },
        });

        window.location.href = data.createAfterpayCheckout.checkout.redirectUri;
      } catch (e) {
        this.setPaymentError(e.message);
      }
    },
    async afterpayProcess() {
      if (this.checkoutAction === 'submit') {
        this.setPaymentLoading(true);

        try {
          const { data } = await this.$apollo.mutate({
            mutation: PROCESS_AFTERPAY_CHECKOUT,
            variables: {
              userId: this.userId,
              token: this.$route.query.orderToken,
            },
          });

          const { status } = data.processAfterpayCheckout.payment;

          if (status.toLowerCase() === 'declined') {
            this.declinedPayment();
          } else if (status.toLowerCase() === 'approved') {
            this.successfulPayment(data.processAfterpayCheckout.payment);
          }
        } catch (e) {
          this.$nuxt.$emit('unlockForm');
          this.setPaymentError(e.message);
          this.setPaymentProcessing(false);
        }
      } else if (this.checkoutAction === 'renew') {
        await this.reactivateSubscription(
          null,
          null,
          this.$route.query.orderToken
        )
          .then(({ data, errors }) => {
            this.setPaymentSuccess(true);
            this.setPaymentProcessing(false);
            this.setPaymentLoading(false);

            this.$router.push({
              path: this.$route.path,
            });
          })
          .catch((e) => {
            this.$nuxt.$emit('unlockForm');
            this.setPaymentError(e.message);
            this.setPaymentProcessing(false);
          });
      }
    },
  },
};
</script>
