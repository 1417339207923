<template>
  <div :class="classes">
    <CheckoutFaqItem
      v-for="(qa, index) in faq"
      :key="`qa-${index}`"
      class="flex items-top mb-3 last-child:mb-0"
      :qa="qa"
    />
  </div>
</template>

<script>
import CheckoutFaqItem from '~/components/CheckoutFaqItem';
export default {
  name: 'CheckoutFaq',
  components: {
    CheckoutFaqItem,
  },
  props: {
    faq: {
      type: Array,
      required: true,
    },
    classes: {
      default: '',
      type: String,
    },
  },
};
</script>
