<template>
  <div class="flex">
    <Tooltip
      class="flex-grow md:flex-grow-0"
      :content="tooltip"
      :disabled="!disabled"
    >
      <BaseButton
        class="btn btn-xl btn-slate btn-focus w-full md:w-auto text-white"
        :class="classes"
        :disabled="disabled"
        @click="downloadWill"
      >
        {{ buttonText }}
      </BaseButton>
    </Tooltip>
    <div class="relative ml-2">
      <Dropdown :direction="direction">
        <template #toggle>
          <div v-if="!disabled" class="btn btn-xl bg-slate-100 text-white">
            <div class="flex justify-center">
              <BaseGlyph :id="glyph" />
            </div>
          </div>
        </template>
        <template #items>
          <MenuItem
            to=""
            text="View signing instructions"
            vertical
            @click="viewSigningInstructions"
          />
          <MenuItem
            to=""
            :icon="hasExpiredSubscription ? 'lock' : null"
            text="Update Will"
            vertical
            @click="unlockWill"
          />
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import DOWNLOAD_WILL_QUERY from '~/graphql/queries/DownloadWill';

import BaseButton from '~/components/BaseButton';
import BaseGlyph from '~/components/BaseGlyph';
import Dropdown from '~/components/Dropdown';
import MenuItem from '~/components/MenuItem';
import Tooltip from '~/components/Tooltip';

import { subscription, user, will } from '~/mixins/apollo';

export default {
  name: 'WillDownloadComboButton',
  components: {
    BaseButton,
    BaseGlyph,
    Dropdown,
    MenuItem,
    Tooltip,
  },
  mixins: [subscription, user, will],
  apollo: {
    pdf: {
      query: DOWNLOAD_WILL_QUERY,
      update: (data) => data.downloadWill && data.downloadWill.will.pdf,
      variables() {
        return {
          id: this.willId,
        };
      },
      skip() {
        return ['IN_PROGRESS', 'AWAITING_APPROVAL'].includes(this.willStatus);
      },
    },
  },
  computed: {
    ...mapGetters(['token', 'willId', 'willStatus', 'verified']),
    buttonText() {
      return ['base', 'sm'].includes(this.$mq)
        ? 'Download Will'
        : 'Download your Will';
    },
    classes() {
      return {
        'btn-disabled text-grey-500': this.disabled,
      };
    },
    direction() {
      return ['base', 'sm'].includes(this.$mq) ? 'up' : 'down';
    },
    disabled() {
      return (
        this.will &&
        (this.will.status === 'AWAITING_APPROVAL' ||
          (this.will.status === 'APPROVED' && !this.verified))
      );
    },
    glyph() {
      return ['base', 'sm'].includes(this.$mq) ? 'angle-up' : 'angle-down';
    },
    pdfFilename() {
      return `safewill-${this.will.hashId}_${this.willMeta.name_last},${this.willMeta.name_first}`.toLowerCase();
    },
    tooltip() {
      return this.will && this.will.status === 'AWAITING_APPROVAL'
        ? 'Our team is reviewing your Will. You’ll receive an email once the Will is ready to download for signing.'
        : 'You need to verify your email before you can download your will';
    },
  },
  methods: {
    ...mapActions('ui', [
      'setShowSubscriptionExpiredModal',
      'setShowUnlockWillModal',
    ]),
    downloadWill() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: '📥 Download Will',
      });
      this.$downloadPdf(this.pdf, this.pdfFilename);
    },
    unlockWill() {
      if (this.hasValidSubscription) {
        this.setShowUnlockWillModal(true);
      } else {
        this.setShowSubscriptionExpiredModal(true);
      }
    },
    viewSigningInstructions() {
      window.open('https://safewill.com/blog/how-do-i-sign-my-will', '_blank');
    },
  },
};
</script>
