<template>
  <span
    class="inline-flex justify-center items-center h-6 px-3 rounded-full font-bold text-sm text-white leading-none tracking-widest uppercase select-none"
    :class="classes"
    v-text="label"
  />
</template>

<script>
export default {
  name: 'Chip',
  props: {
    label: {
      type: String,
      required: true,
    },
    type: {
      default: 'confirm',
      type: String,
      validator: (s) => ['confirm', 'warn', 'error'].includes(s),
    },
  },
  computed: {
    classes() {
      return {
        'bg-teal-200': this.type === 'confirm',
        'bg-charcoal-500': this.type === 'notice',
        'bg-orange-300': this.type === 'warn',
        'bg-red-300': this.type === 'error',
      };
    },
  },
};
</script>
