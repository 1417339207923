<template>
  <BaseModal v-if="willMeta && willMeta.show_welcome_modal" hide-close>
    <div class="flex mb-6">
      <div
        v-for="i in totalSteps"
        :key="i"
        class="w-2 h-2 rounded-full mr-2 bg-grey-300 cursor-pointer"
        :class="{ 'bg-teal-200': i - 1 === step }"
        @click="step = i - 1"
      />
    </div>
    <div ref="container" class="welcome-modal">
      <div v-if="step === 0">
        <h2 class="mb-4 text-6xl leading-tight">
          <Squiggle>Welcome</Squiggle> <br />to Safewill.
        </h2>
        <p class="text-2xl">
          With just a few more steps, you can finish your Will and safeguard
          your life’s work. Most people only take 10 minutes to get things
          sorted from here.
        </p>
      </div>
      <FeatureBlock
        v-else-if="step === 1"
        class="py-2"
        heading="Complete your Will"
        icon="check"
      >
        <p>
          We will ask you a few simple questions that allow us to create your
          Will. You can start and submit your Will for review today to secure
          the future of those you love.
        </p>
      </FeatureBlock>
      <FeatureBlock
        v-else-if="step === 2"
        class="py-2"
        heading="Update any time"
        icon="edit"
      >
        <p>
          Once checked by our experts, you can update your Will whenever your
          situation changes.
        </p>
      </FeatureBlock>
      <FeatureBlock
        v-else-if="step === 3"
        class="py-2"
        heading="We’re here to help"
        icon="chat"
      >
        <p>
          The best way to contact our Customer Care team is
          <button
            type="button"
            class="link link-dark whitespace-no-wrap"
            @click="showChat"
          >
            via chat
          </button>
          , or you can call us on
          <a href="tel:1800103310" class="link link-dark whitespace-no-wrap">
            1800 10 33 10
          </a>
          - we are open 7 days a week.
        </p>
      </FeatureBlock>
    </div>
    <div class="flex items-baseline mt-8">
      <BaseButton class="mr-auto text-xl text-grey-600" @click="prevStep">
        {{ prevText }}
      </BaseButton>
      <BaseButton class="btn-xl btn-slate" @click="nextStep">
        <div class="flex items-center">
          <span
            class="text-white"
            :class="{ 'mr-4': nextText }"
            v-text="nextText"
          />
          <BaseIcon id="arrow-right" />
        </div>
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script>
import { showChat } from '@/modules/chat';
import BaseButton from '~/components/BaseButton';
import BaseIcon from '~/components/BaseIcon';
import BaseModal from '~/components/BaseModal';
import FeatureBlock from '~/components/FeatureBlock';
import Squiggle from '~/components/Squiggle';

import { user, will } from '~/mixins/apollo';

export default {
  name: 'WelcomeModal',
  components: {
    BaseButton,
    BaseIcon,
    BaseModal,
    FeatureBlock,
    Squiggle,
  },
  mixins: [user, will],
  data() {
    return {
      step: 0,
      totalSteps: 4,
    };
  },
  computed: {
    nextText() {
      let text = 'Next';

      if (this.step === 0) {
        text = 'How it works';
      } else if (this.step === this.totalSteps - 1) {
        text = 'Okay, got it!';
      }

      return text;
    },
    prevText() {
      return this.step === 0 ? 'Skip' : 'Back';
    },
  },
  methods: {
    close() {
      this.willMeta.show_welcome_modal = false;
      this.updateWillMeta();
    },
    nextStep() {
      this.$refs.container.style.minHeight = `${this.$refs.container.offsetHeight}px`;

      if (this.step < this.totalSteps - 1) {
        this.step++;
      } else {
        this.close();
      }
    },
    prevStep() {
      if (this.step === 0) {
        this.close();
      } else {
        this.step--;
      }
    },
    showChat() {
      showChat();
    },
  },
};
</script>
