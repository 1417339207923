<template>
  <ModuleStep :index="3" :prev-step="prevStep">
    <Form
      v-slot="{ loading }"
      :hide-submit="
        !(
          willMeta.professional_executor_terms_check &&
          willMeta.professional_executor_payment_check
        )
      "
      submit-arrow
      @submit="onSubmit"
    >
      <FormSection>
        <template #heading> Professional Executor </template>
        <div
          ref="terms"
          class="h-84 px-8 py-6 border-2 border-grey-200 rounded overflow-y-scroll bg-white professional-terms"
          @scroll="onScroll"
        >
          <div class="text-content opacity-80 text-base text-charcoal-400">
            <p>
              <strong>SAFEWILL PROFESSIONAL EXECUTOR TERMS & CONDITIONS</strong>
            </p>
            <p>
              The following terms and conditions are provided by Safewill
              Estates Pty Ltd <strong>(Safewill Estates).</strong> Will Pty Ltd
              makes no representations or warranties as to the content or
              application of these terms and conditions as provider of the
              Safewill Platform only, and does not make any recommendation or
              provide any legal or other advice in relation to these terms.
            </p>
            <ol>
              <li><strong>1. INTRODUCTION</strong></li>
            </ol>
            <p>
              These terms and conditions are between you as testator of the Will
              generated on the Safewill Platform and Safewill Estates Pty Ltd
              (“we”, “us” or “our”) and set out the agreement for the
              appointment of our Estate Administrator as an Executor of your
              Will and the provision of associated services. You acknowledge
              that these terms and conditions will bind your estate, and your
              beneficiaries, heirs, successors, assigns, administrators, or
              legal personal representative following your death.
            </p>
            <p>
              If you are choosing to appoint our Estate Administrator as your
              Executor, please read these terms and conditions carefully before
              executing your Will, as they explain who will be performing
              services for your estate, how the services will be performed, and
              the associated fees.
            </p>
            <p>
              If you have any questions regarding any of these terms and
              conditions, please feel free to contact Safewill at
              <a
                href="mailto:support@safewill.com"
                class="link link-dark whitespace-no-wrap"
              >
                support@safewill.com
              </a>
              or call us on
              <a
                href="tel:1800103310"
                class="link link-dark whitespace-no-wrap"
              >
                1800 10 33 10
              </a>
              - we are open 7 days a week.
            </p>
            <p>
              If you are still uncertain, we recommend seeking independent legal
              advice.
            </p>
            <ol>
              <li><strong>2. DEFINITION AND INTERPRETATION</strong></li>
            </ol>
            <p>
              <strong>Estate Services</strong> means the services provided by
              the Estate Administrator (and by Safewill Estates on behalf of the
              Estate Administrator) as part of their role as your Executor, as
              more fully described in clause 4 below.
            </p>
            <p>
              <strong>Fees</strong> means the Service Fees and any Trust Fees
              (as applicable) for the Estate Services.
            </p>
            <p>
              <strong>Personnel</strong> means officers, employees, contractors,
              agents, subcontractors and professional advisors.
            </p>
            <p>
              <strong>Safewill Account Holder</strong> means the individual
              named in the ‘About Yourself’ section of the Safewill Platform.
            </p>
            <p>
              <strong>Safewill Group Member</strong> means Safewill Estates and
              its related bodies corporate (as defined in the Corporations Act
              2001 (Cth)).
            </p>
            <p>
              <strong>Safewill Platform</strong> means the proprietary digital
              platform which, as at the date of these terms and conditions, is
              owned by Safe Will Pty Ltd and is hosted on the website
              https://safewill.com/.
            </p>
            <p>
              <strong>Safewill Third Parties</strong> has the meaning as set out
              in Clause 5.4.
            </p>
            <p>
              As used in these terms and conditions, the term
              ‘<strong>Executor</strong>’ includes an executor, trustee and
              personal representative.
            </p>
            <ol>
              <li><strong>3. CHANGES TO THESE TERMS AND CONDITIONS</strong></li>
            </ol>
            <p>
              We may, at our discretion, change these terms and conditions
              (including the Fees) for any reason at any time by publishing the
              updated terms and providing notice to you. Notice of changes will
              generally be given via email or will be made available when you
              next log in to your account on the Safewill Platform, as deemed
              appropriate by us. The terms and conditions most recently
              published and as at the date of your death will apply.
            </p>
            <p>
              You may change the appointment of your Executor (including in
              circumstances where you do not agree with any changes made to
              these terms and conditions) at any time by creating a new legally
              valid and binding Will. For the avoidance of doubt, these terms
              and conditions will no longer apply in circumstances where the
              Estate Administrator is not named in any capacity as an Executor
              of your Will.
            </p>
            <ol>
              <li>
                <a><strong>4. ESTATE SERVICES</strong></a>
              </li>
            </ol>
            <p>
              The Estate Services provided by us may include (as applicable) but
              are not limited to:
            </p>
            <ol class="ml-4 list-disc">
              <li>
                assisting with arrangements for your funeral (in collaboration
                with your family and/or beneficiaries);
              </li>
              <li>
                engaging solicitors to apply for probate;
              </li>
              <li>determining and securing your estate’s assets;</li>
              <li>
                identifying and getting in contact with your beneficiaries, and
                distributing your assets in accordance with your Will
              </li>
              <li>
                seeking and implementing advice from legal, financial, taxation
                and other professionals and specialists;
              </li>
              <li>
                identifying and arranging for payment of your estate’s debts;
              </li>
              <li>
                finalising the tax and financial position of the estate; and
              </li>
              <li>
                managing the administration of testamentary trusts.
              </li>
            </ol>
            <ol>
              <li>
                <strong>5. FEES</strong>
              </li>
            </ol>
            <p>
              Neither Safewill Estates or its Personnel will charge any Fees to
              you or your estate under these terms and conditions for the Estate
              Services prior to your death.
            </p>
            <ol>
              <li>
                <strong>5.1</strong>
                <p>
                  You agree that all Fees shall be payable to Safewill Estates
                  (or its successor organisation) as employer of the Estate
                  Administrator. To the extent permitted by law, all Fees paid
                  under these terms and conditions are non-refundable. The Fees
                  may be charged as a periodic payment, or as a lump sum.
                </p>
              </li>
            </ol>
            <ol>
              <li><strong>5.2 Services</strong></li>
            </ol>
            <p>
              The following Service Fees will be charged in $AUD (exc. GST) for
              for the Estate Services per annum:
            </p>
            <table
              class="table-auto w-full rounded-t-md m-5 w-5/6 mx-auto bg-teal-100"
            >
              <tbody>
                <tr class="text-left border-b-2 border-teal-200">
                  <td class="px-4 py-3">
                    <p><strong>Estate value ($)AUD</strong></p>
                  </td>
                  <td class="px-4 py-3">
                    <p><strong>% of estate charged</strong></p>
                  </td>
                </tr>
                <tr class="bg-teal-100 border-b border-grey-200">
                  <td class="px-4 py-3">
                    <p>0 - 100000</p>
                  </td>
                  <td class="px-4 py-3">
                    <p>4.40%</p>
                  </td>
                </tr>
                <tr class="bg-teal-100 border-b border-grey-200">
                  <td class="px-4 py-3">
                    <p>100001 - 200000</p>
                  </td>
                  <td class="px-4 py-3">
                    <p>3.85%</p>
                  </td>
                </tr>
                <tr class="bg-teal-100 border-b border-grey-200">
                  <td class="px-4 py-3">
                    <p>200001 - 300000</p>
                  </td>
                  <td class="px-4 py-3">
                    <p>2.75%</p>
                  </td>
                </tr>
                <tr class="bg-teal-100 border-b border-grey-200">
                  <td class="px-4 py-3">
                    <p>300001+</p>
                  </td>
                  <td class="px-4 py-3">
                    <p>1.65%</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <ol>
              <li>
                The Service Fees do not include the Trust Fees, fees for
                professional services (such as legal, financial and taxation
                services), third-party services and other disbursements and
                expenses (see further below).
              </li>
            </ol>
            <ol>
              <li><strong>5.3 Trust and estate management</strong></li>
            </ol>
            <p>
              In circumstances where we conduct estate management and administer
              a testamentary trust as part of the Estate Services, or are
              required to hold estate assets for more than 12 months after the
              grant of probate (or letters of administration, as the case may
              be), the following Trust Fees apply (in addition to the Service
              Fees) from the date 12 months after probate or letters of
              administration are granted (as the case may be):
            </p>
            <p>
              Estate management 1.5% per annum on value of assets held (incl but
              not limited to bank accounts, realty, shares in companies, cash,
              or any other assets held by Safewill Estates on behalf of the
              Estate)
            </p>
            <p>
              Income: 1.5% charged per annum on all income earned in the estate
            </p>
            <p>
              The Trust Fees do not include the Service Fees, fees for
              professional services (such as legal, financial and taxation
              services), third-party services and other disbursements and
              expenses (see further below).
            </p>
            <ol>
              <li>
                <strong>
                  5.4 Safewill Legal and other related party services
                </strong>
              </li>
            </ol>
            <p>
              You agree that Safewill Estates Pty Ltd and the Estate
              Administrator (as your Executor) may engage its partners and
              affiliates <strong>(Safewill Third Parties)</strong> to assist
              with or advise on the provision of the Estate Services despite the
              fact that Safewill Estates and your Executor may receive a direct
              or indirect benefit. If such work is undertaken by Safewill Third
              Parties, their fees will be in addition to the Fees charged by
              Safewill Estates and shall be charged in accordance with (a) the
              charges published or endorsed by Safewill Estates at the time the
              work is performed or (b) at a rate otherwise considered reasonable
              in accordance with market standards.
            </p>
            <ol>
              <li>
                <strong>5.5 External legal, taxation and other services</strong>
              </li>
            </ol>
            <p>
              You agree that we may engage third-party service providers
              (including, but not limited to, legal, financial, property and
              other professionals or specialists) to advise or assist in the
              provision of Estate Services under this Agreement as we see fit
              and without notice to your beneficiaries. If any such work is
              undertaken by external third-party service providers, their costs
              will be deducted from your estate in addition to the Fees
            </p>
            <ol>
              <li><strong>5.6 Disbursements and other expenses</strong></li>
            </ol>
            <p>
              The Fees do not include disbursements and expenses and you agree
              your estate must reimburse us for these actual costs incurred.
              These may include, but are not limited to:
            </p>
            <ol class="ml-4" style="list-style-type: lower-alpha;">
              <li>property management costs;</li>
              <li>
                premiums for insurance we consider are reasonably necessary;
              </li>
              <li>staff travel for provision of the Estate Services; and</li>
              <li>Court fees.</li>
            </ol>
            <ol>
              <li>
                <strong>
                  6. APPOINTMENT OF SAFEWILL WITH FAMILY & FRIENDS
                </strong>
              </li>
            </ol>
            <p>
              In circumstances where you have selected to appoint a Safewill
              Professional Executor (the Estate Administrator) and a friend or
              family member <strong>(Designated Contact)</strong> of your
              choosing:
            </p>
            <ol class="ml-4" style="list-style-type: lower-alpha;">
              <li>
                your Designated Contact will be appointed as your back-up
                executor in the Will document generated by the Safewill
                Platform;
              </li>
              <li>
                we will make commercially reasonable efforts to consult with
                your Designated Contact from time to time during the provision
                of the Estate Services; and
              </li>
              <li>
                following consultation with your Designated Contact and your
                beneficiaries, the Estate Administrator may renounce their
                entitlement to an appointment as your Executor if we consider it
                is in the best interest of both Safewill Estates and your estate
                (including your beneficiaries).
              </li>
            </ol>
            <ol>
              <li>
                <a><strong>7. PRIVACY & DATA</strong></a>
              </li>
            </ol>
            <p>
              Safewill Estates agrees to comply with the Privacy Act 1988 (Cth)
              to the extent it is applicable to the Estate Services.
            </p>
            <p>
              Subject to the Safewill Privacy Policy (available at
              https://safewill.com/privacy), you agree that your personal
              information may be collected from, used by, and disclosed to,
              Safewill Group Members for the purpose of providing the Estate
              Services.
            </p>
            <p>
              We may disclose your personal information:
            </p>
            <ol class="ml-4" style="list-style-type: lower-alpha;">
              <li>
                to our Personnel in connection with the provision of the Estate
                Services;
              </li>
              <li>
                to third parties for the purpose of providing the Estate
                Services only; and
              </li>
              <li>as otherwise required by law.</li>
            </ol>
            <p>
              Except as set out in this clause 7 or the Safewill Privacy Policy,
              we will not disclose your personal information to third parties.
            </p>
            <ol>
              <li>
                <a><strong>8. WARRANTIES & DISCLAIMERS</strong></a>
              </li>
            </ol>
            <ol>
              <li><strong>8.1. Warranties</strong></li>
            </ol>
            <ol>
              <li>
                <a>8.2. Safewill Estates warrants that:</a>
                <ol class="ml-4" style="list-style-type: lower-alpha;">
                  <li>
                    it will deliver the Executor Services with due care and
                    skill; and
                  </li>
                  <li>
                    provide the Executor Services within a reasonable time.
                  </li>
                </ol>
              </li>
            </ol>
            <p>
              To the extent permissible by law, Safewill excludes all other
              warranties not expressly set out in this clause 8.2.
            </p>
            <ol>
              <li>
                8.3. You warrant that by entering into this Agreement:
                <ol class="ml-4" style="list-style-type: lower-alpha;">
                  <li>
                    you are the Safewill Account Holder and intended testator of
                    the Will generated by the Safewill Platform;
                  </li>
                  <li>
                    all information provided to us by you is accurate and up to
                    date;
                  </li>
                  <li>
                    you understand that, after your death, these terms and
                    conditions will bind and may impact your estate and its
                    beneficiaries; and
                  </li>
                  <li>
                    you have sought independent legal advice regarding the
                    effect of these terms and conditions, or have elected not to
                    exercise your right do so.
                  </li>
                </ol>
              </li>
            </ol>
            <ol>
              <li><strong>8.4. Disclaimers</strong></li>
            </ol>
            <p>You acknowledge that:</p>
            <ol class="ml-4" style="list-style-type: lower-alpha;">
              <li>
                you may appoint another person who may not charge a commission
                (noting that while there is no automatic right to commission,
                any executor may make an application to the Court for such);
              </li>
              <li>
                the effect of clause 5 (Fees) and the charging clause
                subsequently included in your Will is that, once appointed as
                Executor, we will receive payment based on the size of the state
                and irrespective of the amount of work performed, and without
                the independent scrutiny of the Court; and
              </li>
              <li>
                the Fees are not fixed by the Court but have instead been
                determined by us.
              </li>
            </ol>
            <p>
              Safewill Estates is not a law firm and does not provide legal or
              financial advice, and a solicitor-client relationship is not
              established by these terms and conditions. We recommend seeking
              independent advice regarding the effect of these terms and
              conditions.
            </p>
            <ol>
              <li><strong>8.5. Renunciation</strong></li>
            </ol>
            <p>
              Safewill Estates (including its Personnel) does not warrant that
              the nominated executor(s) will accept the nomination and seek a
              grant of probate. Any executor has a right to renounce probate in
              accordance with the law in the relevant state or territory and
              these terms and conditions do not alter or limit those rights in
              any way. You warrant that you have been given the opportunity to
              appoint a backup executor to act as Executor of your estate in
              circumstances where the nominated executor(s) renounce the
              appointment, and in failing to do so (or appointing a backup
              executor who later too renounces their appointment) you indemnify
              and hold all Safewill Group Members (and their Personnel) entirely
              free from any liability or loss (including consequential loss)
              howsoever arising as a result of no valid executor being
              appointed.
            </p>
            <p>
              You acknowledge that in circumstances where the Estate
              Administrator renounces probate, or their appointment as Executor
              is deemed invalid, these terms and conditions will be terminated
              without any liability on behalf of either party and your estate
              will be administered in accordance with the law in the relevant
              state or territory.
            </p>
            <p>
              Without limiting anything else set out in these terms and
              conditions, the Estate Administrator is likely to renounce in
              circumstances where:
            </p>
            <ol class="ml-4" style="list-style-type: lower-alpha;">
              <li>
                the total value of your estate is less than $100,000 (AUD);
              </li>
              <li>
                we determine, at our discretion, that there is a likelihood that
                any provision of the Will shall be contested for any reason;
              </li>
              <li>
                we are unable to engage commercially appropriate third-party
                service providers to assist or advise on the administration of
                your estate; or
              </li>
              <li>
                your Will contains complexities not generally accounted for in
                Wills generated by the Safewill Platform (for example, if it
                requires your Executor to take over a business role, manage
                conditional gifts, dispose of overseas assets, or administer
                testamentary trusts that are not for the benefit of an underage
                beneficiary) and we determine, at our discretion that it would
                be in the best interests of your beneficiaries to renounce the
                appointment.
              </li>
            </ol>
            <ol>
              <li><strong>9. INDEMNITY</strong></li>
            </ol>
            <p>
              To the extent permitted by law, your estate shall indemnify and
              hold harmless Safewill Estates, its Personnel (including the
              Estate Administrator) and Safewill Third Parties (together the
              ’<strong>Indemnified</strong>’) against all losses, damages,
              liabilities, claims and expenses arising out of or in connection
              with the Estate Services (including any refusal to provide the
              Estate Services via renunciation as your Executor in any
              circumstance or otherwise by termination of these terms and
              conditions).
            </p>
            <ol>
              <li><strong>10. LIMITATION OF LIABILITY</strong></li>
            </ol>
            <ol class="ml-4" style="list-style-type: lower-alpha;">
              <li>
                Safewill Group Members (including its Personnel) shall not be
                liable to you, your estate, beneficiaries, heirs, successors,
                assignees, administrators, or legal personal representative for
                any loss (including consequential loss) arising out of or in
                connection with (i) the Estate Services; or (ii) these terms and
                conditions.
              </li>
              <br />
              <li>
                Without limiting clause 10(a), to the extent permitted by law,
                Safewill Group Members (including its Personnel) accept no
                liability and your estate agrees to indemnify and hold Safewill
                Group Members (including its Personnel) harmless from and
                against any and all actions, claims, proceedings, demands,
                liabilities, losses, damages, expenses and costs that may be
                brought against Safewill Group Members (including its Personnel)
                or any Third Party Providers that may arise in connection with:
              </li>
              <br />
              <ol class="ml-4 list-roman">
                <li>
                  the provision of the Estate Services by any of the Indemnified
                  or a third-party provider;
                </li>
                <li>
                  the revocation of the Estate Administrator’s appointment as
                  Executor of your estate either under the conditions specified
                  in this agreement, by you, your beneficiaries, or relevant the
                  Court; or
                </li>
                <li>
                  any loss howsoever incurred by your estate, your
                  beneficiaries, or any third person arising in connection with
                  the administration of your estate;
                </li>
              </ol>
              <br />
              <p>
                except in situations where that loss has arisen as a result of
                Safewill Group Members (including its Personnel) acting
                dishonestly or fraudulently.
              </p>
              <br />
              <li>
                Without limiting clauses 10(a) or 10(b), the aggregate liability
                of Safewill Group Members (including its Personnel) Personnel)
                under these terms and conditions will not exceed the Fees paid
                to Safewill Estates Pty Ltd for the Estate Services.
              </li>
            </ol>
            <ol>
              <li><strong>11. ASSIGNMENT</strong></li>
            </ol>
            <p>
              We may assign or novate this Agreement and any of our rights
              existing under it to a third-party at any time by providing notice
              to you, or your beneficiaries, heirs, successors, assigns,
              administrators, or legal personal representative.
            </p>
            <ol>
              <li><strong>12. GENERAL</strong></li>
            </ol>
            <ol>
              <li><strong>12.1. Governing Law</strong></li>
            </ol>
            <p>
              This document is governed by the laws of the State of Victoria.
              Each party submits to the jurisdiction of any court that may hear
              appeals from any of those courts, for any proceedings in
              connection with this document.
            </p>
            <p><strong>12.2. Severability</strong></p>
            <ol class="ml-4" style="list-style-type: lower-alpha;">
              <li>
                If, for any reason, any clause or any part of a clause of these
                terms and conditions is deemed void, voidable or unenforceable,
                such clause or part thereof shall be severable from all other
                clauses of these terms and conditions, and all other clauses
                will remain unaffected.
              </li>
              <li>
                Should the severance of such clause or part thereof be
                considered by Safewill Estates to be materially averse to its
                interest, Safewill Estates may terminate these terms and
                conditions immediately.
              </li>
            </ol>
            <p>Last updated: 20 April 2021</p>
          </div>
        </div>
        <!-- <div class="text-right">
          <button
            class="text-base underline hover:no-underline text-teal-200"
            type="button"
            @click="onSendEmail"
          >
            Email me a copy
          </button>
        </div> -->
        <FormRow>
          <Tooltip
            :disabled="hasScrolledTerms"
            content="You'll need to read the terms before you tick this box."
          >
            <CheckboxInput
              id="professional_executor_terms"
              v-model="willMeta.professional_executor_terms_check"
              :disabled="!hasScrolledTerms"
              class="mt-4 md:mt-6"
              required
            >
              I have read, understood, and agree to be bound by these terms for
              appointing a Safewill Professional Executor.
            </CheckboxInput>
          </Tooltip>
        </FormRow>
        <FormRow>
          <CheckboxInput
            id="professional_executor_payment"
            v-model="willMeta.professional_executor_payment_check"
            class="mt-4 md:mt-6"
            required
          >
            I understand that I am appointing a Professional Executor who will
            charge for their services (and such fees may be charged on the basis
            of the value of my estate only, as determined by Safewill).
          </CheckboxInput>
        </FormRow>
        <FormRow>
          <CheckboxInput
            id="professional_executor_court"
            v-model="willMeta.professional_executor_court_check"
            class="mt-4 md:mt-6"
            required
          >
            I understand that any executor (professional or otherwise) may apply
            to a Court for payment, but is not required to do so.
          </CheckboxInput>
        </FormRow>
        <Tip>
          Administration charges for carrying out your will with Professional
          Executors are deducted from your Estate after you die, meaning there’s
          nothing to be paid up-front from you and no costs are paid by your
          family.
        </Tip>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import CheckboxInput from '~/components/CheckboxInput';
import Form from '~/components/Form';
import FormRow from '~/components/FormRow';
import FormSection from '~/components/FormSection';
import ModuleStep from '~/components/ModuleStep';
import Tip from '~/components/Tip';
import Tooltip from '~/components/Tooltip';

import { executors, user, will } from '~/mixins/apollo';

export default {
  name: 'ExecutorsOptions',
  components: {
    CheckboxInput,
    Form,
    FormRow,
    FormSection,
    ModuleStep,
    Tip,
    Tooltip,
  },
  mixins: [executors, user, will],
  data() {
    return {
      hasScrolledTerms: false,
      isLoading: false,
    };
  },
  computed: {
    prevStep() {
      return this.willMeta.executors_option === 'professional'
        ? '/executors/options'
        : '/executors/primary-contact';
    },
  },
  methods: {
    onScroll() {
      if (!this.hasScrolledTerms) {
        this.hasScrolledTerms =
          this.$refs.terms.scrollHeight -
            this.$refs.terms.scrollTop -
            this.$refs.terms.clientHeight <
          10;
      }
    },
    onSendEmail() {},
    async onSubmit() {
      this.isLoading = true;

      await this.updateWillMeta();

      this.$router.push({
        path:
          this.willMeta.executors_option === 'professional'
            ? '/executors/backup'
            : '/dashboard',
      });
    },
  },
};
</script>

<style scoped>
.text-content > *:not(:last-child) {
  @apply mb-6;
}
</style>
