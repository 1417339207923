<template>
  <ModuleStep :index="4" :prev-step="previous" :show-help="true">
    <Form
      v-slot="{ loading }"
      :hide-submit="!isComplete"
      submit-arrow
      @submit.prevent="onSubmit"
    >
      <FormSection>
        <template #heading>
          Do you want your decision makers to be able to spend your money to
          provide for the reasonable living expenses of anyone else?
        </template>
        <Tip v-if="['nsw', 'wa', 'sa'].includes(userState)">
          This will allow your attorney to use your money to provide for the
          reasonable living and medical expenses of the people you select below
          (for example, your partner or any underage children).
        </Tip>
        <Tip v-else>
          This will allow your attorney to use your money to provide for the
          reasonable needs of the people you select below (for example, your
          partner or any underage children).
        </Tip>
        <LargeRadioButtons
          id="has_inclusions"
          v-model="poaFinancialMeta.has_inclusions"
          class="mb-12"
          :options="[
            {
              label: `Yes`,
              value: true,
            },
            {
              label: `No`,
              value: false,
            },
          ]"
          @change="onChange($event)"
        />
      </FormSection>
      <FormSection v-if="poaFinancialMeta.has_inclusions">
        <template #heading>
          People
        </template>
        <PersonSelector
          v-model="selectedInclusionPeopleIDs"
          :loading="loading"
          :people="people"
          :generic-person-selector="true"
          :min="1"
        />
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  queries as metaQueries,
  mutations as metaMutations,
} from '@/modules/apollo-queries/poa-meta';
import {
  queries as inclusionsQueries,
  mutations as inclusionsMutations,
} from '@/modules/apollo-queries/poa-inclusions';
import { queries as attorneysQueries } from '@/modules/apollo-queries/poa-attorneys';
import { prevStep, nextStep } from '@/modules/poa-flow/financial/index';
import * as peopleQueries from '@/modules/apollo-queries/people';
import Tip from '~/components/Tip';
import Form from '~/components/Form';
import ModuleStep from '~/components/ModuleStep';
import FormSection from '~/components/FormSection';
import LargeRadioButtons from '~/components/LargeRadioButtons';
import PersonSelector from '~/components/PersonSelector';

import { will } from '~/mixins/apollo';

export default {
  name: 'PoaFinancialSpending',
  components: {
    Tip,
    Form,
    ModuleStep,
    FormSection,
    LargeRadioButtons,
    PersonSelector,
  },
  mixins: [will],
  apollo: {
    attorneys: {
      ...attorneysQueries.getAttorneys({ power: 'FINANCIAL' }),
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
    poaFinancialMeta: {
      ...metaQueries.poaFinancialMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
    people: {
      ...peopleQueries.queries.getPeopleOfAccount(),
      variables() {
        return {
          userId: this.userId,
        };
      },
    },
    inclusionPeople: {
      ...inclusionsQueries.getInclusionPeople({
        power: 'FINANCIAL',
      }),
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
  },
  data() {
    return {
      attorneys: undefined,
      inclusionPeople: [],
      selectedInclusionPeopleIDs: [],
      poaFinancialMeta: {
        has_inclusions: undefined,
      },
    };
  },
  computed: {
    ...mapGetters(['poaId', 'userId']),
    previous() {
      return prevStep({
        addressState: this.userState,
        currentUrl: window.location.pathname,
        poaFinancialMeta: this.poaFinancialMeta,
        numPrimaryAttorneysSelected: this.attorneys?.filter(
          (attorney) => attorney.role === 'PRIMARY'
        ).length,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
      });
    },
    isComplete() {
      return (
        this.poaFinancialMeta.has_inclusions === false ||
        (this.poaFinancialMeta.has_inclusions === true &&
          this.selectedInclusionPeopleIDs.length > 0)
      );
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
  },
  watch: {
    inclusionPeople(inclusionPeople) {
      if (inclusionPeople.length && !this.selectedInclusionPeopleIDs.length) {
        this.selectedInclusionPeopleIDs = inclusionPeople.map(
          (people) => people.id
        );
      }
    },
  },
  methods: {
    onChange($event) {
      if ($event === false) {
        this.onSubmit();
      }
    },
    async onSubmit() {
      await Promise.all([
        this.$apollo.mutate(
          metaMutations.updatePoaMeta(this.poaId, {
            key: 'has_inclusions',
            value: this.poaFinancialMeta.has_inclusions ? 'true' : 'false',
            category: 'FINANCIAL',
          })
        ),
        this.$apollo.mutate(
          inclusionsMutations.setInclusionPeople({
            peopleIds: this.selectedInclusionPeopleIDs,
            power: 'FINANCIAL',
            poaId: this.poaId,
          })
        ),
      ]);

      const path = this.next;
      this.$router.push({ path });
    },
  },
  head() {
    return {
      title: 'Financial spending',
    };
  },
};
</script>
