var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleStep',{attrs:{"index":1,"prev-step":"/dashboard","show-help":true}},[_c('Form',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"hide-submit":!_vm.isComplete,"auto-submit":_vm.isComplete && !_vm.willMeta.has_children,"submit-arrow":""},on:{"done":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('FormSection',{staticClass:"step-1",scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        Do you have any\n        "),_c('GlossaryTooltip',{attrs:{"term":"Child"}},[_vm._v(" children ")]),_vm._v("?\n      ")]},proxy:true}],null,true)},[_vm._v(" "),_c('Tip',[_vm._v("\n        If you have a child on the way, select no for now. You can then come\n        back and update this once they are born.\n      ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"hasChildren","options":[
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          } ]},model:{value:(_vm.willMeta.has_children),callback:function ($$v) {_vm.$set(_vm.willMeta, "has_children", $$v)},expression:"willMeta.has_children"}})],1),_vm._v(" "),(_vm.willMeta.has_children)?_c('FormSection',{staticClass:"step-2",attrs:{"tip":"We’ll ask you to add or select people at relevant places in your Will. Their details can be edited on the People, Pets & Charities page."},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v(" My children are... ")]},proxy:true}],null,true)},[_vm._v(" "),_c('PersonSelector',{attrs:{"loading":loading,"people":_vm.filteredPeople,"type":"child"},model:{value:(_vm.selectedChildren),callback:function ($$v) {_vm.selectedChildren=$$v},expression:"selectedChildren"}})],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }