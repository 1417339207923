<template>
  <div
    class="flex justify-center items-center relative text-green-400 text-2xl font-medium h-10 md:h-12 w-10 md:w-12 uppercase"
  >
    <span
      class="absolute bg-green-300 opacity-30 rounded-full top-0 left-0 h-full w-full"
    />
    <div class="flex justify-center items-center">
      <span v-if="text" v-text="text" />
      <BaseIcon v-else :id="icon" classes="h-6 w-6" />
    </div>
  </div>
</template>

<script>
import BaseIcon from '~/components/BaseIcon';

export default {
  name: 'MenuDisc',
  components: {
    BaseIcon,
  },
  props: {
    text: {
      default: null,
      type: String,
    },
    icon: {
      default: 'menu',
      type: String,
    },
  },
};
</script>
