<template>
  <Tooltip
    class="inline-block w-full md:w-auto"
    :content="tooltip"
    :disabled="!disabled"
  >
    <BaseButton
      class="btn btn-xl btn-teal btn-focus w-full md:w-auto font-medium will-submit"
      :disabled="disabled || $nuxt.isOffline"
      :loading="loading"
      @click="handleSubmitWill"
    >
      <div class="flex items-center">
        <span class="inline-block mr-auto w-32">
          Submit
        </span>
        <span class="ml-4">
          <BaseIcon id="arrow-right" />
        </span>
      </div>
    </BaseButton>
  </Tooltip>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import SUBMIT_WILL_MUTATION from '~/graphql/mutations/SubmitWill';

import BaseButton from '~/components/BaseButton';
import BaseIcon from '~/components/BaseIcon';
import Tooltip from '~/components/Tooltip';

import { subscription, will } from '~/mixins/apollo';

export default {
  name: 'WillSubmitButton',
  components: {
    BaseButton,
    BaseIcon,
    Tooltip,
  },
  mixins: [subscription, will],
  props: {
    ready: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['verified', 'willStatus']),
    ...mapFields('signup', ['meta.has_partner']),
    disabled() {
      return !this.ready || this.willStatus === 'FLAGGED';
    },
    tooltip() {
      return this.willStatus === 'FLAGGED'
        ? 'One or more cards below need your attention before you can submit for review again.'
        : 'You’ll need to answer all the questions before you can submit.';
    },
  },
  methods: {
    ...mapActions(['setWillStatus']),
    ...mapActions('ui', ['setShowNotifyCharityConsentModal']),
    ...mapActions('checkout', ['submitWill', 'resetCheckoutState']),
    async resubmitWill() {
      const withPartner = this.has_partner || this.willMeta.has_partner;
      const willProduct = withPartner ? 'WILL_X2' : 'WILL';

      await this.$apollo
        .mutate({
          mutation: SUBMIT_WILL_MUTATION,
          variables: {
            id: this.willId,
            products: [willProduct],
          },
        })
        .then(({ data, errors }) => {
          if (data.submitWill && data.submitWill.success) {
            this.$nuxt.$emit('sendTrackingEvent', {
              event: '📑 Resubmit Will',
              props: {},
              integrations: {
                Amplitude: false,
                Intercom: false,
                'Facebook Pixel': false,
                'Facebook Pixel Server Side': false,
                'Personas Facebook Custom Audiences': false,
              },
            });
            this.setWillStatus(data.submitWill.will.status);
          }
        });
    },
    async handleSubmitWill() {
      if (this.hasValidSubscription) {
        await this.resubmitWill();
      } else {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '🛒 Open Checkout',
        });
        await this.refetchWill();
        await this.submitWill();
        this.resetCheckoutState();
        this.$router.replace({ path: '/checkout' });
      }
      this.willMeta.notify_charities = null;
      this.updateWillMeta();
      this.loading = false;
    },
  },
};
</script>
