var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleStep',{attrs:{"index":5,"prev-step":_vm.previous,"show-help":true}},[_c('Form',{attrs:{"hide-submit":!_vm.isComplete,"submit-arrow":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('FormSection',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        Would you like to appoint\n        "+_vm._s(_vm.canHaveMultipleSubstitutes ? 'substitute' : 'a substitute')+"?\n      ")]},proxy:true}],null,true)},[_vm._v(" "),(['nsw'].includes(_vm.userState))?_c('p',{staticClass:"mb-4"},[_vm._v("\n        In New South Wales, your substitute decision maker will only be\n        granted power if all of your original decision makers are unable to\n        act.\n      ")]):(['vic'].includes(_vm.userState))?_c('p',{staticClass:"mb-4"},[_vm._v("\n        In Victoria, your substitute decision maker will take over at any time\n        your primary decision maker is unavailable.\n      ")]):(['qld'].includes(_vm.userState))?_c('p',{staticClass:"mb-4"},[_vm._v("\n        In Queensland, your substitute decision maker will only be granted\n        power if all of your original decision makers are unable to act.\n      ")]):(['act'].includes(_vm.userState))?_c('p',{staticClass:"mb-4"},[_vm._v("\n        In ACT your substitute decision makers will only be granted power if\n        all of your original decision makers are unable to act.\n      ")]):(['wa'].includes(_vm.userState))?_c('p',{staticClass:"mb-4"},[_vm._v("\n        In Western Australia your substitute decision maker will replace any\n        of your original decision makers that are unable to act.\n      ")]):(['tas'].includes(_vm.userState))?_c('p',{staticClass:"mb-4"},[_vm._v("\n        In Tasmania your substitute decision makers will replace any of your\n        original decision makers that are unable to act.\n      ")]):(['nt'].includes(_vm.userState))?_c('p',{staticClass:"mb-4"},[_vm._v("\n        In the Northern Territory your substitute decision makers will only be\n        granted power if all of your original decision makers are unable to\n        act.\n      ")]):_vm._e(),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"has_substitute","options":[
          {
            label: ("Yes, I'd like to appoint " + (_vm.canHaveMultipleSubstitutes
                ? 'substitute decision makers'
                : 'a substitute decision maker')),
            value: true,
          },
          {
            label: ("No, I don't want to appoint  " + (_vm.canHaveMultipleSubstitutes
                ? 'any substitute decision makers'
                : 'a substitute decision maker')),
            value: false,
          } ]},on:{"change":function($event){return _vm.onSubmit()}},model:{value:(_vm.poaMedicalMeta.has_substitute),callback:function ($$v) {_vm.$set(_vm.poaMedicalMeta, "has_substitute", $$v)},expression:"poaMedicalMeta.has_substitute"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }