<template>
  <div>
    <div class="flex items-center mb-18">
      <div
        class="flex justify-center items-center relative text-green-400 text-6xl font-medium h-28 w-28"
      >
        <span
          class="absolute bg-green-300 opacity-30 rounded-full top-0 left-0 h-full w-full"
        ></span>
        <span>{{ userInitial }}</span>
      </div>
      <div class="ml-6">
        <p class="text-2xl lg:text-3xl font-medium leading-tight text-grey-600">
          {{ fullName }}
        </p>
        <h1 class="text-4xl md:text-5xl lg:text-6xl font-medium leading-tight">
          {{ title }}
        </h1>
      </div>
    </div>
    <div
      class="border-l-8 border-teal-100 bg-white p-4 lg:p-12 rounded-lg mb-8"
    >
      <div class="relative">
        <BaseIcon
          v-if="willStatus !== 'APPROVED'"
          id="quill"
          classes="absolute right-0 bottom-0 w-24 h-auto"
        />
        <div class="lg:flex relative">
          <div
            v-if="willStatus === 'IN_PROGRESS'"
            class="w-full lg:w-5/12 mb-8 lg:mb-0"
          >
            <div v-if="progress < 100">
              <h2 class="text-2xl lg:text-3xl leading-tight mb-3">
                Finish your Will
              </h2>
              <p class="text-lg mb-8 text-charcoal-400">
                Takes as little as 20 minutes for most people.
              </p>
              <BaseButton
                class="btn relative btn btn-lg btn-slate text-white text-center text-base w-64"
                to="/dashboard"
              >
                Continue
              </BaseButton>
            </div>
            <div v-else>
              <h2 class="text-2xl lg:text-3xl leading-tight mb-3">
                Submit your Will
              </h2>
              <p class="text-lg mb-8 text-charcoal-400">
                Our legal advisors are here and ready to review your Will.
              </p>
              <BaseButton
                class="btn relative btn btn-lg btn-slate text-white text-center text-base w-64"
                to="/dashboard"
              >
                Continue
              </BaseButton>
            </div>
          </div>
          <div
            v-else-if="willStatus === 'AWAITING_APPROVAL'"
            class="w-9/12 lg:w-7/12"
          >
            <div>
              <h2 class="text-2xl lg:text-3xl leading-tight mb-3">
                Your Will is being reviewed
              </h2>
              <p class="text-lg text-charcoal-400">
                We’ll typically be in touch within 3 business days with your
                approved Will for signing.
              </p>
            </div>
          </div>
          <div v-else-if="willStatus === 'FLAGGED'" class="w-9/12 lg:w-7/12">
            <div>
              <h2 class="text-2xl lg:text-3xl leading-tight mb-3">
                Your Will needs attention.
              </h2>
              <p class="text-lg mb-8 text-charcoal-400">
                Our support team will get in touch within the next 1 - 2
                business days.
              </p>
              <BaseButton
                class="btn relative btn btn-lg btn-slate text-white text-center text-base w-64"
                to="/dashboard"
              >
                Go to Will
              </BaseButton>
            </div>
          </div>
          <div
            v-else-if="willStatus === 'APPROVED'"
            class="w-full lg:w-6/12 mb-8 lg:mb-0"
          >
            <div>
              <h2
                class="flex items-center text-2xl lg:text-3xl leading-tight mb-3"
              >
                <span class="relative bg-teal-100 rounded-full w-8 h-8 mr-4">
                  <BaseIcon
                    id="tick"
                    classes="absolute top-1/2 left-1/2 translate-negative-1/2 text-teal-200 w-4 h-auto"
                  />
                </span>
                <span>Your Will is complete</span>
              </h2>
              <p class="text-lg text-charcoal-400">
                Congratulations! Your will is ready for download.
              </p>
            </div>
          </div>
          <div v-if="willStatus === 'IN_PROGRESS'" class="w-full lg:w-7/12">
            <ul class="lg:pl-20 text-grey-600">
              <li class="flex items-center mb-8">
                <span
                  class="relative flex-shrink-0 rounded-full w-9 h-9 mr-4 bg-white"
                  :class="progress >= 100 ? 'bg-teal-100' : 'bg-white'"
                >
                  <span
                    class="absolute top-0 left-1/2 border-r-2 ml-px h-14 mt-10"
                    :class="
                      progress >= 100
                        ? 'border-teal-200 border-solid'
                        : 'border-grey-450 border-dashed'
                    "
                  ></span>
                  <BaseIcon
                    v-if="progress >= 100"
                    id="tick"
                    classes="w-4 h-auto text-teal-200"
                  />
                  <ProgressCircle
                    v-else
                    class="absolute text-teal-200 h-10 w-10"
                    :progress="progress"
                  />
                </span>
                <p :class="progress < 100 ? 'text-charcoal-400' : ''">
                  Finish your will online or over the phone
                </p>
              </li>
              <li class="flex items-center mb-8">
                <span
                  class="relative flex-shrink-0 rounded-full w-9 h-9 mr-4 bg-white"
                  :class="
                    progress >= 100
                      ? 'border-teal-200 border-solid'
                      : 'border-grey-450 border-dashed'
                  "
                >
                  <span
                    class="absolute top-0 left-1/2 border-r-2 ml-px border-dashed border-grey-450 h-12 mt-9"
                  ></span>
                  <ProgressCircle
                    class="absolute text-teal-200 h-10 w-10"
                    :progress="0"
                  />
                </span>
                <p :class="progress >= 100 ? 'text-charcoal-400' : ''">
                  Purchase and submit for review
                </p>
              </li>
              <li class="flex items-center mb-8">
                <span
                  class="relative flex-shrink-0 rounded-full bg-white w-9 h-9 mr-4"
                >
                  <span
                    class="absolute top-0 left-1/2 border-r-2 ml-px border-dashed border-grey-450 h-14 mt-9"
                  ></span>
                  <ProgressCircle
                    class="absolute text-teal-200 h-10 w-10"
                    :progress="0"
                  />
                </span>
                <p>Print and sign with two witnesses</p>
              </li>
              <li class="flex items-center">
                <span
                  class="relative flex-shrink-0 rounded-full bg-white w-9 h-9 mr-4"
                >
                  <ProgressCircle
                    class="absolute text-teal-200 h-10 w-10"
                    :progress="0"
                  />
                </span>
                <p>Update your will when you need to</p>
              </li>
            </ul>
          </div>
          <div v-else-if="willStatus === 'APPROVED'" class="w-full lg:w-6/12">
            <div
              class="flex flex-wrap lg:flex-no-wrap justify-end items-center h-full"
            >
              <BaseButton
                class="w-full lg:w-auto bg-teal-100 text-center text-base text-charcoal-200 hover:text-charcoal-100 py-3 px-4 mb-4 lg:mb-0 lg:mr-4"
                @click="downloadWill"
              >
                Print &amp; Sign
              </BaseButton>
              <BaseButton
                class="w-full lg:w-auto btn-white border border-grey-300 text-center text-base text-charcoal-200 hover:bg-white hover:text-charcoal-100 py-3 px-4 mb-4 lg:mb-0 lg:mr-4"
                to="/dashboard/upload-will"
              >
                Upload signed will
              </BaseButton>
              <BaseButton
                class="w-full lg:w-auto btn-white border border-grey-300 text-center text-base text-charcoal-200 hover:bg-white hover:text-charcoal-100 py-3 px-4"
                @click="unlockWill"
              >
                Make an update
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showPoa"
      class="relative border-l-8 border-teal-100 bg-white rounded-lg p-4 mb-18 lg:p-12"
    >
      <BaseIcon
        id="shield-bg"
        classes="absolute right-0 bottom-0 w-24 h-auto mr-4 mb-4 lg:mr-12 lg:mb-12"
      />
      <div v-if="poaStatus === null" class="pr-40 mb-6">
        <Chip label="Recommended" class="mb-3" />
        <h2 class="text-2xl lg:text-3xl leading-tight mb-3">
          Appoint your Medical and Financial Powers of Attorney
        </h2>
        <p class="text-lg mb-8 text-charcoal-400">
          An important legal tool to appoint someone to protect your living
          wishes should you end up in a vulnerable position where you can’t make
          decisions for yourself.
        </p>
        <div class="text-grey-600 text-lg lg:flex items-center">
          <BaseButton
            class="btn relative btn btn-lg btn-slate text-white text-center text-base w-64 mr-4"
            to="/poa/checkout"
          >
            Purchase for $50
          </BaseButton>
          <div class="ml-2 mt-2 lg:m-0">
            or
            <nuxt-link
              to="/poa"
              class="text-charcoal-400 hover:text-charcoal-500 border-b-2 border-solid border-teal-200 hover:border-teal-300 inline-block ml-2 mr-1"
            >
              learn more
            </nuxt-link>
          </div>
        </div>
      </div>
      <div v-else-if="poaStatus === 'IN_PROGRESS'" class="lg:flex">
        <div class="lg:w-5/12 mb-6 lg:mb-0">
          <Chip label="Purchased" type="notice" class="mb-3" />
          <h2 class="text-2xl lg:text-3xl leading-tight mb-3">
            Complete your Powers of Attorney
          </h2>
          <p class="text-lg mb-8 text-charcoal-400">
            Appoint someone to protect your living wishes around your lifestyle
            or financial decisions.
          </p>
          <div class="flex items-center text-grey-600">
            <BaseButton
              class="btn relative btn btn-lg btn-slate text-white text-center text-base w-64 mr-4"
              to="/poa/dashboard"
            >
              Continue
            </BaseButton>
          </div>
        </div>
        <div class="lg:w-7/12">
          <ul class="lg:pl-20 text-grey-600">
            <li class="flex items-center mb-8">
              <div class="relative w-9 h-9 mr-4">
                <div
                  class="absolute top-0 left-1/2 border-r-2 ml-px border-dashed border-grey-700 opacity-50 h-12 mt-9"
                ></div>
                <ProgressCircle
                  class="absolute text-teal-200 h-10 w-10"
                  :progress="progress"
                />
              </div>
              <p :class="progress < 100 ? 'text-charcoal-400' : ''">
                Complete your POA online or over the phone
              </p>
            </li>
            <li class="flex items-center mb-8">
              <div class="relative w-9 h-9 mr-4">
                <div
                  class="absolute top-0 left-1/2 border-r-2 ml-px border-dashed border-grey-700 opacity-50 h-12 mt-9"
                ></div>
                <ProgressCircle class="absolute text-teal-200 h-10 w-10" />
              </div>
              <p>
                Submit for review
              </p>
            </li>
            <li class="flex items-center mb-8">
              <div class="relative w-9 h-9 mr-4">
                <div
                  class="absolute top-0 left-1/2 border-r-2 ml-px border-dashed border-grey-700 opacity-50 h-12 mt-9"
                ></div>
                <ProgressCircle class="absolute text-teal-200 h-10 w-10" />
              </div>
              <p>Print and sign with two witnesses</p>
            </li>
            <li class="flex items-center">
              <div class="relative w-9 h-9 mr-4">
                <ProgressCircle class="absolute text-teal-200 h-10 w-10" />
              </div>
              <p>Update your POA when you need to</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { queries } from '@/modules/apollo-queries/poa-status';

import {
  assets,
  beneficiaries,
  charities,
  executors,
  gifts,
  guardians,
  people,
  pets,
  subscription,
  user,
  will,
} from '~/mixins/apollo';

import DOWNLOAD_WILL_QUERY from '~/graphql/queries/DownloadWill';

import BaseIcon from '~/components/BaseIcon';
import BaseButton from '~/components/BaseButton';
import Chip from '~/components/Chip';
import ProgressCircle from '~/components/ProgressCircle';

export default {
  name: 'Home',
  layout: 'dashboard',
  middleware: ['authenticated'],
  components: {
    BaseIcon,
    BaseButton,
    Chip,
    ProgressCircle,
  },
  mixins: [
    assets,
    beneficiaries,
    charities,
    executors,
    gifts,
    guardians,
    people,
    pets,
    subscription,
    user,
    will,
  ],
  apollo: {
    poaStatus: {
      ...queries.getPoaStatus(),
      variables() {
        return {
          id: this.poaId,
        };
      },
    },
    pdf: {
      query: DOWNLOAD_WILL_QUERY,
      update: (data) => data.downloadWill && data.downloadWill.will.pdf,
      variables() {
        return {
          id: this.willId,
        };
      },
      skip() {
        return ['IN_PROGRESS', 'AWAITING_APPROVAL'].includes(this.willStatus);
      },
    },
  },
  data() {
    return {
      poaStatus: null,
      title: '',
    };
  },
  computed: {
    ...mapGetters(['willStatus']),
    ...mapGetters('modules', ['modules']),
    ...mapGetters(['poaId']),
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    showPoa() {
      return (
        (this.$ff.powerOfAttorney() &&
          (this.poaStatus === 'IN_PROGRESS' || this.poaStatus === null)) ||
        (!this.$ff.powerOfAttorney() && this.poaStatus === 'IN_PROGRESS')
      );
    },
    totalSteps() {
      let steps = 0;

      this.modules.forEach((module) => {
        steps += module.totalSteps;
      });

      return steps;
    },
    progress() {
      return (this.completedSteps * 100) / this.totalSteps;
    },
    validEstateDistribution() {
      return this.beneficiaries.every(
        (beneficiary) =>
          beneficiary.distribution && beneficiary.distribution !== '0'
      );
    },
    validEstateBackup() {
      return this.beneficiaries.every((beneficiary) => {
        if (beneficiary.charity) return true;
        if (beneficiary.meta.estate_backup_split === 'custom') {
          return beneficiary.backup.every((b) => {
            return b.distribution && b.distribution !== '0';
          });
        } else {
          return !!beneficiary.meta.estate_backup_split;
        }
      });
    },
    completedSteps() {
      let steps = 0;

      if (this.willMeta) {
        // Yourself
        if (
          this.willMeta.has_alt_name === false ||
          (this.willMeta.alt_name_first && this.willMeta.alt_name_last)
        ) {
          steps++;
        }
        if (this.willMeta.date_of_birth) {
          steps++;
        }
        if (
          this.willMeta.address_street &&
          this.willMeta.address_suburb &&
          this.willMeta.address_state &&
          this.willMeta.address_postcode
        ) {
          steps++;
        }
        if (this.willMeta.has_partner === false || this.partners.length) {
          steps++;
          // Phone
          steps++;
        }

        // Guardians
        if (this.willMeta.has_children === false || this.children.length) {
          steps++;
        }
        if (this.willMeta.has_children && !this.minors.length) {
          steps += 2;
        }
        if (
          this.willMeta.has_children === false ||
          this.willMeta.has_primary_guardian === false ||
          this.primaryGuardians.length
        ) {
          steps++;
        }
        if (
          this.willMeta.has_children === false ||
          this.willMeta.has_primary_guardian === false ||
          this.willMeta.has_backup_guardian === false ||
          this.backupGuardians.length
        ) {
          steps++;
        }
        if (this.willMeta.has_pets === false || this.pets.length) {
          steps++;
        }
        if (
          this.willMeta.has_pets === false ||
          this.willMeta.has_pet_guardians === false ||
          this.pets.find((pet) => pet.person)
        ) {
          steps++;
          // Pet Care Fund
          steps++;
        }

        // Executors
        if (this.primaryExecutors.length) {
          steps++;
          steps++;
        }
        if (
          this.willMeta.has_backup_executor === false ||
          this.backupExecutors.length
        ) {
          steps++;
        }

        // Estate
        if (this.willMeta.estate_split) {
          steps++;
          steps++;

          if (this.validEstateBackup) {
            steps++;
            steps++;
          }

          if (
            this.willMeta.estate_split === 'custom' &&
            this.validEstateDistribution
          ) {
            steps++;
          } else if (
            this.willMeta.estate_split === 'partner' ||
            this.willMeta.estate_split === 'children'
          ) {
            steps++;
          }

          if (
            this.willMeta.referral_charity &&
            this.willMeta.charity_in_estate !== null
          ) {
            steps++;
          } else if (this.willMeta.estate_split) {
            steps++;
          }
        }

        // Gifts
        if (this.willMeta.has_gifts === false || this.gifts.length) {
          steps++;

          if (this.willMeta.referral_charity) {
            if (
              this.willMeta.charity_in_estate !== null ||
              this.willMeta.charity_in_gifts !== null
            ) {
              steps++;
            }
          } else {
            steps++;
          }
        }

        // Assets
        if (this.willMeta.has_assets === false || this.assets.length) {
          steps++;
          steps++; // Step again for optional notes step
        }

        // Funeral
        if (
          this.willMeta.funeral_skip === true ||
          this.willMeta.funeral_type !== null
        ) {
          steps++;
          steps++;
          steps++;
          steps++;
        }
      }

      return steps;
    },
    userInitial() {
      const hasName = 'name_first' in this.willMeta;
      return hasName ? this.willMeta.name_first.charAt(0) : '';
    },
    fullName() {
      let name = '';

      if ('name_first' in this.willMeta) {
        name = this.willMeta.name_first.trim();

        if (this.willMeta.name_middle) {
          name = `${name} ${this.willMeta.name_middle.trim()}`;
        }

        name = `${name} ${this.willMeta.name_last.trim()}`;
      }

      return name;
    },
  },
  watch: {
    willMeta() {
      // Set title once will meta has loaded
      if (!this.title && 'show_welcome_modal' in this.willMeta) {
        const firstVisit = this.willMeta.show_welcome_modal;
        this.title = firstVisit ? 'Welcome' : 'Welcome back';
      }
    },
  },
  mounted() {
    this.setShowGiftWizard(false);
  },
  methods: {
    ...mapActions('gift', ['setShowGiftWizard']),
    ...mapActions('checkout', ['submitWill']),
    ...mapActions('ui', [
      'setShowSubscriptionExpiredModal',
      'setShowUnlockWillModal',
    ]),
    unlockWill() {
      if (this.hasValidSubscription) {
        this.setShowUnlockWillModal(true);
      } else {
        this.setShowSubscriptionExpiredModal(true);
      }

      this.$router.push({ path: '/dashboard' });
    },
    downloadWill() {
      const pdfFilename = `safewill-${this.will.hashId}_${this.willMeta.name_last},${this.willMeta.name_first}`.toLowerCase();

      this.$nuxt.$emit('sendTrackingEvent', {
        event: '📥 Download Will',
      });
      this.$downloadPdf(this.pdf, pdfFilename);
    },
  },
};
</script>
