<template>
  <div>
    <AdminBackLink to="/admin/submissions">Submissions</AdminBackLink>
    <div v-if="will" class="flex items-baseline mt-3 mb-2">
      <h3
        class="admin-id px-2 py-2 mr-6 font-mono font-medium text-2xl leading-none"
        v-text="will.hashId.toUpperCase()"
      />
      <span v-text="$formatDate(will.updatedAt)" />
    </div>
    <div v-if="latestWillData">
      This is not the latest will of the user. The Latest will is
      <a
        class="underline uppercase"
        :href="`/admin/submissions/${latestWillData.id}`"
      >
        {{ latestWillData.hashId }}
      </a>
    </div>
    <AdminMainSideLayout>
      <template #main>
        <AdminCard v-if="will" title="Submission Details">
          <AdminModuleSummary
            v-for="(module, index) in modules"
            :id="snakeCase(module.name)"
            :key="index"
            v-model="willIssues[module.name]"
            :disabled="isDisabled.toggleFlags"
            :title="`${index + 1}. ${module.name}`"
            :will-id="will.id"
            :module-name="module.name"
          >
            <AdminSimpleTable
              :rows="tableRows[module.name]"
              :module-name="module.name"
            />
            <AdminComments :will-id="will.id" :module-name="module.name" />
          </AdminModuleSummary>
        </AdminCard>
      </template>
      <template #side>
        <AdminCard>
          <AdminCardSection v-if="will" title="Customer">
            <h5 class="mb-2 opacity-50">Contact Details</h5>
            <p
              class="leading-snug"
              v-text="`${willMeta.name_first} ${willMeta.name_last}`"
            />
            <a
              class="inline-block w-full leading-snug truncate underline hover:no-underline"
              :href="`mailto:${will.email}`"
              v-text="will.email"
            />
            <a
              v-if="willMeta.phone_number"
              class="inline-block w-full leading-snug truncate underline hover:no-underline"
              :href="`tel:${willMeta.phone_number}`"
              v-text="willMeta.phone_number"
            />

            <label for="awaitingOn" class="mt-6 inline-block">
              Waiting response from:
            </label>
            <LargeRadioButtons
              id="awaitingOn"
              :value="will.awaitingOn"
              horizontal
              :options="[
                {
                  label: 'Admin',
                  value: 'Admin',
                },
                {
                  label: 'Customer',
                  value: 'Customer',
                },
              ]"
              @change="assignAwaitingOn"
            />
          </AdminCardSection>
          <AdminCardSection v-if="will">
            <h5 v-if="willMeta.date_of_birth" class="mb-2 opacity-50">
              Date Of Birth
            </h5>
            <p v-if="willMeta.date_of_birth" class="leading-snug mb-4">
              {{ willMeta.date_of_birth }} ({{ getAge() }} years old)
            </p>
            <h5 class="mb-2 opacity-50">Address</h5>
            <p class="leading-snug">
              {{ willMeta.address_street }}
              <br />
              {{ willMeta.address_suburb }}, {{ willMeta.address_state }}
              {{ willMeta.address_postcode }}
            </p>
          </AdminCardSection>
          <AdminCardSection>
            <h5 class="mb-2 opacity-50">Actions</h5>
            <AdminActionLink
              v-if="isAdminClassUser"
              class="mb-4 text-blue-200"
              glyph="user"
              :disabled="!!latestWillData"
              @click="masquerade"
            >
              Masquerade as user
            </AdminActionLink>
            <AdminActionLink
              class="mb-4 text-green-100"
              glyph="circle-tick"
              :disabled="(will && will.verified) || !!latestWillData"
              @click="verify"
            >
              Mark as verified
            </AdminActionLink>
            <AdminActionLink
              class="mb-4 text-orange-200"
              glyph="document"
              :disabled="!willMeta || !willMeta.has_partner"
              @click="showPartnerModal = true"
            >
              Duplicate will
            </AdminActionLink>
          </AdminCardSection>
          <AdminCardSection v-if="will && debug">
            <h5 class="mb-2 opacity-50">Debug</h5>
            <div class="mb-4 last-child:mb-0">
              <h6 class="font-medium">User Agent</h6>
              <p
                class="leading-snug"
                v-text="willMeta.user_agent || 'No user agent'"
              />
            </div>
            <div class="mb-4 last-child:mb-0">
              <h6 class="font-medium">Device Type</h6>
              <p
                class="leading-snug"
                v-text="willMeta.device_type || 'No device type'"
              />
            </div>
          </AdminCardSection>
        </AdminCard>
        <AdminCard>
          <AdminCardSection v-if="will" title="Status">
            <AdminStatusChip :status="will && will.status" />
          </AdminCardSection>
          <AdminCardSection title="Actions">
            <AdminActionLink
              v-if="isAdminClassUser"
              class="mb-4 text-orange-200"
              :disabled="$apollo.queries.pdf.loading || !!latestWillData"
              glyph="cogs"
              :loading="$apollo.queries.pdf.loading"
              @click="generateWill()"
            >
              Generate will
            </AdminActionLink>
            <AdminActionLink
              class="mb-4 text-blue-200"
              :disabled="isDisabled.downloadPdf"
              glyph="document"
              :loading="$apollo.queries.pdf.loading"
              @click="$downloadPdf(pdf, pdfFilename)"
            >
              Download will
            </AdminActionLink>
            <AdminActionLink
              class="mb-4 text-green-100"
              :disabled="isDisabled.approveWill"
              glyph="circle-tick"
              :loading="approvingWill"
              @click="approveWill"
            >
              Mark as approved
            </AdminActionLink>
            <AdminActionLink
              class="mb-4 text-green-100"
              glyph="question-mark"
              :loading="validatingWill"
              @click="validateWill"
            >
              Validate will
            </AdminActionLink>
            <AdminActionLink
              class="text-red-300"
              :disabled="isDisabled.updateFlags"
              glyph="flag"
              :loading="updatingFlags"
              @click="updateFlags"
            >
              {{ flagActionLabel }}
            </AdminActionLink>
          </AdminCardSection>
        </AdminCard>
        <AdminCard>
          <AdminCardSection v-if="invites" title="Referrals">
            <AdminActionLink
              class="mb-4 text-blue-200"
              glyph="document"
              :underline="false"
              :loading="$apollo.queries.invites.loading"
            >
              <p>
                Invites Emailed: <strong>{{ invitesSent.length }}</strong>
              </p>
            </AdminActionLink>
            <AdminActionLink
              class="mb-4 text-green-100"
              glyph="circle-tick"
              :underline="false"
              :loading="$apollo.queries.invites.loading"
            >
              <p>
                Invites Complete: <strong>{{ invitesComplete.length }}</strong>
              </p>
            </AdminActionLink>
            <AdminActionLink
              v-if="invitedBy"
              class="mb-4 text-cerise-200"
              glyph="child"
              :underline="false"
              :loading="$apollo.queries.invites.loading"
            >
              <p>
                Referrer: <strong>{{ invitedBy }}</strong>
              </p>
            </AdminActionLink>
          </AdminCardSection>
        </AdminCard>
        <AdminCard v-if="paidInvites.length">
          <AdminCardSection title="Partner Code">
            <div
              v-for="(item, index) in paidInvites"
              :key="`paid-invite-${index}`"
            >
              <p>{{ item.code }}</p>
            </div>
          </AdminCardSection>
        </AdminCard>
      </template>
    </AdminMainSideLayout>
    <AdminPartnerModal
      v-if="showPartnerModal"
      :show-modal="showPartnerModal"
      :will-data="getDuplicateData"
      @close:partnerModal="showPartnerModal = false"
    />
    <Toast />
  </div>
</template>

<script>
import snakeCase from 'lodash/snakeCase';
import { mapGetters, mapMutations } from 'vuex';

import DOWNLOAD_WILL_QUERY from '~/graphql/queries/DownloadWill';
import UPDATE_ISSUES_MUTATION from '~/graphql/mutations/UpdateIssues';
import UPDATE_WILL_STATUS_MUTATION from '~/graphql/mutations/UpdateWillStatus';
import UPDATE_WILL_AWAITING_ON_MUTATION from '~/graphql/mutations/UpdateWillAwaitingOn';
import VALIDATE_WILL_QUERY from '~/graphql/queries/ValidateWill';
import MASQURADE from '~/graphql/mutations/Masquerade';
import FORCE_VERIFY from '~/graphql/mutations/ForceVerify';
import GENERATE_WILL_MUTATION from '~/graphql/mutations/GenerateWill';
import GET_INVITES from '~/graphql/queries/GetInvites';
import GET_POA_BY_USER_ID from '~/graphql/queries/GetPoaByUserId';
import GET_WILL_BY_EMAIL from '~/graphql/queries/GetWillByEmail';

import AdminActionLink from '~/components/AdminActionLink';
import AdminBackLink from '~/components/AdminBackLink';
import AdminCard from '~/components/AdminCard';
import AdminCardSection from '~/components/AdminCardSection';
import AdminMainSideLayout from '~/components/AdminMainSideLayout';
import AdminModuleSummary from '~/components/AdminModuleSummary';
import AdminSimpleTable from '~/components/AdminSimpleTable';
import AdminStatusChip from '~/components/AdminStatusChip';
import AdminComments from '~/components/AdminComments';
import AdminPartnerModal from '~/components/AdminPartnerModal';
import Toast from '~/components/Toast';
import LargeRadioButtons from '~/components/LargeRadioButtons';

import {
  metaArrayToObject,
  objectToMetaArray,
  f,
  yn,
  money,
  age,
  formatError,
} from '~/utilities';

import {
  assets,
  beneficiaries,
  charities,
  executors,
  gifts,
  guardians,
  liabilities,
  people,
  pets,
  will,
} from '~/mixins/apollo';

export default {
  name: 'AdminSubmissionSingle',
  layout: 'admin',
  components: {
    AdminActionLink,
    AdminBackLink,
    AdminCard,
    AdminCardSection,
    AdminMainSideLayout,
    AdminModuleSummary,
    AdminSimpleTable,
    AdminStatusChip,
    AdminComments,
    AdminPartnerModal,
    Toast,
    LargeRadioButtons,
  },
  mixins: [
    assets,
    beneficiaries,
    charities,
    executors,
    gifts,
    guardians,
    liabilities,
    people,
    pets,
    will,
  ],
  apollo: {
    getWillByEmail: {
      query: GET_WILL_BY_EMAIL,
      variables() {
        return {
          email: this.will?.email,
        };
      },
      skip() {
        return !this.will;
      },
    },
    pdf: {
      query: DOWNLOAD_WILL_QUERY,
      update: (data) => data.downloadWill && data.downloadWill.will.pdf,
      variables() {
        return {
          id: this.willId,
        };
      },
      skip() {
        return !this.willStatus || this.willStatus === 'IN_PROGRESS';
      },
    },
    invites: {
      query: GET_INVITES,
      variables() {
        return {
          willId: this.willId,
        };
      },
      update: (data) => data.getInvites && data.getInvites.invites,
    },
  },
  data() {
    return {
      DOWNLOAD_WILL_QUERY,
      approvingWill: false,
      validatingWill: false,
      pdf: null,
      updatingFlags: false,
      will: null,
      willMeta: null,
      showPartnerModal: false,
      partnerCode: '',
    };
  },
  computed: {
    ...mapGetters(['token', 'role']),
    ...mapGetters('admin', ['debug']),
    ...mapGetters('modules', ['modules']),
    willId() {
      return this.$route.params.id;
    },
    isDisabled() {
      return {
        approveWill: this.willStatus !== 'AWAITING_APPROVAL' || this.hasFlags,
        downloadPdf: !this.pdf,
        toggleFlags: this.willStatus !== 'AWAITING_APPROVAL',
        updateFlags:
          !this.hasFlags || ['APPROVED', 'ARCHIVED'].includes(this.willStatus),
      };
    },
    hasFlags() {
      return this.modules.some((module) => {
        return this.willIssues && this.willIssues[module.name];
      });
    },
    flagActionLabel() {
      return this.willStatus === 'FLAGGED'
        ? 'Remove flagged sections'
        : 'Save flagged sections';
    },
    pdfFilename() {
      return `safewill-${this.will.hashId}_${this.willMeta.name_last},${this.willMeta.name_first}`.toLowerCase();
    },
    paidInvites() {
      return this.invites?.filter((item) => item.type === 'PAID') ?? [];
    },
    latestWillData() {
      const latestWill = this.getWillByEmail?.will;

      if (latestWill?.id === this.willId) {
        return null;
      }

      return latestWill;
    },
    getDuplicateData() {
      let data = null;

      if (this.partners.length) {
        const [partner] = this.partners;

        // get new user email and name
        const nameObject = partner.meta.find((i) => i.key === 'full_name');
        const emailObject = partner.meta.find((i) => i.key === 'email');

        // create parter name
        let partnerName = this.willMeta.name_first;

        if (this.willMeta.name_middle) {
          partnerName = `${partnerName} ${this.willMeta.name_middle}`;
        }

        // add all data
        data = {
          user: {
            email: (emailObject && emailObject.value) || '',
            fullName: (nameObject && nameObject.value) || '',
          },
          partner: {
            email: this.will.email,
            fullName: `${partnerName.trim()} ${this.willMeta.name_last}`,
          },
          assets: this.assets,
          liabilities: this.liabilities,
          beneficiaries: this.beneficiaries,
          charities: this.charities,
          executors: this.executors,
          gifts: this.gifts,
          guardians: this.guardians,
          people: this.people,
          pets: this.pets,
          will: this.will,
          willMeta: this.willMeta,
        };
      }

      return data;
    },
    tableRows() {
      return {
        'About Yourself': (() => {
          let rows = [
            ['First Name', this.willMeta.name_first],
            ['Middle Name', f(this.willMeta.name_middle)],
            ['Last Name', this.willMeta.name_last],
            [],
            ['Alt First Name', f(this.willMeta.alt_name_first)],
            ['Alt Middle Name', f(this.willMeta.alt_name_middle)],
            ['Alt Last Name', f(this.willMeta.alt_name_last)],
            [],
            ['Street Address', this.willMeta.address_street],
            ['Suburb', this.willMeta.address_suburb],
            ['State', this.willMeta.address_state],
            ['Postcode', this.willMeta.address_postcode],
            [],
            ['Has partner?', yn(this.willMeta.has_partner)],
            [],
            ['Notify Charities Consent', yn(this.willMeta.notify_charities)],
          ];

          this.partners.forEach((partner) => {
            rows = rows.concat(this.getPersonRows(partner, 'Partner'));
          });

          return rows;
        })(),
        Guardians: (() => {
          let rows = [['Has children?', yn(this.willMeta.has_children)]];

          this.children.forEach((child, i) => {
            const prefix = `Child #${i + 1}`;

            rows = rows.concat([[]]).concat(this.getPersonRows(child, prefix));
          });

          rows = rows
            .concat([[]])
            .concat([
              ['Has primary guardian?', yn(this.willMeta.has_primary_guardian)],
            ]);

          this.primaryGuardians.forEach((guardian, i) => {
            rows = rows.concat(this.getPersonRows(guardian.person, 'Primary'));
          });

          rows = rows
            .concat([[]])
            .concat([
              ['Has backup guardian?', yn(this.willMeta.has_backup_guardian)],
            ]);

          this.backupGuardians.forEach((guardian, i) => {
            rows = rows.concat(this.getPersonRows(guardian.person, 'Backup'));
          });

          rows = rows
            .concat([[]])
            .concat([['Has pets?', yn(this.willMeta.has_pets)]]);

          if (this.willMeta.pet_care_fund) {
            rows = rows
              .concat([[]])
              .concat([['Pet care fund?', this.willMeta.pet_care_fund]]);
          }

          this.pets.forEach((pet, i) => {
            const meta = metaArrayToObject(pet.meta);
            let prefix = `Pet #${i + 1}`;

            rows = rows.concat([
              [],
              [`${prefix} - Name`, meta.name],
              [`${prefix} - Type`, meta.type],
            ]);

            if (pet.person) {
              prefix = `Pet Guardian #${i + 1}`;

              rows = rows.concat(this.getPersonRows(pet.person, prefix));
            }
          });

          return rows;
        })(),
        Executors: (() => {
          let rows = [];

          rows = rows.concat([
            ['Executors option', this.willMeta.executors_option || '–'],
          ]);

          rows.push([]);

          this.primaryExecutors.forEach((executor, i) => {
            const prefix =
              this.primaryExecutors.length > 1
                ? `Primary #${i + 1}`
                : 'Primary Executor';

            if (i > 0) {
              rows = rows.concat([[]]);
            }

            rows = rows.concat(this.getPersonRows(executor.person, prefix));
          });

          rows.push([]);

          this.backupExecutors.forEach((executor, i) => {
            const prefix =
              this.primaryExecutors.length > 1
                ? `Backup #${i + 1}`
                : 'Backup Executor';

            if (i > 0) {
              rows = rows.concat([[]]);
            }

            rows = rows.concat(this.getPersonRows(executor.person, prefix));
          });

          if (!this.executors.length) {
            rows = rows.concat([['No Executors', '–']]);
          }

          return rows;
        })(),
        Estate: (() => {
          let rows = [];

          this.beneficiaries.forEach((beneficiary, i) => {
            const prefix = `Beneficiary #${i + 1}`;

            if (i > 0) {
              rows = rows.concat([[]]);
            }

            if (beneficiary.charity) {
              rows = rows.concat(
                this.getCharityRows(beneficiary.charity, prefix)
              );
            } else if (beneficiary.person) {
              rows = rows.concat(
                this.getPersonRows(beneficiary.person, prefix)
              );
            }

            rows = rows.concat([
              [`${prefix} - Distribution`, `${beneficiary.distribution}%`],
            ]);

            const backup = beneficiary.meta.estate_backup_split;
            if (backup === 'custom') {
              beneficiary.backup.forEach((backupBeneficiary, j) => {
                const backupPrefix = `Backup #${i + 1}.${j + 1}`;
                rows = rows.concat([[]]);

                if (backupBeneficiary.charity) {
                  rows = rows.concat(
                    this.getCharityRows(backupBeneficiary.charity, backupPrefix)
                  );

                  rows = rows.concat([
                    [
                      `${backupPrefix} - Distribution`,
                      `${backupBeneficiary.distribution}%`,
                    ],
                  ]);
                } else if (backupBeneficiary.person) {
                  rows = rows.concat(
                    this.getPersonRows(backupBeneficiary.person, backupPrefix)
                  );

                  rows = rows.concat([
                    [
                      `${backupPrefix} - Distribution`,
                      `${backupBeneficiary.distribution}%`,
                    ],
                  ]);
                }
              });
            } else if (backup === 'children') {
              rows = rows.concat([[`${prefix} - Backup`, 'Their children']]);
            } else if (backup === 'remaining') {
              rows = rows.concat([
                [`${prefix} - Backup`, 'Remaining beneficiaries'],
              ]);
            }
          });

          if (!this.beneficiaries.length) {
            rows = rows.concat([['No Beneficiaries', '–']]);
          }

          return rows;
        })(),
        Gifts: (() => {
          let rows = [];

          this.gifts.forEach((gift, i) => {
            const meta = metaArrayToObject(gift.meta);
            let prefix = `Gift #${i + 1}`;

            if (i > 0) {
              rows = rows.concat([[]]);
            }

            if (meta.amount) {
              rows = rows.concat([[`${prefix} - Amount`, money(meta.amount)]]);
            } else {
              rows = rows.concat([
                [`${prefix} - Description`, meta.description],
              ]);
            }

            rows = rows.concat([[`${prefix} - Note`, f(meta.note)]]);

            prefix = `Recipient #${i + 1}`;

            if (gift.charity) {
              rows = rows.concat(this.getCharityRows(gift.charity, prefix));
            } else if (gift.person) {
              rows = rows.concat(this.getPersonRows(gift.person, prefix));
            }
          });

          rows = rows.concat([[]]);

          if (!this.gifts.length) {
            rows = rows.concat([['No Gifts', '–']]);
          }

          return rows;
        })(),
        Assets: (() => {
          let rows = [];

          this.assets.forEach((asset, i) => {
            const meta = metaArrayToObject(asset.meta);
            const prefix = `Asset #${i + 1}`;

            if (i > 0) {
              rows = rows.concat([[]]);
            }

            rows = rows.concat([
              [`${prefix} - Type`, meta.type],
              [`${prefix} - Description`, meta.description],
            ]);
          });

          if (!this.assets.length) {
            rows = rows.concat([['No Assets', '–']]);
          }

          rows.push([]);

          this.liabilities.forEach((liability, i) => {
            const meta = metaArrayToObject(liability.meta);
            const prefix = `Liability #${i + 1}`;

            if (i > 0) {
              rows = rows.concat([[]]);
            }

            rows = rows.concat([
              [`${prefix} - Type`, meta.type],
              [`${prefix} - Description`, meta.description],
            ]);
          });

          if (!this.liabilities.length) {
            rows = rows.concat([['No Liabilities', '–']]);
          }

          rows.push([]);

          rows = rows.concat([
            ['Liabilities & Assets Note', this.willMeta.asset_note || '–'],
          ]);

          return rows;
        })(),
        Funeral: (() => {
          let rows = [];

          const dict = (val) => {
            let str;

            switch (val) {
              case 'burial':
                str = 'Burial';
                break;
              case 'cremation':
                str = 'Cremation';
                break;
              case 'donate':
                str = 'Donate';
                break;
              case 'executor':
                str = `Let my executor${this.executors.length > 1 ? 's' : ''}`;
                break;
              default:
                str = '–';
                break;
            }

            return str;
          };

          rows = rows.concat([
            ['Funeral Type', dict(this.willMeta.funeral_type)],
            ['Funeral Location', f(this.willMeta.funeral_location)],
            ['Funeral Note', f(this.willMeta.funeral_note)],
          ]);

          return rows;
        })(),
      };
    },
    willStatus() {
      return this.will && this.will.status;
    },
    invitedBy() {
      return this.will?.invite?.user?.email;
    },
    invitesSent() {
      return this.invites?.filter((invite) => invite.type !== 'PUBLIC');
    },
    invitesComplete() {
      return this.invites?.filter((invite) => invite.purchased);
    },
    isAdminClassUser() {
      return ['ADMIN', 'SUPER_ADMIN'].includes(this.role);
    },
  },
  methods: {
    ...mapMutations([
      'setMasquerading',
      'setEmail',
      'setUserId',
      'setVerified',
      'setWillId',
      'setWillStatus',
      'setPoaId',
    ]),
    snakeCase,
    async approveWill() {
      this.approvingWill = true;

      await this.updateWillStatus('APPROVED');

      this.approvingWill = false;
    },
    async validateWill() {
      this.validatingWill = true;
      await this.$apollo
        .mutate({
          mutation: VALIDATE_WILL_QUERY,
          variables: {
            id: this.willId,
          },
        })
        .then(({ data }) => {
          const validateResponse = data.validateWill;
          this.$nuxt.$emit('toast', {
            type: validateResponse.isValid ? 'success' : 'error',
            message: validateResponse.isValid
              ? validateResponse.message
              : formatError(validateResponse.message),
          });
        })
        .catch((e) => {
          console.error(e);
          this.$nuxt.$emit('toast', {
            type: 'error',
            message: formatError(e.message),
          });
        });

      this.validatingWill = false;
    },
    getAddress(meta) {
      let address = `${meta.address_street}, ${meta.address_suburb} ${meta.address_state} ${meta.address_postcode}`;

      if (meta.address_country) {
        address += ` ${meta.address_country}`;
      }

      return address;
    },
    getIdentifier(meta) {
      if (meta.identifier) {
        switch (meta.identifier) {
          case 'dob':
            return meta.date_of_birth;
          case 'email':
            return meta.email;
          case 'address':
            return this.getAddress(meta);
        }
      }
      // fallback option if meta.identifier is not present.
      if (meta.address_street) {
        return this.getAddress(meta);
      } else if (meta.date_of_birth) {
        return meta.date_of_birth;
      } else if (meta.email) {
        return meta.email;
      }
    },
    getCharityRows(charity, prefix) {
      const meta = Array.isArray(charity.meta)
        ? metaArrayToObject(charity.meta)
        : charity.meta;

      return [
        [`${prefix} - Name`, meta.name],
        [`${prefix} - Address`, meta.address],
      ];
    },
    getIdentifierDisplayName(meta) {
      if (meta.identifier) {
        switch (meta.identifier) {
          case 'dob':
            return 'Date of Birth';
          case 'email':
            return 'Email';
          case 'address':
            return 'Address';
        }
      }
      // fallback option if meta.identifier is not present.
      if (meta.address_street) {
        return 'Address';
      } else if (meta.date_of_birth) {
        return 'Date of Birth';
      } else {
        return 'Email';
      }
    },
    getPersonRows(person, prefix) {
      const meta = Array.isArray(person.meta)
        ? metaArrayToObject(person.meta)
        : person.meta;

      const identifier = this.getIdentifierDisplayName(meta);

      const rows = [
        [`${prefix} - Name`, meta.full_name],
        [`${prefix} - ${identifier}`, this.getIdentifier(meta)],
      ];

      if (identifier !== 'Email') {
        rows.push([`${prefix} - Email`, f(meta.email)]);
      }

      rows.push([`${prefix} - Over 18?`, yn(meta.is_over_18)]);

      return rows;
    },
    getField(field) {
      return field || '-';
    },
    getAge() {
      if (!this.willMeta.date_of_birth) return 0;
      return age(this.willMeta.date_of_birth);
    },
    async saveFlaggedSections() {
      await this.$apollo
        .mutate({
          mutation: UPDATE_ISSUES_MUTATION,
          variables: {
            willId: this.willId,
            issues: objectToMetaArray(this.willIssues),
          },
        })
        .catch((e) => {
          console.error(e);
        });
      await this.updateWillStatus('FLAGGED');
    },
    async removeFlaggedSections() {
      this.modules.forEach((module) => {
        this.willIssues[module.name] = false;
      });
      await this.$apollo
        .mutate({
          mutation: UPDATE_ISSUES_MUTATION,
          variables: {
            willId: this.willId,
            issues: objectToMetaArray(this.willIssues),
          },
        })
        .catch((e) => {
          console.error(e);
        });

      await this.updateWillStatus('AWAITING_APPROVAL');
    },
    async updateFlags() {
      this.updatingFlags = true;

      if (this.willStatus === 'FLAGGED') {
        await this.removeFlaggedSections();
      } else {
        await this.saveFlaggedSections();
      }

      this.$root.$emit('updateFlags', true);
      this.updatingFlags = false;
    },
    async updateWillStatus(status) {
      await this.$apollo
        .mutate({
          mutation: UPDATE_WILL_STATUS_MUTATION,
          variables: {
            id: this.willId,
            status,
          },
        })
        .catch((e) => {
          console.error(e);

          this.$nuxt.$emit('toast', {
            type: 'error',
            message: formatError(e.message),
          });
        });
    },
    async masquerade() {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: MASQURADE,
          variables: {
            id: this.will.userId,
          },
        });

        const poaData = await this.$apollo.query({
          query: GET_POA_BY_USER_ID,
          variables: {
            userId: this.will.userId,
          },
        });
        if (poaData.data && poaData.data.poaByUserId) {
          this.setPoaId(poaData.data.poaByUserId.id);
        }

        this.setMasquerading(true);
        this.setEmail(data.masquerade.user.email);
        this.setUserId(data.masquerade.user.id);
        this.setVerified(data.masquerade.user.verified);
        this.setWillId(this.will.id);
        this.setWillStatus(this.will.status);
        this.$router.push({ path: '/dashboard' });
      } catch (e) {
        console.error(e.message);
      }
    },
    async verify() {
      if (!this.will.verified) {
        try {
          const { data } = await this.$apollo.mutate({
            mutation: FORCE_VERIFY,
            variables: {
              id: this.will.userId,
            },
          });

          this.will.verified = data.forceVerify.user.verified;
        } catch (e) {
          console.error(e.message);
        }
      }
    },
    async generateWill() {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: GENERATE_WILL_MUTATION,
          variables: {
            id: this.will.id,
          },
        });
        if (data.generateWill && data.generateWill.success) {
          this.setWillStatus(data.generateWill.will.status);
        }
        console.info('Generated:', data);
      } catch (e) {
        console.error(e.message);
      }
    },
    async assignAwaitingOn(value) {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: UPDATE_WILL_AWAITING_ON_MUTATION,
          variables: {
            id: this.will.id,
            awaitingOn: value,
          },
        });

        if (data.updateWillAwaitingOn && data.updateWillAwaitingOn.success) {
          this.will.awaitingOn = data.updateWillAwaitingOn.will.awaitingOn;
        }
      } catch (e) {
        console.error(e.message);
      }
    },
  },
};
</script>
