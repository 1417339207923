<template>
  <div
    class="flex flex-col w-full min-h-screen overflow-hidden"
    :class="chatIsOnline ? 'pb-20' : ''"
  >
    <GlobalHeader />
    <div class="container gutters pb-20 mx-auto">
      <Nav />
      <nuxt />
    </div>
    <GlossaryPanel />
    <MobileNav />
    <PersonPanel />
    <PortalTarget name="sidePanel" />
    <PetPanel />
    <SubmitHelperModal />
    <SubscriptionExpiredModal />
    <UnlockWillModal />
    <OnlineBar v-if="chatIsOnline" />
    <NotifyCharitiesConsentModal />
  </div>
</template>

<script>
import { PortalTarget } from 'portal-vue';
import GlobalHeader from '~/components/GlobalHeader';
import GlossaryPanel from '~/components/GlossaryPanel';
import MobileNav from '~/components/MobileNav';
import Nav from '~/components/Nav';
import PersonPanel from '~/components/PersonPanel';
import PetPanel from '~/components/PetPanel';
import SubmitHelperModal from '~/components/SubmitHelperModal';
import SubscriptionExpiredModal from '~/components/SubscriptionExpiredModal';
import UnlockWillModal from '~/components/UnlockWillModal';

import OnlineBar from '~/components/OnlineBar';
import NotifyCharitiesConsentModal from '~/components/NotifyCharitiesConsentModal';

import tracking from '~/mixins/tracking';

export default {
  name: 'DashboardLayout',
  components: {
    PortalTarget,
    GlobalHeader,
    GlossaryPanel,
    MobileNav,
    Nav,
    PersonPanel,
    PetPanel,
    SubmitHelperModal,
    SubscriptionExpiredModal,
    UnlockWillModal,
    OnlineBar,
    NotifyCharitiesConsentModal,
  },
  mixins: [tracking],
  computed: {
    chatIsOnline() {
      const today = new Date(
        new Date().toLocaleString('en-US', {
          timeZone: 'Australia/Sydney',
        })
      );
      const day = today.getDay();
      const hour = today.getHours();
      const onlineDay = day > 0 && day < 6; // 0 is Sunday
      const onlineTime = hour >= 9 && hour < 20;
      return onlineDay && onlineTime;
    },
  },
};
</script>
