<template>
  <ModuleStep :index="7" :prev-step="previous" :show-help="true">
    <Form
      v-slot="{ loading }"
      :hide-submit="!isComplete"
      submit-arrow
      @submit.prevent="onSubmit"
    >
      <FormSection>
        <template #heading>
          Are there any limits or exclusions you’d like to place on your
          financial decision makers?
        </template>
        <p v-if="['nsw'].includes(userState)" class="mb-4">
          You can limit your attorney's power to only carrying out certain
          tasks. For example you can specify that your attorney may only act to
          sell your house, or that the attorney can only act to deal with your
          shares.
        </p>
        <p v-else-if="['nt'].includes(userState)" class="mb-4">
          For example, you may only want them to manage certain assets, only
          give gifts up to certain amounts, and not be able to sell others (such
          as your house).
        </p>
        <p v-else class="mb-4">
          For example, you may only want them to manage certain assets, and not
          be able to sell others (such as your house).
        </p>
        <LargeRadioButtons
          id="has_limitations"
          v-model="poaFinancialMeta.has_limitations"
          class="mb-12"
          :options="[
            {
              label: `Yes`,
              value: true,
            },
            {
              label: `No`,
              value: false,
            },
          ]"
          @change="onChange($event)"
        />
        <div v-if="poaFinancialMeta.has_limitations">
          <TextArea
            id="poa_financial_limitations"
            v-model="poaFinancialMeta.limitations"
            :disabled="loading"
            maxlength="2500"
            placeholder="It is my instruction that..."
          />
        </div>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';
import { queries, mutations } from '@/modules/apollo-queries/poa-meta';
import { prevStep, nextStep } from '@/modules/poa-flow/financial/index';
import Form from '~/components/Form';
import ModuleStep from '~/components/ModuleStep';
import FormSection from '~/components/FormSection';
import LargeRadioButtons from '~/components/LargeRadioButtons';
import TextArea from '~/components/TextArea';

import { user, will } from '~/mixins/apollo';

export default {
  name: 'FinancialPOALimits',
  components: {
    Form,
    ModuleStep,
    FormSection,
    LargeRadioButtons,
    TextArea,
  },
  mixins: [user, will],
  apollo: {
    poaFinancialMeta: {
      ...queries.poaFinancialMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
  },
  data() {
    return {
      poaFinancialMeta: {
        has_limitations: undefined,
        limitations: undefined,
      },
    };
  },
  computed: {
    ...mapGetters(['poaId']),
    previous() {
      return prevStep({
        addressState: this.userState,
        currentUrl: window.location.pathname,
        poaFinancialMeta: this.poaFinancialMeta,
        numPrimaryAttorneysSelected: this.attorneys?.filter(
          (attorney) => attorney.role === 'PRIMARY'
        ).length,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
      });
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    isComplete() {
      return (
        this.poaFinancialMeta.has_limitations === false ||
        (this.poaFinancialMeta.has_limitations === true &&
          this.poaFinancialMeta.limitations?.length > 0)
      );
    },
  },
  methods: {
    onChange($event) {
      if ($event === false) {
        this.onSubmit();
      }
    },
    async onSubmit() {
      await this.$apollo.mutate(
        mutations.updatePoaMetas(this.poaId, [
          {
            key: 'has_limitations',
            value: this.poaFinancialMeta.has_limitations ? 'true' : 'false',
            category: 'FINANCIAL',
          },
          {
            key: 'limitations',
            value: this.poaFinancialMeta.has_limitations
              ? this.poaFinancialMeta.limitations
              : null,
            category: 'FINANCIAL',
          },
        ])
      );

      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✅ Module POA financial completed',
      });

      const path = this.next;
      this.$router.push({ path });
    },
  },
  head() {
    return {
      title: 'Financial limitations',
    };
  },
};
</script>
