var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleStep',{attrs:{"index":7,"prev-step":_vm.previous,"show-help":true}},[_c('Form',{attrs:{"hide-submit":!_vm.isComplete,"submit-arrow":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('FormSection',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        Do you want to add any notes about any preferences, values or wishes\n        when it comes to healthcare decisions?\n      ")]},proxy:true}],null,true)},[_vm._v(" "),(['nsw', 'vic', 'tas'].includes(_vm.userState))?[_c('p',{staticClass:"mb-4"},[_vm._v("\n          These preferences can be important for your decision maker(s) to\n          know.\n        ")])]:_vm._e(),_vm._v(" "),(['qld', 'nt'].includes(_vm.userState))?[_c('p',{staticClass:"mb-4"},[_vm._v("\n          While these preferences are not binding, they can be important for\n          your decision maker(s) to know.\n        ")])]:_vm._e(),_vm._v(" "),(['wa'].includes(_vm.userState))?[_c('p',{staticClass:"mb-4"},[_vm._v("\n          This is where you should include how you would like your attorney to\n          use their power.\n        ")])]:_vm._e(),_vm._v(" "),(['sa'].includes(_vm.userState))?[_c('p',{staticClass:"mb-4"},[_vm._v("\n          Your decision maker can generally make decisions related to your\n          personal affairs (such as your relationships, personal grooming,\n          employment and holidays), your living arrangements.\n        ")]),_vm._v(" "),_c('p',{staticClass:"mb-4"},[_vm._v("\n          While your preferences for any of the above may not be binding, they\n          can be important for your decision maker(s) to know.\n        ")])]:_vm._e(),_vm._v(" "),_c('p',{staticClass:"mb-4"},[_vm._v("\n        For example, you may have preferences for a nursing home or at-home\n        care, medical treatments you don’t wish to receive due to cultural\n        beliefs, or circumstances where you would prefer not to remain on life\n        support or receive medical treatment.\n      ")]),_vm._v(" "),_c('LargeRadioButtons',{staticClass:"mb-12",attrs:{"id":"preferences","options":[
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          } ]},on:{"change":function($event){return _vm.onChange($event)}},model:{value:(_vm.poaMedicalMeta.has_preferences),callback:function ($$v) {_vm.$set(_vm.poaMedicalMeta, "has_preferences", $$v)},expression:"poaMedicalMeta.has_preferences"}}),_vm._v(" "),(_vm.poaMedicalMeta.has_preferences)?_c('div',[_c('TextArea',{attrs:{"id":"preferences","disabled":loading,"maxlength":"2500","placeholder":"It is my preference that..."},model:{value:(_vm.poaMedicalMeta.preferences),callback:function ($$v) {_vm.$set(_vm.poaMedicalMeta, "preferences", $$v)},expression:"poaMedicalMeta.preferences"}})],1):_vm._e()],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }