<template>
  <div>
    <h2 class="w-130 pr-20 mb-8 text-5xl leading-tight">
      Upload Your Signed Will
    </h2>
    <MainSideLayout>
      <template #side>
        <p class="mb-16 text-2xl">
          Upload a signed copy of your will that your executor can access in
          case it cannot be located.
        </p>
        <Tip>
          You still need to hold on to the signed original copy of your will as
          this is what the court will ask for. Store it someplace safe, where
          your executors can find it.
        </Tip>
      </template>
      <template #main>
        <div class="mb-12">
          <BaseCard
            class="mb-4 last-child:mb-0"
            badge-color="bg-slate-100"
            primary-text="Your Will"
          >
            <DropFileBox
              v-if="isDragAndDropCapable"
              ref="uploadbox"
              @passFilesToParent="filesDropped"
            >
            </DropFileBox>
            <div
              v-if="isLoading"
              :key="1"
              class="mt-8 inset-0 flex justify-center items-center"
            >
              <span class="mr-4">
                Uploading
              </span>
              <LoadingDots />
            </div>
            <input
              id="file"
              ref="file"
              class="mt-10"
              type="file"
              accept="application/pdf, image/*"
              multiple
              @change="handleFileUpload()"
            />
            <div class="flex justify-between mt-6">
              <BaseButton
                v-if="retrievedFile"
                class="text-teal-300 underline"
                @click="downloadSignedWill"
              >
                {{ fileName }}
              </BaseButton>
              <BaseButton
                v-if="retrievedFile"
                class="btn btn-md btn-outline"
                @click="removeSignedWill"
              >
                Remove
              </BaseButton>
            </div>
            <BaseAlert
              v-if="hasUploadedWill"
              class="mt-8"
              compact
              type="confirm"
            >
              <p class="opacity-75">
                Your signed Will has been uploaded
              </p>
            </BaseAlert>
            <BaseAlert v-if="errorMessage" class="mt-8" compact type="error">
              <p class="opacity-75" v-text="errorMessage" />
            </BaseAlert>
            <div
              v-if="fetchingFilesLoader"
              class="mt-8 inset-0 flex justify-center items-center"
            >
              <span class="mr-4">
                Fetching Uploaded files
              </span>
              <LoadingDots />
            </div>
          </BaseCard>

          <BaseCard
            class="mb-4 last-child:mb-0"
            badge-color="bg-slate-100"
            primary-text="Notify Executors"
            secondary-text="Allow my executor to contact Safewill and request the signed
                copy of my will after they have confirmed their identity."
          >
            <MetaSlot
              v-for="(executor, index) in executors"
              :key="index"
              v-slot="{ executorMeta, personMeta }"
              :executor-meta="executor.meta"
              :person-meta="executor.person.meta"
            >
              <ExecutorNotified
                v-if="executorMeta.notified"
                :key="executor.id"
                :meta="personMeta"
              />
              <ExecutorNotify
                v-else
                :key="executor.id"
                :executor="executor"
                :prefill="personMeta.email"
                :meta="personMeta"
              />
            </MetaSlot>
          </BaseCard>
        </div>
      </template>
    </MainSideLayout>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import BJSON from 'buffer-json';
import UPDATE_SIGNED_WILL from '~/graphql/mutations/UpdateSignedWill';
import GET_SIGNED_WILL from '~/graphql/queries/GetSignedWill';

import MainSideLayout from '~/components/MainSideLayout';
import Tip from '~/components/Tip';
import BaseButton from '~/components/BaseButton';
import BaseAlert from '~/components/BaseAlert';
import DropFileBox from '~/components/DropFileBox';
import LoadingDots from '~/components/LoadingDots';
import BaseCard from '~/components/BaseCard';
import ExecutorNotified from '~/components/ExecutorNotified';
import ExecutorNotify from '~/components/ExecutorNotify';
import MetaSlot from '~/components/MetaSlot';

import { user, will, error, executors } from '~/mixins/apollo';

export default {
  name: 'DashboardUploadWill',
  components: {
    MainSideLayout,
    Tip,
    BaseButton,
    BaseAlert,
    DropFileBox,
    LoadingDots,
    BaseCard,
    ExecutorNotified,
    ExecutorNotify,
    MetaSlot,
  },
  mixins: [user, will, executors],
  data() {
    return {
      UPDATE_SIGNED_WILL,
      GET_SIGNED_WILL,
      isDragAndDropCapable: false,
      file: null,
      retrievedFile: null,
      fileName: '',
      hasUploadedWill: false,
      executorHasPermission: false,
      isLoading: false,
      fetchingFilesLoader: false,
      errorMessage: '',
    };
  },
  mounted() {
    if (window) {
      this.getSignedWillIfExists();
      this.isDragAndDropCapable = this.determineDragAndDropCapable();
    }
  },
  methods: {
    async getSignedWillIfExists() {
      try {
        this.fetchingFilesLoader = true;
        this.errorMessage = '';
        const { data } = await this.$apollo.query({
          fetchPolicy: 'no-cache',
          query: GET_SIGNED_WILL,
          variables: {
            id: this.willId,
          },
        });
        if (data.getSignedWill.signedWill) {
          this.retrievedFile = data.getSignedWill.signedWill.data;
          this.fileName = data.getSignedWill.signedWill.fileName;
          this.executorHasPermission =
            data.getSignedWill.signedWill.executorHasPermission;
        }
      } catch (error) {
        console.error(error);
        this.errorMessage =
          'Oh no! Something went wrong while fetching the uploaded files.';
      } finally {
        this.fetchingFilesLoader = false;
      }
    },
    determineDragAndDropCapable() {
      const div = document.createElement('div');
      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window &&
        'FileReader' in window
      );
    },
    filesDropped(value) {
      this.zipFiles(value);
    },
    downloadSignedWill() {
      const data = BJSON.parse(this.retrievedFile);
      const blob = new Blob([data], {
        type: 'application/zip',
      });
      saveAs(blob, this.fileName);
    },
    handleFileUpload() {
      this.$refs.uploadbox.addFiles(this.$refs.file.files);
      this.zipFiles(this.$refs.file.files);
    },
    zipFiles(files) {
      this.isLoading = true;
      const zip = new JSZip();
      let totalSizeBytes = 0;
      for (let i = 0; i < files.length; i++) {
        totalSizeBytes += files[i].size;
        zip.file(files[i].name, files[i]);
      }

      const fileLimitMb = 40;
      if (totalSizeBytes / 1000000 > fileLimitMb) {
        this.isLoading = false;
        this.$refs.uploadbox.clearFiles();
        this.$refs.file.value = null;
        alert(`File size cannot exceed ${fileLimitMb}mb`);
      } else {
        zip
          .generateAsync({
            type: 'base64',
            compression: 'DEFLATE',
            compressionOptions: {
              level: 9, // max compression
            },
          })
          .then((content) => {
            this.file = content;
            this.updateSignedWill();
          });
      }
    },
    assignFileToRetrievedFile(fileName) {
      this.fileName = fileName;
      this.retrievedFile = BJSON.stringify({
        type: 'Buffer',
        data: `base64: ${this.file}`,
      });
    },
    async updateSignedWill() {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: UPDATE_SIGNED_WILL,
          variables: {
            id: this.willId,
            data: this.file,
            executorHasPermission: this.executorHasPermission,
          },
        });

        if (data.updateSignedWill.fileName) {
          this.hasUploadedWill = true;
          this.$nuxt.$emit('sendTrackingEvent', {
            event: '📤 Upload Signed Will',
          });
          this.assignFileToRetrievedFile(data.updateSignedWill.fileName);
        }
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isLoading = false;
        this.$refs.uploadbox.clearFiles();
        this.$refs.file.value = null;
      }
    },
    async removeSignedWill() {
      await this.$apollo
        .mutate({
          mutation: UPDATE_SIGNED_WILL,
          variables: {
            id: this.willId,
            data: null,
          },
        })
        .then(({ data, errors }) => {
          this.retrievedFile = null;
          this.hasUploadedWill = false;
        })
        .catch(error);
    },
    async updateExecutorHasPermission() {
      await this.$apollo
        .mutate({
          mutation: UPDATE_SIGNED_WILL,
          variables: {
            id: this.willId,
            executorHasPermission: this.executorHasPermission,
          },
        })
        .catch(error);
    },
  },
};
</script>
