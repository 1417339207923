<template>
  <div class="header">
    <div
      class="bg-white absolute top-0 left-0 right-0"
      :class="navHeightClass"
    ></div>
    <div
      class="flex items-center mb-5 md:mb-10 relative bg-white font-medium text-lg"
      :class="navHeightClass"
    >
      <nuxt-link to="/" class="inline-block mr-auto lg:mr-12 xl:mr-16">
        <Logo wordmark class="h-8 md:h-12" />
      </nuxt-link>
      <div class="hidden lg:flex mr-auto">
        <MenuItem to="/" icon="home" text="Overview" />
        <MenuItem to="/dashboard" icon="feather-alt" text="Your Will" />
        <MenuItem
          v-if="showPoa"
          :to="hasPoa ? '/poa/dashboard' : '/poa'"
          icon="shield"
          text="Power of Attorney"
        />
        <client-only>
          <MenuItem
            v-if="willStatus != 'IN_PROGRESS' && willStatus != 'FLAGGED'"
            id="invite"
            to="/dashboard/invite"
            icon="refer"
            text="Invite"
          />
          <MenuItem
            v-if="willStatus === 'APPROVED'"
            id="upload"
            to="/dashboard/upload-will"
            icon="upload"
            text="Uploads"
          />
        </client-only>
      </div>
      <button
        type="button"
        class="lg:hidden"
        @click="setShowMobileNav(!showMobileNav)"
      >
        <MenuDisc />
      </button>
      <Dropdown>
        <template #toggle>
          <MenuDisc class="hidden lg:flex ml-auto" :text="userInitials" />
        </template>
        <template #items>
          <MenuItem
            to="/dashboard/account-settings"
            text="Account"
            icon="user"
            vertical
          >
            <WarningBadge
              v-if="willStatus === 'APPROVED' && hasExpiredSubscription"
            />
          </MenuItem>
          <MenuItem to="/log-out" icon="logout" text="Log Out" vertical />
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<script>
import { queries } from '@/modules/apollo-queries/poa-status';
import { mapActions, mapGetters } from 'vuex';

import Dropdown from '~/components/Dropdown';
import Logo from '~/components/Logo';
import MenuDisc from '~/components/MenuDisc';
import MenuItem from '~/components/MenuItem';
import WarningBadge from '~/components/WarningBadge';

import { subscription, will } from '~/mixins/apollo';

export default {
  name: 'Nav',
  components: {
    Dropdown,
    Logo,
    MenuDisc,
    MenuItem,
    WarningBadge,
  },
  mixins: [subscription, will],
  apollo: {
    poaStatus: {
      ...queries.getPoaStatus(),
      variables() {
        return {
          id: this.poaId,
        };
      },
    },
  },
  data() {
    return {
      poaStatus: null,
      navHeightClass: 'h-18 md:h-28',
    };
  },
  computed: {
    ...mapGetters('ui', ['showMobileNav']),
    ...mapGetters(['verified', 'willStatus']),
    ...mapGetters(['poaId']),
    hasPoa() {
      return this.poaStatus !== null;
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    showPoa() {
      return this.$ff.powerOfAttorney() || this.hasPoa;
    },
    userInitials() {
      const firstNameInitial =
        'name_first' in this.willMeta ? this.willMeta.name_first.charAt(0) : '';
      const lastNameInitial =
        'name_last' in this.willMeta ? this.willMeta.name_last.charAt(0) : '';
      return firstNameInitial + lastNameInitial;
    },
  },
  methods: {
    ...mapActions('ui', ['setShowMobileNav']),
  },
};
</script>
