<template>
  <div class="relative w-full">
    <label class="group inline-flex items-center" :for="id">
      <div
        id="checkbox"
        class="relative inline-flex flex-shrink-0 justify-center items-center w-10 h-10 rounded"
        :class="classes.checkbox"
      >
        <input
          :id="id"
          v-model="checked"
          class="btn btn-focus absolute top-0 left-0 w-full h-full p-0 rounded"
          :class="classes.input"
          :disabled="disabled"
          :name="id"
          :required="required"
          type="checkbox"
          @change="$emit('input', checked)"
        />
        <BaseIcon id="tick" />
      </div>
      <span
        :class="
          disabled
            ? 'text-grey-500 cursor-not-allowed'
            : 'text-slate-100 cursor-pointer'
        "
        class="w-full ml-4 select-none"
      >
        <slot />
      </span>
    </label>
    <div
      v-if="error"
      class="absolute top-100 mt-2 text-red-300"
      v-text="error"
    />
  </div>
</template>

<script>
import BaseIcon from '~/components/BaseIcon';

export default {
  name: 'CheckboxInput',
  components: {
    BaseIcon,
  },
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    error: {
      default: '',
      type: String,
    },
    id: {
      type: String,
      required: true,
    },
    required: {
      default: false,
      type: Boolean,
    },
    value: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      checked: this.value,
    };
  },
  computed: {
    classes() {
      return {
        checkbox: {
          'bg-grey-200 text-shade-200': !this.checked,
          'group-hover:bg-teal-100 cursor-pointer':
            !this.checked && !this.disabled,
          'bg-teal-200 text-black': this.checked,
          'cursor-not-allowed': this.disabled,
        },
        input: {
          'border-black focus:border-black': !this.disabled,
          'border-grey-300 focus:border-black': this.disabled,
          'border-red-300 hover:border-red-300 focus:border-red-300': this
            .error,
        },
      };
    },
  },
};
</script>
