<template>
  <div>
    <div v-if="invite && invite.type === type" class="relative my-4">
      <div
        class="w-full max-w-full h-20 p-6 border-2 border-grey-200 rounded text-xl text-charcoal-200 whitespace-no-wrap overflow-hidden"
      >
        {{ invite.email }}
      </div>
      <div
        class="pt-4 md:pt-0 md:absolute md:top-0 md:right-0 md:bottom-0 flex items-center md:pr-6"
      >
        <div v-if="invite.purchased" class="flex text-teal-200">
          <span class="text-charcoal-100 mr-1">Completed</span>
          <BaseIcon id="tick" />
        </div>

        <Tooltip v-else content="Available every 24 hours">
          <BaseButton
            :loading="loading"
            :disabled="recentlyInvited(invite)"
            class="btn btn-xl btn-outline w-full mb-8 md:mb-0 md:w-auto md:p-2 md:h-10"
            @click="sendReminder(invite.id)"
          >
            Send Reminder
          </BaseButton>
        </Tooltip>
      </div>
    </div>
    <p v-if="error" class="mt-1 text-red-300 truncate" v-text="error" />
    <p
      v-if="success"
      class="mt-1 text-charcoal-100 truncate"
      v-text="success"
    />
  </div>
</template>

<script>
import GET_INVITES_QUERY from '~/graphql/queries/GetInvites';
import SEND_INVITE_REMINDER from '~/graphql/mutations/SendInviteReminder';

import Tooltip from '~/components/Tooltip';
import BaseButton from '~/components/BaseButton';
import BaseIcon from '~/components/BaseIcon';
import { formatError } from '~/utilities';

export default {
  name: 'SentInvite',
  components: {
    Tooltip,
    BaseButton,
    BaseIcon,
  },
  props: {
    invite: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      error: '',
      success: '',
      loading: false,
    };
  },
  computed: {
    variables() {
      return {
        type: this.type,
        email: this.email,
      };
    },
    getInvitesQuery() {
      return {
        query: GET_INVITES_QUERY,
        variables: {
          willId: this.willId,
        },
      };
    },
  },
  beforeCreate() {
    this.id = Math.random()
      .toString(36)
      .substr(2, 9);
  },
  methods: {
    recentlyInvited(invite) {
      const now = new Date();
      const then = this.$parseDate(invite.remindedAt);
      const diff = (now.getTime() - then.getTime()) / 1000;
      return diff < 86400; // 24 hours
    },
    async sendReminder(id) {
      try {
        this.loading = true;
        const { data } = await this.$apollo.mutate({
          mutation: SEND_INVITE_REMINDER,
          variables: { id },
          update: (store, { data: { sendInviteReminder } }) => {
            const data = store.readQuery(this.getInvitesQuery);
            const index = data.getInvites.invites.findIndex((i) => i.id === id);

            if (index !== -1) {
              data.getInvites.invites.splice(
                index,
                1,
                sendInviteReminder.invite
              );

              store.writeQuery({
                ...this.getInvitesQuery,
                data,
              });
            }
          },
        });
        this.loading = false;
        this.success = data.sendInviteReminder.message;
      } catch (error) {
        this.loading = false;
        this.error = formatError(error.message);
      }
    },
  },
};
</script>
