<template>
  <div
    class="text-base text-charcoal-400 mt-6 mb-10 px-5 pt-5 pb-4 border-2 border-grey-300 rounded-lg"
  >
    <h4 v-if="headline" class="font-medium mb-3 uppercase flex">
      <BaseIcon
        v-if="icon"
        :id="icon"
        classes="text-teal-200 fill-current w-4 mr-2"
      />
      {{ headline }}
    </h4>
    <slot />
  </div>
</template>

<script>
import BaseIcon from '~/components/BaseIcon';

export default {
  name: 'Notice',
  components: {
    BaseIcon,
  },
  props: {
    icon: {
      default: null,
      type: String,
    },
    headline: {
      default: null,
      type: String,
    },
  },
};
</script>
