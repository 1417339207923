<template functional>
  <svg width="24" height="24" class="stroke-current" :class="props.classes">
    <use
      v-bind="{
        'xlink:href': `${require('~/assets/svg/icons.svg')}#${props.id}`,
      }"
    />
  </svg>
</template>

<script>
export default {
  name: 'BaseIcon',
  props: {
    id: {
      type: String,
      required: true,
    },
    classes: {
      default: null,
      type: String,
    },
  },
};
</script>
