<template>
  <div class="rounded-md overflow-hidden bg-white">
    <button
      class="flex justify-between items-center w-full h-20 px-10 rounded-md text-left"
      type="button"
      @click="$emit('click')"
    >
      <span class="text-xl">
        <slot name="title" />
      </span>
      <span class="text-teal-200">
        <BaseGlyph :id="open ? 'angle-up' : 'angle-down'" />
      </span>
    </button>
    <div :class="!open && 'h-0'" class="px-10 overflow-hidden">
      <div class="pb-10 border-t-2 border-grey-300">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import BaseGlyph from '~/components/BaseGlyph';

export default {
  name: 'ExpandingCard',
  components: {
    BaseGlyph,
  },
  props: {
    open: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
