<template>
  <ModuleStep :index="1" prev-step="/dashboard" :show-help="true">
    <Form
      :hide-submit="!isComplete"
      submit-arrow
      :auto-submit="isDirty"
      @submit="onSubmit"
    >
      <FormSection>
        <template #heading>
          Funeral Wishes
        </template>
        <p>
          You can help your loved ones to celebrate your life and understand
          your priorities by adding some notes about your funeral wishes.
        </p>
        <Tip>
          Choose the answer which best describes your wishes. You can add more
          detail or specific requests in the following steps.
        </Tip>
        <LargeRadioButtons
          id="funeral_skip"
          v-model="willMeta.funeral_skip"
          :options="options"
          @change="isDirty = true"
        />
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import LargeRadioButtons from '~/components/LargeRadioButtons';
import ModuleStep from '~/components/ModuleStep';
import Tip from '~/components/Tip';

import { user, will } from '~/mixins/apollo';

export default {
  name: 'FuneralWishes',
  components: {
    Form,
    FormSection,
    LargeRadioButtons,
    ModuleStep,
    Tip,
  },
  mixins: [user, will],
  data() {
    return {
      options: [
        {
          label: "I'd like to add some notes about my funeral",
          value: false,
        },
        {
          label: "I'd like to skip this and finish my Will",
          value: true,
        },
      ],
      isDirty: false,
    };
  },
  computed: {
    isComplete() {
      return this.willMeta.funeral_skip !== null;
    },
  },
  watch: {
    isDirty() {
      this.onSubmit();
    },
  },
  methods: {
    async onSubmit() {
      this.willMeta.funeral_type = this.willMeta.funeral_skip
        ? 'executor'
        : null;
      this.willMeta.funeral_location = this.willMeta.funeral_skip
        ? null
        : this.willMeta.funeral_location;
      this.willMeta.funeral_note = this.willMeta.funeral_skip
        ? null
        : this.willMeta.funeral_note;

      if (this.willMeta.funeral_skip) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '✅ Module 7 (Funeral)',
        });
      }

      await this.updateWillMeta();

      this.$router.push({
        path: this.willMeta.funeral_skip ? '/dashboard' : '/funeral/type',
      });
    },
  },
};
</script>
