<template>
  <BasePanel :title="title" class="person-panel" @close="close">
    <Form
      v-slot="{ loading }"
      auto-focus
      :disabled="!!ageError || !isComplete"
      :mutation="mutation"
      :variables="variables"
      :submit-label="buttonText"
      @update="update"
      @done="close"
    >
      <FormSection>
        <FormRow>
          <TextInput
            id="person_full_name"
            v-model="full_name"
            :disabled="loading"
            label="Full Name"
            placeholder="Their full name"
          />
        </FormRow>
        <FormRow v-if="!hasRequirements">
          <div class="invisible md:visible absolute top-0 right-0 mt-4">
            <Tooltip
              content="This is used by your executor to identify people in your will."
            >
              <div
                class="flex justify-center items-center w-5 h-5 rounded-full bg-grey-500 text-grey-200"
              >
                <BaseGlyph id="question-mark" />
              </div>
            </Tooltip>
          </div>
          <label class="input-label" :for="identifier">
            Identifying Information
          </label>
        </FormRow>
        <FormRow v-if="!hasRequirements">
          <LargeRadioButtons
            id="identifier"
            v-model="identifier"
            horizontal
            :options="[
              {
                label: 'Email',
                value: 'email',
              },
              {
                label: 'Date of Birth',
                value: 'dob',
              },
              {
                label: 'Address',
                value: 'address',
              },
            ]"
          />
        </FormRow>
        <FormRow v-if="shownIdentifierFields.includes('email')">
          <TextInput
            id="person_email"
            v-model="email"
            :disabled="loading"
            label="Email Address"
            rules="required|email"
            placeholder="e.g. person@example.com"
          />
        </FormRow>
        <FormRow v-if="shownIdentifierFields.includes('dob')">
          <DateInput
            id="person_date_of_birth"
            v-model="date_of_birth"
            :disabled="loading"
            label="Date of Birth"
            rules="required|date"
          />
        </FormRow>
        <FormRow v-if="ageError && shownIdentifierFields.includes('dob')">
          <p class="mt-1 text-red-300 truncate" v-text="ageError" />
        </FormRow>
        <FormRow v-if="shownIdentifierFields.includes('address')">
          <div
            v-if="showAddressHelper"
            class="absolute top-0 right-0 mt-3 md:mt-4 z-10"
          >
            <BaseButton
              class="text-teal-200 underline use-mine"
              focusable
              @click="prefillAddressFields"
            >
              Use my address
            </BaseButton>
          </div>
          <TextInput
            id="person_address_street"
            v-model="address_street"
            :disabled="loading"
            label="Street Address"
            placeholder="e.g. 12 Cleveland St"
          />
        </FormRow>
        <FormRow v-if="shownIdentifierFields.includes('address')">
          <TextInput
            id="person_address_suburb"
            v-model="address_suburb"
            :disabled="loading"
            label="Suburb"
            rules="required"
          />
        </FormRow>
        <FormRow v-if="shownIdentifierFields.includes('address')">
          <SelectInput
            v-if="address_country === 'Australia'"
            id="person_address_state"
            v-model="address_state"
            class="md:w-1/2"
            :disabled="loading"
            label="State"
            :options="australianStates"
            required
            searchable
          />
          <TextInput
            v-else
            id="person_address_state"
            v-model="address_state"
            class="md:w-1/2"
            :disabled="loading"
            label="State"
            :rules="address_country === 'Australia' ? 'required' : ''"
          />
          <TextInput
            id="person_address_postcode"
            v-model="address_postcode"
            class="md:w-1/2 md:ml-2"
            :disabled="loading"
            label="Postcode"
            :rules="address_country === 'Australia' ? 'required' : ''"
          />
        </FormRow>
        <FormRow v-if="shownIdentifierFields.includes('address')">
          <Tooltip class="w-full" :content="countryTooltip">
            <SelectInput
              id="person_address_country"
              v-model="address_country"
              :disabled="loading || countryDisabled"
              label="Country"
              placeholder="Select a country"
              :options="countries"
              searchable
            />
          </Tooltip>
        </FormRow>
        <FormRow v-if="!shownIdentifierFields.includes('email')">
          <TextInput
            id="person_email"
            v-model="email"
            :disabled="loading"
            label="Email Address"
            rules="email"
            placeholder="Their email address (optional)"
          />
        </FormRow>
        <FormRow v-if="!shownIdentifierFields.includes('dob')">
          <BaseRadio
            id="person_is_over_18"
            v-model="is_over_18"
            large
            class="mt-6"
            label="Is this person over 18?"
            :options="[
              {
                label: 'Under 18',
                value: false,
              },
              {
                label: 'Over 18',
                value: true,
              },
            ]"
            :error="ageError"
          />
        </FormRow>
      </FormSection>
    </Form>
    <Tip>
      Rest assured we won’t contact anyone without your permission.
    </Tip>
  </BasePanel>
</template>

<script>
import upperFirst from 'lodash/upperFirst';
import { mapGetters } from 'vuex';

import { queries } from '@/modules/apollo-queries/people';
import BaseRadio from '~/components/BaseRadio';
import LargeRadioButtons from '~/components/LargeRadioButtons';
import BaseButton from '~/components/BaseButton';
import BasePanel from '~/components/BasePanel';
import BaseGlyph from '~/components/BaseGlyph';
import DateInput from '~/components/DateInput';
import Form from '~/components/Form';
import FormRow from '~/components/FormRow';
import FormSection from '~/components/FormSection';
import SelectInput from '~/components/SelectInput';
import TextInput from '~/components/TextInput';
import Tip from '~/components/Tip';
import Tooltip from '~/components/Tooltip';

import { people } from '~/mixins/apollo';
import { objectToMetaArray, age, date, metaArrayToObject } from '~/utilities';

export default {
  name: 'GenericPersonPanel',
  components: {
    BaseRadio,
    BaseGlyph,
    BaseButton,
    BasePanel,
    Form,
    FormRow,
    FormSection,
    SelectInput,
    TextInput,
    DateInput,
    Tip,
    Tooltip,
    LargeRadioButtons,
  },
  mixins: [people],
  props: {
    existingPerson: {
      type: Object,
      required: false,
      default: () => undefined,
    },
    identifierRequirements: {
      type: Array,
      required: false,
      default: () => undefined,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    const existingPersonMeta =
      (this.existingPerson && metaArrayToObject(this.existingPerson.meta)) ||
      {};

    return {
      full_name: existingPersonMeta.full_name,
      address_street: existingPersonMeta.address_street,
      address_suburb: existingPersonMeta.address_suburb,
      address_state: existingPersonMeta.address_state,
      address_postcode: existingPersonMeta.address_postcode,
      address_country: existingPersonMeta.address_country || 'Australia',
      date_of_birth: existingPersonMeta.date_of_birth,
      email: existingPersonMeta.email,
      is_over_18: existingPersonMeta.is_over_18 || true,
      identifier: existingPersonMeta.identifier || 'address',
    };
  },
  computed: {
    ...mapGetters('ui', ['australianStates', 'countries']),
    ...mapGetters(['userId']),
    countryTooltip() {
      if (this.type === 'attorney') {
        return 'It is recommended that you appoint an attorney in Australia, as it can be difficult to deal with assets and communicate with banks and other institutions from overseas';
      }

      return 'Keep in mind that an executor has a number of responsibilities such as organising a funeral and obtaining probate that can be difficult to do if they’re located overseas.';
    },
    isComplete() {
      const fulfilsEmailRequirement = this.shownIdentifierFields.includes(
        'email'
      )
        ? !!this.email
        : true;

      const fulfilsAddressRequirement = this.shownIdentifierFields.includes(
        'address'
      )
        ? !!this.address_street
        : true;

      const fulfilsDobRequirement = this.shownIdentifierFields.includes('dob')
        ? !!this.date_of_birth
        : true;

      return (
        this.full_name &&
        fulfilsEmailRequirement &&
        fulfilsAddressRequirement &&
        fulfilsDobRequirement
      );
    },
    ageError() {
      return ['executor', 'guardian', 'attorney'].includes(this.type) &&
        !this.is_over_18
        ? `${this.capitalisedType} must be over 18 years of age.`
        : '';
    },
    buttonText() {
      return this.id ? 'Update Person' : `Save ${this.capitalisedType}`;
    },
    capitalisedType() {
      return upperFirst(this.type);
    },
    countryDisabled() {
      return false;
    },
    mutation() {
      return this.existingPerson
        ? this.UPDATE_PERSON_MUTATION
        : this.ADD_PERSON_MUTATION;
    },
    showAddressHelper() {
      return (
        this.shownIdentifierFields.includes('address') &&
        ['child', 'partner'].includes(this.type)
      );
    },
    title() {
      return this.id ? 'Editing person' : `Adding a new ${this.type}`;
    },
    variables() {
      const variables = {
        meta: objectToMetaArray({
          full_name: this.full_name,
          address_street: this.address_street,
          address_suburb: this.address_suburb,
          address_state: this.address_state,
          address_postcode: this.address_postcode,
          address_country: this.address_country,
          date_of_birth: this.date_of_birth,
          email: this.email,
          is_over_18: this.is_over_18,
          identifier: this.identifier,
        }),
      };
      if (this.existingPerson) {
        variables.id = this.existingPerson.id;
      } else {
        variables.userId = this.userId;
        variables.category = 'none';
      }
      return variables;
    },
    hasRequirements() {
      return this.identifierRequirements !== null;
    },
    shownIdentifierFields() {
      if (this.hasRequirements) {
        return this.identifierRequirements;
      }
      return this.identifier || 'address';
    },
  },
  watch: {
    address_country() {
      this.address_state = '';
    },
    date_of_birth(newValue) {
      if (!date(newValue)) return;
      this.is_over_18 = age(newValue) >= 18;
    },
    hasRequirements(newValue) {
      if (this.hasRequirements) {
        this.identifier = this.identifierRequirements[0];
      } else {
        this.identifier = 'address';
      }
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    update(store, { data: { addPerson } }) {
      if (addPerson) {
        const getPeopleOfAccountQuery = {
          variables: this.userId && { userId: this.userId },
          ...queries.getPeopleOfAccount(),
        };
        const data = store.readQuery(getPeopleOfAccountQuery);
        data.getPeopleOfAccount.people.push(addPerson.person);
        store.writeQuery({
          ...getPeopleOfAccountQuery,
          data,
        });
      }
    },
    altText(alt) {
      return alt === 'dob' ? 'date of birth' : alt;
    },
    prefillAddressFields() {
      this.address_street = this.willMeta.address_street;
      this.address_suburb = this.willMeta.address_suburb;
      this.address_state = this.willMeta.address_state;
      this.address_postcode = this.willMeta.address_postcode;
    },
  },
};
</script>
