var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleStep',{attrs:{"index":7,"prev-step":_vm.previous,"show-help":true}},[_c('Form',{attrs:{"hide-submit":!_vm.isComplete,"submit-arrow":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('FormSection',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        Are there any limits or exclusions you’d like to place on your\n        financial decision makers?\n      ")]},proxy:true}],null,true)},[_vm._v(" "),(['nsw'].includes(_vm.userState))?_c('p',{staticClass:"mb-4"},[_vm._v("\n        You can limit your attorney's power to only carrying out certain\n        tasks. For example you can specify that your attorney may only act to\n        sell your house, or that the attorney can only act to deal with your\n        shares.\n      ")]):(['nt'].includes(_vm.userState))?_c('p',{staticClass:"mb-4"},[_vm._v("\n        For example, you may only want them to manage certain assets, only\n        give gifts up to certain amounts, and not be able to sell others (such\n        as your house).\n      ")]):_c('p',{staticClass:"mb-4"},[_vm._v("\n        For example, you may only want them to manage certain assets, and not\n        be able to sell others (such as your house).\n      ")]),_vm._v(" "),_c('LargeRadioButtons',{staticClass:"mb-12",attrs:{"id":"has_limitations","options":[
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          } ]},on:{"change":function($event){return _vm.onChange($event)}},model:{value:(_vm.poaFinancialMeta.has_limitations),callback:function ($$v) {_vm.$set(_vm.poaFinancialMeta, "has_limitations", $$v)},expression:"poaFinancialMeta.has_limitations"}}),_vm._v(" "),(_vm.poaFinancialMeta.has_limitations)?_c('div',[_c('TextArea',{attrs:{"id":"poa_financial_limitations","disabled":loading,"maxlength":"2500","placeholder":"It is my instruction that..."},model:{value:(_vm.poaFinancialMeta.limitations),callback:function ($$v) {_vm.$set(_vm.poaFinancialMeta, "limitations", $$v)},expression:"poaFinancialMeta.limitations"}})],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }