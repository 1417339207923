<template>
  <div>
    <div v-if="willMeta && willStatus" class="relative z-10 mb-8 md:mb-16">
      <div class="mb-10">
        <BaseAlert
          v-if="willStatus === 'APPROVED' && hasExpiredSubscription"
          type="error"
          class="mb-4"
        >
          <div class="w-full">
            <span>
              Your subscription has expired. You can still view and download
              your previous Will, but you’ll need to
              <BaseButton
                class="inline-block text-red-300 underline"
                @click="handleRenewSubscription"
              >
                unlock your account
              </BaseButton>
              to edit your Will again.
            </span>
          </div>
        </BaseAlert>
        <BaseAlert
          v-else-if="willStatus === 'APPROVED' && showUpdateSubscriptionBanner"
          type="warn"
          class="mb-4"
        >
          <div class="w-full">
            <span>
              Your Safewill subscription is set to expire on the
              <span v-text="`${formattedExpiryDate}. `" />
              If you choose not to renew your subscription, you will lose access
              to free unlimited updates and any uploaded copies of your Will
              will no longer be stored on your profile.
              <nuxt-link
                v-if="!hasStripePaymentMethod"
                to="/checkout"
                class="text-green-300 underline"
                @click.native="handleUpdateCardDetails"
              >
                Update card details
              </nuxt-link>
              <nuxt-link
                v-else
                :to="{ path: '/dashboard/account-settings', hash: '#billing' }"
                class="text-green-300 underline"
              >
                Enable Auto-Renew
              </nuxt-link>
              to keep your subscription active.
            </span>
          </div>
        </BaseAlert>
        <BaseAlert
          v-else-if="
            willStatus === 'IN_PROGRESS' && allModulesCompleted && verified
          "
          type="info"
          class="mb-4"
        >
          <div class="flex items-baseline lg:items-center w-full">
            <div class="mr-2 opacity-50">
              <BaseGlyph id="circle-tick-large" :large="true" />
            </div>
            <div class="lg:flex flex-grow">
              <span>
                Your Will is ready to submit for our team to review.
              </span>
              <BaseButton
                class="mt-2 lg:mt-0 lg:ml-auto font-normal underline text-left"
                @click="setShowSubmitHelperModal(true)"
              >
                Why should I submit first and update later?
              </BaseButton>
            </div>
          </div>
        </BaseAlert>

        <BaseAlert v-if="showVerifyReminder" type="warn" class="mb-4">
          <div class="w-full">
            <span>
              To download your Will or upload the signed copy to your account,
              please verify your email.
            </span>
            <nuxt-link to="/dashboard/account-settings" class="underline">
              Go to account settings.
            </nuxt-link>
          </div>
        </BaseAlert>
      </div>
      <div
        class="flex flex-col md:flex-row justify-between items-start md:items-center"
      >
        <div>
          <p
            class="text-2xl lg:text-3xl leading-tight text-grey-600 font-medium"
          >
            {{ fullName }}
          </p>
          <h1
            class="font-medium md:font-normal leading-tight text-4xl md:text-5xl lg:text-6xl font-medium"
          >
            Your Will <StatusText :ready="allModulesCompleted" />.
          </h1>
        </div>
        <div
          v-if="willStatus"
          id="submit-btn"
          class="fixed md:static left-0 right-0 bottom-0 flex-shrink-0 p-2 zendesk-offset md:p-0 bg-white md:bg-transparent shadow-md md:shadow-none"
        >
          <div class="w-full">
            <WillSubmitButton
              v-if="showSubmitButton"
              :ready="allModulesCompleted"
            />
            <WillDownloadComboButton v-else />
          </div>
        </div>
      </div>
      <Tip v-if="tipText" large>
        {{ tipText }}
      </Tip>
    </div>
    <Slider v-if="willMeta" :slide="currentSlide">
      <SliderItem v-for="(module, moduleIndex) in modules" :key="moduleIndex">
        <ModuleCard
          :title="module.displayName || module.name"
          :description="module.description"
          :duration="module.duration"
          :issues="willStatus === 'FLAGGED' && !!willIssues[module.name]"
          :number="moduleIndex + 1"
          :loading="isLoading[module.name]"
          :progress="progress[module.name]"
          :ready="isReady[module.name]"
          :to="to[module.name]"
        >
          <template v-if="module.name === 'About Yourself'">
            <ModuleCardText
              :text="`${willMeta.name_first} ${willMeta.name_last}`"
            />
            <MetaSlot
              v-if="partners.length"
              v-slot="{ meta }"
              :meta="partners[0].meta"
            >
              <ModuleCardText :text="meta.full_name" />
            </MetaSlot>
          </template>
          <template v-if="module.name === 'Guardians'">
            <template v-if="children.length && guardians.length">
              <ModuleCardText glyph="child" light text="Children" />
              <ModuleCardText :text="guardianNames" />
            </template>
            <MetaSlot
              v-for="(pet, index) in petsWithGuardians.slice(0, maxPets)"
              :key="index"
              v-slot="{ petMeta, personMeta }"
              :pet-meta="pet.meta"
              :person-meta="pet.person.meta"
            >
              <ModuleCardText glyph="paw" light :text="petMeta.name" />
              <ModuleCardText :text="personMeta.full_name" />
            </MetaSlot>
            <ModuleCardText
              v-if="petsWithGuardians.length > maxPets"
              light
              :text="`+ ${petsWithGuardians.length - maxPets} more`"
            />
            <template
              v-if="!(guardians.length && petsWithGuardians) && children.length"
            >
              <ModuleCardText light text="Children" />
              <MetaSlot
                v-for="(child, index) in children.slice(0, maxChildren)"
                :key="index"
                v-slot="{ childMeta }"
                :child-meta="child.meta"
              >
                <ModuleCardText :text="childMeta.full_name" />
              </MetaSlot>
              <ModuleCardText
                v-if="children.length > maxChildren"
                :text="`+ ${children.length - maxChildren} more`"
              />
            </template>
            <template v-else>
              <ModuleCardText light text="None specified" />
            </template>
          </template>
          <template v-if="module.name === 'Executors'">
            <template v-if="willMeta.executors_option !== 'friendsFamily'">
              <ModuleCardText
                :truncate="false"
                text="Safewill Professional Executors"
              />
            </template>
            <template v-if="primaryExecutors.length">
              <ModuleCardText
                :text="
                  willMeta.executors_option === 'friendsFamilyAndProfessional'
                    ? 'Primary Contact'
                    : 'Primary'
                "
                light
              />
              <ModuleCardText :text="primaryExecutorNames" />
            </template>
            <template v-if="backupExecutors.length">
              <ModuleCardText light text="Backup" />
              <ModuleCardText :text="backupExecutorNames" />
            </template>
          </template>
          <template v-if="module.name === 'Estate'">
            <template
              v-if="willMeta.estate_split !== null && beneficiaries.length"
            >
              <div
                v-for="(beneficiary, index) in beneficiaries.slice(0, maxItems)"
                :key="index"
              >
                <ModuleCardText
                  v-if="beneficiary.person"
                  :percentage="`${beneficiary.distribution}%`"
                  :text="beneficiary.person.meta.full_name"
                />
                <ModuleCardText
                  v-else-if="beneficiary.charity"
                  :percentage="`${beneficiary.distribution}%`"
                  :text="beneficiary.charity.meta.name"
                />
              </div>
              <ModuleCardText
                v-if="beneficiaries.length > maxItems"
                light
                :text="`+ ${beneficiaries.length - maxItems} more`"
              />
            </template>
            <template v-else>
              No beneficiaries
            </template>
          </template>
          <template v-if="module.name === 'Gifts'">
            <MetaSlot
              v-for="(gift, index) in gifts.slice(0, maxPeople)"
              :key="index"
              v-slot="{ giftMeta, personMeta, charityMeta }"
              :gift-meta="gift.meta"
              :person-meta="gift.person && gift.person.meta"
              :charity-meta="gift.charity && gift.charity.meta"
            >
              <ModuleCardText
                light
                :text="
                  giftMeta.type === 'money'
                    ? `$${giftMeta.amount}`
                    : giftMeta.description
                "
              />
              <ModuleCardText v-if="gift.person" :text="personMeta.full_name" />
              <ModuleCardText v-if="gift.charity" :text="charityMeta.name" />
            </MetaSlot>
            <ModuleCardText
              v-if="gifts.length > maxPeople"
              light
              :text="`+ ${gifts.length - maxPeople} more`"
            />
            <ModuleCardText v-else-if="gifts.length === 0" text="No gifts" />
          </template>
          <template v-if="module.name === 'Assets'">
            <template>
              <div v-if="assets.length">
                <MetaSlot
                  v-for="(asset, index) in assets.slice(0, maxItems / 2)"
                  :key="index"
                  v-slot="{ meta }"
                  :meta="asset && asset.meta"
                >
                  <ModuleCardText :text="meta.description" />
                </MetaSlot>
                <ModuleCardText
                  v-if="assets.length > maxItems"
                  light
                  :text="`+ ${assets.length - maxItems / 2} more`"
                />
              </div>
              <div v-else>
                No assets
              </div>
              <div v-if="liabilities.length">
                <MetaSlot
                  v-for="(liability, index) in liabilities.slice(
                    0,
                    maxItems / 2
                  )"
                  :key="index"
                  v-slot="{ meta }"
                  :meta="liability && liability.meta"
                >
                  <ModuleCardText :text="meta.description" />
                </MetaSlot>
                <ModuleCardText
                  v-if="liabilities.length > maxItems"
                  light
                  :text="`+ ${liabilities.length - maxItems / 2} more`"
                />
              </div>
              <div v-else>
                No liabilities
              </div>
            </template>
          </template>
          <template v-if="module.name === 'Liabilities'">
            <template v-if="liabilities.length">
              <MetaSlot
                v-for="(liability, index) in liabilities.slice(0, maxItems)"
                :key="index"
                v-slot="{ meta }"
                :meta="liability && liability.meta"
              >
                <ModuleCardText :text="meta.description" />
              </MetaSlot>
              <ModuleCardText
                v-if="liabilities.length > maxItems"
                light
                :text="`+ ${liabilities.length - maxItems} more`"
              />
            </template>
            <template v-else>
              No liabilities
            </template>
          </template>
          <template v-if="module.name === 'Funeral'">
            <ModuleCardText light text="You would like to" />
            <ModuleCardText :text="funeralText" />
          </template>
          <!-- <template v-if="module.name === 'Financial POA'">
            <ModuleCardText light text="You would like to" />
          </template>
          <template v-if="module.name === 'Medical POA'">
            <ModuleCardText light text="You would like to" />
          </template> -->
        </ModuleCard>
      </SliderItem>
    </Slider>
    <WelcomeModal />
    <FlaggedWillAttentionModal />
  </div>
</template>

<script>
import kebabCase from 'lodash/kebabCase';
import detectIt from 'detect-it';
import { mapActions, mapGetters } from 'vuex';
import cookies from 'js-cookie';

import { referralCodesToCharities } from '@/modules/referralCodes';
import { renewSubscriptionHandler } from '@/mixins/subscriptions';

import BaseAlert from '~/components/BaseAlert';
import BaseButton from '~/components/BaseButton';
import BaseGlyph from '~/components/BaseGlyph';
import MetaSlot from '~/components/MetaSlot';
import ModuleCard from '~/components/ModuleCard';
import ModuleCardText from '~/components/ModuleCardText';
import Slider from '~/components/Slider';
import SliderItem from '~/components/SliderItem';
import StatusText from '~/components/StatusText';
import Tip from '~/components/Tip';
import WillDownloadComboButton from '~/components/WillDownloadComboButton';
import WillSubmitButton from '~/components/WillSubmitButton';
import WelcomeModal from '~/components/WelcomeModal';
import FlaggedWillAttentionModal from '~/components/FlaggedWillAttentionModal';

import {
  assets,
  liabilities,
  beneficiaries,
  charities,
  executors,
  gifts,
  guardians,
  people,
  pets,
  // poas,
  subscription,
  user,
  will,
} from '~/mixins/apollo';
import { metaArrayToObject } from '~/utilities';

export default {
  name: 'DashboardYourWill',
  components: {
    BaseAlert,
    BaseButton,
    BaseGlyph,
    MetaSlot,
    ModuleCard,
    ModuleCardText,
    Slider,
    SliderItem,
    StatusText,
    Tip,
    WillDownloadComboButton,
    WillSubmitButton,
    WelcomeModal,
    FlaggedWillAttentionModal,
  },
  mixins: [
    assets,
    liabilities,
    beneficiaries,
    charities,
    executors,
    gifts,
    guardians,
    people,
    pets,
    // poas,
    subscription,
    user,
    will,
    renewSubscriptionHandler,
  ],
  data() {
    return {
      maxItems: 4,
      maxPeople: 2,
      maxChildren: 2,
      tour: null,
    };
  },
  computed: {
    ...mapGetters(['verified', 'willStatus']),
    ...mapGetters('modules', ['modules']),
    ...mapGetters('checkout', ['couponCode']),
    allModulesCompleted() {
      return this.modules.every((module) => {
        return this.progress[module.name] === 1;
      });
    },
    completedSteps() {
      return {
        'About Yourself': (() => {
          let steps = 0;
          if (this.willMeta) {
            if (
              this.willMeta.has_alt_name === false ||
              (this.willMeta.alt_name_first && this.willMeta.alt_name_last)
            ) {
              steps++;
            }
            if (this.willMeta.date_of_birth) {
              steps++;
            }
            if (
              this.willMeta.address_street &&
              this.willMeta.address_suburb &&
              this.willMeta.address_state &&
              this.willMeta.address_postcode
            ) {
              steps++;
            }
            if (this.willMeta.has_partner === false || this.partners.length) {
              steps++;
              // Phone
              steps++;
            }
          }
          return steps;
        })(),
        Guardians: (() => {
          let steps = 0;
          if (this.willMeta) {
            if (this.willMeta.has_children === false || this.children.length) {
              steps++;
            }
            if (this.willMeta.has_children && !this.minors.length) {
              steps += 2;
            }
            if (
              this.willMeta.has_children === false ||
              this.willMeta.has_primary_guardian === false ||
              this.primaryGuardians.length
            ) {
              steps++;
            }
            if (
              this.willMeta.has_children === false ||
              this.willMeta.has_primary_guardian === false ||
              this.willMeta.has_backup_guardian === false ||
              this.backupGuardians.length
            ) {
              steps++;
            }
            if (this.willMeta.has_pets === false || this.pets.length) {
              steps++;
            }
            if (
              this.willMeta.has_pets === false ||
              this.willMeta.has_pet_guardians === false ||
              this.pets.find((pet) => pet.person)
            ) {
              steps++;
              // Pet Care Fund
              steps++;
            }
          }
          return steps;
        })(),
        Executors: (() => {
          let steps = 0;
          if (this.willMeta) {
            // if (this.primaryExecutors.length) {
            //   steps++;
            //   steps++;
            // }
            // if (
            //   this.willMeta.has_backup_executor === false ||
            //   this.backupExecutors.length
            // ) {
            //   steps++;
            // }

            if (this.willMeta.executors_option) {
              steps++;
              steps++;

              switch (this.willMeta.executors_option) {
                case 'friendsFamily':
                  if (this.primaryExecutors.length) {
                    steps++;
                  }

                  if (
                    this.willMeta.has_backup_executor === false ||
                    this.backupExecutors.length
                  ) {
                    steps++;
                  }
                  break;
                case 'professional':
                  if (
                    this.willMeta.professional_executor_terms_check &&
                    this.willMeta.professional_executor_payment_check
                  ) {
                    steps++;
                    steps++;
                  }
                  break;
                case 'friendsFamilyAndProfessional':
                  if (this.primaryExecutors.length) {
                    steps++;
                  }

                  if (
                    this.willMeta.professional_executor_terms_check &&
                    this.willMeta.professional_executor_payment_check
                  ) {
                    steps++;
                  }
                  break;
                default:
                  break;
              }
            }
          }
          return steps;
        })(),
        Estate: (() => {
          let steps = 0;
          if (this.willMeta) {
            if (this.willMeta.estate_split) {
              steps++;
              steps++;
              if (this.validEstateBackup) {
                steps++;
                steps++;
              }

              if (
                this.willMeta.estate_split === 'custom' &&
                this.validEstateDistribution
              ) {
                steps++;
              } else if (
                this.willMeta.estate_split === 'partner' ||
                this.willMeta.estate_split === 'children'
              ) {
                steps++;
              }

              if (
                this.willMeta.referral_charity &&
                this.willMeta.charity_in_estate !== null
              ) {
                steps++;
              } else if (this.willMeta.estate_split) {
                steps++;
              }
              if (this.beneficiaries && this.beneficiaries.length) {
                const totalDistributions = this.beneficiaries.reduce(
                  (total, beneficiary) =>
                    (total = parseFloat(
                      (total + Number(beneficiary.distribution)).toFixed(2)
                    )),
                  0
                );
                if (totalDistributions === 100) {
                  steps++;
                }
              }

              let isBackupBeneficiariesValid = true;
              for (const backupBeneficiary of this.beneficiaries) {
                if (backupBeneficiary.backup.length) {
                  const totalBackupBeneficiary = backupBeneficiary.backup.reduce(
                    (total, backupBeneficiary) =>
                      total + parseFloat(backupBeneficiary.distribution),
                    0
                  );
                  if (totalBackupBeneficiary !== 100) {
                    isBackupBeneficiariesValid = false;
                    break;
                  }
                }
              }
              if (isBackupBeneficiariesValid) {
                steps++;
              }
            }
          }
          return steps;
        })(),
        Gifts: (() => {
          let steps = 0;
          if (this.willMeta) {
            if (this.willMeta.has_gifts === false || this.gifts.length) {
              steps++;

              if (this.willMeta.referral_charity) {
                if (
                  this.willMeta.charity_in_estate !== null ||
                  this.willMeta.charity_in_gifts !== null
                ) {
                  steps++;
                }
              } else {
                steps++;
              }
            }
          }

          return steps;
        })(),
        Assets: (() => {
          let steps = 0;
          if (this.willMeta) {
            if (this.willMeta.has_assets === false || this.assets.length) {
              steps++;
              steps++; // Step again for optional notes step
            }
            if (
              this.willMeta.has_liabilities === false ||
              this.liabilities.length
            ) {
              steps++;
              steps++; // Step again for optional notes step
            }
          }
          return steps;
        })(),
        Funeral: (() => {
          let steps = 0;
          if (this.willMeta) {
            if (
              this.willMeta.funeral_skip === true ||
              this.willMeta.funeral_type !== null
            ) {
              steps++;
              steps++;
              steps++;
              steps++;
            }
          }
          return steps;
        })(),
      };
    },
    currentSlide() {
      const incomplete = [];
      this.modules.forEach((module, i) => {
        if (this.progress[module.name] !== 1) {
          incomplete.push(i);
        }
      });
      return incomplete.length ? Math.max(0, Math.min(...incomplete) - 1) : 0;
    },
    isLoading() {
      const {
        assets,
        beneficiaries,
        executors,
        gifts,
        guardians,
        people,
        pets,
        will,
      } = this.$apollo.queries;
      return {
        'About Yourself': people.loading && will.loading,
        Guardians: guardians.loading && pets.loading && will.loading,
        Executors: executors.loading && will.loading,
        Estate: beneficiaries.loading && will.loading,
        Gifts: gifts.loading && will.loading,
        Assets: assets.loading && will.loading,
        Funeral: will.loading,
      };
    },
    isReady() {
      const isReady = {};
      this.modules.forEach((module, index) => {
        isReady[module.name] = this.modules.slice(0, index).every((mod) => {
          return this.progress[mod.name] === 1;
        });
      });
      return isReady;
    },
    progress() {
      const progress = {};
      this.modules.forEach((module) => {
        progress[module.name] =
          this.completedSteps[module.name] > module.totalSteps
            ? 1
            : this.completedSteps[module.name] / module.totalSteps;
      });
      return progress;
    },
    to() {
      const to = {};
      this.modules.forEach((module) => {
        let uri = `/${kebabCase(module.name)}`;
        // const step = module.steps[this.completedSteps[module.name] % module.totalSteps]
        const step = module.steps[0];
        if (step && step.length) {
          uri += `/${step}`;
        }
        to[module.name] = uri;
      });
      return to;
    },
    guardianNames() {
      const names = this.guardians.map((guardian) => {
        const meta = metaArrayToObject(guardian.person.meta);
        return meta.full_name;
      });
      return names.join(', ');
    },
    maxPets() {
      let maxPets = this.maxPeople;
      if (this.children.length) {
        maxPets--;
      }
      return maxPets;
    },
    petsWithGuardians() {
      return this.pets.filter((pet) => {
        return pet.person;
      });
    },
    primaryExecutorNames() {
      const names = this.primaryExecutors.map((executor) => {
        const meta = metaArrayToObject(executor.person.meta);
        return meta.full_name;
      });
      return names.join(' & ');
    },
    backupExecutorNames() {
      const names = this.backupExecutors.map((executor) => {
        const meta = metaArrayToObject(executor.person.meta);
        return meta.full_name;
      });
      return names.join(' & ');
    },
    validEstateDistribution() {
      return this.beneficiaries.every(
        (beneficiary) =>
          beneficiary.distribution && beneficiary.distribution !== '0'
      );
    },
    validEstateBackup() {
      return this.beneficiaries.every((beneficiary) => {
        if (beneficiary.charity) return true;
        if (beneficiary.meta.estate_backup_split === 'custom') {
          return beneficiary.backup.every((b) => {
            return b.distribution && b.distribution !== '0';
          });
        } else {
          return !!beneficiary.meta.estate_backup_split;
        }
      });
    },
    funeralText() {
      let text = '';
      switch (this.willMeta.funeral_type) {
        case 'burial':
          text = 'be buried.';
          break;
        case 'cremation':
          text = 'be cremated.';
          break;
        case 'donate':
          text = 'donate your body to science.';
          break;
        case 'executor':
          text = 'let your executor decide.';
          break;
        default:
          break;
      }
      return text;
    },
    tipText() {
      let text;
      switch (this.willStatus) {
        case 'AWAITING_APPROVAL':
          text =
            'We’ll email you your Will once it is ready. You can make more changes after that.';
          break;
        case 'APPROVED':
          text =
            'Follow the instructions provided to properly sign and witness your Will.';
          break;
        default:
          break;
      }
      return text;
    },
    showSubmitButton() {
      return ['IN_PROGRESS', 'FLAGGED'].includes(this.willStatus);
    },
    showVerifyReminder() {
      return (
        !this.verified &&
        (this.willStatus === 'AWAITING_APPROVAL' ||
          this.willStatus === 'APPROVED')
      );
    },
    isTouchDevice() {
      return (
        (typeof navigator !== 'undefined' && navigator.webdriver) ||
        detectIt.deviceType !== 'mouseOnly' ||
        ['base', 'sm'].includes(this.$mq)
      );
    },
    fullName() {
      let name = '';

      if ('name_first' in this.willMeta) {
        name = this.willMeta.name_first.trim();

        if (this.willMeta.name_middle) {
          name = `${name} ${this.willMeta.name_middle.trim()}`;
        }

        name = `${name} ${this.willMeta.name_last.trim()}`;
      }

      return name;
    },
  },
  watch: {
    async will() {
      if (
        this.will.status === 'APPROVED' &&
        this.willMeta.show_download_tour === undefined &&
        !this.tour
      ) {
        this.showDownloadTour();
      }

      if (!this.willMeta.referral_charity && !this.masquerading) {
        this.willMeta.referral_charity = cookies.get('referral_charity');
        await this.updateWillMeta();
        const charityKeys =
          this.willMeta.referral_charity &&
          referralCodesToCharities[this.willMeta.referral_charity];
        if (charityKeys) await this.addPartnerCharities(charityKeys);
      }
    },
  },
  methods: {
    ...mapActions('ui', ['setShowSubmitHelperModal']),
    ...mapActions('checkout', ['updateCardDetails']),
    handleUpdateCardDetails() {
      this.updateCardDetails().then(() =>
        this.$router.push({ path: '/checkout' })
      );
    },
    showDownloadTour() {
      this.tour = this.$shepherd({
        useModalOverlay: true,
        defaultStepOptions: {
          cancelIcon: {
            enabled: true,
          },
          classes: 'class-1 class-2',
          scrollTo: {
            behavior: 'smooth',
            block: 'center',
          },
          modalOverlayOpeningPadding: 10,
          modalOverlayOpeningRadius: 4,
          popperOptions: {
            modifiers: [{ name: 'offset', options: { offset: [0, 35] } }],
          },
          when: {
            show: () => {
              const currentStepElement = this.tour.currentStep.el;
              const header = currentStepElement.querySelector(
                '.shepherd-header'
              );
              const progress = document.createElement('span');
              const current =
                this.tour.steps.indexOf(this.tour.currentStep) + 1;
              progress.classList.add('shepherd-progress');
              progress.textContent = `Tip ${current} of ${this.tour.steps.length}`;
              header.insertBefore(
                progress,
                currentStepElement.querySelector('.shepherd-title')
              );
            },
          },
        },
      });
      this.tour.addStep({
        title: 'Welcome back',
        attachTo: !this.isTouchDevice && {
          element: '#submit-btn',
          on: 'left-start',
        },
        text: `Once you’ve verified your email address, download your will ${
          this.isTouchDevice ? 'below' : 'here'
        }.`,
        classes: 'step-1',
        buttons: [
          {
            action() {
              return this.next();
            },
            text: 'Next',
          },
        ],
      });
      this.tour.addStep({
        attachTo: !this.isTouchDevice && {
          element: '#invite',
          on: 'bottom',
        },
        classes: 'step-2',
        title: 'Earn your money back',
        text:
          'Refer your friends and family to write their Will, and we’ll refund your purchase. For each person you refer who writes a Will, they’ll get 20% off and you’ll earn $10 back 🚀',
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: 'shepherd-button-secondary',
            text: 'Back',
          },
          {
            action() {
              return this.next();
            },
            text: 'Next',
          },
        ],
      });
      this.tour.addStep({
        attachTo: !this.isTouchDevice && {
          element: '#upload',
          on: 'bottom',
        },
        classes: 'step-3',
        title: 'Upload your signed Will',
        text: `Once you’ve signed your Will, upload it here in case you lose it. You can allow your Executor to access the Will in case they need it. ${
          this.isTouchDevice ? 'Click on menu to learn more.' : ''
        }`,
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: 'shepherd-button-secondary',
            text: 'Back',
          },
          {
            action() {
              return this.hide();
            },
            text: 'Finish',
          },
        ],
      });

      this.tour.start();
      this.willMeta.show_download_tour = false;
      this.updateWillMeta();
    },
  },
};
</script>
