<template>
  <client-only>
    <div>
      <BaseCard v-if="subscription" primary-text="Unlimited Will Updates">
        <BaseAlert :type="statusType">
          <Chip :label="subscriptionStatus" :type="statusType" />
          <div class="ml-3 font-medium opacity-75">
            <p v-text="statusText" />
          </div>
        </BaseAlert>
        <p
          v-if="hasValidSubscription"
          class="py-6 opacity-50 text-black"
          v-text="renewalText"
        />
        <div v-if="hasValidSubscription" class="flex">
          <BaseButton
            v-if="!isCancelled"
            class="btn btn-md btn-outline btn-focus mr-3"
            @click="handleUpdateCardDetails"
          >
            Update Credit Card
          </BaseButton>
          <BaseButton
            v-if="isCancelled"
            class="btn btn-md btn-outline btn-focus"
            :loading="$apollo.loading"
            @click="enableAutoRenew"
          >
            Enable Auto-Renew
          </BaseButton>
          <BaseButton
            v-else
            class="btn btn-md btn-outline btn-focus"
            :loading="$apollo.loading"
            @click="cancelSubscription"
          >
            Disable Auto-Renew
          </BaseButton>
        </div>
        <div v-else class="py-6">
          <BaseButton
            class="btn btn-md btn-outline btn-focus"
            :loading="$apollo.loading"
            @click="handleRenewSubscription"
          >
            Unlock Account
          </BaseButton>
        </div>
      </BaseCard>
      <EmptyState v-if="!subscription">
        Once you submit your Will, your billing details can be managed here.
      </EmptyState>
    </div>
  </client-only>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { renewSubscriptionHandler } from '@/mixins/subscriptions';

import BaseButton from '~/components/BaseButton';
import BaseCard from '~/components/BaseCard';
import BaseAlert from '~/components/BaseAlert';
import Chip from '~/components/Chip';
import EmptyState from '~/components/EmptyState';

import { subscription, user } from '~/mixins/apollo';

export default {
  name: 'SubscriptionStatusCard',
  components: {
    BaseButton,
    BaseCard,
    BaseAlert,
    Chip,
    EmptyState,
  },
  mixins: [subscription, user, renewSubscriptionHandler],
  computed: {
    ...mapGetters('checkout', ['defaultSubscriptionPrice']),
    isCancelled() {
      return this.subscription.expireAtPeriodEnd;
    },
    statusType() {
      return this.hasValidSubscription ? 'confirm' : 'error';
    },
    renewalText() {
      return this.subscription.expireAtPeriodEnd
        ? `Renew your subscription for $${this.defaultSubscriptionPrice} / year to make updates to your Will.`
        : `Your next payment of $${this.defaultSubscriptionPrice} will be billed on ${this.subscriptionRenewalDate}.`;
    },
    subscriptionExpiryDate() {
      return this.$formatDate(
        this.subscription.currentPeriodEnd,
        'dS mmmm yyyy'
      );
    },
    subscriptionRenewalDate() {
      const date = new Date(this.subscription.currentPeriodEnd);

      date.setDate(date.getDate() + 1);

      return this.$formatDate(date, 'dS mmmm yyyy');
    },
    subscriptionStatus() {
      return this.hasValidSubscription ? 'Active' : 'Inactive';
    },
    statusText() {
      return this.hasValidSubscription
        ? `You can make unlimited updates until ${this.subscriptionRenewalDate}.`
        : 'Unlock your account to make updates to your Will';
    },
  },
  methods: {
    ...mapActions('checkout', ['updateCardDetails']),
    handleUpdateCardDetails() {
      this.updateCardDetails().then(() =>
        this.$router.push({ path: '/checkout' })
      );
    },
    enableAutoRenew() {
      if (this.subscription.hasStripePaymentMethod) {
        this.reactivateSubscription();
      } else {
        this.handleUpdateCardDetails();
      }
    },
  },
};
</script>
