<template>
  <ModuleStep :index="1" prev-step="/dashboard" :show-help="true">
    <Form
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      :auto-submit="isComplete && !willMeta.has_children"
      submit-arrow
      @done="submit"
    >
      <FormSection class="step-1">
        <template #heading>
          <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
          Do you have any
          <GlossaryTooltip term="Child"> children </GlossaryTooltip>?
        </template>
        <Tip>
          If you have a child on the way, select no for now. You can then come
          back and update this once they are born.
        </Tip>
        <LargeRadioButtons
          id="hasChildren"
          v-model="willMeta.has_children"
          :options="[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]"
        />
      </FormSection>
      <FormSection
        v-if="willMeta.has_children"
        class="step-2"
        tip="We’ll ask you to add or select people at relevant places in your Will. Their details can be edited on the People, Pets & Charities page."
      >
        <template #heading> My children are... </template>
        <PersonSelector
          v-model="selectedChildren"
          :loading="loading"
          :people="filteredPeople"
          type="child"
        />
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapActions } from 'vuex';

import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import GlossaryTooltip from '~/components/GlossaryTooltip';
import LargeRadioButtons from '~/components/LargeRadioButtons';
import ModuleStep from '~/components/ModuleStep';
import PersonSelector from '~/components/PersonSelector';
import Tip from '~/components/Tip';

import { beneficiaries, guardians, people, user, will } from '~/mixins/apollo';

export default {
  name: 'GuardiansYourChildren',
  components: {
    Form,
    FormSection,
    GlossaryTooltip,
    LargeRadioButtons,
    ModuleStep,
    PersonSelector,
    Tip,
  },
  mixins: [beneficiaries, guardians, people, user, will],
  data() {
    return {
      selectedChildren: [],
    };
  },
  computed: {
    isComplete() {
      return !!(
        this.willMeta.has_children === false || this.selectedChildren.length
      );
    },
    filteredPeople() {
      return this.people.filter((person) => {
        return person.category === 'none' || person.category === 'child';
      });
    },
    nextStep() {
      return this.willMeta.has_children && this.minors.length
        ? '/guardians/primary'
        : '/guardians/your-pets';
    },
  },
  watch: {
    children(children) {
      if (children.length && !this.selectedChildren.length) {
        this.selectedChildren = children.map((child) => child.id);
      }
    },
  },
  methods: {
    ...mapActions('person', ['addPerson']),
    async submit() {
      let childrenUpdated = false;

      await Promise.all(
        this.people.map((person) => {
          const isChild = person.category === 'child';
          const isSelected = this.selectedChildren.includes(person.id);

          if (this.willMeta.has_children && isSelected) {
            if (!isChild) {
              childrenUpdated = true;

              return this.updatePerson(person, {
                category: 'child',
              });
            }
          } else if (isChild) {
            childrenUpdated = true;

            return this.updatePerson(person, {
              category: 'none',
            });
          }
        })
      );

      if (
        this.willMeta.estate_split === 'children' &&
        (this.willMeta.has_children === false || childrenUpdated)
      ) {
        this.willMeta.estate_split = null;

        await this.removeAllBeneficiaries();
        await this.updateWillMeta();
      }

      if (!this.willMeta.has_children) {
        this.willMeta.has_primary_guardian = null;
        this.willMeta.has_backup_guardian = null;
        await this.updateWillMeta();
        await this.removeAllGuardians();
      }

      this.$nuxt.$emit('sendTrackingAttributes', {
        has_children: this.willMeta.has_children,
      });

      this.$router.push({
        path: this.nextStep,
      });
    },
  },
};
</script>
