var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleStep',{attrs:{"index":4,"prev-step":"/executors/primary"}},[_c('Form',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"hide-submit":!_vm.isComplete,"auto-submit":!_vm.willMeta.has_backup_executor,"submit-arrow":""},on:{"done":_vm.done},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('FormSection',{staticClass:"step-1",scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        Would you like to appoint a backup executor?\n      ")]},proxy:true}],null,true)},[_vm._v(" "),(_vm.willMeta.executors_option === 'professional')?_c('Tip',[_vm._v("\n        If Safewill decides to step aside in the best interests of your\n        beneficiaries or estate, you can appoint a backup executor to act as\n        executor\n      ")]):_c('Tip',[_vm._v("\n        In the event that your primary executors are unable or unwilling to\n        act, you can select up to two people to replace them as executor of\n        your estate\n      ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"hasBackupExecutor","options":[
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          } ]},model:{value:(_vm.willMeta.has_backup_executor),callback:function ($$v) {_vm.$set(_vm.willMeta, "has_backup_executor", $$v)},expression:"willMeta.has_backup_executor"}})],1),_vm._v(" "),(_vm.willMeta.has_backup_executor)?_c('FormSection',{staticClass:"step-2",attrs:{"tip":"We’ll ask you to add or select people at relevant places in your will. Their details can be edited on the People, Pets & Charities page."},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        Who should be your backup executor?\n      ")]},proxy:true}],null,true)},[_vm._v(" "),_c('PersonSelector',{attrs:{"loading":loading,"max":2,"people":_vm.filteredPeople,"type":"executor"},model:{value:(_vm.selectedBackupExecutors),callback:function ($$v) {_vm.selectedBackupExecutors=$$v},expression:"selectedBackupExecutors"}})],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }