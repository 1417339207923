<template>
  <svg viewBox="0 0 40 40" preserveAspectRatio="xMinYMin meet">
    <g>
      <circle
        fill="#fff"
        cx="50%"
        cy="50%"
        :r="radius"
        :stroke-width="stroke"
        stroke="#babbca"
        stroke-dasharray="5, 3"
        style="transform: rotate(5.5deg); transform-origin: 50%;"
      />
      <circle
        v-if="started"
        stroke="currentColor"
        fill="none"
        :stroke-dasharray="`${circumference} ${circumference}`"
        :stroke-dashoffset="offset"
        :stroke-width="stroke"
        :r="radius"
        cx="50%"
        cy="50%"
        style="transform: rotate(-90deg); transform-origin: 50%;"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      required: true,
    },
  },
  data() {
    const started = this.progress > 0;
    const stroke = 2.5;
    const radius = 18;
    const circumference = radius * 2 * Math.PI;
    const offset = circumference - (this.progress / 100) * circumference;

    return {
      started,
      stroke,
      radius,
      offset,
      circumference,
    };
  },
  computed: {
    strokeDashoffset() {
      return this.circumference - (this.progress / 100) * this.circumference;
    },
  },
};
</script>
