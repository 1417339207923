<template>
  <div>
    <div class="flex items-baseline justify-between">
      <h3 class="mb-12 text-3xl">
        Coupons
      </h3>
      <BaseButton class="btn btn-slate px-4 py-3" @click="showCouponModal">
        <div class="flex items-center">
          <span class="text-white">
            + Add Coupon
          </span>
        </div>
      </BaseButton>
    </div>

    <div class="flex items-baseline justify-between">
      <BaseButton
        class="btn btn-slate px-4 py-3 text-white"
        :disabled="$apollo.loading"
        @click="toggleCouponSortOrder"
      >
        Sort by Date
        <BaseIcon
          :id="couponsSortOrder === 'DESC' ? 'arrow-up' : 'arrow-down'"
          classes="ml-2 inline"
        />
      </BaseButton>

      <div class="flex">
        <input
          id="search"
          class="input input-compact"
          :v-model="couponSearch"
          placeholder="Search..."
          :compact="true"
          @keydown="search"
        />
      </div>
    </div>
    <AdminCard>
      <AdminDataTable
        v-if="coupons"
        :columns="columns"
        :loading="$apollo.loading"
        :min-rows="pageSize"
        :row-count="coupons.length"
      >
        <template #rows>
          <AdminDataTableRow
            v-for="(coupon, index) in coupons"
            :key="index"
            class="group"
          >
            <AdminDataTableCell>
              <span
                class="admin-id px-2 py-1 border rounded font-mono font-medium text-sm leading-none"
                v-text="coupon.code.toUpperCase()"
              />
            </AdminDataTableCell>
            <AdminDataTableCell>
              {{ getDiscount(coupon) }}
            </AdminDataTableCell>
            <AdminDataTableCell truncate>
              {{ coupon.used || 0 }}
            </AdminDataTableCell>
            <AdminDataTableCell truncate>
              {{ coupon.description }}
            </AdminDataTableCell>
            <AdminDataTableCell truncate>
              {{ $formatDate(coupon.createdAt) }}
            </AdminDataTableCell>
            <AdminDataTableCell>
              <BaseSwitch
                v-model="coupon.active"
                @change="setCouponActive(coupon)"
              />
            </AdminDataTableCell>
            <AdminDataTableCell>
              <AdminArchiveCouponButton :coupon="coupon" />
            </AdminDataTableCell>
          </AdminDataTableRow>
        </template>
        <template #pagination>
          <AdminDataTableButton
            :disabled="couponsPageIndex === 0"
            :loading="$apollo.loading"
            @click="prevPage"
          >
            <BaseIcon id="arrow-left" />
          </AdminDataTableButton>
          <AdminDataTableButton
            :disabled="coupons.length !== pageSize"
            :loading="$apollo.loading"
            @click="nextPage"
          >
            <BaseIcon id="arrow-right" />
          </AdminDataTableButton>
        </template>
      </AdminDataTable>
    </AdminCard>
    <AdminCouponModal @addedCoupon="addedCoupon" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'throttle-debounce';

import GET_COUPONS_QUERY from '~/graphql/queries/GetCoupons';
import AdminCard from '~/components/AdminCard';
import AdminDataTable from '~/components/AdminDataTable';
import AdminDataTableButton from '~/components/AdminDataTableButton';
import AdminDataTableRow from '~/components/AdminDataTableRow';
import AdminDataTableCell from '~/components/AdminDataTableCell';
import AdminArchiveCouponButton from '~/components/AdminArchiveCouponButton';
import AdminCouponModal from '~/components/AdminCouponModal';
import BaseIcon from '~/components/BaseIcon';
import BaseButton from '~/components/BaseButton';
import BaseSwitch from '~/components/BaseSwitch';
import { coupons } from '~/mixins/apollo';

export default {
  name: 'AdminCoupons',
  layout: 'admin',
  middleware: ['adminAuthenticated'],
  components: {
    AdminCard,
    AdminDataTable,
    AdminDataTableButton,
    AdminDataTableRow,
    AdminDataTableCell,
    AdminArchiveCouponButton,
    AdminCouponModal,
    BaseButton,
    BaseIcon,
    BaseSwitch,
  },
  apollo: {
    coupons: {
      query: GET_COUPONS_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          limit: this.pageSize,
          offset: this.couponsPageIndex * this.pageSize,
          searchQuery: this.couponSearch,
          sort: this.couponsSortOrder,
        };
      },
      update: (data) => data.getCoupons && data.getCoupons.coupons,
    },
  },
  mixins: [coupons],
  data() {
    return {
      columns: [
        'Code',
        'Discount',
        'Used',
        'Description',
        'Date Created',
        'Active',
        '',
      ],
      pageSize: 10,
      coupons: [],
    };
  },
  computed: {
    ...mapGetters('admin', [
      'couponsPageIndex',
      'couponSearch',
      'couponsSortOrder',
    ]),
  },
  mounted() {
    this.setCouponSearch('');
  },
  methods: {
    ...mapActions('admin', [
      'nextCouponsPage',
      'prevCouponsPage',
      'resetCouponsPageIndex',
      'setCouponSearch',
      'toggleCouponSortOrder',
    ]),
    ...mapActions('coupon', ['setShowCouponModal']),
    nextPage() {
      if (!this.$apollo.loading) {
        this.nextCouponsPage();
      }
    },
    prevPage() {
      if (!this.$apollo.loading) {
        this.prevCouponsPage();
      }
    },
    getDiscount(coupon) {
      return coupon.type === 'PERCENTAGE'
        ? `${coupon.factor}%`
        : `$${coupon.factor}`;
    },
    showCouponModal() {
      this.setShowCouponModal(true);
    },
    setCouponActive(coupon) {
      this.$confirm(
        {
          title: `Edit ${coupon.code.toUpperCase()}?`,
          message: `Are you sure you want to ${
            coupon.active ? 'activate' : 'deactivate'
          } this coupon?`,
        },
        (action) => {
          if (action) {
            this.updateCoupon(coupon);
          } else {
            coupon.active = !coupon.active;
          }
        }
      );
    },
    search: debounce(300, function(event) {
      this.resetCouponsPageIndex();
      this.setCouponSearch(event.target.value);
    }),
    async addedCoupon() {
      await this.$apollo.queries.coupons.refetch();
    },
  },
};
</script>
