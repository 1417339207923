<template>
  <ModuleStep :index="7" :prev-step="previous" :show-help="true">
    <Form
      v-slot="{ loading }"
      :hide-submit="!isComplete"
      submit-arrow
      @submit.prevent="onSubmit"
    >
      <FormSection>
        <template #heading>
          Do you want to add any notes about any preferences, values or wishes
          when it comes to healthcare decisions?
        </template>
        <template v-if="['nsw', 'vic', 'tas'].includes(userState)">
          <p class="mb-4">
            These preferences can be important for your decision maker(s) to
            know.
          </p>
        </template>
        <template v-if="['qld', 'nt'].includes(userState)">
          <p class="mb-4">
            While these preferences are not binding, they can be important for
            your decision maker(s) to know.
          </p>
        </template>
        <template v-if="['wa'].includes(userState)">
          <p class="mb-4">
            This is where you should include how you would like your attorney to
            use their power.
          </p>
        </template>
        <template v-if="['sa'].includes(userState)">
          <p class="mb-4">
            Your decision maker can generally make decisions related to your
            personal affairs (such as your relationships, personal grooming,
            employment and holidays), your living arrangements.
          </p>
          <p class="mb-4">
            While your preferences for any of the above may not be binding, they
            can be important for your decision maker(s) to know.
          </p>
        </template>
        <p class="mb-4">
          For example, you may have preferences for a nursing home or at-home
          care, medical treatments you don’t wish to receive due to cultural
          beliefs, or circumstances where you would prefer not to remain on life
          support or receive medical treatment.
        </p>
        <LargeRadioButtons
          id="preferences"
          v-model="poaMedicalMeta.has_preferences"
          class="mb-12"
          :options="[
            {
              label: `Yes`,
              value: true,
            },
            {
              label: `No`,
              value: false,
            },
          ]"
          @change="onChange($event)"
        />
        <div v-if="poaMedicalMeta.has_preferences">
          <TextArea
            id="preferences"
            v-model="poaMedicalMeta.preferences"
            :disabled="loading"
            maxlength="2500"
            placeholder="It is my preference that..."
          />
        </div>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';
import { queries, mutations } from '@/modules/apollo-queries/poa-meta';
import { prevStep, nextStep } from '@/modules/poa-flow/medical/index';
import Form from '~/components/Form';
import ModuleStep from '~/components/ModuleStep';
import FormSection from '~/components/FormSection';
import LargeRadioButtons from '~/components/LargeRadioButtons';
import TextArea from '~/components/TextArea';

import { user, will } from '~/mixins/apollo';

export default {
  name: 'MedicalPOAPreferences',
  components: {
    Form,
    ModuleStep,
    FormSection,
    LargeRadioButtons,
    TextArea,
  },
  mixins: [user, will],
  apollo: {
    poaMedicalMeta: {
      ...queries.poaMedicalMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
  },
  data() {
    return {
      poaMedicalMeta: {
        has_preferences: undefined,
        preferences: undefined,
      },
    };
  },
  computed: {
    ...mapGetters(['poaId']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
      });
    },
    isComplete() {
      return (
        this.poaMedicalMeta.has_preferences === false ||
        (this.poaMedicalMeta.has_preferences === true &&
          this.poaMedicalMeta.preferences?.length > 0)
      );
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
  },
  methods: {
    onChange($event) {
      if ($event === false) {
        this.onSubmit();
      }
    },
    async onSubmit() {
      await this.$apollo.mutate(
        mutations.updatePoaMetas(this.poaId, [
          {
            key: 'has_preferences',
            value: this.poaMedicalMeta.has_preferences ? 'true' : 'false',
            category: 'MEDICAL',
          },
          {
            key: 'preferences',
            value: this.poaMedicalMeta.has_preferences
              ? this.poaMedicalMeta.preferences
              : null,
            category: 'MEDICAL',
          },
        ])
      );

      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✅ Module POA medical completed',
      });
      const path = this.next;
      await this.$router.push({ path });
    },
  },
  head() {
    return {
      title: 'Medical preferences',
    };
  },
};
</script>
