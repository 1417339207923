var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleStep',{attrs:{"index":4,"prev-step":_vm.previous,"show-help":true}},[_c('Form',{attrs:{"hide-submit":!_vm.isComplete,"submit-arrow":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('FormSection',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        Do you want your decision makers to be able to spend your money to\n        provide for the reasonable living expenses of anyone else?\n      ")]},proxy:true}],null,true)},[_vm._v(" "),(['nsw', 'wa', 'sa'].includes(_vm.userState))?_c('Tip',[_vm._v("\n        This will allow your attorney to use your money to provide for the\n        reasonable living and medical expenses of the people you select below\n        (for example, your partner or any underage children).\n      ")]):_c('Tip',[_vm._v("\n        This will allow your attorney to use your money to provide for the\n        reasonable needs of the people you select below (for example, your\n        partner or any underage children).\n      ")]),_vm._v(" "),_c('LargeRadioButtons',{staticClass:"mb-12",attrs:{"id":"has_inclusions","options":[
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          } ]},on:{"change":function($event){return _vm.onChange($event)}},model:{value:(_vm.poaFinancialMeta.has_inclusions),callback:function ($$v) {_vm.$set(_vm.poaFinancialMeta, "has_inclusions", $$v)},expression:"poaFinancialMeta.has_inclusions"}})],1),_vm._v(" "),(_vm.poaFinancialMeta.has_inclusions)?_c('FormSection',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        People\n      ")]},proxy:true}],null,true)},[_vm._v(" "),_c('PersonSelector',{attrs:{"loading":loading,"people":_vm.people,"generic-person-selector":true,"min":1},model:{value:(_vm.selectedInclusionPeopleIDs),callback:function ($$v) {_vm.selectedInclusionPeopleIDs=$$v},expression:"selectedInclusionPeopleIDs"}})],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }