<template>
  <div>
    <div class="flex items-baseline justify-between">
      <h3 class="mb-12 text-3xl">
        Submissions
      </h3>
      <div class="flex">
        <input
          id="search"
          class="input input-compact"
          :v-model="submissionsSearch"
          placeholder="Search..."
          :compact="true"
          @keydown="search"
        />
      </div>
    </div>
    <AdminCard>
      <AdminDataTable
        v-if="wills"
        :columns="columns"
        :current-sorting-column="currentSortingColumn"
        :sortable-columns="['Date', 'Awaiting On']"
        :loading="$apollo.loading"
        :min-rows="pageSize"
        :row-count="wills.length"
        @sortColumnClicked="handleSortColumn"
      >
        <template #filters>
          <AdminDataTableFilter
            v-for="(filter, index) in filters"
            :key="index"
            :active="filter.value === submissionsStatusFilter"
            :label="filter.label"
            @click="setSubmissionsStatusFilter(filter.value)"
          />
        </template>
        <template #rows>
          <AdminDataTableRow
            v-for="(will, index) in wills"
            :key="index"
            :to="`/admin/submissions/${will.id}`"
          >
            <AdminDataTableCell>
              <span
                class="admin-id px-2 py-1 border rounded font-mono font-medium text-sm leading-none"
                v-text="will.hashId.toUpperCase()"
              />
            </AdminDataTableCell>
            <AdminDataTableCell>
              {{ $formatDate(will.updatedAt, 'dd mmm yyyy "at" hh:MM tt') }}
            </AdminDataTableCell>
            <AdminDataTableCell truncate>
              {{ will.name }}
            </AdminDataTableCell>
            <AdminDataTableCell truncate>
              {{ will.email }}
            </AdminDataTableCell>
            <AdminDataTableCell full>
              <div class="flex items-center">
                <AdminStatusChip :status="will.status" />
                <nuxt-link
                  :to="`/admin/submissions/${will.id}`"
                  class="inline-flex justify-center items-center w-4 h-4 rounded-full ml-4 bg-slate-100 opacity-0 group-hover:opacity-100 text-white fill-current transition-opacity transition-100"
                >
                  <svg width="6" height="8" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 7.4L.6 6l2-2-2-2L2 .6 5.4 4z" />
                  </svg>
                </nuxt-link>
              </div>
            </AdminDataTableCell>
            <AdminDataTableCell>
              {{ will.awaitingOn }}
            </AdminDataTableCell>
          </AdminDataTableRow>
        </template>
        <template #pagination>
          <AdminDataTableButton
            :disabled="submissionsPageIndex === 0"
            :loading="$apollo.loading"
            @click="prevPage"
          >
            <BaseIcon id="arrow-left" />
          </AdminDataTableButton>
          <AdminDataTableButton
            :disabled="wills.length !== pageSize"
            :loading="$apollo.loading"
            @click="nextPage"
          >
            <BaseIcon id="arrow-right" />
          </AdminDataTableButton>
        </template>
      </AdminDataTable>
    </AdminCard>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { debounce } from 'throttle-debounce';

import GET_WILLS_BY_STATUS_QUERY from '~/graphql/queries/GetWillsByStatus';

import AdminCard from '~/components/AdminCard';
import AdminDataTable from '~/components/AdminDataTable';
import AdminDataTableButton from '~/components/AdminDataTableButton';
import AdminDataTableFilter from '~/components/AdminDataTableFilter';
import AdminStatusChip from '~/components/AdminStatusChip';
import AdminDataTableRow from '~/components/AdminDataTableRow';
import AdminDataTableCell from '~/components/AdminDataTableCell';
import BaseIcon from '~/components/BaseIcon';

export default {
  name: 'AdminSubmissionsTable',
  layout: 'admin',
  components: {
    AdminCard,
    AdminDataTable,
    AdminDataTableButton,
    AdminDataTableFilter,
    AdminStatusChip,
    AdminDataTableRow,
    AdminDataTableCell,
    BaseIcon,
  },
  apollo: {
    wills: {
      query() {
        return GET_WILLS_BY_STATUS_QUERY;
      },
      update: (data) => {
        return data.getWillsByStatus.wills;
      },
      fetchPolicy: 'network-only',
      variables() {
        return {
          limit: this.pageSize,
          offset: this.submissionsPageIndex * this.pageSize,
          status: this.submissionsStatusFilter,
          searchQuery: this.submissionsSearch,
          customOrder: {
            direction: this.customOrderDirection,
            field: this.customOrderField,
          },
        };
      },
    },
  },
  data() {
    return {
      GET_WILLS_BY_STATUS_QUERY,
      columns: [
        'ID',
        'Date',
        'Customer Name',
        'Customer Email',
        'Status',
        'Awaiting On',
      ],
      filters: [
        {
          label: 'All',
          value: null,
        },
        {
          label: 'In Progress',
          value: 'IN_PROGRESS',
        },
        {
          label: 'Awaiting Review',
          value: 'AWAITING_APPROVAL',
        },
        {
          label: 'Flagged',
          value: 'FLAGGED',
        },
        {
          label: 'Approved',
          value: 'APPROVED',
        },
        {
          label: 'Archived',
          value: 'ARCHIVED',
        },
      ],
      pageSize: 10,
      wills: [],
    };
  },
  computed: {
    ...mapGetters('admin', [
      'submissionsStatusFilter',
      'submissionsPageIndex',
      'submissionsSearch',
    ]),
    ...mapState('admin', ['customOrderDirection', 'customOrderField']),
    currentSortingColumn() {
      if (this.customOrderField === 'updatedAt') {
        return ['Date', this.customOrderDirection];
      } else if (this.customOrderField === 'awaitingOn') {
        return ['Awaiting On', this.customOrderDirection];
      }

      throw new Error(`Unknown sort field ${this.customOrderField}`);
    },
  },
  watch: {
    submissionsStatusFilter() {
      this.resetSubmissionsPageIndex();
    },
  },
  mounted() {
    this.setSubmissionsSearch('');
  },
  methods: {
    ...mapActions('admin', [
      'nextSubmissionsPage',
      'prevSubmissionsPage',
      'resetSubmissionsPageIndex',
      'setSubmissionsStatusFilter',
      'setSubmissionsSearch',
      'setSortOrder',
    ]),
    nextPage() {
      if (!this.$apollo.loading) {
        this.nextSubmissionsPage();
      }
    },
    prevPage() {
      if (!this.$apollo.loading) {
        this.prevSubmissionsPage();
      }
    },
    search: debounce(300, function(event) {
      this.resetSubmissionsPageIndex();
      this.setSubmissionsSearch(event.target.value);
    }),
    handleSortColumn(column) {
      if (['Date', 'Awaiting On'].includes(column) === true) {
        let [field, direction] = this.currentSortingColumn;

        if (field === column) {
          // just change sort direction
          if (direction === 'ASC') {
            direction = 'DESC';
          } else if (direction === 'DESC') {
            direction = 'ASC';
          }
        } else {
          field = column;
          direction = 'DESC'; // DESC by default on a new column
        }

        const columnToFieldMapping = {
          Date: 'updatedAt',
          'Awaiting On': 'awaitingOn',
        };

        this.setSortOrder({
          field: columnToFieldMapping[field],
          direction,
        });
      }
    },
  },
};
</script>
