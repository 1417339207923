<template>
  <div class="password-validator">
    <PasswordValidatorItem id="min-length" :valid="hasMinLength">
      At least {{ minLength }} characters
    </PasswordValidatorItem>
    <PasswordValidatorItem id="includes-symbol" :valid="hasNumberOrSymbol">
      Contains a number or symbol
    </PasswordValidatorItem>
  </div>
</template>

<script>
import PasswordValidatorItem from '~/components/molecules/PasswordValidatorItem';

export default {
  name: 'PasswordValidator',
  components: {
    PasswordValidatorItem,
  },
  model: {
    event: 'change',
  },
  props: {
    input: {
      type: String,
      required: true,
    },
    minLength: {
      default: 8,
      type: Number,
    },
  },
  computed: {
    isValid() {
      return this.hasMinLength && this.hasNumberOrSymbol;
    },
    hasMinLength() {
      return this.input.length >= this.minLength;
    },
    hasNumberOrSymbol() {
      return !!this.input.match(/[0-9 !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/);
    },
  },
  watch: {
    isValid(newValue) {
      this.$emit('change', newValue);
    },
  },
};
</script>

<style lang="scss">
.password-validator {
  display: flex;
  flex-direction: column;
}
</style>
