<template>
  <div>
    <div class="flex flex-wrap lg:flex-no-wrap mb-8 lg:mb-12">
      <div class="w-full lg:mb-0 lg:pr-15 lg:w-8/12 mb-8 lg:mb-0">
        <p class="text-2xl lg:text-3xl font-medium leading-tight text-grey-700">
          {{ fullName }}
        </p>
        <h1
          class="text-charcoal-500 text-4xl md:text-5xl lg:text-6xl font-medium mb-6"
        >
          Appoint your Medical and Financial Powers of Attorney
        </h1>
        <p class="text-charcoal-400 text-2xl">
          Your Powers of Attorney are important legal tools that allow you to
          appoint someone to make financial and medical decisions on your behalf
          should you be living but unable to make your own decisions.
        </p>
      </div>
      <div class="w-full lg:w-4/12">
        <div v-if="showAlert" class="w-full mb-8 lg:mb-12">
          <div
            class="flex-col bg-grey-200 items-baseline p-8 my-4 rounded font-medium text-base"
          >
            <span
              class="inline-flex justify-center items-center h-6 px-3 rounded-full font-bold text-sm text-white leading-none tracking-widest uppercase select-none bg-teal-300"
            >
              {{ userStateName }} RESIDENTS
            </span>
            <div class="flex mt-4">
              <BaseIcon
                id="info-alt"
                classes="flex-shrink-0 mr-4 mt-1 text-teal-300"
              />
              <div class="font-normal text-charcoal-500">
                <p>
                  We are currently not offering Power of Attorney documents in
                  your state.
                </p>
                <p>We will contact you once it's available.</p>
              </div>
            </div>
          </div>
        </div>
        <BaseButton
          v-else
          to="/poa/checkout"
          class="btn-xl btn-slate btn-focus w-full md:w-auto lg:mt-12 xl:ml-15 text-white text-center"
        >
          Purchase now for $50
        </BaseButton>
      </div>
    </div>
    <div
      class="relative overflow-hidden bg-white px-10 pt-10 pb-8 mb-8 lg:mb-12"
    >
      <BaseIcon
        id="shield-bg"
        classes="absolute -right-32 top-15 -ml-8 hidden lg:block w-auto h-92"
      />
      <div class="relative w-full lg:w-8/12 xl:w-6/12">
        <h2 class="text-charcoal-500 text-3xl mb-10">
          The easiest way to choose someone to look after your financial and
          healthcare decisions — when you can’t.
        </h2>
        <div class="flex flex-wrap">
          <div
            v-for="(item, index) in wishes"
            :key="`wish-${index}`"
            class="w-full md:w-6/12 mb-10 md:odd:pr-8 md:even:pl-8 lg:odd:pr-10 lg:even:pl-10 xl:odd:pr-10 xl:even:pl-10"
          >
            <BaseIcon :id="item.iconId" classes="mb-4 lg:mb-5" />
            <h4 class="text-charcoal-500 text-lg lg:text-xl mb-1 lg:mb-2">
              {{ item.title }}
            </h4>
            <p class="text-charcoal-400 text-base">
              {{ item.description }}
            </p>
          </div>
        </div>
        <p class="text-charcoal-400 text-base">
          The documents you’ll receive are commonly known in the legal world as
          an <strong>Enduring Power of Attorney</strong>, and
          <template v-if="['nsw'].includes(userState)">
            an <strong>Enduring Guardian</strong> under New South Wales law.
          </template>
          <template v-else-if="['act'].includes(userState)">
            an <strong>Enduring Power of Attorney for Healthcare</strong> under
            Australian Capital Territory law.
          </template>
          <template v-else-if="['vic'].includes(userState)">
            an
            <strong>Appointment of Medical Treatment Decision Maker</strong>
            under Victorian law.
          </template>
          <template v-else-if="['nt'].includes(userState)">
            an <strong>Advance Personal Plan for Healthcare</strong> under
            Northern Territory law.
          </template>
          <template v-else-if="['sa'].includes(userState)">
            an <strong>Advance Care Directive</strong> under South Australian
            law.
          </template>
          <template v-else-if="['tas'].includes(userState)">
            an <strong>Enduring Guardian</strong> under Tasmanian law.
          </template>
          <template v-else-if="['wa'].includes(userState)">
            an <strong>Enduring Guardian</strong> under Western Australian law.
          </template>
          <template v-else-if="['qld'].includes(userState)">
            a <strong>medical Power of Attorney</strong> under Queensland law.
          </template>
        </p>
      </div>
    </div>
    <h2 class="mb-4 text-4xl">FAQ</h2>
    <AccordionPanel>
      <template v-slot:title>What is a Power of Attorney?</template>
      <template v-slot:content>
        <p class="mb-4">
          Powers of Attorney are legal documents that allow you to appoint
          someone to make decisions on your behalf if you are unable to do so
          yourself. There are two types of Powers of Attorney you can make on
          the Safewill Platform:
        </p>
        <ul class="list-disc pl-6 text-lg">
          <li class="mb-4">
            <strong>Financial Powers of Attorney</strong> allow you to choose
            someone to manage your financial and legal affairs, which includes
            buying and selling real estate, shares and other assets, operating
            your bank accounts and spending money on your behalf.
          </li>
          <li class="mb-4">
            <strong>Medical Powers of Attorney</strong> allow you to choose
            someone to manage your health and lifestyle matters, including the
            type of medical care you receive, or the nature of your living
            arrangements. Depending on the State you live in, this medical Power
            of Attorney document is commonly known as either an ‘Enduring
            Guardianship', ‘Advance Personal Plan’ or ‘Advance Care Directive’.
          </li>
        </ul>
        <p class="mb-4">
          Your appointed decision maker(s) will be subject to a number of legal
          obligations and duties, including to always act in your best interests
          and in accordance with your written instructions.
        </p>
      </template>
    </AccordionPanel>
    <AccordionPanel>
      <template v-slot:title>
        When will my Powers of Attorney take effect?
      </template>
      <template v-slot:content>
        <p class="mb-4">
          At Safewill, the Powers of Attorney you create will only come into
          effect if you lose your decision-making capacity.
        </p>
        <p class="mb-4">
          The authority given under the Power of Attorney will continue
          throughout the time you lack decision-making capacity, and will come
          to an end when you pass away.
        </p>
      </template>
    </AccordionPanel>
    <AccordionPanel>
      <template v-slot:title>
        What’s the difference between this and my Will?
      </template>
      <template v-slot:content>
        <p class="mb-4">
          Your Will and your Powers of Attorney function at different times to
          carry out and safeguard your wishes.
        </p>
        <p class="mb-4">
          Powers of Attorney come into effect while you are still alive but have
          lost the ability to make decisions for yourself.
          <strong>
            The effect of the Power of Attorney ends when you pass away.
          </strong>
        </p>
        <p class="mb-4">
          Your Will, on the other hand,
          <strong>only comes into effect once you pass away</strong>. Under your
          Will, you appoint an Executor to carry out the wishes in your Will,
          and manage the transfer of your assets to the required beneficiaries
          upon your death.
        </p>
      </template>
    </AccordionPanel>
    <AccordionPanel>
      <template v-slot:title>Do I need Powers of Attorney?</template>
      <template v-slot:content>
        <p class="mb-4">
          People tend to assume that because their loved ones know what their
          wishes would be in certain circumstances, those same people would have
          the ability to make decisions on their behalf. However, without a
          valid Power of Attorney appointment in place, this is unlikely to be
          the case.
        </p>
        <p class="mb-4">
          Completing a Power of Attorney document provides certainty that
          someone you trust is able to help manage your affairs and make
          decisions on your behalf when it matters most.
        </p>
        <p class="mb-4">
          For financial matters, this means your chosen attorney can readily
          access your solely held assets, such as bank accounts that may be
          required to pay for medical treatment or supporting your dependents.
          For health matters, a medical Power of Attorney will allow you to
          appoint someone to speak with doctors and nurses on your behalf about
          the types of treatment you receive. In some states you can also give
          your decision maker the ability to make decisions about your personal
          matters, such as who may visit you, whether you prefer a specific diet
          (e.g. vegetarian), and which long-term care facility you move to (if
          necessary).
        </p>
      </template>
    </AccordionPanel>
    <AccordionPanel>
      <template v-slot:title>
        What happens if I don’t have Powers of Attorney?
      </template>
      <template v-slot:content>
        <p class="mb-4">
          If there is no valid Power of Attorney and you lose capacity, your
          loved ones may have to go through alternative, lengthy Court or
          Tribunal processes to be legally appointed as your decision maker.
          This means that even if the right person is eventually granted
          decision making power, there may be significant delay between you
          losing the ability to make decisions and your decision maker being
          appointed. Often this can result in bills going unpaid, dependents not
          being cared for, and medical decisions being made that may not be what
          you would have wanted.
        </p>
        <p class="mb-4">
          There is also a chance that a Court or Tribunal will not appoint the
          person you would have otherwise chosen to make these decisions for
          you, and an alternative decision maker is appointed. In all these
          cases, it is much better to have certainty over who will be appointed,
          and grant them the necessary powers to quickly make decisions if the
          need ever arose.
        </p>
      </template>
    </AccordionPanel>
    <AccordionPanel>
      <template v-slot:title>What do I need to get started?</template>
      <template v-slot:content>
        <p class="mb-4">
          You’ll just need 10 minutes and an idea of who you’d trust with
          decisions around your finances or healthcare. We’ll guide you through
          the rest.
        </p>
      </template>
    </AccordionPanel>
    <AccordionPanel>
      <template v-slot:title>How much does it normally cost?</template>
      <template v-slot:content>
        <p class="mb-4">
          With a lawyer, Power of Attorney documents can cost hundreds, if not
          thousands of dollars. It can also be a lengthy and difficult process
          to go through. You can access your medical and financial Power of
          Attorney documents at an introductory price of just $50 (normally
          $100).
        </p>
      </template>
    </AccordionPanel>
    <AccordionPanel>
      <template v-slot:title>How long will it take?</template>
      <template v-slot:content>
        <p class="mb-4">
          With Safewill, completing your Power of Attorney can take as little as
          10 minutes.
        </p>
      </template>
    </AccordionPanel>
    <AccordionPanel>
      <template v-slot:title>
        What powers will my decision makers legally have?
      </template>
      <template v-slot:content>
        <p class="mb-4">
          The details are slightly different in each state, but in all states
          the principle is the same and your chosen decision makers have the
          power to make decisions on your behalf. Remember that in all cases,
          they are legally bound to act in your best interests. Even so, you
          should make sure to appoint people that you trust completely to fill
          the role.
        </p>
      </template>
    </AccordionPanel>
    <AccordionPanel>
      <template v-slot:title>
        Can I place any limitations or exclusions on their power?
      </template>
      <template v-slot:content>
        <p class="mb-4">
          Yes, it’s up to you. We’ll ask you if there are any specific
          limitations or exclusions you want to include in your Powers of
          Attorney for your decision makers.
        </p>
      </template>
    </AccordionPanel>
    <AccordionPanel>
      <template v-slot:title>
        What if I’ve previously created Powers of Attorney?
      </template>
      <template v-slot:content>
        <p class="mb-4">
          The rules are different in each state. In Victoria, creating a new
          Powers of Attorney document will override any previous documents. In
          the remaining states, creating a new document will not automatically
          override any previous documents — if you’d like those previous
          documents to be terminated, you’ll need to revoke your previous
          documents separately.
        </p>
      </template>
    </AccordionPanel>
    <AccordionPanel>
      <template v-slot:title>Can I purchase this later?</template>
      <template v-slot:content>
        <p class="mb-4">
          The 50% introductory discount is valid for a limited time only. If
          you’re not ready to complete today, you can still take advantage of
          the offer by purchasing now and completing the documents when you’re
          ready.
        </p>
      </template>
    </AccordionPanel>
  </div>
</template>

<script>
import { stateCodeToStateName } from '@/modules/stateCodeToStateName';

import AccordionPanel from '~/components/AccordionPanel';
import BaseButton from '~/components/BaseButton';
import BaseIcon from '~/components/BaseIcon';
import { user, will } from '~/mixins/apollo';

export default {
  name: 'PoaLandingPage',
  components: {
    AccordionPanel,
    BaseButton,
    BaseIcon,
  },
  mixins: [user, will],
  data() {
    return {
      wishes: [
        {
          iconId: 'wallet-bg',
          title: 'Financial Decisions',
          description: 'Appoint someone to manage your property and finances.',
        },
        {
          iconId: 'pen-bg',
          title: 'Legal Affairs',
          description: 'Choose someone you trust to manage legal affairs.',
        },
        {
          iconId: 'aidkit-bg',
          title: 'Medical Treatment',
          description:
            'Give instructions in advance around medical treatment and preferences.',
        },
        {
          iconId: 'house-bg',
          title: 'Lifestyle Decisions',
          description:
            'Protect your lifestyle decisions, including where you live.',
        },
      ],
    };
  },
  computed: {
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    userStateName() {
      return this.userState ? stateCodeToStateName(this.userState) : null;
    },
    showAlert() {
      return !this.$ff.powerOfAttorney();
    },
    fullName() {
      let name = '';
      if ('name_first' in this.willMeta) {
        name = this.willMeta.name_first.trim();
        if (this.willMeta.name_middle) {
          name = `${name} ${this.willMeta.name_middle.trim()}`;
        }
        name = `${name} ${this.willMeta.name_last.trim()}`;
      }
      return name;
    },
  },
};
</script>
