<template>
  <ModuleStep :index="3" prev-step="/funeral/type">
    <Form
      v-slot="{ loading }"
      auto-focus
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      submit-arrow
      :submit-label="submitLabel"
      @done="onDone"
    >
      <FormSection>
        <template #heading>
          {{ getHeadingText }}
        </template>
        <Tip>
          Describe a location which will be easily identifiable to your
          executor. They will do their best to make this happen.
        </Tip>
        <TextInput
          id="funeral_location"
          v-model="willMeta.funeral_location"
          :disabled="loading"
          placeholder="Enter location"
        />
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import ModuleStep from '~/components/ModuleStep';
import TextInput from '~/components/TextInput';
import Tip from '~/components/Tip';

import { user, will } from '~/mixins/apollo';

export default {
  name: 'FuneralLocation',
  components: {
    Form,
    FormSection,
    ModuleStep,
    TextInput,
    Tip,
  },
  mixins: [user, will],
  computed: {
    submitLabel() {
      return this.willMeta.funeral_location ? 'Next' : 'Skip';
    },
    getHeadingText() {
      return this.willMeta.funeral_type === 'cremation'
        ? `Would you like to choose a location for your ashes?`
        : `Would you like to choose a location for your burial?`;
    },
  },
  methods: {
    onDone() {
      this.$router.push({
        path: '/funeral/other-requests',
      });
    },
  },
};
</script>
