<template>
  <div class="person mb-8 last-child:mb-0">
    <div v-if="label" class="flex items-center mb-4">
      <h4 class="flex-grow text-2xl">
        People
      </h4>
      <Tooltip
        class="ml-4"
        content="We’ll ask you to add or select people at relevant places in your Will. Their details can be edited on the People, Pets & Charities page."
      >
        <div
          class="flex justify-center items-center w-5 h-5 rounded-full bg-grey-500 text-grey-200"
        >
          <BaseGlyph id="question-mark" />
        </div>
      </Tooltip>
    </div>
    <div class="relative">
      <MetaSlot
        v-for="(person, index) in people"
        :key="index"
        v-slot="{ meta }"
        :meta="person.meta"
      >
        <div class="mb-4">
          <LocalScope
            :id="`person_${person.id}__${id}`"
            v-slot="{ id, quickEdit, selected }"
            :quick-edit="index >= quickEditIndex"
            :selected="isSelected[index]"
          >
            <label
              class="group block rounded cursor-pointer select-none"
              :class="classes.label[index]"
              :for="id"
            >
              <BaseCard
                :class="[
                  classes.card[index],
                  { 'rounded-bl-none rounded-br-none': quickEdit },
                ]"
                inline
                :primary-text="meta.full_name"
                :secondary-text="buildIdentifier(meta)"
              >
                <template #actions>
                  <div class="flex flex-col">
                    <div
                      class="relative inline-flex justify-center items-center cursor-pointer"
                      :class="classes.checkbox[index]"
                    >
                      <input
                        :id="id"
                        class="btn absolute top-0 left-0 w-full h-full p-0"
                        :class="classes.input[index]"
                        :checked="selected"
                        :name="id"
                        type="checkbox"
                        @change="onChange($event.target.checked, person)"
                      />
                      <span v-if="selected" class="text-lg">
                        Unselect
                      </span>
                      <span v-else class="text-lg">
                        Select
                      </span>
                    </div>
                    <div
                      class="relative inline-flex justify-center items-center cursor-pointer mt-2"
                    >
                      <BaseButton
                        class="btn btn-md transition-duration-0 transition-all"
                        :class="classes.edit[index]"
                        @click="onEditPerson(person)"
                      >
                        Edit
                      </BaseButton>
                    </div>
                  </div>
                </template>
              </BaseCard>
            </label>
          </LocalScope>
        </div>
      </MetaSlot>
    </div>
    <BaseButton
      class="add-person btn-xl btn-white w-full"
      :disabled="loading"
      focusable
      @click="showAddPersonPanel"
    >
      + Add a person
    </BaseButton>
    <Portal v-if="showingGenericPersonPanel" to="sidePanel">
      <GenericPersonPanel
        :existing-person="personBeingEdited"
        :identifier-requirements="identifierRequirements"
        :type="type"
        @close="closePersonPanel"
      />
    </Portal>
  </div>
</template>

<script>
import { LocalScope } from 'vue-local-scope';
import { mapActions } from 'vuex';

import { Portal } from 'portal-vue';
import GenericPersonPanel from '@/components/GenericPersonPanel';
import BaseButton from '~/components/BaseButton';
import BaseCard from '~/components/BaseCard';
import BaseGlyph from '~/components/BaseGlyph';
import MetaSlot from '~/components/MetaSlot';
import Tooltip from '~/components/Tooltip';

export default {
  name: 'PersonSelector',
  components: {
    GenericPersonPanel,
    BaseButton,
    BaseCard,
    BaseGlyph,
    LocalScope,
    MetaSlot,
    Tooltip,
    Portal,
  },
  props: {
    id: {
      default: '',
      type: String,
    },
    label: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    max: {
      default: Infinity,
      type: Number,
    },
    people: {
      type: Array,
      default: () => [],
      required: true,
    },
    type: {
      default: 'person',
      type: String,
    },
    value: {
      type: Array,
      required: true,
    },
    genericPersonSelector: {
      type: Boolean,
      default: false,
      required: false,
    },
    identifierRequirements: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      quickEditIndex: Infinity,
      selected: this.value,
      showingGenericPersonPanel: false,
      personBeingEdited: undefined,
    };
  },
  computed: {
    classes() {
      return {
        card: (() => {
          return this.isSelected.map((selected) => {
            return {
              'bg-teal-200': selected,
            };
          });
        })(),
        checkbox: (() => {
          return this.isSelected.map((selected) => {
            return {
              'h-10 px-3 btn-outline w-24': !selected,
              'h-10 px-3 rounded bg-teal-400 text-white  w-24': selected,
            };
          });
        })(),
        edit: (() => {
          return this.isSelected.map((selected) => {
            return {
              'btn-outline w-24': !selected,
              'btn-outline border-teal-400 text-teal-400 w-24': selected,
            };
          });
        })(),
        input: (() => {
          return this.isSelected.map((selected) => {
            return {
              'rounded-full': !selected,
            };
          });
        })(),
        label: (() => {
          return this.isSelected.map((selected) => {
            return {
              'opacity-50 pointer-events-none cursor-not-allowed':
                !selected && this.max > 1 && this.selected.length === this.max,
            };
          });
        })(),
      };
    },
    isSelected() {
      return this.people.map((person) => {
        return this.selected.includes(person.id);
      });
    },
  },
  watch: {
    value(newValue) {
      if (!this.loading) {
        this.selected = newValue;
      }
    },
  },
  mounted() {
    this.$nuxt.$on('addPerson', (person) => {
      if (this.quickEditIndex === Infinity) {
        this.quickEditIndex = this.people.length;
      }

      this.onChange(true, person);
    });
  },
  methods: {
    ...mapActions('person', [
      'addPerson',
      'setIdentifierRequirements',
      'editPerson',
    ]),
    closePersonPanel() {
      this.showingGenericPersonPanel = false;
      this.personBeingEdited = undefined;
    },
    onEditPerson(person) {
      if (this.genericPersonSelector) {
        this.personBeingEdited = person;
        this.showingGenericPersonPanel = true;
      } else {
        this.editPerson({ person });
      }
    },
    showAddPersonPanel() {
      if (this.genericPersonSelector) {
        this.showingGenericPersonPanel = true;
      } else {
        this.addPerson(this.type);
        if (this.identifierRequirements !== null) {
          this.setIdentifierRequirements(this.identifierRequirements);
        }
      }
    },
    buildIdentifier(meta) {
      switch (meta.identifier) {
        case 'email':
          return meta.email;
        case 'dob':
          return meta.date_of_birth;
        default:
          return meta.address_street
            ? this.buildAddress(meta)
            : meta.date_of_birth
            ? meta.date_of_birth
            : meta.email;
      }
    },
    buildAddress(meta) {
      return meta.address_state && meta.address_postcode
        ? `${meta.address_street}, ${meta.address_suburb} ${meta.address_state} ${meta.address_postcode}`
        : `${meta.address_street}, ${meta.address_suburb}`;
    },
    onChange(checked, person) {
      const index = this.selected.indexOf(person.id);
      if (!checked && index > -1) {
        this.selected.splice(index, 1);
      } else if (this.selected.length < this.max) {
        this.selected.push(person.id);
      } else if (this.max === 1) {
        this.selected = [person.id];
      }

      this.$emit('input', this.selected);
    },
  },
};
</script>
