<template>
  <Module title="Liabilities" :total-steps="2">
    <nuxt-child />
  </Module>
</template>

<script>
import Module from '~/components/Module';

export default {
  name: 'Liabilities',
  layout: 'module',
  middleware: ['authenticated'],
  components: {
    Module,
  },
};
</script>
