<template>
  <BaseModal v-if="showSubmitHelperModal" @close="close">
    <div class="text-teal-100">
      <BaseDuoIcon id="info" />
    </div>
    <h4 class="mb-4 text-3xl">
      Submit now. Update later.
    </h4>
    <div class="mb-4 text-xl">
      <p>
        It’s recommended that you put a Will in place first and update it later
        if you need to. Unlike traditional Will-making options, Safewill allows
        you to log in and easily update your Will at anytime. A year of free
        updates is included with your Will.
      </p>
    </div>
    <Tip>
      We’ll typically process your Will within 3 business days.
    </Tip>
    <Form
      submit-arrow
      submit-label="Okay, got it!"
      submit-wide
      @submit="submit"
    />
  </BaseModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import BaseDuoIcon from '~/components/BaseDuoIcon';
import BaseModal from '~/components/BaseModal';
import Form from '~/components/Form';
import Tip from '~/components/Tip';

export default {
  name: 'SubmitHelperModal',
  components: {
    BaseDuoIcon,
    BaseModal,
    Form,
    Tip,
  },
  computed: {
    ...mapGetters('ui', ['showSubmitHelperModal']),
    variables() {
      return {
        id: this.willId,
      };
    },
  },
  methods: {
    ...mapActions('ui', ['setShowSubmitHelperModal']),
    close() {
      this.setShowSubmitHelperModal(false);
    },
    submit() {
      this.close();
    },
  },
};
</script>
