<template>
  <svg
    class="overflow-visible"
    width="39"
    height="7"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g ref="group" class="fill-current">
      <circle cx="3.5" cy="3.5" r="3.5" />
      <circle cx="3.5" cy="3.5" r="3.5" />
      <circle cx="19.25" cy="3.5" r="3.5" />
      <circle cx="35" cy="3.5" r="3.5" />
    </g>
  </svg>
</template>

<script>
import anime from 'animejs';

export default {
  name: 'LoadingDots',
  props: {
    animation: {
      default: 'bounce',
      type: String,
      validator(animation) {
        return ['bounce', 'slide'].includes(animation);
      },
    },
  },
  data() {
    return {
      timeline: null,
    };
  },
  computed: {
    circles() {
      return this.$refs.group.querySelectorAll('circle');
    },
  },
  mounted() {
    this.timeline = anime.timeline({
      loop: true,
    });

    this[this.animation]();
  },
  methods: {
    bounce() {
      const delay = 100;
      const duration = 300;

      this.circles.forEach((circle, i) => {
        if (i > 0) {
          this.timeline
            .add(
              {
                targets: circle,
                opacity: [0.4, 1],
                translateY: [0, -2],
                translateZ: 0,
                duration,
                easing: 'easeInOutQuad',
              },
              i * delay
            )
            .add(
              {
                targets: circle,
                opacity: 0.4,
                translateY: 0,
                translateZ: 0,
                duration,
                easing: 'easeInOutQuad',
              },
              i * delay + duration
            );
        } else {
          anime.set(circle, {
            opacity: 0,
          });
        }
      });
    },
    slide() {
      const duration = 600;

      this.circles.forEach((circle, i) => {
        switch (i) {
          case 0:
            anime.set(circle, {
              transformOrigin: '3.5px 3.5px 0',
            });

            this.timeline.add(
              {
                targets: circle,
                scale: [0, 1],
                translateZ: 0,
                duration,
                easing: 'easeInOutQuad',
              },
              0
            );
            break;
          case this.circles.length - 1:
            anime.set(circle, {
              transformOrigin: '35px 3.5px 0',
            });

            this.timeline.add(
              {
                targets: circle,
                scale: [1, 0],
                translateZ: 0,
                duration,
                easing: 'easeInOutQuad',
              },
              0
            );
            break;
          default:
            this.timeline.add(
              {
                targets: circle,
                translateX: [0, 16],
                translateZ: 0,
                duration,
                easing: 'easeInOutQuad',
              },
              0
            );
            break;
        }
      });
    },
  },
};
</script>
