<template>
  <ModuleStep :index="3" prev-step="/executors/">
    <Form
      v-slot="{ loading }"
      :hide-submit="!isComplete"
      submit-arrow
      @submit="submit"
    >
      <FormSection
        tip="We’ll ask you to add or select people at relevant places in your Will. Their details can be edited on the People, Pets & Charities page."
      >
        <template #heading>
          Who should be your primary executor?
        </template>
        <Tip>
          Select up to 2 people over the age of 18.
        </Tip>
        <PersonSelector
          v-model="selectedPrimaryExecutors"
          :loading="loading"
          :max="2"
          :people="filteredPeople"
          type="executor"
        />
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import ModuleStep from '~/components/ModuleStep';
import PersonSelector from '~/components/PersonSelector';
import Tip from '~/components/Tip';

import { executors, people, user } from '~/mixins/apollo';
import { metaArrayToObject } from '~/utilities';

export default {
  name: 'ExecutorsPrimary',
  components: {
    Form,
    FormSection,
    ModuleStep,
    PersonSelector,
    Tip,
  },
  mixins: [executors, people, user],
  data() {
    return {
      selectedPrimaryExecutors: [],
    };
  },
  computed: {
    isComplete() {
      return !!this.selectedPrimaryExecutors.length;
    },
    filteredPeople() {
      return this.people.filter((person) => {
        const meta = metaArrayToObject(person.meta);
        const personIsAlreadySelected = this.selectedPrimaryExecutors.includes(
          person.id
        );
        return (
          personIsAlreadySelected ||
          (meta.is_over_18 && !this.isBackupExecutor(person))
        );
      });
    },
  },
  watch: {
    primaryExecutors(executors) {
      if (executors.length && !this.selectedPrimaryExecutors.length) {
        this.selectedPrimaryExecutors = this.people
          .filter((person) => {
            return executors.find(
              (executor) => person.id === executor.person.id
            );
          })
          .map((person) => person.id);
      }
    },
  },
  methods: {
    async submit() {
      await Promise.all(
        this.people.map((person) => {
          const isPrimaryExecutor = this.isPrimaryExecutor(person);
          const isSelected = this.selectedPrimaryExecutors.includes(person.id);

          if (isSelected) {
            if (!isPrimaryExecutor) {
              return this.addExecutor(person, 'primary');
            }
          } else if (isPrimaryExecutor) {
            return this.removeExecutor(person);
          }
        })
      );

      this.$router.push({
        path: '/executors/backup',
      });
    },
  },
};
</script>
