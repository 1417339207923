var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleStep',{attrs:{"index":1,"prev-step":"/dashboard","show-help":true}},[_c('Form',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"hide-submit":!_vm.isComplete,"auto-submit":!_vm.willMeta.has_assets,"submit-arrow":""},on:{"done":_vm.done},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('FormSection',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        Add notes about your assets and debts\n      ")]},proxy:true}],null,true)},[_vm._v(" "),_c('p',[_vm._v("\n        These notes are instructive only and are non binding. You can divide\n        your estate in the Estate section, or leave specific assets to\n        particular people in the Gifts section.\n      ")]),_vm._v(" "),_c('Tip',[_vm._v("\n        This section is optional and should only be used to help guide your\n        executor. If there is any information about your assets and\n        liabilities that you want your executor to know, you can add it here.\n      ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"hasAssets","options":[
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          } ]},model:{value:(_vm.willMeta.has_assets),callback:function ($$v) {_vm.$set(_vm.willMeta, "has_assets", $$v)},expression:"willMeta.has_assets"}})],1),_vm._v(" "),(_vm.willMeta.has_assets)?_c('FormSection',[_c('div',[(!_vm.selectedAssets.length)?_c('EmptyState',[_vm._v("\n          No assets added to your will yet.\n        ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.selectedAssets),function(asset,index){return _c('AssetInputs',{key:index,attrs:{"id":("asset_" + (asset.id || index)),"disabled":loading,"description":asset.meta.description,"type":asset.meta.type},on:{"description":function($event){asset.meta.description = $event},"type":function($event){asset.meta.type = $event},"add":_vm.addAssetInput,"remove":function($event){return _vm.removeAssetInput(asset)}}})})],2),_vm._v(" "),_c('div',{staticClass:"flex justify-end"},[_c('BaseButton',{staticClass:"btn-md btn-teal add-asset",attrs:{"focusable":""},on:{"click":_vm.addAssetInput}},[_vm._v("\n          + Add\n        ")])],1)]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }