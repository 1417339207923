<template>
  <nuxt-child />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Admin',
  layout: 'admin',
  mounted() {
    window.addEventListener('keydown', this.keyHandler);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyHandler);
  },
  methods: {
    ...mapActions('admin', ['toggleDebug']),
    keyHandler(e) {
      switch (e.keyCode) {
        case 68:
          if (e.altKey && e.ctrlKey && e.metaKey) {
            e.preventDefault();
            this.toggleDebug();
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
