<template>
  <div class="border-grey-400 border-b-2 border-solid border-opacity-12">
    <div class="py-6" @click="toggle">
      <button
        class="text-xl md:text-2xl lg:text-3xl mr-6 relative w-full text-left"
      >
        <slot name="title"></slot>
        <BaseIcon
          id="minus"
          :classes="
            `text-teal-200 absolute top-0 right-0 mt-1 transition-all transition-duration-100 transform ${
              collapsed ? 'rotate-90' : ''
            }`
          "
          type="button"
        />
        <BaseIcon
          id="minus"
          :classes="
            `text-teal-200 absolute top-0 right-0 mt-1 transition-all transition-duration-100 transform ${
              collapsed ? 'rotate-180' : ''
            }`
          "
          type="button"
        />
      </button>
    </div>
    <div
      class="text-charcoal-400 transition-all transition-duration-100 overflow-hidden opacity-0 h-0 text-lg"
      :class="!collapsed ? 'opacity-100 h-auto' : ''"
    >
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import BaseIcon from '~/components/BaseIcon';

export default {
  name: 'AccordionPanel',
  components: {
    BaseIcon,
  },
  data() {
    return {
      collapsed: true,
      icon: 'plus',
    };
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
      this.icon = this.collapsed ? 'plus' : 'minus';
    },
  },
};
</script>
