<template>
  <ModuleStep :index="5" :prev-step="previous" :show-help="true">
    <BaseAlert v-if="errorMessage" compact type="error">
      <p class="font-normal" v-text="errorMessage" />
    </BaseAlert>
    <Form
      v-slot="{ loading }"
      :hide-submit="!isComplete"
      submit-arrow
      @submit.prevent="onSubmit"
    >
      <FormSection>
        <template #heading>
          Who should be your
          {{ canHaveMultipleSubstitutes ? 'substitutes' : 'substitute' }}?
        </template>
        <p v-if="canHaveMultipleSubstitutes" class="mb-4">
          In your state, you can appoint up to
          {{ substitutesAllowed[userState] }} substitutes.
        </p>
        <p v-else class="mb-4">
          In your state, you can appoint one substitute.
        </p>
        <PersonSelector
          v-model="selectedSecondaryAttorneyPersonIDs"
          :loading="loading"
          :people="filteredPeople"
          :max="substitutesAllowed[userState]"
          :generic-person-selector="true"
          :identifier-requirements="identifierRequirements"
          type="attorney"
        />
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';
import { queries } from '@/modules/apollo-queries/poa-attorneys';
import { prevStep, nextStep } from '@/modules/poa-flow/medical/index';
import * as peopleQueries from '@/modules/apollo-queries/people';
import Form from '~/components/Form';
import ModuleStep from '~/components/ModuleStep';
import FormSection from '~/components/FormSection';
import PersonSelector from '~/components/PersonSelector';
import BaseAlert from '~/components/BaseAlert';

import SET_ATTORNEYS from '~/graphql/mutations/SetAttorneys';

import { will } from '~/mixins/apollo';
import { metaArrayToObject } from '~/utilities';

import { DUPLICATE_ATTORNEY_ERROR_MESSAGE } from '~/constants/power-of-attorney/error-messages';

export default {
  name: 'MedicalPOAAttorneySubstitutes',
  components: {
    Form,
    ModuleStep,
    FormSection,
    PersonSelector,
    BaseAlert,
  },
  mixins: [will],
  apollo: {
    people: {
      ...peopleQueries.queries.getPeopleOfAccount(),
      variables() {
        return {
          userId: this.userId,
        };
      },
    },
    attorneys: {
      ...queries.getAttorneys({ power: 'MEDICAL' }),
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
  },
  data() {
    return {
      attorneys: undefined,
      selectedSecondaryAttorneyPersonIDs: [],
      substitutesAllowed: {
        act: 4,
        nsw: 1,
        nt: 1,
        qld: 1,
        tas: 1,
        vic: 1,
        wa: 1,
      },
      errorMessage: null,
    };
  },
  computed: {
    ...mapGetters(['poaId', 'userId']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
        addressState: this.userState,
        numPrimaryAttorneysSelected: this.primaryAttorneyPeopleIDs.length,
      });
    },
    canHaveMultipleSubstitutes() {
      return this.substitutesAllowed[this.userState] > 1;
    },
    isComplete() {
      return this.selectedSecondaryAttorneyPersonIDs.length > 0;
    },
    filteredPeople() {
      return (
        this.people &&
        this.people.filter((person) => {
          const meta = metaArrayToObject(person.meta);
          if (!this.primaryAttorneyPeopleIDs) return false;
          const personIsAlreadySecondaryMedicalAttorney = this.selectedSecondaryAttorneyPersonIDs.includes(
            person.id
          );
          return (
            personIsAlreadySecondaryMedicalAttorney ||
            (meta.is_over_18 &&
              meta.identifier === 'address' &&
              !this.primaryAttorneyPeopleIDs?.includes(person.id))
          );
        })
      );
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    identifierRequirements() {
      return ['address', ...(this.userState === 'vic' ? ['dob'] : [])];
    },
    primaryAttorneyPeopleIDs() {
      return this.attorneys
        ?.filter((attorney) => attorney.role === 'PRIMARY')
        ?.map((attorney) => attorney.person.id);
    },
    oldSecondaryAttorneys() {
      return this.attorneys?.filter(
        (attorney) => attorney.role === 'SECONDARY'
      );
    },
  },
  watch: {
    attorneys(attorneys) {
      if (attorneys.length && !this.selectedSecondaryAttorneyPersonIDs.length) {
        this.selectedSecondaryAttorneyPersonIDs = attorneys
          .filter((attorney) => attorney.role === 'SECONDARY')
          .map((attorney) => attorney.person.id);
      }
    },
  },
  methods: {
    async onSubmit() {
      this.errorMessage = '';
      try {
        await this.$apollo.mutate({
          mutation: SET_ATTORNEYS,
          variables: {
            personIds: this.selectedSecondaryAttorneyPersonIDs,
            power: 'MEDICAL',
            role: 'SECONDARY',
            poaId: this.poaId,
          },
        });
        await this.$apollo.queries.attorneys.refetch();
      } catch (error) {
        await this.$apollo.queries.people.refetch();
        await this.$apollo.queries.attorneys.refetch();
        this.selectedSecondaryAttorneyPersonIDs = [];
        this.$nuxt.$emit('unlockForm');
        this.errorMessage = DUPLICATE_ATTORNEY_ERROR_MESSAGE;
        return;
      }

      const path = this.next;
      this.$router.push({ path });
    },
  },
  head() {
    return {
      title: 'Substitute medical decision maker',
    };
  },
};
</script>
