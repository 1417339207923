<template>
  <ModuleStep :index="2" prev-step="/guardians/your-children">
    <Form
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      :auto-submit="!willMeta.has_primary_guardian"
      submit-arrow
      @done="submit"
    >
      <FormSection class="step-1">
        <template #heading>
          <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
          Would you like to appoint a
          <GlossaryTooltip term="Guardian">
            guardian
          </GlossaryTooltip>
          for your children?
        </template>
        <p>
          A guardian is the person who becomes responsible for your child if you
          were to pass away.
        </p>
        <Tip>
          Normally, the surviving parent will be the legal guardian of their
          child. If both parents die, and no guardian has been nominated in a
          Will, there may be disagreement as to who looks after the children and
          this may need to be settled in Court.
        </Tip>
        <LargeRadioButtons
          id="hasPrimaryGuardians"
          v-model="willMeta.has_primary_guardian"
          :options="[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]"
        />
      </FormSection>
      <FormSection
        v-if="willMeta.has_primary_guardian"
        class="step-2"
        tip="We’ll ask you to add or select people at relevant places in your Will. Their details can be edited on the People, Pets & Charities page."
      >
        <template #heading>
          Who should be the primary guardian?
        </template>
        <p>
          Your primary guardian will take responsibility for your underage child
          if all others with parental responsibility are unable to.
        </p>
        <Tip>
          You shouldn’t appoint the child’s other parent, as they would
          typically already have parental responsibility.
          <br />
          <br />
          <ExternalLink
            href="https://safewill.com/blog/how-do-i-choose-a-guardian"
          >
            How do I choose a guardian?
          </ExternalLink>
        </Tip>
        <PersonSelector
          v-model="selectedPrimaryGuardian"
          :loading="loading"
          :max="1"
          :people="filteredPeople"
          type="guardian"
        />
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapActions } from 'vuex';

import ExternalLink from '~/components/ExternalLink';
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import GlossaryTooltip from '~/components/GlossaryTooltip';
import LargeRadioButtons from '~/components/LargeRadioButtons';
import ModuleStep from '~/components/ModuleStep';
import PersonSelector from '~/components/PersonSelector';
import Tip from '~/components/Tip';

import { guardians, people, user, will } from '~/mixins/apollo';
import { metaArrayToObject } from '~/utilities';

export default {
  name: 'GuardiansPrimary',
  components: {
    ExternalLink,
    Form,
    FormSection,
    GlossaryTooltip,
    LargeRadioButtons,
    ModuleStep,
    PersonSelector,
    Tip,
  },
  mixins: [guardians, people, user, will],
  data() {
    return {
      selectedPrimaryGuardian: [],
    };
  },
  computed: {
    isComplete() {
      return !!(
        this.willMeta.has_primary_guardian === false ||
        this.selectedPrimaryGuardian.length
      );
    },
    filteredPeople() {
      return this.people.filter((person) => {
        const meta = metaArrayToObject(person.meta);
        const personIsAlreadySelected = this.selectedPrimaryGuardian.includes(
          person.id
        );
        return (
          personIsAlreadySelected ||
          (meta.is_over_18 && !this.isBackupGuardian(person))
        );
      });
    },
    nextStep() {
      return this.willMeta.has_primary_guardian
        ? '/guardians/backup'
        : '/guardians/your-pets';
    },
    variables() {
      return {
        id: this.willId,
        meta: this.willMeta.toArray(),
      };
    },
  },
  watch: {
    primaryGuardians(guardians) {
      if (guardians && !this.selectedPrimaryGuardian.length) {
        this.selectedPrimaryGuardian = this.people
          .filter((person) => {
            return guardians.find(
              (guardian) => guardian.person.id === person.id
            );
          })
          .map((person) => person.id);
      }
    },
  },
  methods: {
    ...mapActions('person', ['addPerson']),
    async submit() {
      for (const person of this.people) {
        const isPrimaryGuardian = this.isPrimaryGuardian(person);
        const isSelected = this.selectedPrimaryGuardian.includes(person.id);

        if (this.willMeta.has_primary_guardian && isSelected) {
          if (!isPrimaryGuardian) {
            await this.addGuardian(person, 'primary');
          }
        } else if (isPrimaryGuardian) {
          await this.removeGuardian(person);
        }
      }

      this.$router.push({
        path: this.nextStep,
      });
    },
  },
};
</script>
