<template>
  <Module title="Guardians" :total-steps="6">
    <nuxt-child />
  </Module>
</template>

<script>
import Module from '~/components/Module';

export default {
  name: 'Guardians',
  layout: 'module',
  middleware: ['authenticated'],
  components: {
    Module,
  },
  fetch({ redirect, route }) {
    if (route.path === '/guardians') redirect(301, '/guardians/your-children');
  },
};
</script>
