<template>
  <div class="w-full rounded bg-white" :class="classes.padding">
    <div class="flex items-start mb-4 last-child:mb-0" :class="classes.wrapper">
      <div v-if="icon" class="mb-2 md:mb-0 md:mr-5 text-teal-300">
        <BaseIcon :id="icon" />
      </div>
      <div v-if="showMiddleColumn" class="leading-none mr-2 flex-grow">
        <div
          v-if="logo"
          class="flex items-center"
          :class="[primaryText && !small ? 'mb-3' : '', classes.logoMin]"
        >
          <Tooltip
            v-if="tooltipText"
            :content="tooltipText"
            class="flex justify-center w-6 md:w-8 mr-2 md:mr-0 md:-ml-8"
          >
            <BaseIcon
              id="info-alt"
              classes="w-4 h-4 text-charcoal-400 opacity-50 hover:opacity-100"
            />
          </Tooltip>
          <img class="flex-shrink-10" :class="classes.logoMax" :src="logo" />
        </div>
        <div
          v-if="badge"
          class="inline-flex justify-center items-center flex-shrink-0 h-6  mb-6 rounded-full text-white text-sm text-white leading-none font-medium uppercase px-4"
          :class="badgeColor"
          v-text="badge"
        />
        <h4
          v-if="primaryText"
          class="leading-tight text-slate"
          :class="classes.nameSize"
          v-text="primaryText"
        />
        <h5
          v-if="secondaryText"
          class="opacity-50 text-lg text-black leading-normal"
          v-text="secondaryText"
        />
      </div>
      <div class="flex items-center" :class="classes.actions">
        <slot name="actions" />
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import BaseIcon from '~/components/BaseIcon';
import Tooltip from '~/components/Tooltip';

export default {
  name: 'BaseCard',
  components: {
    BaseIcon,
    Tooltip,
  },
  props: {
    icon: {
      default: null,
      type: String,
    },
    inline: {
      default: false,
      type: Boolean,
    },
    logo: {
      default: null,
      type: String,
    },
    primaryText: {
      default: null,
      type: String,
    },
    badge: {
      default: null,
      type: String,
    },
    badgeColor: {
      default: null,
      type: String,
    },
    secondaryText: {
      default: null,
      type: String,
    },
    tooltipText: {
      default: null,
      type: String,
    },
    small: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    classes() {
      return {
        padding: {
          'p-6 md:p-8': !this.small,
          'p-3 md:p-5': this.small,
        },
        nameSize: {
          'mb-2 text-2xl': !this.small,
          'mb-1 text-base': this.small,
        },
        logoMax: {
          'max-h-logo-small': this.small,
          'max-h-logo border-1 border-black': !this.small,
        },
        logoMin: {
          'min-h-logo border-1 border-black': this.small,
          'min-h-logo-small': !this.small,
        },
        actions: {
          'ml-2 md:ml-0': this.inline,
          'mt-2 md:mt-0': !this.inline,
          'min-h-logo': this.logo,
        },
        wrapper: {
          flex: this.inline,
          'md:flex': !this.inline,
        },
      };
    },
    showMiddleColumn() {
      return this.logo || this.badge || this.primaryText || this.secondaryText;
    },
  },
};
</script>
