<template>
  <ModuleStep :index="7" prev-step="/about-yourself/partner">
    <Form
      v-if="willMeta"
      v-slot="{ loading }"
      auto-focus
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :submit-label="btnLabel"
      submit-arrow
      @done="onDone"
    >
      <FormSection>
        <template #heading> What's your phone number? </template>
        <Tip>
          This is an optional security feature. We'll only call you if you are
          locked out of your account, or to help you with your Will.
        </Tip>
        <FormRow>
          <TextInput
            id="phone"
            v-model="willMeta.phone_number"
            :disabled="loading"
            placeholder="Your phone number"
            type="tel"
            rules="phone"
          />
        </FormRow>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import Form from '~/components/Form';
import FormRow from '~/components/FormRow';
import FormSection from '~/components/FormSection';
import ModuleStep from '~/components/ModuleStep';
import TextInput from '~/components/TextInput';
import Tip from '~/components/Tip';

import { user, will } from '~/mixins/apollo';

export default {
  name: 'AboutYourselfPhone',
  components: {
    Form,
    FormRow,
    FormSection,
    ModuleStep,
    TextInput,
    Tip,
  },
  mixins: [user, will],
  computed: {
    btnLabel() {
      return this.willMeta.phone_number ? 'Next' : 'Skip';
    },
  },
  methods: {
    onDone() {
      if (this.willMeta.phone_number) {
        this.$nuxt.$emit('sendTrackingAttributes', {
          phone: `${this.willMeta.phone_number}`,
        });
      }

      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✅ Module 1 (About Yourself)',
      });

      this.$router.push({
        path: '/dashboard',
      });
    },
  },
};
</script>
