<template>
  <ModuleStep :index="5" prev-step="/get-started/name">
    <Form
      v-slot="{ loading }"
      auto-focus
      :hide-submit="!isComplete"
      submit-arrow
      @submit="done"
    >
      <FormSection>
        <template #heading> What's your email address? </template>
        <Tip>
          A valid email address is required to log in and submit, update, or
          download your Will.
        </Tip>
        <FormRow>
          <TextInput
            id="email"
            v-model="email"
            :disabled="loading"
            placeholder="Email Address"
            rules="required|email"
            type="email"
          />
        </FormRow>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapFields } from 'vuex-map-fields';

import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import FormRow from '~/components/FormRow';
import ModuleStep from '~/components/ModuleStep';
import TextInput from '~/components/TextInput';
import Tip from '~/components/Tip';
import { user } from '~/mixins/apollo';

export default {
  name: 'GetStartedEmail',
  components: {
    Form,
    FormSection,
    FormRow,
    ModuleStep,
    TextInput,
    Tip,
  },
  mixins: [user],
  computed: {
    ...mapFields('signup', ['email']),
    isComplete() {
      return !!this.email;
    },
  },
  methods: {
    done() {
      this.$nuxt.$emit('sendTrackingAttributes', {
        email: this.email,
      });

      this.$router.push({
        path: '/get-started/password',
        query: this.$route.query,
      });
    },
  },
};
</script>
