<template>
  <BasePanel
    v-if="showPersonPanel"
    :title="title"
    class="person-panel"
    @close="close"
  >
    <Form
      v-slot="{ loading }"
      auto-focus
      :disabled="!!ageError || !isComplete"
      :mutation="mutation"
      :variables="variables"
      :submit-label="buttonText"
      @update="update"
      @done="close"
    >
      <FormSection>
        <FormRow>
          <TextInput
            id="person_full_name"
            v-model="full_name"
            :disabled="loading"
            label="Full Name"
            placeholder="Their full name"
          />
        </FormRow>
        <FormRow v-if="!hasRequirements">
          <div class="invisible md:visible absolute top-0 right-0 mt-4">
            <Tooltip
              content="This is used by your executor to identify people in your will."
            >
              <div
                class="flex justify-center items-center w-5 h-5 rounded-full bg-grey-500 text-grey-200"
              >
                <BaseGlyph id="question-mark" />
              </div>
            </Tooltip>
          </div>
          <label class="input-label" :for="identifier">
            Identifying Information
          </label>
        </FormRow>
        <FormRow v-if="!hasRequirements">
          <LargeRadioButtons
            id="identifier"
            v-model="identifier"
            horizontal
            :options="[
              {
                label: 'Email',
                value: 'email',
              },
              {
                label: 'Date of Birth',
                value: 'dob',
              },
              {
                label: 'Address',
                value: 'address',
              },
            ]"
          />
        </FormRow>
        <FormRow v-if="shownIdentifierFields.includes('email')">
          <TextInput
            id="person_email"
            v-model="email"
            :disabled="loading"
            label="Email Address"
            rules="required|email"
            placeholder="e.g. person@example.com"
          />
        </FormRow>
        <FormRow v-if="shownIdentifierFields.includes('dob')">
          <DateInput
            id="person_date_of_birth"
            v-model="date_of_birth"
            :disabled="loading"
            label="Date of Birth"
            rules="required|date"
          />
        </FormRow>
        <FormRow v-if="ageError && shownIdentifierFields.includes('dob')">
          <p class="mt-1 text-red-300 truncate" v-text="ageError" />
        </FormRow>
        <FormRow v-if="shownIdentifierFields.includes('address')">
          <div
            v-if="showAddressHelper"
            class="absolute top-0 right-0 mt-3 md:mt-4 z-10"
          >
            <BaseButton
              class="text-teal-200 underline use-mine"
              focusable
              @click="prefillAddressFields"
            >
              Use my address
            </BaseButton>
          </div>
          <TextInput
            id="person_address_street"
            v-model="address_street"
            :disabled="loading"
            label="Street Address"
            placeholder="e.g. 12 Cleveland St"
          />
        </FormRow>
        <FormRow v-if="shownIdentifierFields.includes('address')">
          <TextInput
            id="person_address_suburb"
            v-model="address_suburb"
            :disabled="loading"
            label="Suburb"
            rules="required"
          />
        </FormRow>
        <FormRow v-if="shownIdentifierFields.includes('address')">
          <SelectInput
            v-if="address_country === 'Australia'"
            id="person_address_state"
            v-model="address_state"
            class="md:w-1/2"
            :disabled="loading"
            label="State"
            :options="australianStates"
            required
            searchable
          />
          <TextInput
            v-else
            id="person_address_state"
            v-model="address_state"
            class="md:w-1/2"
            :disabled="loading"
            label="State"
            :rules="address_country === 'Australia' ? 'required' : ''"
          />
          <TextInput
            id="person_address_postcode"
            v-model="address_postcode"
            class="md:w-1/2 md:ml-2"
            :disabled="loading"
            label="Postcode"
            :rules="address_country === 'Australia' ? 'required' : ''"
          />
        </FormRow>
        <FormRow v-if="shownIdentifierFields.includes('address')">
          <Tooltip class="w-full" :content="countryTooltip">
            <SelectInput
              id="person_address_country"
              v-model="address_country"
              :disabled="loading || countryDisabled"
              label="Country"
              placeholder="Select a country"
              :options="countries"
              searchable
            />
          </Tooltip>
        </FormRow>
        <FormRow v-if="!shownIdentifierFields.includes('email')">
          <TextInput
            id="person_email"
            v-model="email"
            :disabled="loading"
            label="Email Address"
            rules="email"
            placeholder="Their email address (optional)"
          />
        </FormRow>
        <FormRow v-if="!shownIdentifierFields.includes('dob')">
          <BaseRadio
            id="person_is_over_18"
            v-model="is_over_18"
            large
            class="mt-6"
            label="Is this person over 18?"
            :options="[
              {
                label: 'Under 18',
                value: false,
              },
              {
                label: 'Over 18',
                value: true,
              },
            ]"
            :error="ageError"
          />
        </FormRow>
      </FormSection>
    </Form>
    <Tip>
      {{ personTip }}
    </Tip>
  </BasePanel>
</template>

<script>
import upperFirst from 'lodash/upperFirst';
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import BaseRadio from '~/components/BaseRadio';
import LargeRadioButtons from '~/components/LargeRadioButtons';
import BaseButton from '~/components/BaseButton';
import BasePanel from '~/components/BasePanel';
import BaseGlyph from '~/components/BaseGlyph';
import DateInput from '~/components/DateInput';
import Form from '~/components/Form';
import FormRow from '~/components/FormRow';
import FormSection from '~/components/FormSection';
import SelectInput from '~/components/SelectInput';
import TextInput from '~/components/TextInput';
import Tip from '~/components/Tip';
import Tooltip from '~/components/Tooltip';

import { people, user, will } from '~/mixins/apollo';
import { objectToMetaArray, age, date } from '~/utilities';

export default {
  name: 'PersonPanel',
  components: {
    BaseRadio,
    BaseGlyph,
    BaseButton,
    BasePanel,
    Form,
    FormRow,
    FormSection,
    SelectInput,
    TextInput,
    DateInput,
    Tip,
    Tooltip,
    LargeRadioButtons,
  },
  mixins: [people, user, will],
  data() {
    return {};
  },
  computed: {
    ...mapFields('person', [
      'fields.full_name',
      'fields.address_street',
      'fields.address_suburb',
      'fields.address_state',
      'fields.address_postcode',
      'fields.address_country',
      'fields.date_of_birth',
      'fields.email',
      'fields.is_over_18',
      'fields.identifier',
    ]),
    ...mapGetters('person', [
      'id',
      'type',
      'identifierRequirements',
      'fields',
      'showPersonPanel',
    ]),
    ...mapGetters('ui', ['australianStates', 'countries']),
    personTip() {
      if (this.type === 'attorney') {
        return 'Rest assured we won’t contact anyone without your permission. The information you provide here is included in the documentation to help identify this person.';
      }

      return 'Rest assured we won’t contact anyone without your permission. The information you provide here is to help the executors identify this person.';
    },
    countryTooltip() {
      if (this.type === 'attorney') {
        return 'It is recommended that you appoint an attorney in Australia, as it can be difficult to deal with assets and communicate with banks and other institutions from overseas';
      }

      return 'Keep in mind that an executor has a number of responsibilities such as organising a funeral and obtaining probate that can be difficult to do if they’re located overseas.';
    },
    isComplete() {
      const fulfilsEmailRequirement = this.shownIdentifierFields.includes(
        'email'
      )
        ? !!this.email
        : true;

      const fulfilsAddressRequirement = this.shownIdentifierFields.includes(
        'address'
      )
        ? !!this.address_street
        : true;

      const fulfilsDobRequirement = this.shownIdentifierFields.includes('dob')
        ? !!this.date_of_birth
        : true;

      return (
        this.full_name &&
        fulfilsEmailRequirement &&
        fulfilsAddressRequirement &&
        fulfilsDobRequirement
      );
    },
    ageError() {
      return ['executor', 'guardian', 'attorney'].includes(this.type) &&
        !this.is_over_18
        ? `${this.capitalisedType} must be over 18 years of age.`
        : '';
    },
    buttonText() {
      return this.id ? 'Update Person' : `Save ${this.capitalisedType}`;
    },
    capitalisedType() {
      return upperFirst(this.type);
    },
    countryDisabled() {
      // return this.type === 'executor';
      return false;
    },
    mutation() {
      return this.id ? this.UPDATE_PERSON_MUTATION : this.ADD_PERSON_MUTATION;
    },
    showAddressHelper() {
      return (
        this.shownIdentifierFields.includes('address') &&
        ['child', 'partner'].includes(this.type)
      );
    },
    title() {
      return this.id ? 'Editing person' : `Adding a new ${this.type}`;
    },
    variables() {
      const variables = {
        meta: objectToMetaArray(this.fields),
      };
      if (this.id) {
        variables.id = this.id;
      } else {
        variables.willId = this.willId;
        variables.category = 'none';
      }
      return variables;
    },
    hasRequirements() {
      return this.identifierRequirements !== null;
    },
    shownIdentifierFields() {
      if (this.hasRequirements) {
        return this.identifierRequirements;
      }
      return this.identifier || 'address';
    },
  },
  watch: {
    address_country() {
      this.address_state = '';
    },
    date_of_birth(newValue) {
      if (!date(newValue)) return;
      this.is_over_18 = age(newValue) >= 18;
    },
    hasRequirements(newValue) {
      if (this.hasRequirements) {
        this.identifier = this.identifierRequirements[0];
      } else {
        this.identifier = 'address';
      }
    },
  },
  methods: {
    ...mapActions('person', [
      'setShowPersonPanel',
      'setIdentifierRequirements',
    ]),
    close() {
      this.setShowPersonPanel(false);
      this.setIdentifierRequirements(null);
    },
    altText(alt) {
      return alt === 'dob' ? 'date of birth' : alt;
    },
    prefillAddressFields() {
      this.address_street = this.willMeta.address_street;
      this.address_suburb = this.willMeta.address_suburb;
      this.address_state = this.willMeta.address_state;
      this.address_postcode = this.willMeta.address_postcode;
    },
    update(store, { data: { addPerson, updatePerson } }) {
      if (addPerson) {
        const data = store.readQuery(this.getPeopleQuery);
        data.getPeople.people.push(addPerson.person);
        store.writeQuery({
          ...this.getPeopleQuery,
          data,
        });
        this.$nuxt.$emit('addPerson', addPerson.person);
      }
    },
  },
};
</script>
