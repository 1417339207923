<template>
  <ModuleStep :index="3" prev-step="/get-started/resident">
    <Form
      :hide-submit="!isComplete"
      :auto-submit="true"
      submit-arrow
      @submit="done"
    >
      <FormSection>
        <template #heading>Are you over 18?</template>
        <Tip>Safewill can only be used by adults over the age of 18.</Tip>
        <LargeRadioButtons
          id="is_adult"
          v-model="is_adult"
          :options="[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]"
        />
      </FormSection>
      <FormSection v-if="is_adult === false" class="problem">
        <template #heading>We've got a problem!</template>
        <p>
          Unfortunately, Safewill is currently not available for anyone living
          outside Australia or under the age of 18.
        </p>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapFields } from 'vuex-map-fields';

import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import LargeRadioButtons from '~/components/LargeRadioButtons';
import ModuleStep from '~/components/ModuleStep';
import Tip from '~/components/Tip';

export default {
  name: 'GetStartedAge',
  components: {
    Form,
    FormSection,
    LargeRadioButtons,
    ModuleStep,
    Tip,
  },
  data() {
    return {
      nextStepPath: '/get-started/name',
    };
  },
  computed: {
    ...mapFields('signup', ['meta.is_adult']),
    isComplete() {
      return !!this.is_adult;
    },
  },
  methods: {
    done() {
      const path = this.nextStepPath;
      this.$router.push({
        path,
        query: this.$route.query,
      });
    },
  },
};
</script>
