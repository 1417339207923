<template>
  <span
    v-tooltip="disabled ? null : options"
    class="leading-none"
    :class="classes"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    content: {
      type: String,
      required: true,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    placement: {
      default: null,
      type: String,
    },
  },
  computed: {
    classes() {
      return {
        'cursor-help': !this.disabled,
      };
    },
    options() {
      const options = {
        classes: [
          'max-w-md',
          'px-4',
          'py-3',
          'mb-4',
          'rounded',
          'bg-black',
          'shadow',
          'font-medium',
          'text-center',
          'text-white',
        ],
        content: this.content,
        hideOnTargetClick: false,
        popperOptions: this.popperOptions,
      };

      if (this.placement) {
        options.placement = this.placement;
      }

      return options;
    },
    popperOptions() {
      return {
        modifiers: {
          computeStyle: {
            gpuAcceleration: false,
          },
        },
      };
    },
  },
};
</script>
