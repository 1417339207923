<template>
  <div>
    <div v-if="!hidden" class="flex justify-end">
      <BaseButton
        class="text-teal-200 underline hover:no-underline"
        @click="toggle"
      >
        <div class="flex items-center">
          <span class="mr-2">
            Have a code?
          </span>
          <BaseGlyph :id="collapsed ? 'angle-down' : 'angle-up'" />
        </div>
      </BaseButton>
    </div>
    <div v-if="!collapsed && !hidden" class="relative mt-4">
      <div class="relative">
        <BaseInput
          id="coupon_code"
          v-model="modelValue"
          classes="pr-32 uppercase"
          :disabled="loading"
          name="coupon_code"
          placeholder="Your code"
          :value="value"
          @focus="error = null"
          @input="$emit('input', modelValue)"
          @keydown.enter.prevent="$nuxt.$emit('applyCoupon', modelValue)"
        />
        <div class="absolute top-0 right-0 bottom-0 flex items-center pr-6">
          <BaseButton
            class="btn btn-md btn-teal"
            :loading="loading"
            @click="$nuxt.$emit('applyCoupon', modelValue)"
          >
            Apply
          </BaseButton>
        </div>
      </div>
    </div>
    <p v-if="error" class="mt-2 text-red-300 truncate" v-text="error" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import GET_PRICE_QUERY from '~/graphql/queries/GetPrice';

import BaseButton from '~/components/BaseButton';
import BaseGlyph from '~/components/BaseGlyph';
import BaseInput from '~/components/BaseInput';
import { formatError } from '~/utilities';

export default {
  name: 'CouponInput',
  components: {
    BaseButton,
    BaseGlyph,
    BaseInput,
  },
  props: {
    hidden: {
      default: false,
      type: Boolean,
    },
    value: {
      default: '',
      type: String,
    },
    willProduct: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      collapsed: true,
      error: null,
      loading: false,
      modelValue: this.value,
    };
  },
  computed: {
    ...mapGetters(['willId', 'willStatus']),
    ...mapGetters('checkout', [
      'couponCode',
      'defaultSetupPrice',
      'checkoutAction',
    ]),
  },
  watch: {
    value(newValue) {
      this.modelValue = newValue;
    },
  },
  mounted() {
    this.$nuxt.$on('toggleCouponInputLoader', (value) => {
      this.error = null;
      this.loading = value;
    });
    this.$nuxt.$on('applyCoupon', async (code) => {
      this.error = null;
      this.loading = true;

      try {
        const { data } = await this.$apollo.query({
          query: GET_PRICE_QUERY,
          variables: {
            code,
            willId: this.willId,
            products: [this.willProduct],
          },
        });
        if (data.getPrice && data.getPrice.coupon) {
          const { coupon, setup, plan } = data.getPrice;
          if (coupon.error) {
            this.error = coupon.error;
          } else if (!coupon.active) {
            this.error = 'Code expired';
          } else {
            this.setCouponCode(coupon.code);

            const price =
              this.checkoutAction === 'submit'
                ? setup.finalPrice
                : plan.finalPrice;
            this.setFinalSetupPrice(parseInt(price, 10) / 100);
          }
        } else if (data.getPrice && data.getPrice.invite) {
          const { invite, setup } = data.getPrice;
          this.setCouponCode(invite.code);
          this.setFinalSetupPrice(parseInt(setup.finalPrice, 10) / 100);
        } else if (data.getPrice && !code) {
          const { setup, plan } = data.getPrice;
          const expiredSubscription =
            this.willStatus === 'APPROVED' && this.hasExpiredSubscription;

          this.setCouponCode(null);
          const price =
            this.checkoutAction === 'submit'
              ? expiredSubscription
                ? plan.basePrice
                : setup.finalPrice
              : plan.finalPrice;
          this.setFinalSetupPrice(parseInt(price, 10) / 100);
        } else {
          this.error = 'Invalid code';
        }
      } catch (err) {
        this.error = formatError(err.message);
      } finally {
        if (!this.error) {
          this.collapsed = true;
          this.modelValue = '';
        }
        this.loading = false;
      }
    });
  },
  methods: {
    ...mapActions('checkout', ['setCouponCode', 'setFinalSetupPrice']),
    toggle() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>
