<template>
  <div>
    <div v-if="!hidden" class="flex justify-end">
      <BaseButton
        class="text-teal-200 underline hover:no-underline"
        @click="toggle"
      >
        <div class="flex items-center">
          <span class="mr-2">
            Have a code?
          </span>
          <BaseGlyph :id="collapsed ? 'angle-down' : 'angle-up'" />
        </div>
      </BaseButton>
    </div>
    <div v-if="!collapsed && !hidden" class="relative mt-4">
      <div class="relative">
        <BaseInput
          id="coupon_code"
          v-model="modelValue"
          classes="pr-32 uppercase"
          :disabled="loading"
          name="coupon_code"
          placeholder="Your code"
          :value="value"
          @focus="error = null"
          @input="$emit('input', modelValue)"
          @keydown.enter.prevent="$emit('applyCoupon', modelValue)"
        />
        <div class="absolute top-0 right-0 bottom-0 flex items-center pr-6">
          <BaseButton
            class="btn btn-md btn-teal"
            :loading="loading"
            @click="$emit('applyCoupon', modelValue)"
          >
            Apply
          </BaseButton>
        </div>
      </div>
    </div>
    <p v-if="error" class="mt-2 text-red-300 truncate" v-text="error" />
  </div>
</template>

<script>
import BaseButton from '~/components/BaseButton';
import BaseGlyph from '~/components/BaseGlyph';
import BaseInput from '~/components/BaseInput';

export default {
  name: 'GenericCouponInput',
  components: {
    BaseButton,
    BaseGlyph,
    BaseInput,
  },
  props: {
    hidden: {
      default: false,
      type: Boolean,
    },
    value: {
      default: '',
      type: String,
    },
    error: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collapsed: true,
      modelValue: this.value,
    };
  },
  computed: {},
  watch: {
    value(newValue) {
      this.modelValue = newValue;
    },
  },
  mounted() {
    if (!this.error) {
      this.collapsed = true;
      this.modelValue = '';
    }
    this.loading = false;
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>
