<template>
  <div v-if="!hide">
    <label class="input-label" :for="executor.id">
      {{ meta.full_name }}
    </label>
    <div class="relative">
      <ValidationObserver
        ref="observer"
        tag="form"
        class="flex w-full"
        @submit.prevent="submit"
      >
        <TextInput
          :id="executor.id"
          v-model="email"
          class="w-full mr-4"
          :disabled="loading"
          name="invite"
          rules="required|email"
          placeholder="name@example.com"
          @focus="error = null"
        />
        <BaseButton
          class="btn btn-xl btn-slate text-white"
          :loading="loading"
          @click="submit"
        >
          Send
        </BaseButton>
      </ValidationObserver>
    </div>
    <p v-if="error" class="mt-2 text-red-300 truncate" v-text="error" />
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

import TextInput from '~/components/TextInput';
import BaseButton from '~/components/BaseButton';
import { executors, user } from '~/mixins/apollo';
import { formatError } from '~/utilities';

import NOTIFY_EXECUTOR_MUTATION from '~/graphql/mutations/NotifyExecutor';

export default {
  name: 'ExecutorNotify',
  components: {
    ValidationObserver,
    TextInput,
    BaseButton,
  },
  mixins: [executors, user],
  props: {
    prefill: {
      type: String,
      default: '',
    },
    executor: {
      type: Object,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      email: this.prefill,
      error: null,
      loading: false,
      done: false,
    };
  },
  computed: {
    variables() {
      return {
        email: this.email,
      };
    },
    hide() {
      return this.prefill && this.done;
    },
  },
  watch: {
    prefill() {
      this.email = this.prefill;
    },
  },
  methods: {
    async submit($event, mutate) {
      const isValid =
        this.$refs.observer && (await this.$refs.observer.validate());

      if (isValid) {
        this.loading = true;

        try {
          await this.$apollo.mutate({
            mutation: NOTIFY_EXECUTOR_MUTATION,
            variables: {
              id: this.executor.id,
              email: this.email,
              willId: this.willId,
            },
            refetchQueries: [this.getExecutorsQuery],
          });

          this.$nuxt.$emit('sendTrackingEvent', {
            event: '🔔 Notified Executor',
            props: { executor_email: this.email },
          });

          this.$nuxt.$emit('resetInputs', this.id);
          this.$refs.observer.reset();
          this.error = null;
          this.done = true;
        } catch (error) {
          this.$nuxt.$emit('done');
          this.error = formatError(error.message);
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>
