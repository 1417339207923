<template>
  <div>
    <nuxt-child />
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  layout: 'dashboard',
  middleware: ['authenticated'],
};
</script>
