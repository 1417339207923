<template>
  <nuxt-link
    :class="
      `flex items-center cursor-pointer whitespace-no-wrap hover:text-charcoal-500 transition-all ease-in-out group ${linkClass} ${activeLinkClass}`
    "
    :to="to"
    @click.native="$emit('click')"
  >
    <BaseIcon
      v-if="icon"
      :id="icon"
      :classes="
        `w-5 h-5 mr-2 transition-all ease-in-out ${iconClass} ${activeIconClass}`
      "
    />
    {{ text }}
    <slot />
  </nuxt-link>
</template>

<script>
import BaseIcon from '~/components/BaseIcon';
export default {
  name: 'MenuItem',
  components: {
    BaseIcon,
  },
  props: {
    to: {
      default: null,
      type: String,
    },
    icon: {
      default: null,
      type: String,
    },
    text: {
      default: null,
      type: String,
    },
    vertical: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    active() {
      return this.$route.path === this.to;
    },
    linkClass() {
      return this.vertical
        ? 'mx-1 my-px first-child:mt-1 last-child:mb-1 py-2 pl-4 pr-8 hover:bg-teal-100 rounded-sm'
        : 'p-1 mr-2 xl:mr-5 last-child:mr-0';
    },
    activeLinkClass() {
      return this.active
        ? 'text-charcoal-500 pointer-events-none'
        : 'text-grey-700';
    },
    activeIconClass() {
      return this.active
        ? 'text-green-300'
        : 'text-grey-600 group-hover:text-charcoal-500';
    },
    iconClass() {
      return this.icon === 'logout' ? 'text-red-500' : '';
    },
  },
};
</script>
