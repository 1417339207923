<template>
  <div class="w-full min-h-screen overflow-hidden">
    <GlobalHeader />
    <div class="container gutters py-8 md:py-20 lg:py-28 mx-auto">
      <nuxt />
    </div>
  </div>
</template>

<script>
import GlobalHeader from '~/components/GlobalHeader';
import tracking from '~/mixins/tracking';

export default {
  name: 'DefaultLayout',
  components: {
    GlobalHeader,
  },
  mixins: [tracking],
};
</script>
