<template>
  <AuthPanel>
    <template #heading> Reset your <br />password </template>
    <template #form>
      <Form
        v-slot="{ loading }"
        :mutation="isSent ? null : FORGOT_PASSWORD_MUTATION"
        :variables="variables"
        submit-arrow
        :submit-label="submitLabel"
        submit-wide
        @done="done"
        @submit="goToHomepage"
      >
        <p v-if="isSent" class="text-2xl">
          An email with instructions on how to reset your password has been sent
          to
          <span class="text-teal-200" v-text="email" />
        </p>
        <FormRow v-else>
          <TextInput
            id="email"
            v-model="email"
            :disabled="loading"
            label="Email Address"
            placeholder="you@example.com"
            rules="required|email"
            type="email"
          />
        </FormRow>
      </Form>
    </template>
  </AuthPanel>
</template>

<script>
import FORGOT_PASSWORD_MUTATION from '~/graphql/mutations/ForgotPassword';

import AuthPanel from '~/components/AuthPanel';
import Form from '~/components/Form';
import FormRow from '~/components/FormRow';
import TextInput from '~/components/TextInput';

export default {
  name: 'ForgotPassword',
  middleware: ['notAuthenticated'],
  components: {
    AuthPanel,
    Form,
    FormRow,
    TextInput,
  },
  data() {
    return {
      FORGOT_PASSWORD_MUTATION,
      email: '',
      isSent: false,
    };
  },
  computed: {
    submitLabel() {
      return this.isSent ? 'Go to homepage' : 'Reset Password';
    },
    variables() {
      return {
        email: this.email,
      };
    },
  },
  methods: {
    done({ data }) {
      this.isSent = data.forgotPassword.success;
      this.$nuxt.$emit('unlockForm');
    },
    goToHomepage() {
      window.location.href = 'https://safewill.com/';
    },
  },
};
</script>
