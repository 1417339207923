<template>
  <div>
    <label
      v-for="(option, index) in options"
      :key="index"
      class="group block border-2 border-transparent rounded mb-4 cursor-pointer select-none"
      :class="classes.label[index]"
      :for="`estate_${option.value}`"
    >
      <BaseCard
        :primary-text="option.label"
        :secondary-text="option.description"
      >
        <template #actions>
          <div
            class="relative inline-flex justify-center items-center w-12 h-12 rounded-full cursor-pointer"
            :class="classes.checkbox[index]"
          >
            <input
              :id="`estate_${option.value}`"
              v-model="selected"
              class="btn absolute top-0 left-0 w-full h-full p-0"
              name="estate"
              type="radio"
              :value="option.value"
            />
            <BaseIcon id="tick" />
          </div>
        </template>
        <BaseAlert
          v-if="option.note && isSelected[index]"
          compact
          type="confirm"
        >
          <p class="font-normal" v-text="option.note" />
        </BaseAlert>
      </BaseCard>
    </label>
  </div>
</template>

<script>
import BaseAlert from '~/components/BaseAlert';
import BaseCard from '~/components/BaseCard';
import BaseIcon from '~/components/BaseIcon';

import { people, user } from '~/mixins/apollo';

export default {
  name: 'EstateSelector',
  components: {
    BaseAlert,
    BaseCard,
    BaseIcon,
  },
  mixins: [people, user],
  props: {
    value: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    classes() {
      return {
        checkbox: (() => {
          return this.isSelected.map((selected) => {
            return {
              'bg-grey-200 group-hover:bg-teal-100 text-grey-400 group-hover:text-teal-300': !selected,
              'bg-teal-200 text-black': selected,
            };
          });
        })(),
        label: (() => {
          return this.isSelected.map((selected) => {
            return {
              'border-teal-200': selected,
            };
          });
        })(),
      };
    },
    options() {
      const options = [];

      if (this.partners.length) {
        options.push({
          label: 'My Partner',
          description: 'Leave 100% of your estate to your partner.',
          // note: this.children.length ? `If your partner dies before you, their share will go to your ${this.children.length > 1 ? 'children' : 'child'}.` : 'If your partner dies before you, their share will be given to their children if they have any, or otherwise to your next of kin.',
          value: 'partner',
        });
      }

      if (this.children.length) {
        options.push({
          label: this.children.length > 1 ? 'My Children' : 'My Child',
          description:
            this.children.length > 1
              ? 'Split your estate equally between your children.'
              : 'Leave 100% of your estate to your child.',
          // note: this.children.length > 1 ? 'If any of your children die before you their share will go to their own children if they have any, or otherwise split between the remaining beneficiaries.' : 'If your child dies before you, their share will be given to their children if they have any, or otherwise to your next of kin.',
          value: 'children',
        });
      }

      options.push({
        label: 'Other',
        description: 'Choose your beneficiaries and customise their share.',
        value: 'custom',
      });

      return options;
    },
    isSelected() {
      return this.options.map((option) => {
        return option.value === this.selected;
      });
    },
  },
  watch: {
    selected(newValue) {
      this.$emit('input', newValue);
    },
  },
  mounted() {
    this.selected = this.value;
  },
};
</script>
