<template>
  <div class="relative flex flex-col md:flex-row mb-8 md:mb-3">
    <SelectInput
      :id="`${id}_type`"
      v-model="selectModel"
      class="flex-none md:max-w-2xs mb-3 md:mb-0 md:mr-4"
      auto-width
      :disabled="disabled"
      :options="options"
      placeholder="Select type"
      @change="$emit('type', selectModel)"
    />
    <div class="flex-grow">
      <BaseInput
        :id="`${id}_description`"
        v-model="inputModel"
        :name="`${id}_description`"
        :disabled="disabled || !selectModel"
        :placeholder="placeholder"
        rules="required"
        @input="$emit('description', inputModel)"
        @keydown.enter.prevent="$emit('add')"
      />
    </div>
    <div class="flex" :class="classes.remove">
      <BaseButton
        class="text-lg"
        :class="classes.button"
        focusable
        @click="$emit('remove')"
      >
        <span v-if="$mq === 'base'" v-text="'Remove'" />
        <div v-else class="flex justify-center">
          <BaseGlyph id="remove" :large="true" />
        </div>
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from '~/components/BaseButton';
import BaseGlyph from '~/components/BaseGlyph';
import BaseInput from '~/components/BaseInput';
import SelectInput from '~/components/SelectInput';

export default {
  name: 'AssetInputs',
  components: {
    BaseButton,
    BaseGlyph,
    BaseInput,
    SelectInput,
  },
  props: {
    description: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: [
        'Property',
        'Bank Account',
        'Financial Assets (e.g. Bonds, Stocks)',
        'Superannuation Fund',
        'Life Insurance Policy',
        'Valuable',
        'Other (e.g. Legal interests)',
      ],
      placeholders: [
        '77 Lucky St',
        'Bank name',
        'shares, etc',
        'Australian Ethical Super',
        'policy with insurer',
        'car, coin collection',
        'legal/business interests',
      ],
      selectModel: this.type,
      inputModel: this.description,
    };
  },
  computed: {
    classes() {
      return {
        button: {
          'w-8 h-8 rounded-full text-slate-100 opacity-25 hover:opacity-100 focus:opacity-100 transition-opacity transition-100':
            this.$mq !== 'base',
          'mt-1 underline': this.$mq === 'base',
        },
        remove: {
          'justify-end': this.$mq === 'base',
          'absolute top-0 bottom-0 left-100 items-center ml-4':
            this.$mq !== 'base',
        },
      };
    },
    placeholder() {
      return this.selectModel
        ? `e.g. ${this.placeholders[this.options.indexOf(this.selectModel)]}`
        : '';
    },
  },
  watch: {
    description(newValue) {
      this.inputModel = newValue;
    },
    type(newValue) {
      this.selectModel = newValue;
    },
  },
};
</script>
