<template>
  <div class="w-full">
    <label v-if="label" class="input-label" :for="id" v-text="label" />
    <div class="relative">
      <BaseInput
        :id="id"
        classes="pl-12"
        :disabled="disabled"
        :min="1"
        :max="max"
        :name="id"
        :rules="rules"
        inputmode="decimal"
        :value="value"
        :placeholder="placeholder"
        @input="$emit('input', $event)"
      />
      <div
        class="absolute top-0 left-0 bottom-0 flex justify-end items-center w-10 opacity-30 text-xl pointer-events-none user-select-none"
        v-text="currency"
      />
    </div>
  </div>
</template>

<script>
import BaseInput from '~/components/BaseInput';

export default {
  name: 'MoneyInput',
  components: {
    BaseInput,
  },
  props: {
    currency: {
      default: '$',
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      default: null,
      type: String,
    },
    rules: {
      default: null,
      type: String,
    },
    value: {
      default: null,
      type: [String, Number],
    },
    placeholder: {
      default: null,
      type: String,
    },
    max: {
      default: null,
      type: Number,
    },
  },
};
</script>
