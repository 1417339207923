<template>
  <div v-if="visible" class="my-1">
    <div class="relative w-full h-12 text-base">
      <div class="absolute top-0 right-0 mt-3 md:mt-4">
        <BaseButton
          class="text-teal-200 underline use-mine"
          focusable
          @click="splitEvenly"
        >
          Split evenly
        </BaseButton>
      </div>
    </div>
    <div class="flex flex-col">
      <div
        v-for="(beneficiary, index) in beneficiaries"
        :key="index"
        class="flex mb-4"
      >
        <label
          :for="beneficiary.id"
          class="flex flex-grow items-center min-w-0 px-6 md:px-8 rounded-tl rounded-bl bg-white text-2xl text-charcoal-200 cursor-pointer"
        >
          <div class="w-full truncate">
            {{ labelText(beneficiary) }}
          </div>
        </label>
        <div class="relative w-28 flex-none">
          <BaseInput
            :id="beneficiary.id"
            v-model="values[index]"
            classes="pl-2 pr-6 rounded-tl-none rounded-bl-none text-right"
            :name="beneficiary.id"
            type="number"
            rules="required|beneficiarySplit"
            :min="1"
            :max="beneficiaries.length > 1 ? 99 : 100"
            :step="0.01"
            :show-error="false"
            @input="onInput"
          />
          <div
            class="absolute top-0 right-0 bottom-0 flex justify-start items-center w-6 opacity-30 text-xl pointer-events-none"
            v-text="'%'"
          />
        </div>
      </div>
    </div>
    <div
      class="flex justify-between pl-8 pr-6 text-2xl text-charcoal-200"
      :class="{ 'text-red-300': !valid }"
    >
      <span v-text="'Total'" />
      <span v-text="`${total}%`" />
    </div>
  </div>
</template>

<script>
import BaseInput from '~/components/BaseInput';
import BaseButton from '~/components/BaseButton';
import { isBeneficiarySplitValid } from '~/utilities';

export default {
  name: 'BeneficiaryInputs',
  components: {
    BaseInput,
    BaseButton,
  },
  props: {
    beneficiaries: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      values: this.beneficiaries.map((beneficiary) => beneficiary.distribution),
    };
  },
  computed: {
    visible() {
      return this.beneficiaries && this.beneficiaries.length;
    },
    valid() {
      return (
        this.total === 100 &&
        this.values.every((value) => isBeneficiarySplitValid(value))
      );
    },
    total() {
      let total = 0;

      this.values.forEach((value) => {
        total += Math.round(parseFloat(value || 0) * 100) / 100;
      });

      return +total.toFixed(2);
    },
  },
  watch: {
    beneficiaries(beneficiaries) {
      this.values = beneficiaries.map(
        (beneficiary) => beneficiary.distribution
      );
      this.onInput();
    },
  },
  mounted() {
    this.onInput();
  },
  methods: {
    onInput() {
      this.$emit('input', this.values);
      this.$emit('valid', this.valid);
    },
    labelText(beneficiary) {
      return (
        (beneficiary.charity && beneficiary.charity.meta.name) ||
        (beneficiary.person && beneficiary.person.meta.full_name)
      );
    },
    splitEvenly() {
      this.values = this.beneficiaries.map((beneficiary) => {
        const split = parseFloat(100 / this.beneficiaries.length).toFixed(2);
        return (beneficiary.distribution = +split);
      });
      this.onInput();
    },
  },
};
</script>
