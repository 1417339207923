<template>
  <div>
    <div
      class="lg:flex lg:items-baseline"
      :class="reverse ? 'flex-row-reverse' : null"
    >
      <div
        class="relative flex-shrink-0 lg:w-92 xl:w-115 mb-10"
        :class="reverse ? 'pl-20' : 'pr-20'"
      >
        <slot name="side" />
      </div>
      <div class="relative flex-1">
        <slot name="main" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainSideLayout',
  props: {
    reverse: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
