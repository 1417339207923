<template>
  <AuthPanel show-cta>
    <template #heading>
      <Squiggle>Welcome</Squiggle>
      <br />back.
    </template>
    <template #form>
      <Form
        v-slot="{ loading }"
        :mutation="LOGIN_MUTATION"
        :variables="variables"
        submit-arrow
        submit-label="Log In"
        submit-wide
        @done="done"
      >
        <!-- Email -->
        <FormRow>
          <TextInput
            id="email"
            v-model="email"
            :disabled="loading"
            label="Email Address"
            placeholder="you@example.com"
            rules="required|email"
            type="email"
          />
        </FormRow>
        <!-- Password -->
        <FormRow>
          <PasswordInput
            id="password"
            v-model="password"
            :disabled="loading"
            label="Password"
            placeholder="Password"
          />
          <div class="absolute top-0 right-0">
            <nuxt-link to="/forgot-password" class="input-label link-alt">
              Forgot password?
            </nuxt-link>
          </div>
        </FormRow>
        <!-- 2FA Code -->
        <FormRow v-if="twoFactorEnabled">
          <TextInput
            id="email"
            v-model="twoFactorCode"
            :disabled="loading"
            label="2FA Code"
            placeholder="000000"
            rules="required"
            type="text"
          />
        </FormRow>
      </Form>
    </template>
  </AuthPanel>
</template>

<script>
import { mapActions } from 'vuex';
import cookies from 'js-cookie';

import { removeLegacyCookie } from '@/modules/cookies';
import GET_WILL_QUERY from '~/graphql/queries/GetWill';
import LOGIN_MUTATION from '~/graphql/mutations/Login';

import AuthPanel from '~/components/AuthPanel';
import Form from '~/components/Form';
import FormRow from '~/components/FormRow';
import PasswordInput from '~/components/PasswordInput';
import Squiggle from '~/components/Squiggle';
import TextInput from '~/components/TextInput';
import { sanitizeLogInRedirect } from '~/utilities/allowed-redirects';

import { isIE11, getUserFullName, metaArrayToObject } from '~/utilities';

export default {
  name: 'LogIn',
  middleware: ['notAuthenticated'],
  components: {
    AuthPanel,
    Form,
    FormRow,
    PasswordInput,
    Squiggle,
    TextInput,
  },
  data() {
    return {
      LOGIN_MUTATION,
      email: '',
      password: '',
      twoFactorCode: '',
      twoFactorEnabled: false,
    };
  },
  computed: {
    variables() {
      return {
        email: this.email,
        password: this.password,
        twoFactorCode: this.twoFactorCode,
      };
    },
  },
  mounted() {
    removeLegacyCookie();
    this.$apolloHelpers.onLogout();
    this.$analytics.reset();
    this.resetSignup();

    if (Object.keys(this.$route.query).length) {
      if (this.$route.query.charity) {
        cookies.set('referral_charity', this.$route.query.charity);
      }

      if (this.$route.query.coupon) {
        this.setCouponCode(this.$route.query.coupon);
      }
    }
  },
  methods: {
    ...mapActions([
      'setEmail',
      'setRole',
      'setToken',
      'setPassword',
      'setTwoFactorEnabled',
      'setUserId',
      'setVerified',
      'setWillId',
      'setWillStatus',
    ]),
    ...mapActions('signup', ['resetSignup', 'referralCharities']),
    ...mapActions('checkout', ['setCouponCode']),
    async done({
      data: {
        login: { token, user },
      },
    }) {
      try {
        await this.$apolloHelpers.onLogin(token);
        this.setEmail(user.email);
        this.setRole(user.role);
        this.setUserId(user.id);
        this.setVerified(user.verified);
        this.setTwoFactorEnabled(user.twoFactorEnabled);
        this.$ff.refreshFeatureFlags();

        if (user.twoFactorEnabled) {
          this.setPassword(this.password);
        } else {
          this.setToken(token);
        }
        if (user.twoFactorEnabled) {
          this.goTo('/log-in/2fa');
        } else if (
          process.env.NODE_ENV === 'production' &&
          (user.role === 'MODERATOR' ||
            user.role === 'ADMIN' ||
            user.role === 'SUPER_ADMIN')
        ) {
          this.goTo('/log-in/2fa/setup');
        } else if (
          user.role === 'MODERATOR' ||
          user.role === 'ADMIN' ||
          user.role === 'SUPER_ADMIN'
        ) {
          this.goTo('/admin');
        } else {
          const { data } = await this.$apollo.query({ query: GET_WILL_QUERY });
          if (data.getWill && data.getWill.will) {
            this.setWillId(data.getWill.will.id);
            this.setWillStatus(data.getWill.will.status);
          }
          this.$zendesk.identify({
            name: getUserFullName(
              metaArrayToObject(data.getWill.will.meta || [])
            ),
            email: user.email,
          });

          const urlParams = new URLSearchParams(window.location.search);

          if (urlParams.has('redirect')) {
            const where = sanitizeLogInRedirect(urlParams.get('redirect'));

            if (where) {
              return this.goTo(where);
            }
          }

          this.goTo('/');
        }
      } catch (error) {
        console.error(error.message);
      }
    },
    goTo(path) {
      if (isIE11()) {
        window.location.href = path;
      } else {
        this.$router.push({ path });
      }
    },
  },
};
</script>
