<template>
  <div class="ru:header__parent">
    <div class="container ru:header">
      <a href="https://safewill.com/">
        <Logo class="ru:header__logo" />
      </a>
      <div class="ru:header__navigation">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '~/components/atoms/Logo';
export default {
  name: 'Header',
  components: {
    Logo,
  },
};
</script>
<style lang="scss">
.ru\:header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--header-height);
  padding-right: #{calc(var(--scrollbar-width) * var(--is-mobile))};
  margin: 0 auto;
  width: #{calc(100% - var(--gutter-width))};
  max-width: 1260px;
  &__parent {
    pointer-events: none;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(248, 249, 252, 0.8);
    @supports (backdrop-filter: blur(20px)) {
      backdrop-filter: blur(20px);
    }
    @supports not (backdrop-filter: blur(20px)) {
      background-image: linear-gradient(
        to bottom,
        rgba(248, 249, 252, 1) #{calc(100% - 1rem)},
        rgba(248, 249, 252, 0)
      );
    }
    @include mq('min-md') {
      transition: var(--transition);
      transform: translatey(#{calc(-50% * var(--window-scrolled))});
    }
    a,
    button {
      pointer-events: all;
    }
  }
  @include mq('min-md') {
    transition: var(--transition);
    transform: translatey(#{calc(25% * var(--window-scrolled))});
  }
  &__navigation {
    flex-shrink: 0;
  }
  &__logo {
    --logo-height: 36px;
    @include mq('min-md') {
      --logo-height: 46px;
    }
  }
}
</style>
