<template>
  <div class="pl-4 border-l-4 border-grey-300 mt-4 md:mt-2 text-base">
    <PasswordValidatorItem id="min-length" :valid="hasMinLength">
      At least {{ minLength }} characters
    </PasswordValidatorItem>
    <PasswordValidatorItem
      id="includes-symbol"
      class="mt-4"
      :valid="hasNumberOrSymbol"
    >
      Contains a number or symbol
    </PasswordValidatorItem>
    <PasswordValidatorItem
      v-if="exclude.length"
      id="exclude-name"
      class="mt-4"
      :valid="noNameOrEmail"
    >
      Cannot contain your name or email
    </PasswordValidatorItem>
  </div>
</template>

<script>
import PasswordValidatorItem from '~/components/PasswordValidatorItem';

export default {
  name: 'PasswordValidator',
  components: {
    PasswordValidatorItem,
  },
  model: {
    event: 'change',
  },
  props: {
    exclude: {
      type: Array,
      required: true,
    },
    input: {
      type: String,
      required: true,
    },
    minLength: {
      default: 8,
      type: Number,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isValid() {
      return this.hasMinLength && this.hasNumberOrSymbol && this.noNameOrEmail;
    },
    hasMinLength() {
      return this.input.length >= this.minLength;
    },
    hasNumberOrSymbol() {
      return !!this.input.match(/[0-9 !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/);
    },
    noNameOrEmail() {
      let match = false;

      this.exclude.forEach((str) => {
        if (
          str &&
          str.length &&
          this.input.toLowerCase().includes(str.toLowerCase())
        ) {
          match = true;
        }
      });

      return !match;
    },
  },
  watch: {
    isValid(newValue) {
      this.$emit('change', newValue);
    },
  },
};
</script>
