<template>
  <div>
    <div v-if="$mq !== 'base'">
      <div ref="swiper" class="swiper-container overflow-visible">
        <div class="swiper-wrapper transition-fast-in-slow-out">
          <slot />
        </div>
      </div>
      <div class="flex items-center mt-12">
        <div
          ref="scrollbar"
          class="swiper-scrollbar w-full max-w-3xl h-1 rounded mr-auto overflow-hidden cursor-ew-resize"
        />
        <div class="flex text-teal-200">
          <BaseButton
            ref="prev"
            class="btn-sm mr-5 transition-250 transition-ease transition-opacity"
            focusable
          >
            <BaseIcon id="arrow-left" />
          </BaseButton>
          <BaseButton
            ref="next"
            class="btn-sm transition-250 transition-ease transition-opacity"
            focusable
          >
            <BaseIcon id="arrow-right" />
          </BaseButton>
        </div>
      </div>
    </div>
    <div v-else>
      <slot />
    </div>
  </div>
</template>

<script>
import { Swiper, A11y, Mousewheel, Navigation, Scrollbar } from 'swiper/core';
import 'swiper/swiper-bundle.css';

import BaseButton from '~/components/BaseButton';
import BaseIcon from '~/components/BaseIcon';

export default {
  name: 'Slider',
  components: {
    BaseButton,
    BaseIcon,
  },
  props: {
    slide: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      swiper: null,
    };
  },
  watch: {
    $mq(newValue) {
      if (newValue === 'base') {
        this.destroy();
      } else {
        this.$nextTick(this.build);
      }
    },
    slide(newValue) {
      if (this.swiper) {
        this.goToSlide(newValue);
      }
    },
  },
  mounted() {
    Swiper.use([A11y, Mousewheel, Navigation, Scrollbar]);

    this.build();
  },
  beforeDestroy() {
    this.destroy();
  },
  methods: {
    async build() {
      if (this.$refs.swiper && !this.swiper) {
        this.swiper = await new Swiper(this.$refs.swiper, {
          breakpointsInverse: true,
          breakpoints: {
            1024: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
          },
          grabCursor: true,
          mousewheel: {
            forceToAxis: true,
            invert: true,
          },
          navigation: {
            nextEl: this.$refs.next.$el,
            prevEl: this.$refs.prev.$el,
          },
          scrollbar: {
            el: this.$refs.scrollbar,
            draggable: true,
          },
          slidesPerView: 2,
          spaceBetween: 30,
          speed: 500,
          watchSlidesVisibility: true,
        });

        this.goToSlide(this.slide);
      }
    },
    destroy() {
      if (this.swiper) {
        this.swiper.destroy(true, true);
        this.swiper = null;
      }
    },
    goToSlide(index) {
      this.swiper.slideTo(index);
    },
  },
};
</script>
