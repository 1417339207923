<template>
  <div>
    <h4 v-if="label" class="mb-4 text-2xl">
      Charities
    </h4>
    <div
      class="rounded p-6 md:p-8 mb-4 rounded-md bg-white bg-ice-100 text-base text-charcoal-400"
    >
      <div
        class="mb-2 text-slate-300 bg-ice-300 rounded-full w-12 h-12 flex p-2 justify-center items-center"
      >
        <BaseIcon id="charity" />
      </div>
      <strong class="text-slate-300">
        Support the causes you care about
      </strong>
      <br />
      On average, 20% of charity fundraising in Australia comes from wills. It's
      the thoughtfulness of everyday Australians like yourself who enable
      charities to make real change.
    </div>
    <div class="flex flex-wrap -mx-2 items-stretch">
      <div
        v-for="(charityKey, slot) in shuffledCharities"
        :key="`shuffledCharities${slot}`"
        class="px-2"
        :class="layout[slot === 0 ? 'primary' : 'secondary']"
      >
        <CharityCard
          :charity-key="charityKey"
          :selected="isSelected(charityKey)"
          :charity-data="charityData(charityKey)"
          :hide-description="slot > 0"
          :show-tooltip="slot > 0"
          :hide-name="slot > 0"
          @onChange="onChange(charityKey)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import shuffle from 'lodash/shuffle';
import { featuredCharities, referralCharities } from '@/modules/charityData';
import { localCharityData } from '~/utilities/charity';

import BaseIcon from '~/components/BaseIcon';
import CharityCard from '~/components/CharityCard';
import { will } from '~/mixins/apollo';

export default {
  name: 'CharitySuggester',
  components: {
    BaseIcon,
    CharityCard,
  },
  mixins: [will],
  props: {
    label: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    max: {
      default: Infinity,
      type: Number,
    },
  },
  data() {
    return {
      layout: {
        primary: 'w-full',
        secondary: 'w-full lg:w-1/2',
      },
      selectedCharityKeys: [],
    };
  },
  computed: {
    shuffledCharities() {
      return shuffle(featuredCharities.main);
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    selectedCharities() {
      return this.selectedCharityKeys.map((charityKey) => {
        return {
          ...localCharityData(this.userState, charityKey, referralCharities),
          suggestionLocation: window.location.pathname,
          suggestionSlot: this.shuffledCharities.indexOf(charityKey),
        };
      });
    },
  },
  methods: {
    charityData(charityKey) {
      return localCharityData(this.userState, charityKey, referralCharities);
    },
    isSelected(charityKey) {
      return this.selectedCharityKeys.includes(charityKey);
    },
    onChange(charityKey) {
      const index = this.selectedCharityKeys.indexOf(charityKey);
      if (index > -1) {
        this.selectedCharityKeys.splice(index, 1);
      } else if (this.selectedCharityKeys.length < this.max) {
        this.selectedCharityKeys.push(charityKey);
      } else if (this.max === 1) {
        this.selectedCharityKeys = [charityKey];
      }
      this.$emit('input', this.selectedCharities);
    },
  },
};
</script>
