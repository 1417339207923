<template>
  <div v-scroll="onScroll" :style="`--window-scrolled: ${windowScrolled};`">
    <Header>
      Already registered?
      <nuxt-link to="/log-in" class="temp-nav-button">
        Login
      </nuxt-link>
    </Header>
    <div class="ru:container ru:container--tight pt-4">
      <Form
        :mutation="ADD_USER_MUTATION"
        :variables="variables"
        :disabled="!isComplete"
        submit-label="Create account"
        submit-classes="temp-button"
        submit-arrow
        @done="done"
      >
        <h1 class="mb-8 text-6xl leading-none">
          Get <DecoratedText decoration="circle">started</DecoratedText>
        </h1>
        <p class="mb-8 text-base">
          Let's set up your account. We’ll only ask for payment once you’re
          ready to finalise your will.
        </p>
        <FormWrapper>
          <div class="grid form__fieldset">
            <div class="cell cell__12/12">
              <div class="form__control">
                <input
                  id="email"
                  v-model="email"
                  required
                  type="email"
                  class="form__input-text"
                  placeholder="What's your email address?"
                  @blur.once="touch"
                />
                <label
                  for="email"
                  class="form__label"
                  v-text="`Your email address`"
                />
              </div>
            </div>
            <div class="cell cell__12/12">
              <div class="form__control">
                <input
                  id="password"
                  v-model="password"
                  required
                  type="password"
                  class="form__input-text"
                  placeholder="Choose a password"
                  @blur.once="touch"
                />
                <label
                  for="password"
                  class="form__label"
                  v-text="`Choose a password`"
                />
              </div>
              <PasswordValidator
                v-model="validPassword"
                :input="password"
                :exclude="[]"
                plain
                class="mt-4"
              />
            </div>
            <div class="cell cell__12/12">
              <div class="form__control form__control--inline">
                <input
                  id="terms"
                  v-model="terms"
                  type="checkbox"
                  class="form__input-checkbox"
                />
                <label for="terms" class="form__label">
                  By checking this box, you confirm that you have read and
                  accept our
                  <a
                    href="https://safewill.com/terms"
                    class="temp-anchor"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms of use
                  </a>
                  and
                  <a
                    href="https://safewill.com/privacy"
                    class="temp-anchor"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    privacy policy.
                  </a>
                </label>
              </div>
            </div>
          </div>
        </FormWrapper>
      </Form>
      <hr class="temp-hr" />
      <div class="temp-ping">
        <span class="absolute top-0 left-0 flex">
          <span class="flex relative h-6 w-6">
            <span
              class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-300 opacity-75"
            ></span>
            <span
              class="relative inline-flex rounded-full h-6 w-6 bg-green-300 opacity-75"
            ></span>
          </span>
        </span>
        <p>
          We're online and here to help.
          <button
            class="temp-anchor"
            @click="showChat"
            v-text="`Start a live chat`"
          />, or do your will over the phone on
          <a href="tel:1800103310" class="temp-anchor">1800 10 33 10</a> .
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import detectIt from 'detect-it';
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import cookies from 'js-cookie';

import { showChat } from '@/modules/chat';
import { referralCodesToCharities } from '@/modules/referralCodes';

import ADD_USER_MUTATION from '~/graphql/mutations/AddUser';
import GET_WILL_QUERY from '~/graphql/queries/GetWill';
import GET_PRICE_QUERY from '~/graphql/queries/GetPrice';
import LOGIN_MUTATION from '~/graphql/mutations/Login';

import DecoratedText from '~/components/atoms/DecoratedText';
import FormWrapper from '~/components/organisms/Form';
import Header from '~/components/organisms/Header';
import PasswordValidator from '~/components/organisms/PasswordValidator';
import Form from '~/components/Form';

import { objectToMetaArray, saveAnonymousProfile } from '~/utilities';
import { sanitizeSignUpRedirect } from '~/utilities/allowed-redirects';

import { charities } from '~/mixins/apollo';

export default {
  name: 'GetStartedSignUp',
  layout: 'modern',
  components: {
    DecoratedText,
    FormWrapper,
    Header,
    Form,
    PasswordValidator,
  },
  mixins: [charities],
  data() {
    return {
      ADD_USER_MUTATION,
      email: '',
      password: '',
      terms: false,
      validPassword: false,
      scrollTop: 0,
    };
  },
  computed: {
    ...mapGetters(['willId']),
    ...mapFields('signup', [
      'meta',
      'meta.referral_charity',
      'meta.has_partner',
      'coupon',
    ]),
    variables() {
      return {
        email: this.email,
        password: this.password,
        meta: objectToMetaArray(this.meta),
      };
    },
    isComplete() {
      return !!(
        this.email &&
        this.password &&
        this.validPassword &&
        this.terms
      );
    },
    windowScrolled() {
      return Number(this.scrollTop > 0);
    },
  },

  mounted() {
    if (Object.keys(this.$route.query).length) {
      if (this.$route.query.charity) {
        this.referral_charity = this.$route.query.charity;
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '🎗 Referral Charity',
          props: {
            charity: this.referral_charity,
          },
        });
        cookies.set('referral_charity', this.referral_charity);
      }

      if (this.$route.query.coupon) {
        cookies.set('coupon', this.$route.query.coupon);
        saveAnonymousProfile({
          couponCode: this.$route.query.coupon,
        });
      }

      if (this.$route.query.hasPartner) {
        this.has_partner = true;
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '💍 Couples Get Started',
        });
        cookies.set('has_partner', true);
      }

      cookies.remove('signup_redirect');
      if (this.$route.query.redirect) {
        const where = sanitizeSignUpRedirect(
          String(this.$route.query.redirect)
        );

        if (where) {
          cookies.set('signup_redirect', where);
        }
      }

      this.$router.replace({ path: this.$route.path });
    }

    this.user_agent = navigator && navigator.userAgent;
    this.device_type = detectIt.deviceType;

    document.documentElement.style.setProperty(
      '--scrollbar-width',
      window.innerWidth - document.documentElement.clientWidth + 'px'
    );
    document.documentElement.style.setProperty(
      '--is-mobile',
      Number(window.innerWidth - document.documentElement.clientWidth === 0)
    );
  },
  methods: {
    ...mapActions([
      'setEmail',
      'setRole',
      'setToken',
      'setUserId',
      'setVerified',
      'setWillId',
      'setWillStatus',
    ]),
    ...mapActions('signup', ['resetSignup']),
    async done() {
      const { token, user } = await this.$apollo
        .mutate({
          mutation: LOGIN_MUTATION,
          variables: {
            email: this.email,
            password: this.password,
          },
        })
        .then(({ data }) => data && data.login)
        .catch((e) => {
          console.error(e.message);
        });

      await this.$apolloHelpers
        .onLogin(token)
        .then(() => {
          this.setEmail(user.email);
          this.setRole(user.role);
          this.setToken(token);
          this.setUserId(user.id);
          this.setVerified(user.verified);
          this.$ff.refreshFeatureFlags();
        })
        .catch((e) => {
          console.error(e.message);
        });

      await this.$apollo
        .query({
          query: GET_WILL_QUERY,
        })
        .then(async ({ data }) => {
          if (data.getWill.will) {
            this.setWillId(data.getWill.will.id);
            this.setWillStatus(data.getWill.will.status);

            const charityKeys =
              this.referral_charity &&
              referralCodesToCharities[this.referral_charity];
            if (charityKeys) await this.addPartnerCharities(charityKeys);

            const coupon = cookies.get('coupon');
            if (coupon) {
              await this.$apollo.query({
                query: GET_PRICE_QUERY,
                variables: {
                  code: coupon,
                  willId: data.getWill.will.id,
                  products: ['WILL'],
                },
              });

              this.$nuxt.$emit('sendTrackingAttributes', {
                coupon_code: coupon,
              });
            }

            if (this.has_partner) {
              this.$nuxt.$emit('sendTrackingAttributes', {
                has_partner: true,
              });
            }
          }
        })
        .catch((e) => {
          console.error(e.message);
        });

      this.resetSignup();

      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✅ Sign Up Complete',
        props: {},
        integrations: {
          Amplitude: false,
          Intercom: false,
          'Facebook Pixel': false,
          'Facebook Pixel Server Side': false,
          'Personas Facebook Custom Audiences': false,
        },
      });

      const signupRedirect = sanitizeSignUpRedirect(
        String(cookies.get('signup_redirect'))
      );
      cookies.remove('signup_redirect');

      if (signupRedirect) {
        this.$router.push({
          path: signupRedirect,
        });
      } else {
        this.$router.push({
          path: '/dashboard',
          query: { welcome: 'true' },
        });
      }
    },
    onScroll(scrollTop) {
      this.scrollTop = scrollTop;
    },
    touch($event) {
      $event.target.classList.add('form__field--touched');
    },
    showChat() {
      showChat();
    },
  },
};
</script>

<style lang="scss">
.temp {
  &-anchor {
    color: var(--viridian);
    font-weight: 500;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  &-button {
    margin-left: #{calc(var(--input-padding-x) * -1)};
  }
  &-hr {
    border-top-color: var(--iron);
    margin: #{calc(var(--cell-vertical-spacing) * 1.5)
      calc(var(--input-padding-x) * -1)};
  }
  &-ping {
    margin: #{calc(var(--cell-vertical-spacing) * 1.5) 0};
    position: relative;
    padding-left: 3.5rem;
  }
  &-nav-button {
    display: inline-flex;
    background: var(--navy);
    border-radius: 999px;
    color: #fff;
    margin-left: 0.75rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1px;
    font-size: 19px;
    line-height: 3rem;
  }
}
</style>
