<template>
  <div>
    <div class="flex items-baseline justify-between">
      <h3 class="mb-12 text-3xl">
        Users
      </h3>
      <div class="flex">
        <input
          id="search"
          class="input input-compact"
          :v-model="searchQuery"
          placeholder="Search..."
          :compact="true"
          @keydown="search"
        />
      </div>
    </div>
    <AdminCard>
      <AdminDataTable
        v-if="users"
        :columns="columns"
        :loading="$apollo.loading"
        :min-rows="pageSize"
        :row-count="users.length"
      >
        <template #filters>
          <AdminDataTableFilter
            v-for="(filter, index) in filters"
            :key="index"
            :active="filter.value === userRoleFilter"
            :label="filter.label"
            @click="setUserRoleFilter(filter.value)"
          />
        </template>
        <template #rows>
          <AdminDataTableRow
            v-for="(user, index) in users"
            :key="index"
            class="group"
            :to="`/admin/users/${user.id}`"
          >
            <AdminDataTableCell full>
              {{ user.id }}
            </AdminDataTableCell>
            <AdminDataTableCell full>
              {{ user.email }}
            </AdminDataTableCell>
            <AdminDataTableCell>
              {{ user.role }}
            </AdminDataTableCell>
          </AdminDataTableRow>
        </template>
        <template #pagination>
          <AdminDataTableButton
            :disabled="usersPageIndex === 0"
            :loading="$apollo.loading"
            @click="prevPage"
          >
            <BaseIcon id="arrow-left" />
          </AdminDataTableButton>
          <AdminDataTableButton
            :disabled="users.length !== pageSize"
            :loading="$apollo.loading"
            @click="nextPage"
          >
            <BaseIcon id="arrow-right" />
          </AdminDataTableButton>
        </template>
      </AdminDataTable>
    </AdminCard>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'throttle-debounce';

import GET_USERS_QUERY from '~/graphql/queries/GetUsers';
import AdminCard from '~/components/AdminCard';
import AdminDataTable from '~/components/AdminDataTable';
import AdminDataTableRow from '~/components/AdminDataTableRow';
import AdminDataTableCell from '~/components/AdminDataTableCell';
import AdminDataTableFilter from '~/components/AdminDataTableFilter';
import BaseIcon from '~/components/BaseIcon';
import AdminDataTableButton from '~/components/AdminDataTableButton';

export default {
  components: {
    AdminCard,
    AdminDataTable,
    AdminDataTableRow,
    AdminDataTableCell,
    AdminDataTableFilter,
    BaseIcon,
    AdminDataTableButton,
  },
  apollo: {
    users: {
      query: GET_USERS_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          limit: this.pageSize,
          offset: this.usersPageIndex * this.pageSize,
          searchQuery: this.usersPageSearch,
          role: this.userRoleFilter,
        };
      },
      update: (data) => data.getUsers,
    },
  },
  data() {
    return {
      searchQuery: '',
      columns: ['ID', 'Email', 'Role'],
      pageSize: 10,
      filters: [
        {
          label: 'All',
          value: null,
        },
        {
          label: 'Super Admin',
          value: 'SUPER_ADMIN',
        },
        {
          label: 'Admin',
          value: 'ADMIN',
        },
        {
          label: 'Moderator',
          value: 'MODERATOR',
        },
        {
          label: 'Consumer',
          value: 'CONSUMER',
        },
      ],
      users: [],
    };
  },
  computed: {
    ...mapGetters('admin', [
      'usersPageIndex',
      'usersPageSearch',
      'userRoleFilter',
    ]),
  },
  watch: {
    userRoleFilter() {
      this.resetUsersPageIndex();
    },
  },
  methods: {
    ...mapActions('admin', [
      'nextUsersPage',
      'prevUsersPage',
      'resetUsersPageIndex',
      'setUsersPageSearch',
      'setUserRoleFilter',
    ]),
    nextPage() {
      if (!this.$apollo.loading) {
        this.nextUsersPage();
      }
    },
    prevPage() {
      if (!this.$apollo.loading) {
        this.prevUsersPage();
      }
    },
    search: debounce(300, function(event) {
      this.resetUsersPageIndex();
      this.setUsersPageSearch(event.target.value);
    }),
  },
};
</script>
