<template>
  <div>
    <div class="mb-2 text-teal-100">
      <BaseDuoIcon :id="icon" />
    </div>
    <h4 class="mb-4 text-3xl" v-text="heading" />
    <div class="text-xl text-charcoal-200">
      <slot />
    </div>
  </div>
</template>

<script>
import BaseDuoIcon from '~/components/BaseDuoIcon';

export default {
  name: 'FeatureBlock',
  components: {
    BaseDuoIcon,
  },
  props: {
    heading: {
      required: true,
      type: String,
    },
    icon: {
      required: true,
      type: String,
    },
  },
};
</script>
