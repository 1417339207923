var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleStep',{attrs:{"index":3,"prev-step":"/get-started/resident"}},[_c('Form',{attrs:{"hide-submit":!_vm.isComplete,"auto-submit":true,"submit-arrow":""},on:{"submit":_vm.done}},[_c('FormSection',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("Are you over 18?")]},proxy:true}])},[_vm._v(" "),_c('Tip',[_vm._v("Safewill can only be used by adults over the age of 18.")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"is_adult","options":[
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          } ]},model:{value:(_vm.is_adult),callback:function ($$v) {_vm.is_adult=$$v},expression:"is_adult"}})],1),_vm._v(" "),(_vm.is_adult === false)?_c('FormSection',{staticClass:"problem",scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("We've got a problem!")]},proxy:true}],null,false,856600036)},[_vm._v(" "),_c('p',[_vm._v("\n        Unfortunately, Safewill is currently not available for anyone living\n        outside Australia or under the age of 18.\n      ")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }