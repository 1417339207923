<template>
  <div>
    <h3 class="mb-12 text-3xl">User</h3>
    <AdminBackLink to="/admin/users">Users</AdminBackLink>
    <AdminMainSideLayout>
      <template #main>
        <AdminCard v-if="user" title="User Details">
          <div class="w-full px-8 pb-4">
            <AdminSimpleTable :rows="contactRows" />
          </div>
        </AdminCard>
        <AdminCard v-if="subscriptionV2" title="Subscription Details">
          <div class="w-full px-8 pb-4">
            <AdminSimpleTable :rows="subscriptionRows" />
          </div>
        </AdminCard>
        <AdminCard
          v-if="historicalWills && userIsConsumer"
          title="Historical Wills"
        >
          <UserHistoricalWills
            :wills="historicalWills"
            :loading="$apollo.loading"
          />
        </AdminCard>
      </template>
      <template #side>
        <AdminCard v-if="isSuperAdmin" title="Actions">
          <AdminCardSection>
            <h5 class="mb-2 opacity-50">
              Role
            </h5>
            <select
              id="user-role-input"
              v-model="userRole"
              class="w-full h-8"
              @change="changeUserRole"
            >
              <option
                v-for="userRole in userRoleTypes"
                :key="userRole.value"
                :value="userRole.value"
              >
                {{ userRole.name }}
              </option>
            </select>
          </AdminCardSection>
        </AdminCard>
        <AdminCard v-if="userIsConsumer">
          <AdminCardSection>
            <h5 class="mb-2 opacity-50">Actions</h5>
            <AdminActionLink
              v-if="isAdminClassUser"
              class="mb-4 text-blue-200"
              glyph="user"
              @click="masquerade(userId)"
            >
              Masquerade as user
            </AdminActionLink>
            <AdminActionLink
              class="mb-4 text-green-100"
              glyph="circle-tick"
              :disabled="will && will.verified"
              @click="verify"
            >
              Mark as verified
            </AdminActionLink>
          </AdminCardSection>
        </AdminCard>
        <AdminCard v-if="userIsConsumer">
          <AdminCardSection v-if="will" title="Status">
            <AdminStatusChip :status="will && will.status" />
          </AdminCardSection>
          <AdminCardSection title="Actions">
            <AdminActionLink
              v-if="isAdminClassUser"
              class="mb-4 text-orange-200"
              :disabled="$apollo.queries.pdf.loading"
              glyph="cogs"
              :loading="$apollo.queries.pdf.loading"
              @click="generateWill()"
            >
              Generate will
            </AdminActionLink>
            <AdminActionLink
              class="mb-4 text-blue-200"
              :disabled="isDisabled.downloadPdf"
              glyph="document"
              :loading="$apollo.queries.pdf.loading"
              @click="$downloadPdf(pdf, pdfFilename)"
            >
              Download will
            </AdminActionLink>
            <AdminActionLink
              class="mb-4 text-green-100"
              :disabled="isDisabled.approveWill"
              glyph="circle-tick"
              :loading="approvingWill"
              @click="approveWill"
            >
              Mark as approved
            </AdminActionLink>
            <AdminActionLink
              class="mb-4 text-green-100"
              glyph="question-mark"
              :loading="validatingWill"
              @click="validateWill"
            >
              Validate will
            </AdminActionLink>
          </AdminCardSection>
        </AdminCard>
        <AdminCard v-if="userIsConsumer">
          <AdminCardSection v-if="invites" title="Referrals">
            <AdminActionLink
              class="mb-4 text-blue-200"
              glyph="document"
              :underline="false"
              :loading="$apollo.queries.invites.loading"
            >
              <p>
                Invites Emailed: <strong>{{ invitesSent.length }}</strong>
              </p>
            </AdminActionLink>
            <AdminActionLink
              class="mb-4 text-green-100"
              glyph="circle-tick"
              :underline="false"
              :loading="$apollo.queries.invites.loading"
            >
              <p>
                Invites Complete: <strong>{{ invitesComplete.length }}</strong>
              </p>
            </AdminActionLink>
            <AdminActionLink
              v-if="invitedBy"
              class="mb-4 text-cerise-200"
              glyph="child"
              :underline="false"
              :loading="$apollo.queries.invites.loading"
            >
              <p>
                Referrer: <strong>{{ invitedBy }}</strong>
              </p>
            </AdminActionLink>
          </AdminCardSection>
        </AdminCard>
      </template>
    </AdminMainSideLayout>
    <Toast />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

import AdminBackLink from '~/components/AdminBackLink';
import AdminCard from '~/components/AdminCard';
import AdminCardSection from '~/components/AdminCardSection';
import AdminMainSideLayout from '~/components/AdminMainSideLayout';
import AdminSimpleTable from '~/components/AdminSimpleTable';
import dialog from '~/mixins/message-box';
import Toast from '~/components/Toast';

import GET_USER_QUERY from '~/graphql/queries/GetUser';
import UPDATE_USER_ROLE_MUTATION from '~/graphql/mutations/UpdateUserRole';
import GET_WILLS_QUERY from '~/graphql/queries/GetWills';
import GET_WILL_BY_EMAIL from '~/graphql/queries/GetWillByEmail';

import UserHistoricalWills from '~/components/UserHistoricalWills';
import AdminActionLink from '~/components/AdminActionLink';
import AdminStatusChip from '~/components/AdminStatusChip';
import adminUserAction from '~/mixins/admin-user-action';
import { subscriptionV2 } from '~/mixins/apollo';

export default {
  name: 'AdminUsersSingle',
  layout: 'admin',
  components: {
    AdminBackLink,
    AdminCard,
    AdminCardSection,
    AdminMainSideLayout,
    AdminSimpleTable,
    UserHistoricalWills,
    AdminActionLink,
    AdminStatusChip,
    Toast,
  },
  mixins: [dialog, adminUserAction, subscriptionV2],
  apollo: {
    user: {
      query: GET_USER_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          id: this.userId,
        };
      },
      update: (data) => data.getUser,
      result({ data }) {
        this.userRole = data.getUser.role;
      },
    },
    will: {
      query: GET_WILL_BY_EMAIL,
      fetchPolicy: 'network-only',
      variables() {
        return {
          email: this.userEmail,
        };
      },
      skip() {
        return !this.userIsConsumer || !this.userEmail;
      },
      update: (data) => {
        if (data.getWillByEmail) {
          return data.getWillByEmail.will;
        }
        return null;
      },
    },
    historicalWills: {
      query() {
        return GET_WILLS_QUERY;
      },
      update: (data) => {
        if (data.getWills) {
          return data.getWills.wills;
        }
        return [];
      },
      fetchPolicy: 'network-only',
      variables() {
        return {
          searchQuery: this.userEmail,
        };
      },
    },
  },
  data() {
    return {
      userRoleTypes: [
        {
          name: 'Super Admin',
          value: 'SUPER_ADMIN',
        },
        {
          name: 'Admin',
          value: 'ADMIN',
        },
        {
          name: 'Moderator',
          value: 'MODERATOR',
        },
        {
          name: 'Consumer',
          value: 'CONSUMER',
        },
      ],
      userRole: '',
      historicalWills: [],
      will: null,
    };
  },
  computed: {
    ...mapGetters(['role']),
    userId() {
      return this.$route.params.id;
    },
    userEmail() {
      return this.user?.email;
    },
    contactRows() {
      return [
        ['ID', this.user?.id],
        ['Email', this.user?.email],
        ['Role', this.titleCase(this.user?.role)],
        ['Verified', this.user?.verified],
      ];
    },
    subscriptionRows() {
      return [
        ['ID', this.subscriptionV2.id],
        ['Stripe Subscription id', this.subscriptionV2.stripeSubscriptionId],
        ['Auto renew', this.subscriptionV2.autoRenew],
        [
          'Payment method expires at',
          this.formattedSubscriptionPaymentMethodExpiryDate,
        ],
        ['Expires at', this.formattedSubscriptionExpiryDate],
        ['Created at', this.formattedSubscriptionCreatedAtDate],
      ];
    },
    isSuperAdmin() {
      return this.role === 'SUPER_ADMIN';
    },
    userIsConsumer() {
      return this.user?.role === 'CONSUMER';
    },
  },
  methods: {
    titleCase(str) {
      return startCase(toLower(str));
    },
    changeUserRole({ target: { value: userNewRole } }) {
      const userRoleString = this.titleCase(userNewRole);
      this.$confirm(
        {
          title: `Change user role to ${userRoleString}?`,
          message: `Are you sure you want to assign ${userRoleString} role to ${this.user?.email}?`,
        },
        async (action) => {
          if (action) {
            await this.$apollo.mutate({
              mutation: UPDATE_USER_ROLE_MUTATION,
              variables: {
                id: this.user.id,
                role: userNewRole,
              },
            });
          } else {
            this.userRole = this.user?.role;
          }
        }
      );
    },
  },
};
</script>
