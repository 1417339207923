var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showCouponModal)?_c('BaseModal',{attrs:{"modal-wide":""},on:{"close":_vm.close}},[_c('div',{ref:"container"},[_c('div',[_c('h2',{staticClass:"mb-4 text-4xl leading-tight"},[_vm._v("\n        Adding a Coupon\n      ")]),_vm._v(" "),_c('Form',{attrs:{"auto-focus":"","mutation":_vm.ADD_COUPON_MUTATION,"variables":_vm.variables,"submit-wide":"","submit-label":"Add Coupon"},on:{"update":_vm.update,"done":_vm.close}},[_c('FormSection',[_c('FormRow',{attrs:{"half-width":""}},[_c('TextInput',{attrs:{"id":"code","disabled":_vm.loading,"label":"Code","classes":"uppercase","placeholder":"SAFEWILL18","rules":"required"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1),_vm._v(" "),_c('FormRow',[_c('TextInput',{attrs:{"id":"description","disabled":_vm.loading,"label":"Description","placeholder":"18% Discount","rules":"required"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_vm._v(" "),_c('FormRow',[_c('BaseRadio',{attrs:{"id":"coupon_type","label":"Type","options":[
                {
                  label: 'Percentage',
                  value: 'PERCENTAGE',
                },
                {
                  label: 'Fixed',
                  value: 'FIXED',
                } ]},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_vm._v(" "),_c('FormRow',{attrs:{"half-width":""}},[(_vm.type === 'FIXED')?_c('MoneyInput',{attrs:{"id":"factor","disabled":_vm.loading,"label":"Value","placeholder":"00","rules":("required|max_value:" + _vm.defaultSetupPrice)},model:{value:(_vm.factor),callback:function ($$v) {_vm.factor=$$v},expression:"factor"}}):_vm._e(),_vm._v(" "),(_vm.type === 'PERCENTAGE')?_c('PercentageInput',{attrs:{"id":"factor","disabled":_vm.loading,"label":"Value","placeholder":"00","rules":"required|min_value:0|max_value:100|integer"},model:{value:(_vm.factor),callback:function ($$v) {_vm.factor=$$v},expression:"factor"}}):_vm._e()],1)],1)],1)],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }