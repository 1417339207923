<template>
  <AuthPanel>
    <template #heading>
      <Squiggle>Two Factor</Squiggle> Authentication.
    </template>

    <template #form>
      <p class="mb-2">
        You do not currently have two factor authentication enabled on your
        account. To enable it please follow the steps below:
      </p>
      <ol class="list-decimal ml-6 mb-8">
        <li class="pb-2">
          Download and install <strong>Authy</strong> or
          <strong>Google Authenticator</strong> from either the Apple App Store
          or Google Play Store on your phone.
        </li>
        <li class="pb-2">
          Select <strong>Add Token</strong> and scan the QR code below:
        </li>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="w-56 h-56 bg-grey-300 my-4 mx-auto" v-html="image" />
        <li class="pb-2">
          Enter the number which the app generates below to enable.
        </li>
      </ol>
      <Form
        v-slot="{ loading }"
        :mutation="VERIFY_2FA"
        :variables="variables"
        submit-arrow
        submit-label="Activate"
        submit-wide
        @done="done"
      >
        <FormRow>
          <TextInput
            id="twoFactorCode"
            v-model="twoFactorCode"
            :disabled="loading"
            placeholder="000000"
            rules="required"
            type="text"
          />
        </FormRow>
      </Form>
    </template>
  </AuthPanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import GENERATE_2FA from '~/graphql/queries/GenerateTwoFactorSecret';
import VERIFY_2FA from '~/graphql/mutations/VerifyTwoFactorCode';

import AuthPanel from '~/components/AuthPanel';
import Form from '~/components/Form';
import FormRow from '~/components/FormRow';
import Squiggle from '~/components/Squiggle';
import TextInput from '~/components/TextInput';

import { isIE11 } from '~/utilities';

export default {
  name: 'Setup2FA',
  middleware: ['notAuthenticated'],
  components: {
    AuthPanel,
    Form,
    FormRow,
    Squiggle,
    TextInput,
  },
  data() {
    return {
      VERIFY_2FA,
      twoFactorCode: '',
      image: '',
    };
  },
  computed: {
    ...mapGetters(['token', 'twoFactorEnabled']),
    variables() {
      return {
        twoFactorCode: this.twoFactorCode,
      };
    },
  },
  mounted() {
    this.$analytics.reset();

    if (!this.token) {
      if (isIE11()) {
        window.location.href = '/log-in';
      } else {
        this.$router.push({
          path: '/log-in',
        });
      }
    }

    if (this.twoFactorEnabled) {
      if (isIE11()) {
        window.location.href = '/';
      } else {
        this.$router.push({
          path: '/',
        });
      }
    }
  },
  apollo: {
    image: {
      query: GENERATE_2FA,
      update: (data) =>
        data.generateTwoFactorSecret && data.generateTwoFactorSecret.image,
      fetchPolicy: 'no-cache',
    },
  },
  methods: {
    ...mapActions(['setTwoFactorEnabled']),
    done({ data }) {
      if (data.verifyTwoFactorCode.success) {
        this.setTwoFactorEnabled(true);
        if (isIE11()) {
          window.location.href = '/';
        } else {
          this.$router.push({
            path: '/',
          });
        }
      }
    },
  },
};
</script>
