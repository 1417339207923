<template>
  <label
    class="group block rounded mb-4 cursor-pointer select-none"
    :for="charityKey"
  >
    <BaseCard
      :class="classes.card"
      inline
      :primary-text="primaryText"
      :secondary-text="secondaryText"
      :tooltip-text="tooltipText"
      :logo="logo"
      :small="small"
    >
      <template #actions>
        <div
          class="relative inline-flex justify-center items-center cursor-pointer"
          :class="classes.checkbox"
        >
          <input
            :id="charityKey"
            class="btn absolute top-0 left-0 w-full h-full p-0"
            :class="classes.input"
            :checked="selected"
            :name="charityKey"
            type="checkbox"
            @change="$emit('onChange', charityData)"
          />
          <BaseIcon id="tick" />
        </div>
      </template>
    </BaseCard>
  </label>
</template>

<script>
import BaseCard from '~/components/BaseCard';
import BaseIcon from '~/components/BaseIcon';

export default {
  name: 'CharitySelector',
  components: {
    BaseCard,
    BaseIcon,
  },
  props: {
    charityData: {
      required: true,
      type: Object,
    },
    charityKey: {
      required: false,
      default: null,
      type: String,
    },
    selected: {
      default: false,
      type: Boolean,
    },
    hideDescription: {
      default: false,
      type: Boolean,
    },
    showTooltip: {
      default: false,
      type: Boolean,
    },
    hideName: {
      default: false,
      type: Boolean,
    },
    small: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    primaryText() {
      return !this.hideName ? this.charityData.name : null;
    },
    secondaryText() {
      if (this.hideDescription) {
        return null;
      }
      return this.charityData.description || this.charityData.address;
    },
    tooltipText() {
      return this.showTooltip ? this.charityData.description : null;
    },
    logo() {
      return this.charityData.logo || null;
    },
    classes() {
      return {
        card: {
          'border-2 border-teal-200': this.selected,
          'border-2 border-white': !this.selected,
        },
        checkbox: {
          'w-12 h-12 rounded-full bg-grey-200 group-hover:bg-teal-100 text-grey-400 group-hover:text-teal-300': !this
            .selected,
          'w-12 h-12 rounded-full bg-teal-400 text-white': this.selected,
        },
        input: {
          'rounded-full': !this.selected,
        },
      };
    },
  },
};
</script>
