<template>
  <div v-if="$nuxt.isOffline" class="relative z-10 bg-red-300">
    <div class="container gutters mx-auto py-3">
      <div class="flex items-center justify-between flex-wrap">
        <div class="flex items-center">
          <span class="flex p-2 rounded-lg text-white">
            <BaseGlyph id="exclamation" />
          </span>
          <p class="ml-3 font-medium text-white">
            <strong>No Internet Connection</strong> - Some parts of this site
            will be unavailable until you come back online.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseGlyph from '~/components/BaseGlyph';

export default {
  components: {
    BaseGlyph,
  },
};
</script>
