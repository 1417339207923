<template>
  <BaseButton
    class="h-full px-4 pb-5 rounded-none mr-6 last-child:mr-0 text-slate-300 overflow-hidden transition-opacity transition-100"
    :disabled="disabled"
    :loading="loading"
    @click="$emit('click')"
  >
    <span :class="classes.label" v-text="label" />
    <div
      id="underline"
      class="absolute left-0 right-0 bottom-0 h-1 bg-teal-200 transition-transform transition-100"
      :class="classes.underline"
    />
  </BaseButton>
</template>

<script>
import BaseButton from '~/components/BaseButton';

export default {
  name: 'AdminTableFilter',
  components: {
    BaseButton,
  },
  props: {
    active: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    label: {
      type: String,
      required: true,
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    classes() {
      return {
        label: {
          'opacity-50 hover:opacity-100': !this.active,
        },
        underline: {
          'translate-filter-indicator': !this.active,
        },
      };
    },
  },
};
</script>
