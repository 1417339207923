<template>
  <div class="p-6 md:p-8 xl:p-10 border-2 border-grey-300 rounded-t">
    <PaymentMethod value="stripe">
      <h2 class="text-2xl">Secure credit card payment</h2>
    </PaymentMethod>
    <FormSection :class="{ hidden: !active }">
      <FormRow class="mt-2">
        <div class="flex items-center text-charcoal-100">
          <BaseGlyph id="lock" />
          <span class="ml-2">
            Your transaction is protected with bank level 256-bit encryption.
          </span>
        </div>
      </FormRow>
      <FormRow>
        <div class="w-full">
          <div class="flex flex-wrap xl:flex-no-wrap">
            <div class="w-full xl:mr-4">
              <label for="card-number" class="input-label"> Card Number </label>
              <div id="card-number" ref="cardNumber" />
            </div>
            <div class="flex w-full xl:w-auto">
              <div class="xl:flex-none w-1/2 xl:w-48 mr-4">
                <label for="card-expiry" class="input-label"> Expiry </label>
                <div id="card-expiry" ref="cardExpiry" />
              </div>
              <div class="xl:flex-none w-1/2 xl:w-28">
                <label for="card-cvc" class="input-label"> CVC </label>
                <div id="card-cvc" ref="cardCvc" />
              </div>
            </div>
          </div>
        </div>
      </FormRow>
      <FormRow>
        <div class="flex justify-end w-full mt-2">
          <img
            :src="require('~/assets/img/credit-cards/visa.png')"
            class="w-10 h-6 mr-2"
          />
          <img
            :src="require('~/assets/img/credit-cards/mastercard.png')"
            class="w-10 h-6 mr-2"
          />
          <img
            :src="require('~/assets/img/credit-cards/american-express.png')"
            class="w-10 h-6"
          />
        </div>
      </FormRow>
      <FormRow>
        <BaseAlert v-if="paymentError" class="w-full mt-2" type="error">
          {{ paymentError }}
        </BaseAlert>
      </FormRow>
    </FormSection>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import BaseAlert from '~/components/BaseAlert';
import BaseGlyph from '~/components/BaseGlyph';
import FormRow from '~/components/FormRow';
import FormSection from '~/components/FormSection';
import PaymentMethod from '~/components/PaymentMethod';

import SUBMIT_WILL_MUTATION from '~/graphql/mutations/SubmitWill';
import { formatError } from '~/utilities';
import { subscription, user, will } from '~/mixins/apollo';

export default {
  name: 'PaymentStripe',
  components: {
    BaseAlert,
    BaseGlyph,
    FormRow,
    FormSection,
    PaymentMethod,
  },
  mixins: [subscription, user, will],
  props: {
    error: { type: String, default: '' },
    addPartnersWill: {
      type: Boolean,
    },
  },
  data() {
    return {
      cardCvc: null,
      cardNumber: null,
      cardExpiry: null,
      elements: null,
      stripe: null,
      stripeClasses: {
        base: 'input py-6',
        focus: 'input-focus',
        invalid: 'input-error',
      },
      stripeStyles: {
        base: {
          fontFamily: 'Larsseit, Helvetica, Arial',
          fontSize: '20px',
          fontWeight: '400',
          fontSmoothing: 'antialiased',
          lineHeight: '1.6',
          '::-ms-clear': {
            display: 'none',
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters('checkout', [
      'checkoutAction',
      'couponCode',
      'paymentMethod',
      'paymentLoading',
      'paymentError',
      'paymentSuccess',
      'paymentProcessing',
      'finalSetupPrice',
    ]),
    active() {
      return this.paymentMethod === 'stripe';
    },
  },
  async mounted() {
    if (typeof window !== 'undefined') {
      this.stripe = await this.$stripe.import();
      this.createStripeElements();
    }

    if (this.$route.query.success) {
      this.setPaymentProcessing(false);
      this.setPaymentLoading(false);
      this.setPaymentSuccess(true);
    }

    this.$nuxt.$on('checkout', async () => {
      if (this.paymentMethod !== 'stripe' || this.paymentSuccess) return;

      try {
        this.disabledStripeElements(true);
        this.setPaymentLoading(true);
        const { token, error } = await this.stripe.createToken(this.cardNumber);
        if (error) {
          this.setPaymentLoading(false);
          this.setPaymentError(error.message);

          this.$nuxt.$emit('sendTrackingEvent', {
            event: '❌ Payment Failed',
            props: {
              error: error.message,
            },
          });
        } else if (token) {
          await this.takeStripePayment(token);
        }
      } catch (error) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '❌ Payment Failed',
          props: {
            error: error.message,
          },
        });
      } finally {
        this.disabledStripeElements(false);
        this.$nuxt.$emit('unlockForm');
      }
    });
  },
  methods: {
    ...mapActions('checkout', [
      'setPaymentLoading',
      'setPaymentError',
      'setPaymentSuccess',
      'setPaymentProcessing',
      'submitWill',
    ]),
    createStripeElements() {
      this.elements = this.stripe.elements({
        fonts: [
          {
            family: 'Larsseit',
            src: `url('${
              window.location.origin
            }${require('~/static/fonts/Larsseit/Regular.ttf')}') format('truetype')`,
            weight: '400',
          },
        ],
      });

      this.cardNumber = this.elements.create('cardNumber', {
        classes: this.stripeClasses,
        style: this.stripeStyles,
        placeholder: '0000 0000 0000 0000',
        showIcon: true,
      });
      this.cardNumber.mount('#card-number');
      this.$emit('change', this.cardNumber);

      this.cardExpiry = this.elements.create('cardExpiry', {
        classes: this.stripeClasses,
        style: this.stripeStyles,
      });
      this.cardExpiry.mount('#card-expiry');

      this.cardCvc = this.elements.create('cardCvc', {
        classes: this.stripeClasses,
        style: this.stripeStyles,
      });
      this.cardCvc.mount('#card-cvc');
    },
    disabledStripeElements(disabled) {
      if (this.cardNumber) {
        this.cardNumber.update({ disabled });
      }

      if (this.cardExpiry) {
        this.cardExpiry.update({ disabled });
      }

      if (this.cardCvc) {
        this.cardCvc.update({ disabled });
      }
    },
    async takeStripePayment(token) {
      this.setPaymentError(null);
      this.setPaymentLoading(true);

      if (this.checkoutAction === 'submit' && !this.paymentSuccess) {
        const willProduct = this.addPartnersWill ? 'WILL_X2' : 'WILL';
        const hasProfessionalExecutor = [
          'professional',
          'friendsFamilyAndProfessional',
        ].includes(this.willMeta.executors_option);

        try {
          const { data } = await this.$apollo.mutate({
            mutation: SUBMIT_WILL_MUTATION,
            variables: {
              id: this.willId,
              cardToken: token.id,
              coupon: this.couponCode || '',
              products: [willProduct],
            },
          });
          if (data.submitWill && data.submitWill.success) {
            this.setWillStatus(data.submitWill.will.status);

            this.$nuxt.$emit('sendTrackingEvent', {
              event: '📄 Submit Will',
              props: {
                ...(this.couponCode && { coupon_code: this.couponCode }),
                price: this.finalSetupPrice,
                gateway: 'Stripe',
              },
              integrations: {
                Amplitude: false,
                Intercom: false,
                'Facebook Pixel': false,
                'Facebook Pixel Server Side': false,
                'Personas Facebook Custom Audiences': false,
              },
            });

            this.$nuxt.$emit('sendTrackingAttributes', {
              ...(this.couponCode && { coupon_code: this.couponCode }),
              price: this.finalSetupPrice,
              has_professional_executor: hasProfessionalExecutor,
            });

            if (hasProfessionalExecutor) {
              this.$nuxt.$emit('sendTrackingEvent', {
                event: 'Professional Executor Appointed',
              });
            }

            this.setPaymentLoading(false);
            this.setPaymentSuccess(true);

            if (this.addPartnersWill) {
              this.willMeta.partner_bundle = 'true';
              this.updateWillMeta();
            }

            this.$router.push({
              path: this.$route.path,
              query: {
                success: true,
              },
            });
          }
        } catch (error) {
          this.setPaymentLoading(false);
          this.setPaymentError(formatError(error.message));

          this.$nuxt.$emit('sendTrackingEvent', {
            event: '❌ Payment Failed',
            props: {
              error: this.paymentError,
            },
          });
        } finally {
          this.$nuxt.$emit('unlockForm');
        }
      } else {
        try {
          const { data, errors } = await this.updateSubscription(token.id);
          if (data.updateSubscription && data.updateSubscription.success) {
            this.setPaymentSuccess(true);
            this.$nuxt.$emit('sendTrackingEvent', {
              event: '💳 Update Payment Details',
            });
          } else {
            this.setPaymentError(formatError(errors[0].message));
            this.setPaymentLoading(false);
            this.$nuxt.$emit('sendTrackingEvent', {
              event: '❌ Payment Failed',
              props: {
                error: this.paymentError,
              },
            });
          }
        } catch (error) {
          this.setPaymentError(formatError(error.message));
          this.setPaymentLoading(false);
          this.$nuxt.$emit('sendTrackingEvent', {
            event: '❌ Payment Failed',
            props: {
              error: this.paymentError,
            },
          });
        } finally {
          this.$nuxt.$emit('unlockForm');
        }

        if (this.checkoutAction === 'renew') {
          await this.reactivateSubscription(this.couponCode);
        }
      }
    },
  },
};
</script>
