var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleStep',{attrs:{"index":4,"prev-step":_vm.previous,"show-help":true}},[_c('Form',{attrs:{"hide-submit":!_vm.isComplete,"submit-arrow":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('FormSection',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        Would you like to appoint\n        "+_vm._s(_vm.canHaveMultipleSubstitutes
            ? 'substitute decision makers'
            : 'a substitute decision maker')+"?\n      ")]},proxy:true}],null,true)},[_vm._v(" "),(['nsw'].includes(_vm.userState))?[_c('p',{staticClass:"mb-4"},[_vm._v("\n          Your substitute decision maker will only take over if none of your\n          original decision makers are able to act.\n        ")])]:(['vic', 'wa'].includes(_vm.userState))?[_c('p',{staticClass:"mb-4"},[_vm._v("\n          Your substitute decision maker will replace any of your original\n          decision makers that are unable to act.\n        ")]),_vm._v(" "),(['wa'].includes(_vm.userState))?_c('Tip',[_vm._v("\n          If you appointed “joint” attorneys in the previous section, and do\n          not appoint a substitute, the power of attorney will terminate if\n          one of your joint attorneys dies.\n        ")]):_vm._e()]:(['qld', 'act', 'nt'].includes(_vm.userState))?[_c('p',{staticClass:"mb-4"},[_vm._v("\n          Your substitute decision-"+_vm._s(_vm.canHaveMultipleSubstitutes ? 'makers' : 'maker')+"\n          will only take over if none of your original decision makers are\n          able to act.\n        ")])]:_vm._e(),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"has_substitute","options":[
          {
            label: ("Yes, I'd like to appoint " + (_vm.canHaveMultipleSubstitutes
                ? 'substitute decision makers'
                : 'a substitute decision maker')),
            value: true,
          },
          {
            label: ("No, I don't want to appoint  " + (_vm.canHaveMultipleSubstitutes
                ? 'any substitute decision makers'
                : 'a substitute decision maker')),
            value: false,
          } ]},on:{"change":function($event){return _vm.onSubmit()}},model:{value:(_vm.poaFinancialMeta.has_substitute),callback:function ($$v) {_vm.$set(_vm.poaFinancialMeta, "has_substitute", $$v)},expression:"poaFinancialMeta.has_substitute"}})],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }