<template>
  <svg
    :width="size"
    :height="size"
    :viewBox="`0 0 ${size} ${size}`"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        id="ring-gradient"
        x1="8.042%"
        y1="0%"
        x2="65.682%"
        y2="23.865%"
      >
        <stop stop-color="#00dabf" stop-opacity="0" offset="0%" />
        <stop stop-color="#00dabf" stop-opacity=".631" offset="63.146%" />
        <stop stop-color="#00dabf" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(1 1)">
        <path :d="d" stroke="url(#ring-gradient)" :stroke-width="stroke">
          <animateTransform
            attributeName="transform"
            type="rotate"
            :from="`0 ${y} ${y}`"
            :to="`360 ${y} ${y}`"
            dur="0.9s"
            repeatCount="indefinite"
          />
        </path>
        <circle fill="#00dabf" :cx="x" :cy="y" r="1">
          <animateTransform
            attributeName="transform"
            type="rotate"
            :from="`0 ${y} ${y}`"
            :to="`360 ${y} ${y}`"
            dur="0.9s"
            repeatCount="indefinite"
          />
        </circle>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'LoadingRing',
  props: {
    size: {
      default: 20,
      type: Number,
    },
    stroke: {
      default: 2,
      type: Number,
    },
  },
  computed: {
    x() {
      return this.size - this.stroke;
    },
    y() {
      return this.x / 2;
    },
    d() {
      return `M${this.x} ${this.y}c0-${(this.y * 9.94) / 18}-${(this.y * 8.06) /
        18}-${this.y}-${this.y}-${this.y}`;
    },
  },
};
</script>
