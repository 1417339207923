<template>
  <div>
    <h2 class="w-115 pr-20 mb-6 lg:mb-8 text-5xl leading-tight">
      Invite your friends and
      <Squiggle>earn $50</Squiggle>
    </h2>
    <MainSideLayout>
      <template #side>
        <p class="mb-8 lg:mb-16 text-2xl">
          Share a discount with friends and family to write their own will. Earn
          $10 for each person that completes their will with us.
        </p>
        <Tip>
          We will refund your purchase card by $10 for each person who completes
          their Will
        </Tip>
      </template>
      <template #main>
        <div class="mb-12">
          <BaseCard
            v-if="!invitedByPartner"
            class="mb-4 last-child:mb-0"
            badge="Give 50% off"
            badge-color="bg-slate-100"
            primary-text="Partner Discount"
            secondary-text="Invite your partner to get 50% off the price of their will. You can only invite one person to receive your partner discount."
          >
            <SentInvite
              v-for="invite in invites"
              :key="invite.id"
              :invite="invite"
              type="PARTNER"
            />
            <SendInvite
              v-if="!hasInvitedPartner"
              :invites="invites"
              type="PARTNER"
              :prefill="partnerEmail"
            />
          </BaseCard>
          <BaseCard
            class="mb-4 last-child:mb-0"
            badge="Give 20% off"
            badge-color="bg-teal-200"
            primary-text="Friends and Family Discount"
            secondary-text="Invite friends and family to get 20% off the price of their will. You can invite as many people as you like. Earn $10 for each person that completes a will, up to $50."
          >
            <div
              class="bg-teal-100 py-4 px-8 mb-4 rounded flex justify-between"
            >
              <p>Total saved</p>
              <p>${{ totalSaved }} of $50</p>
            </div>
            <h4 class="text-lg text-black leading-normal">Invite via email</h4>
            <SentInvite
              v-for="invite in invites"
              :key="invite.id"
              :invite="invite"
              type="DEFAULT"
            />
            <SendInvite
              v-for="(friend, i) in friends"
              :key="i"
              :invites="invites"
              :prefill="friend"
            />
            <SendInvite :invites="invites" />
            <div class="relative my-6">
              <div
                class="relative text-grey-300 mx-auto w-12 text-center bg-white mt-2 text-xl bold z-10 px-2"
              >
                OR
              </div>
              <div
                class="border-t-2 border-grey-300 absolute w-full mt-4 top-0 z-0"
              />
            </div>
            <h4 class="text-lg text-black leading-normal">
              Share your personal link
            </h4>
            <LinkInvite :link="inviteLink" />
          </BaseCard>
        </div>
      </template>
    </MainSideLayout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import MainSideLayout from '~/components/MainSideLayout';
import Tip from '~/components/Tip';
import BaseCard from '~/components/BaseCard';
import SendInvite from '~/components/SendInvite';
import SentInvite from '~/components/SentInvite';
import LinkInvite from '~/components/LinkInvite';
import Squiggle from '~/components/Squiggle';
import { people, invites, user } from '~/mixins/apollo';
import { metaArrayToObject } from '~/utilities';

export default {
  name: 'DashboardInvite',
  components: {
    MainSideLayout,
    Tip,
    BaseCard,
    SendInvite,
    LinkInvite,
    SentInvite,
    Squiggle,
  },
  mixins: [people, invites, user],
  computed: {
    ...mapGetters('checkout', ['invitedByPartner']),
    inviteLink() {
      const code = (this.publicInvite && this.publicInvite.code) || '';
      return `https://safewill.com/i/${code.toLowerCase()}`;
    },
    hasInvitedPartner() {
      return !!this.invites.find((i) => i.type === 'PARTNER');
    },
    partnerEmail() {
      const meta = this.partners[0] && metaArrayToObject(this.partners[0].meta);
      return (meta && meta.email) || '';
    },
    friends() {
      const emails = this.people.filter((p) => {
        if (p.category !== 'partner' && !this.minors.includes(p)) {
          const meta = metaArrayToObject(p.meta);
          if (meta && meta.email) {
            return !this.invites.find((i) => i.email === meta.email);
          }
        }
      });
      return emails.map((p) => {
        const meta = metaArrayToObject(p.meta);
        if (meta.email) return meta.email;
      });
    },
    totalSaved() {
      const accepted = this.invites.filter((i) => i.purchased);
      return Math.min(accepted.length * 10, 50);
    },
  },
  methods: {
    getTooltip(type) {
      return this.willStatus === 'APPROVED'
        ? `Unlock your Will to add more ${type}.`
        : `You can't add more ${type} while your Will is being reviewed.`;
    },
  },
};
</script>
