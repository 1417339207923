<template>
  <div>
    <template>
      <div class="pt-5">
        <h4
          v-for="(item, i) in checkoutItems"
          :key="`item-${i}`"
          class="flex justify-between text-lg mb-6"
        >
          <div class="flex items-center">
            <BaseIcon :id="item.icon" />
            <span class="ml-4">{{ item.name }}</span>
          </div>
          <span>${{ displayPrice }}</span>
        </h4>
        <div v-if="couponCode" class="mb-6">
          <span
            v-show="couponCode"
            v-text="`${couponCode} Discount Applied.`"
          />
          <BaseButton
            v-if="couponCode"
            class="text-teal-200 underline hover:no-underline"
            @click="this.$emit('applyCoupon', null)"
          >
            Remove
          </BaseButton>
        </div>
        <div
          class="py-5 flex justify-between border-t-2 text-xl border-grey-300"
        >
          <span>Total</span>
          <div>
            <span class="text-grey-600 text-base">AUD</span>
            <span>${{ displayPrice }}</span>
          </div>
        </div>
      </div>
      <div v-if="totalPrice === 0" class="py-5 border-t-2 border-grey-300">
        <p class="flex justify-between text-base">
          <span>
            A valid credit card is required for identity verification processes
          </span>
        </p>
      </div>
    </template>

    <div class="py-5 border-t-2 border-grey-300">
      <GenericCouponInput :hidden="!!couponCode" />
    </div>
  </div>
</template>

<script>
import BaseIcon from '~/components/BaseIcon';
import BaseButton from '~/components/BaseButton';
import GenericCouponInput from '~/components/GenericCouponInput';

export default {
  name: 'GenericCheckoutSummary',
  components: {
    BaseIcon,
    GenericCouponInput,
    BaseButton,
  },
  props: {
    checkoutItems: {
      type: Array,
      required: true,
    },
    totalPrice: {
      type: Number,
      required: true,
    },
    couponCode: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    displayPrice() {
      return this.totalPrice / 100;
    },
    classes() {
      return {
        'bg-teal-100': this.success,
        'bg-white': !this.success,
      };
    },
  },
};
</script>
