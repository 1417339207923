<template>
  <div />
</template>

<script>
import { mapActions } from 'vuex';
import { removeLegacyCookie } from '@/modules/cookies';

export default {
  name: 'LogOut',
  layout: 'minimal',
  async mounted() {
    removeLegacyCookie();
    await this.$apolloHelpers.onLogout();
    this.resetUserState();
    this.resetCheckoutState();
    this.resetUI();
    this.$analytics.reset();
    await this.$router.push({
      path: this.$route.query.redirect || '/log-in',
    });
  },
  methods: {
    ...mapActions(['resetUserState']),
    ...mapActions('ui', ['resetUI']),
    ...mapActions('checkout', ['resetCheckoutState']),
  },
};
</script>
