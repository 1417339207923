<template>
  <transition appear :css="false" @enter="enter" @leave="leave">
    <div class="relative z-40">
      <div
        ref="background"
        class="fixed inset-0 bg-shade-300 cursor-w-resize"
        @click="close"
      />
      <div
        ref="pane"
        class="fixed top-0 right-0 bottom-0 flex flex-col w-screen md:w-120 overflow-hidden bg-grey-100"
      >
        <div
          class="flex flex-shrink-0 justify-between items-center h-24 md:h-28 px-6 md:px-10 bg-white transition-shadow transition-500 transition-ease"
          :class="classes"
        >
          <h2 class="font-medium text-lg" v-text="title" />
          <BaseButton @click="close">
            <BaseGlyph id="close" />
          </BaseButton>
        </div>
        <div
          class="flex-grow px-6 md:px-10 pt-10 md:pt-12 pb-15 overflow-y-auto scrolling-touch"
          @scroll="onScroll"
        >
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import anime from 'animejs';

import BaseButton from '~/components/BaseButton';
import BaseGlyph from '~/components/BaseGlyph';

export default {
  name: 'BasePanel',
  components: {
    BaseButton,
    BaseGlyph,
  },
  props: {
    title: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      scrollTop: 0,
    };
  },
  computed: {
    classes() {
      return {
        shadow: this.scrollTop > 0,
      };
    },
  },
  mounted() {
    window.addEventListener('keydown', this.keyHandler);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyHandler);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    enter(el, complete) {
      const timeline = anime.timeline({ complete });

      timeline
        .add({
          targets: this.$refs.pane,
          translateX: ['100%', 0],
          translateZ: 0,
          duration: 650,
          easing: 'cubicBezier(0.4, 0, 0.2, 1)',
        })
        .add(
          {
            targets: this.$refs.background,
            opacity: [0, 1],
            duration: 250,
            easing: 'linear',
          },
          0
        );
    },
    leave(el, complete) {
      const timeline = anime.timeline({
        complete: () => {
          this.$emit('done');
          complete();
        },
      });

      timeline
        .add({
          targets: this.$refs.pane,
          translateX: [0, '100%'],
          translateZ: 0,
          duration: 650,
          easing: 'cubicBezier(0.4, 0, 0.2, 1)',
        })
        .add(
          {
            targets: this.$refs.background,
            opacity: [1, 0],
            duration: 250,
            easing: 'linear',
          },
          250
        );
    },
    onScroll(e) {
      this.scrollTop = e.target.scrollTop;
    },
    keyHandler(e) {
      switch (e.keyCode) {
        case 27: // ESC
          e.preventDefault();
          this.close();
          break;
        default:
          break;
      }
    },
  },
};
</script>
