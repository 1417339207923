<template>
  <ModuleStep :index="1" prev-step="/poa/dashboard" :show-help="true">
    <Form
      v-slot="{ loading }"
      auto-focus
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      submit-arrow
      @done="onDone"
    >
      <h1
        class="font-medium md:font-normal leading-tight text-4xl md:text-5xl lg:text-5xl mb-6"
      >
        Let's confirm your details.
      </h1>
      <FormSection class="step-1">
        <template #heading>What's your full name?</template>
        <FormRow>
          <TextInput
            id="name_first"
            v-model="willMeta.name_first"
            :disabled="loading"
            placeholder="Your first name"
            rules="required"
          />
        </FormRow>
        <FormRow>
          <TextInput
            id="name_middle"
            v-model="willMeta.name_middle"
            :disabled="loading"
            placeholder="Your middle name (optional)"
          />
        </FormRow>
        <FormRow>
          <TextInput
            id="name_last"
            v-model="willMeta.name_last"
            :disabled="loading"
            placeholder="Your last name"
            rules="required"
          />
        </FormRow>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';

import Form from '~/components/Form';
import FormRow from '~/components/FormRow';
import FormSection from '~/components/FormSection';
import ModuleStep from '~/components/ModuleStep';
import TextInput from '~/components/TextInput';

import { will } from '~/mixins/apollo';
import { getUserFullName } from '~/utilities';

export default {
  name: 'POAAboutYourself',
  components: {
    Form,
    FormRow,
    FormSection,
    ModuleStep,
    TextInput,
  },
  mixins: [will],
  computed: {
    ...mapGetters('ui', ['australianStates']),
    isComplete() {
      return !!(this.willMeta.name_first && this.willMeta.name_last);
    },
  },
  methods: {
    onDone() {
      const willMeta = this.willMeta;

      this.$nuxt.$emit('sendTrackingAttributes', {
        name: getUserFullName(willMeta),
      });

      this.$router.push({
        path: '/poa/about-yourself/address',
      });
    },
  },
};
</script>
