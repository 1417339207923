<template>
  <BaseModal v-if="visible" hide-close>
    <div ref="container">
      <div>
        <h2 class="mb-4 text-4xl leading-tight" v-text="title" />
        <p v-text="message" />
      </div>
      <div class="flex items-baseline mt-8">
        <BaseButton class="mr-auto text-xl text-grey-600" @click="close(false)">
          Cancel
        </BaseButton>
        <BaseButton class="btn-xl btn-slate" @click="close(true)">
          Confirm
        </BaseButton>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseButton from '~/components/BaseButton';
import BaseModal from '~/components/BaseModal';

export default {
  name: 'WelcomeModal',
  components: {
    BaseButton,
    BaseModal,
  },
  data() {
    return {
      title: '',
      message: '',
      callback: null,
      visible: false,
    };
  },
  methods: {
    close(action) {
      this.visible = false;
      this.callback(action);
    },
  },
};
</script>
