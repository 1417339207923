<template>
  <div>
    <div class="relative mt-4">
      <div class="relative">
        <BaseInput
          id="referralLink"
          ref="referralLink"
          v-model="link"
          classes="input-copy"
          :disabled="disabled"
          :ignore-disabled="true"
          name="referralLink"
          :value="link"
        />
        <div
          class="pt-4 xl:pt-0 xl:absolute xl:top-0 xl:right-0 xl:bottom-0 flex items-center xl:pr-6"
        >
          <BaseButton
            id="test-copylink"
            class="btn btn-xl btn-outline w-full xl:w-auto xl:p-2 xl:h-10"
            @click="copyLink()"
          >
            {{ btnText }}
          </BaseButton>
        </div>
      </div>
    </div>
    <div class="flex flex-col xl:flex-row mt-4">
      <BaseButton
        id="test-facebook"
        class="btn-slate btn-xl w-full xl:mr-2"
        style="background: #3d5b96;"
        @click="shareFacebook()"
      >
        <div class="flex justify-center items-center">
          <span class="mr-2 text-white">
            <BaseIcon id="facebook" />
          </span>
          <span class="inline-block text-white">Share on Facebook</span>
        </div>
      </BaseButton>
      <BaseButton
        id="test-whatsapp"
        class="btn-slate btn-xl w-full mt-4 xl:mt-0 xl:ml-2"
        style="background: #33d26b;"
        @click="shareWhatsApp()"
      >
        <div class="flex justify-center items-center">
          <span class="mr-2 text-white">
            <BaseIcon id="whatsapp" />
          </span>
          <span class="inline-block text-white">Share on WhatsApp</span>
        </div>
      </BaseButton>
    </div>
  </div>
</template>

<script>
import copy from 'copy-to-clipboard';

import BaseButton from '~/components/BaseButton';
import BaseInput from '~/components/BaseInput';
import BaseIcon from '~/components/BaseIcon';

export default {
  name: 'LinkInvite',
  components: {
    BaseButton,
    BaseInput,
    BaseIcon,
  },
  props: {
    hidden: {
      default: false,
      type: Boolean,
    },
    link: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      copied: false,
      disabled: true,
      timeout: null,
    };
  },
  computed: {
    btnText() {
      return this.error ? 'Error!' : this.copied ? 'Copied!' : 'Copy Link';
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  methods: {
    copyLink() {
      copy(this.link);
      this.copied = true;
      this.timeout = setTimeout(() => {
        this.copied = false;
      }, 3000);
    },
    popup(url, title, w, h) {
      const y = window.top.outerHeight / 2 + window.top.screenY - h / 2;
      const x = window.top.outerWidth / 2 + window.top.screenX - w / 2;
      return window.open(
        url,
        title,
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
      );
    },
    shareWhatsApp() {
      this.popup(
        `https://api.whatsapp.com/send?text=${this.link.toLowerCase()}`,
        'Share via WhatsApp',
        600,
        600
      );
    },
    shareFacebook() {
      this.popup(
        `https://www.facebook.com/sharer/sharer.php?u=${this.link.toLowerCase()}`,
        'Share via Facebook',
        600,
        600
      );
    },
  },
};
</script>
