<template>
  <div>
    <AdminMasquerade />
    <OfflineAlert />
    <MaintenanceAlert v-if="showMaintenanceAlert" />
  </div>
</template>

<script>
import AdminMasquerade from '~/components/AdminMasquerade';
import MaintenanceAlert from '~/components/MaintenanceAlert';
import OfflineAlert from '~/components/OfflineAlert';

export default {
  name: 'GlobalHeader',
  components: {
    AdminMasquerade,
    MaintenanceAlert,
    OfflineAlert,
  },
  data() {
    return {
      showMaintenanceAlert: false,
    };
  },
};
</script>
