<template>
  <div>
    <h2 class="w-115 pr-20 mb-8 text-5xl leading-tight">Account Settings</h2>
    <MainSideLayout>
      <template #side>
        <p class="mb-16 text-2xl">Manage your account details.</p>
        <Tip>
          Can’t find what you’re looking for? Start a live chat and we’ll get
          back right away.
        </Tip>
      </template>
      <template #main>
        <AccountSection heading="Your Email" divider>
          <Form
            v-slot="{ loading }"
            submit-classes="w-full max-w-sm"
            submit-label="Update email"
            @submit="setShowConfirmPasswordModal(true)"
          >
            <FormSection>
              <FormRow>
                <div class="w-full">
                  <TextInput
                    id="email"
                    v-model="email"
                    :disabled="loading"
                    label="Email"
                    rules="required|email"
                    type="email"
                  />
                  <div class="flex items-center mt-4 leading-none">
                    <div
                      class="flex justify-center items-center w-5 h-5 rounded-full mr-2 bg-teal-200 text-white"
                      :class="verified ? 'bg-teal-200' : 'bg-orange-200'"
                    >
                      <BaseGlyph
                        :id="verified ? 'tick-small' : 'question-mark'"
                      />
                    </div>
                    <span
                      class="opacity-75 text-slate-100"
                      v-text="verified ? 'Verified' : 'Unverified'"
                    />
                    <BaseButton
                      v-if="!verified"
                      class="ml-2 text-teal-300 underline"
                      @click="resendWelcomeEmail"
                    >
                      {{
                        resendTimeout ? 'Sent!' : 'Resend Verification Email'
                      }}
                    </BaseButton>
                    <div v-if="resendLoading" class="ml-2">
                      <LoadingRing />
                    </div>
                  </div>
                  <p
                    v-if="welcomeEmailSent"
                    class="mt-4 font-medium text-orange-400"
                  >
                    If you can’t find an email from us, remember to check your
                    spam folder.
                  </p>
                </div>
              </FormRow>
            </FormSection>
          </Form>
        </AccountSection>
        <AccountSection heading="Your Details" divider>
          <Form
            v-slot="{ loading }"
            :mutation="UPDATE_WILL_MUTATION"
            :variables="UPDATE_WILL_VARS"
            submit-classes="w-full max-w-sm"
            submit-label="Save changes"
            @done="updateDetails"
          >
            <FormSection>
              <FormRow>
                <TextInput
                  id="name_first"
                  v-model="willMeta.name_first"
                  class="w-full"
                  :disabled="loading"
                  label="First Name"
                  rules="required"
                />
                <TextInput
                  id="name_middle"
                  v-model="willMeta.name_middle"
                  class="flex-shrink md:ml-4"
                  :disabled="loading"
                  label="Middle Name"
                  placeholder="None"
                />
              </FormRow>
              <FormRow>
                <TextInput
                  id="name_last"
                  v-model="willMeta.name_last"
                  class="md:w-1/2"
                  :disabled="loading"
                  label="Last Name"
                  rules="required"
                />
              </FormRow>
              <FormRow>
                <TextInput
                  id="phone"
                  v-model="willMeta.phone_number"
                  class="md:w-1/2"
                  :disabled="loading"
                  label="Phone Number"
                  type="tel"
                  rules="phone"
                />
              </FormRow>
              <FormRow>
                <TextInput
                  id="address_street"
                  v-model="willMeta.address_street"
                  class="md:w-1/2"
                  :disabled="loading"
                  label="Street Address"
                  rules="required"
                />
              </FormRow>
              <FormRow>
                <TextInput
                  id="address_suburb"
                  v-model="willMeta.address_suburb"
                  class="md:w-1/2"
                  :disabled="loading"
                  label="Suburb"
                  rules="required"
                />
                <div class="flex-shrink-0 md:w-56 md:ml-4">
                  <SelectInput
                    id="address_state"
                    v-model="willMeta.address_state"
                    :options="australianStates"
                    :disabled="loading"
                    label="State"
                    searchable
                  />
                </div>
                <div class="flex-shrink-0 md:w-32 md:ml-4">
                  <TextInput
                    id="address_postcode"
                    v-model="willMeta.address_postcode"
                    :disabled="loading"
                    label="Postcode"
                    rules="required"
                  />
                </div>
              </FormRow>
            </FormSection>
          </Form>
        </AccountSection>
        <AccountSection heading="Change your password" divider>
          <Form
            v-slot="{ loading }"
            :mutation="UPDATE_PASSWORD_MUTATION"
            :variables="updatePasswordVariables"
            submit-classes="w-full max-w-sm"
            submit-label="Update password"
            @done="resetPasswordInputs"
          >
            <FormSection class="max-w-xl">
              <FormRow>
                <PasswordInput
                  id="oldPassword"
                  v-model="oldPassword"
                  :disabled="loading"
                  label="Old password"
                  placeholder="Enter your old password"
                />
              </FormRow>
              <FormRow>
                <PasswordInput
                  id="newPassword"
                  v-model="newPassword"
                  :disabled="loading"
                  label="New password"
                  placeholder="Enter a new password"
                />
              </FormRow>
              <FormRow>
                <PasswordValidator
                  v-model="validPassword"
                  :input="newPassword"
                  :exclude="[
                    willMeta.name_first,
                    willMeta.name_middle,
                    willMeta.name_last,
                    willMeta.alt_name_first,
                    willMeta.alt_name_middle,
                    willMeta.alt_name_last,
                    email,
                  ]"
                />
              </FormRow>
            </FormSection>
            <p
              v-if="hasChangedPassword && !oldPassword && !newPassword"
              class="text-teal-200 text-base mb-4"
            >
              Your password has been changed
            </p>
          </Form>
        </AccountSection>
        <AccountSection
          id="billing"
          heading="Update your billing details"
          :divider="showNotifyCharities"
        >
          <SubscriptionStatusCard primary-text="Unlimited Will Updates" />
        </AccountSection>
        <AccountSection
          v-if="showNotifyCharities"
          heading="Charity Contact Consent"
        >
          <Form
            v-slot="{ loading }"
            :mutation="UPDATE_WILL_NOTIFY_CHARITY_MUTATION"
            :variables="UPDATE_WILL_NOTIFY_CHARITY_VARS"
            submit-classes="w-full max-w-sm"
            submit-label="Save changes"
            @done="updateWills"
          >
            <FormSection class="max-w-3xl">
              <Tip>
                We'll share your donation and contact details with the recipient
                charity(s) so that they can plan for the gift and maximise its
                impact.
              </Tip>
              <LargeRadioButtons
                id="notifyCharities"
                v-model="willMeta.notify_charities"
                horizontal
                :options="[
                  {
                    label: 'Yes',
                    value: true,
                  },
                  {
                    label: 'No',
                    value: false,
                  },
                ]"
              />
            </FormSection>
          </Form>
        </AccountSection>
      </template>
    </MainSideLayout>
    <BaseModal v-if="showConfirmPasswordModal" @close="closeModal">
      <div
        class="flex justify-center items-center w-10 h-10 rounded-full mb-4 bg-teal-300 text-white"
      >
        <BaseGlyph id="lock" />
      </div>
      <p class="max-w-xs mb-4 text-3xl text-teal-300">
        Confirm password to continue
      </p>
      <Form
        v-slot="{ loading }"
        auto-focus
        :mutation="UPDATE_EMAIL_MUTATION"
        :variables="updateEmailVariables"
        submit-label="Confirm Password"
        @done="updateToken"
      >
        <FormSection>
          <PasswordInput
            id="confirmPassword"
            v-model="confirmPassword"
            :disabled="loading"
            label="Password"
            placeholder="Enter password"
          />
        </FormSection>
      </Form>
    </BaseModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import RESEND_WELCOME_EMAIL from '~/graphql/mutations/ResendWelcomeEmail';
import UPDATE_EMAIL_MUTATION from '~/graphql/mutations/UpdateEmail';
import UPDATE_PASSWORD_MUTATION from '~/graphql/mutations/UpdatePassword';
import UPDATE_WILL_NOTIFY_CHARITY_MUTATION from '~/graphql/mutations/UpdateNotifyCharityOfWill';

import AccountSection from '~/components/AccountSection';
import BaseButton from '~/components/BaseButton';
import BaseGlyph from '~/components/BaseGlyph';
import BaseModal from '~/components/BaseModal';
import Form from '~/components/Form';
import FormRow from '~/components/FormRow';
import FormSection from '~/components/FormSection';
import LoadingRing from '~/components/LoadingRing';
import MainSideLayout from '~/components/MainSideLayout';
import PasswordInput from '~/components/PasswordInput';
import PasswordValidator from '~/components/PasswordValidator';
import SelectInput from '~/components/SelectInput';
import SubscriptionStatusCard from '~/components/SubscriptionStatusCard';
import TextInput from '~/components/TextInput';
import Tip from '~/components/Tip';
import LargeRadioButtons from '~/components/LargeRadioButtons';

import { user, will } from '~/mixins/apollo';

export default {
  name: 'DashboardAccountSettings',
  components: {
    AccountSection,
    BaseButton,
    BaseGlyph,
    BaseModal,
    Form,
    FormRow,
    FormSection,
    LoadingRing,
    MainSideLayout,
    PasswordInput,
    PasswordValidator,
    SelectInput,
    SubscriptionStatusCard,
    TextInput,
    Tip,
    LargeRadioButtons,
  },
  mixins: [user, will],
  data() {
    return {
      UPDATE_WILL_NOTIFY_CHARITY_MUTATION,
      UPDATE_EMAIL_MUTATION,
      UPDATE_PASSWORD_MUTATION,
      confirmPassword: '',
      email: this.$store.state.email,
      oldPassword: '',
      newPassword: '',
      resendEmailText: 'Resend verification email',
      resendLoading: false,
      resendTimeout: null,
      validPassword: false,
      welcomeEmailSent: false,
      hasChangedPassword: false,
    };
  },
  computed: {
    ...mapGetters(['verified']),
    ...mapGetters('ui', ['australianStates', 'showConfirmPasswordModal']),
    showNotifyCharities() {
      return this.willMeta.notify_charities !== null;
    },
    updateEmailVariables() {
      return {
        currentPassword: this.confirmPassword,
        newEmail: this.email,
      };
    },
    updatePasswordVariables() {
      return {
        currentPassword: this.oldPassword,
        newPassword: this.newPassword,
        confirmNewPassword: this.newPassword,
      };
    },
    UPDATE_WILL_NOTIFY_CHARITY_VARS() {
      return {
        userId: this.userId,
        value: this.willMeta.notify_charities,
      };
    },
  },
  methods: {
    ...mapActions(['setEmail', 'setToken']),
    ...mapActions('ui', ['setShowConfirmPasswordModal']),
    closeModal() {
      this.setShowConfirmPasswordModal(false);
      this.confirmPassword = '';
      this.$nuxt.$emit('unlockForm');
    },
    async resendWelcomeEmail() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✉️ Resend Verification',
      });

      this.resendLoading = true;

      await this.$apollo
        .mutate({
          mutation: RESEND_WELCOME_EMAIL,
          variables: {
            email: this.email,
          },
        })
        .then(({ data }) => {
          this.resendLoading = false;

          if (data.resendWelcomeEmail && data.resendWelcomeEmail.success) {
            this.welcomeEmailSent = true;

            this.resendTimeout = window.setTimeout(() => {
              this.resendTimeout = null;
            }, 3000);
          }
        })
        .catch((e) => {
          console.error(e.message);
        });
    },
    updateWills() {
      this.refetchWill();
      this.$nuxt.$emit('unlockForm');
      this.$nuxt.$emit('clearErrors');
    },
    resetPasswordInputs() {
      this.oldPassword = '';
      this.newPassword = '';
      this.$nuxt.$emit('unlockForm');
      this.$nuxt.$emit('clearErrors');
      this.hasChangedPassword = true;
    },
    updateDetails() {
      this.$nuxt.$emit('sendTrackingAttributes', {
        name: `${this.willMeta.name_first} ${this.willMeta.name_last}`,
      });

      this.$ff.refreshFeatureFlags();

      this.$nuxt.$emit('unlockForm');
    },
    async updateToken({
      data: {
        updateEmail: { token, user },
      },
    }) {
      await this.$apolloHelpers
        .onLogin(token)
        .then(() => {
          this.setEmail(user.email);
          this.setToken(token);
          this.$ff.refreshFeatureFlags();
        })
        .catch((e) => {
          console.error(e.message);
        });

      this.closeModal();
    },
  },
};
</script>
