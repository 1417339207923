<template>
  <BaseButton
    class="group focus:outline-none"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <div class="flex items-center">
      <div
        class="inline-flex justify-center items-center w-4 h-4 mr-2"
        :class="classes.glyph"
      >
        <BaseGlyph :id="glyph" />
      </div>
      <div class="text-slate-100 underline" :class="classes.label">
        <slot />
      </div>
      <LoadingRing v-if="loading" class="ml-2" />
    </div>
  </BaseButton>
</template>

<script>
import BaseButton from '~/components/BaseButton';
import BaseGlyph from '~/components/BaseGlyph';
import LoadingRing from '~/components/LoadingRing';

export default {
  name: 'AdminActionLink',
  components: {
    BaseButton,
    BaseGlyph,
    LoadingRing,
  },
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    glyph: {
      type: String,
      required: true,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    underline: {
      default: true,
      type: Boolean,
    },
  },
  computed: {
    classes() {
      return {
        glyph: {
          'opacity-50 cursor-not-allowed': this.disabled,
        },
        label: {
          'opacity-50 cursor-not-allowed': this.disabled,
          'group-hover:no-underline': !this.disabled,
          'cursor-text no-underline': !this.underline,
        },
      };
    },
  },
};
</script>
