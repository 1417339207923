<template>
  <ValidationProvider
    v-slot="{ errors }"
    :disabled="!required"
    :rules="required ? 'required' : null"
    class="w-full"
    tag="div"
    @keydown.up.prevent.self=""
    @keydown.down.prevent.self=""
    @keydown.enter.prevent.self=""
  >
    <label v-if="label" :id="id" class="input-label" v-text="label" />
    <client-only>
      <div v-if="isTouchDevice" class="relative">
        <select
          :id="id"
          v-model="modelValue"
          class="input"
          :class="[classes.native, { 'input-error': errors.length }]"
          :disabled="disabled"
          :placeholder="placeholder"
          autocomplete="off"
          @change="onChange"
        >
          <option
            v-if="placeholder"
            disabled
            selected
            value=""
            v-text="placeholder"
          />
          <option
            v-for="(option, index) in options"
            :key="index"
            :value="option"
            v-text="option"
          />
        </select>
        <div
          class="absolute top-0 right-0 bottom-0 flex items-center w-10 stroke-current pointer-events-none"
          :class="classes.indicator"
        >
          <SelectInputOpenIndicator />
        </div>
      </div>
      <v-select
        v-else
        v-model="modelValue"
        :class="[classes.custom, { 'vs--has-error': errors.length }]"
        v-bind="settings"
        :required="required"
        :value="value"
        @input="onChange"
      />
    </client-only>
    <div
      v-if="errors.length"
      class="mt-1 text-base text-red-300"
      v-text="errors[errors.length - 1]"
    />
  </ValidationProvider>
</template>

<script>
import detectIt from 'detect-it';
import { ValidationProvider } from 'vee-validate';

import SelectInputOpenIndicator from '~/components/SelectInputOpenIndicator';

export default {
  name: 'SelectInput',
  components: {
    SelectInputOpenIndicator,
    ValidationProvider,
  },
  model: {
    event: 'change',
  },
  props: {
    autoWidth: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      default: null,
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      default: null,
      type: String,
    },
    required: {
      default: false,
      type: Boolean,
    },
    searchable: {
      default: false,
      type: Boolean,
    },
    value: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      modelValue: this.value,
    };
  },
  computed: {
    classes() {
      return {
        custom: {
          'vs--auto-width': this.autoWidth,
        },
        native: {
          'text-grey-500': !this.modelValue,
          'input-disabled': this.disabled,
        },
        indicator: {
          'text-grey-600': this.disabled,
          'text-teal-200': !this.disabled,
        },
      };
    },
    isTouchDevice() {
      return (
        (typeof navigator !== 'undefined' && navigator.webdriver) ||
        true ||
        detectIt.deviceType !== 'mouseOnly' ||
        ['base', 'sm'].includes(this.$mq)
      );
    },
    settings() {
      return {
        clearable: false,
        components: {
          OpenIndicator: SelectInputOpenIndicator,
        },
        disabled: this.disabled,
        inputId: this.id,
        options: this.options,
        placeholder: this.placeholder,
        searchable: this.searchable,
      };
    },
  },
  watch: {
    value(newValue) {
      this.modelValue = newValue;
    },
  },
  methods: {
    onChange() {
      this.$emit('change', this.modelValue);
    },
  },
};
</script>
