<template>
  <div>
    <h2 class="w-115 pr-20 mb-6 lg:mb-8 text-5xl leading-tight">
      People, Pets &amp; Charities
    </h2>
    <MainSideLayout>
      <template #side>
        <p class="mb-8 lg:mb-16 text-2xl">
          An overview of the people, pets and charities you’ve added to your
          Will.
        </p>
        <Tip>
          We’ll ask you to add or select people, pets & charities at relevant
          places in your Will.
        </Tip>
      </template>
      <template #main>
        <div class="mb-12">
          <h3 class="mb-6 text-3xl">
            People
          </h3>
          <div v-if="people && people.length">
            <MetaSlot
              v-for="(person, index) in people"
              :key="index"
              v-slot="{ meta }"
              :meta="person.meta"
            >
              <DataCard
                :title="meta.full_name"
                :description="buildIdentifier(meta)"
                :edit="allowEditing"
                :remove="allowEditing && !personAppearances[index].length"
                type="person"
                @edit="editPerson({ person, type: personType[index] })"
                @remove="removePerson(person)"
              >
                <BaseAlert
                  v-if="personAppearances[index].length"
                  compact
                  type="confirm"
                >
                  <p class="opacity-75">
                    Appears as:
                    <span class="pl-1 font-normal">{{
                      personAppearances[index].join(', ')
                    }}</span>
                  </p>
                </BaseAlert>
              </DataCard>
            </MetaSlot>
          </div>
          <div v-else>
            <EmptyState>
              No people added to your Will yet.
            </EmptyState>
          </div>
          <div class="flex justify-end mt-4">
            <Tooltip :content="getTooltip('people')" :disabled="allowEditing">
              <BaseButton
                class="btn-md btn-teal"
                :disabled="!allowEditing"
                focusable
                @click="addPerson"
              >
                + Add
              </BaseButton>
            </Tooltip>
          </div>
        </div>
        <div class="mb-12">
          <h3 class="mb-6 text-3xl">
            Pets
          </h3>
          <div v-if="pets && pets.length">
            <MetaSlot
              v-for="(pet, index) in pets"
              :key="index"
              v-slot="{ meta }"
              :meta="pet.meta"
            >
              <DataCard
                :title="meta.name"
                :description="meta.type"
                :edit="allowEditing"
                :remove="allowEditing"
                type="pet"
                @edit="editPet(pet)"
                @remove="removePet(pet)"
              />
            </MetaSlot>
          </div>
          <div v-else>
            <EmptyState>
              No pets added to your Will yet.
            </EmptyState>
          </div>
          <div class="flex justify-end mt-4">
            <Tooltip
              content="You can't add pets while your Will is being reviewed."
              :disabled="allowEditing"
            >
              <BaseButton
                class="btn-md btn-teal"
                :disabled="!allowEditing"
                focusable
                @click="addPet"
              >
                + Add
              </BaseButton>
            </Tooltip>
          </div>
        </div>
        <div>
          <h3 class="mb-6 text-3xl">
            Charities and Not-for-Profits
          </h3>
          <div v-if="charities && charities.length">
            <MetaSlot
              v-for="(charity, index) in charities"
              :key="index"
              v-slot="{ meta }"
              :meta="charity.meta"
            >
              <DataCard
                :title="meta.logo ? null : meta.name"
                :description="meta.logo ? meta.name : meta.address"
                :logo="meta.logo"
                :edit="allowEditing"
                :remove="allowEditing && !charityAppearances[index].length"
                :suggested="!!meta.referral"
                type="charity"
                @edit="editCharity(charity)"
                @remove="removeCharity(charity)"
              >
                <BaseAlert
                  v-if="charityAppearances[index].length"
                  compact
                  type="confirm"
                >
                  <p class="opacity-75">
                    Appears as:
                    <span class="pl-1 font-normal">{{
                      charityAppearances[index].join(', ')
                    }}</span>
                  </p>
                </BaseAlert>
              </DataCard>
            </MetaSlot>
          </div>
          <div v-else>
            <EmptyState>
              No charities added to your Will yet.
            </EmptyState>
          </div>
          <div class="flex justify-end mt-4">
            <Tooltip
              content="You can't add charities while your Will is being reviewed."
              :disabled="allowEditing"
            >
              <BaseButton
                class="btn-md btn-teal"
                :disabled="!allowEditing"
                focusable
                @click="addCharity"
              >
                + Add
              </BaseButton>
            </Tooltip>
          </div>
        </div>
      </template>
    </MainSideLayout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import BaseAlert from '~/components/BaseAlert';
import BaseButton from '~/components/BaseButton';
import DataCard from '~/components/DataCard';
import EmptyState from '~/components/EmptyState';
import MainSideLayout from '~/components/MainSideLayout';
import MetaSlot from '~/components/MetaSlot';
import Tip from '~/components/Tip';
import Tooltip from '~/components/Tooltip';

import {
  beneficiaries,
  charities,
  executors,
  gifts,
  guardians,
  people,
  pets,
  user,
} from '~/mixins/apollo';

export default {
  name: 'DashboardPeoplePetsCharities',
  components: {
    BaseAlert,
    BaseButton,
    DataCard,
    EmptyState,
    MainSideLayout,
    MetaSlot,
    Tip,
    Tooltip,
  },
  mixins: [
    beneficiaries,
    charities,
    executors,
    gifts,
    guardians,
    people,
    pets,
    user,
  ],
  computed: {
    ...mapGetters(['willStatus']),
    allowEditing() {
      return this.willStatus === 'IN_PROGRESS';
    },
    charityAppearances() {
      return this.charities.map((charity) => {
        const appearances = [];

        if (this.isBeneficiary(charity)) {
          appearances.push('Beneficiary');
        }

        if (this.isGiftRecipient(charity)) {
          appearances.push('Gift Recipient');
        }

        return appearances;
      });
    },
    personAppearances() {
      return this.people.map((person) => {
        const appearances = [];

        if (this.isPartner(person)) {
          appearances.push('Partner');
        }

        if (this.isChild(person)) {
          appearances.push('Child');
        }

        if (this.isGuardian(person)) {
          appearances.push('Child Guardian');
        }

        if (this.isPetGuardian(person)) {
          appearances.push('Pet Guardian');
        }

        if (this.isExecutor(person)) {
          appearances.push('Executor');
        }

        if (this.isBeneficiary(person)) {
          appearances.push('Beneficiary');
        }

        if (this.isGiftRecipient(person)) {
          appearances.push('Gift Recipient');
        }

        return appearances;
      });
    },
    personType() {
      return this.people.map((person) => {
        let type = 'person';

        this.guardians.forEach((guardian) => {
          if (guardian.person.id === person.id) {
            type = 'guardian';
          }
        });

        this.executors.forEach((executor) => {
          if (executor.person.id === person.id) {
            type = 'executor';
          }
        });

        return type;
      });
    },
  },
  methods: {
    getTooltip(type) {
      return this.willStatus === 'APPROVED'
        ? `Unlock your Will to add more ${type}.`
        : `You can't add more ${type} while your Will is being reviewed.`;
    },
    buildIdentifier(meta) {
      switch (meta.identifier) {
        case 'email':
          return meta.email;
        case 'dob':
          return meta.date_of_birth;
        default:
          return meta.address_street
            ? `${meta.address_street}, ${meta.address_suburb} ${meta.address_state} ${meta.address_postcode}`
            : meta.date_of_birth
            ? meta.date_of_birth
            : meta.email;
      }
    },
  },
};
</script>
