<template>
  <div v-if="!hide">
    <div class="relative mt-4">
      <ValidationObserver
        ref="observer"
        tag="form"
        class="flex w-full"
        @submit.prevent="submit"
      >
        <TextInput
          :id="id"
          v-model="email"
          class="w-full mr-4"
          :disabled="loading"
          name="invite"
          placeholder="name@example.com"
          rules="required|email"
          @focus="error = null"
        />
        <BaseButton
          class="btn btn-xl btn-slate text-white"
          :loading="loading"
          @click="submit"
        >
          Send
        </BaseButton>
      </ValidationObserver>
    </div>
    <p v-if="error" class="mt-2 text-red-300 truncate" v-text="error" />
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

import TextInput from '~/components/TextInput';
import BaseButton from '~/components/BaseButton';
import { invites } from '~/mixins/apollo';
import { formatError } from '~/utilities';

import SEND_INVITE_MUTATION from '~/graphql/mutations/SendInvite';

export default {
  name: 'SendInvite',
  components: {
    ValidationObserver,
    TextInput,
    BaseButton,
  },
  mixins: [invites],
  props: {
    type: {
      default: 'DEFAULT',
      type: String,
    },
    prefill: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      email: this.prefill,
      error: null,
      loading: false,
      done: false,
    };
  },
  computed: {
    variables() {
      return {
        type: this.type,
        email: this.email,
      };
    },
    hide() {
      return this.prefill && this.done;
    },
  },
  watch: {
    prefill() {
      this.email = this.prefill;
    },
  },
  beforeCreate() {
    this.id = Math.random()
      .toString(36)
      .substr(2, 9);
  },
  methods: {
    async submit($event, mutate) {
      const isValid =
        this.$refs.observer && (await this.$refs.observer.validate());

      if (isValid) {
        this.loading = true;

        try {
          await this.$apollo.mutate({
            mutation: SEND_INVITE_MUTATION,
            variables: {
              type: this.type,
              email: this.email,
            },
            update: (store, { data: { sendInvite } }) => {
              const data = store.readQuery(this.getInvitesQuery);
              data.getInvites.invites.push(sendInvite.invite);

              store.writeQuery({
                ...this.getInvitesQuery,
                data,
              });
            },
          });

          this.$nuxt.$emit('sendTrackingEvent', {
            event:
              this.type === 'PARTNER'
                ? '💌 Referred Partner'
                : '📧 Referred Friend',
            props: { referred_email: this.email },
          });
          this.$nuxt.$emit('resetInputs', this.id);
          this.$refs.observer.reset();
          this.loading = false;
          this.error = null;
          this.done = true;
        } catch (error) {
          this.$nuxt.$emit('done');
          this.loading = false;
          this.error = formatError(error.message);
        }
      }
    },
  },
};
</script>
