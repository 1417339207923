<template>
  <div class="mb-8 sm:mb-16">
    <h3 v-if="heading" class="mb-6 text-3xl" v-text="heading" />
    <div>
      <slot />
    </div>
    <div
      v-if="divider"
      class="content-box h-px border border-grey-300 my-8 sm:my-16 bg-grey-300"
    />
  </div>
</template>

<script>
export default {
  name: 'AccountSection',
  props: {
    divider: {
      default: false,
      type: Boolean,
    },
    heading: {
      default: null,
      type: String,
    },
  },
};
</script>
