<template>
  <div>
    <AuthPanel>
      <template #form>
        <div class="relative">
          <Form
            :class="classes"
            :mutation="RESEND_WELCOME_EMAIL"
            :variables="variables"
            submit-arrow
            submit-label="Resend Email"
            submit-wide
            @done="$router.push({ path: '/' })"
          >
            <p class="text-xl text-center">
              Your verification token has expired, you can resend the welcome
              email by pressing the button below.
            </p>
          </Form>
          <div
            v-if="!failed"
            class="absolute inset-0 flex justify-center items-center text-teal-200"
          >
            <LoadingDots />
          </div>
        </div>
      </template>
    </AuthPanel>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import RESEND_WELCOME_EMAIL from '~/graphql/mutations/ResendWelcomeEmail';
import VERIFY_MUTATION from '~/graphql/mutations/Verify';

import AuthPanel from '~/components/AuthPanel';
import Form from '~/components/Form';
import LoadingDots from '~/components/LoadingDots';

export default {
  name: 'Verify',
  components: {
    AuthPanel,
    Form,
    LoadingDots,
  },
  fetch({ redirect, route }) {
    if (!route.query.e && !route.query.t) redirect('/');
  },
  data() {
    return {
      RESEND_WELCOME_EMAIL,
      failed: false,
    };
  },
  computed: {
    classes() {
      return {
        invisible: !this.failed,
      };
    },
    variables() {
      return {
        email: this.$route.query.e,
      };
    },
  },
  mounted() {
    const { e, t } = this.$route.query;

    this.$apollo
      .mutate({
        mutation: VERIFY_MUTATION,
        variables: {
          email: e,
          token: t,
        },
      })
      .then(({ data }) => {
        if (data.verify && data.verify.success) {
          this.setVerified(true);

          this.$router.push({
            path: '/',
          });
        } else {
          this.failed = true;
        }
      })
      .catch((e) => {
        this.failed = true;
      });
  },
  methods: {
    ...mapActions(['setVerified']),
  },
};
</script>
