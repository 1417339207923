<template>
  <div>
    <nuxt-child />
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  layout(context) {
    if (context.route.name === 'poa-checkout') {
      return 'minimal';
    } else {
      return 'dashboard';
    }
  },
  middleware: ['authenticated'],
};
</script>
