<template>
  <ModuleStep :index="2" prev-step="/funeral">
    <Form
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      submit-arrow
      :auto-submit="isComplete"
      @done="$router.push({ path: nextStep })"
    >
      <FormSection>
        <template #heading>
          What kind of funeral would you like?
        </template>
        <p>
          These instructions will be given to your executor to help guide them,
          but they are not binding.
        </p>
        <Tip>
          Choose the answer which best describes your wishes. You can add more
          detail or specific requests in the following steps.
        </Tip>
        <LargeRadioButtons
          id="funeral_type"
          v-model="willMeta.funeral_type"
          :options="options"
        />
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import LargeRadioButtons from '~/components/LargeRadioButtons';
import ModuleStep from '~/components/ModuleStep';
import Tip from '~/components/Tip';

import { user, will } from '~/mixins/apollo';

export default {
  name: 'FuneralType',
  components: {
    Form,
    FormSection,
    LargeRadioButtons,
    ModuleStep,
    Tip,
  },
  mixins: [user, will],
  data() {
    return {
      options: [
        {
          label: 'Cremation',
          value: 'cremation',
        },
        {
          label: 'Burial',
          value: 'burial',
        },
        {
          label: 'Donate my body to science',
          value: 'donate',
        },
        {
          label: 'Let my executor decide',
          value: 'executor',
        },
      ],
    };
  },
  computed: {
    isComplete() {
      return !!this.willMeta.funeral_type;
    },
    nextStep() {
      return ['cremation', 'burial'].includes(this.willMeta.funeral_type)
        ? '/funeral/location'
        : '/funeral/other-requests';
    },
  },
  mounted() {
    this.$apollo.queries.will.refetch();
  },
};
</script>
