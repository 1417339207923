<template>
  <ModuleStep :index="4" :prev-step="prevStep">
    <Form
      v-slot="{ loading }"
      auto-focus
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      submit-arrow
      :submit-label="buttonText"
      @done="onDone"
    >
      <FormSection>
        <template #heading>
          Do you have any other funeral related requests?
        </template>
        <p>
          This includes any wishes you have for the day, such as song requests,
          specific speakers or even catering requirements
        </p>
        <Tip>
          We suggest lots of wine and a memorable playlist
        </Tip>
        <TextArea
          id="funeral_notes"
          v-model="willMeta.funeral_note"
          :disabled="loading"
          maxlength="2500"
          placeholder="I would like..."
        />
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import ModuleStep from '~/components/ModuleStep';
import TextArea from '~/components/TextArea';
import Tip from '~/components/Tip';

import { user, will } from '~/mixins/apollo';

export default {
  name: 'FuneralLocation',
  components: {
    Form,
    FormSection,
    ModuleStep,
    TextArea,
    Tip,
  },
  mixins: [user, will],
  computed: {
    buttonText() {
      return this.willMeta.funeral_note ? 'Next' : 'Skip';
    },
    prevStep() {
      return ['cremation', 'burial'].includes(this.willMeta.funeral_type)
        ? '/funeral/location'
        : '/funeral/type';
    },
  },
  methods: {
    onDone() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✅ Module 7 (Funeral)',
      });

      this.$router.push({
        path: '/dashboard',
      });
    },
  },
};
</script>
