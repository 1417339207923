<template>
  <span>
    <span v-if="!['APPROVED', 'FLAGGED'].includes(willStatus)">
      is
    </span>
    <Tooltip class="relative" :content="tooltip">
      <span class="relative inline-block">
        <span class="relative z-10" v-text="text" />
        <span
          class="absolute top-100 left-0 right-0 inline-block mt-1 overflow-hidden"
          :class="
            willStatus === 'FLAGGED' ? 'text-orange-200' : 'text-teal-100'
          "
          :style="`height: ${lineHeight}px`"
        >
          <svg
            class="w-full"
            height="6"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMin"
          >
            <path
              class="stroke-current"
              d="M0 3h9999"
              fill="none"
              stroke-width="6"
              :stroke-dasharray="willStatus === 'FLAGGED' ? '12,8' : null"
            />
          </svg>
        </span>
      </span>
    </Tooltip>
  </span>
</template>

<script>
import { mapGetters } from 'vuex';

import Tooltip from '~/components/Tooltip';

export default {
  name: 'StatusText',
  components: {
    Tooltip,
  },
  props: {
    ready: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(['willStatus']),
    dashed() {
      return this.willStatus === 'FLAGGED';
    },
    lineHeight() {
      return ['base', 'sm'].includes(this.$mq) ? 3 : 6;
    },
    text() {
      let text = '';

      switch (this.willStatus) {
        case 'IN_PROGRESS':
          text = this.ready ? 'ready to submit' : 'in progress';
          break;
        case 'AWAITING_APPROVAL':
          text = 'being reviewed';
          break;
        case 'FLAGGED':
          text = 'needs attention';
          break;
        case 'APPROVED':
          text = 'is complete';
          break;
        default:
          break;
      }

      return text;
    },
    tooltip() {
      let tooltip = '';

      switch (this.willStatus) {
        case 'IN_PROGRESS':
          tooltip = this.ready
            ? 'When you’re ready submit your Will, and our legal advisers will review your application within 3 business days.'
            : 'Once you’ve completed all the cards below, you’ll be able to submit your Will to our legal advisors to review.';
          break;
        case 'AWAITING_APPROVAL':
          tooltip =
            'Our team is reviewing your Will. You’ll receive an email once the Will is approved for signing.';
          break;
        case 'FLAGGED':
          tooltip =
            'One or more cards need your attention before you can submit for review again. Please check your email for more information';
          break;
        case 'APPROVED':
          tooltip = 'Your Will is ready to download, print and sign.';
          break;
        default:
          break;
      }

      return tooltip;
    },
  },
};
</script>
