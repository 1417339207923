var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleStep',{attrs:{"index":4,"prev-step":_vm.prevStep}},[_c('Form',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"hide-submit":!_vm.isComplete,"auto-submit":!_vm.willMeta.has_pets,"submit-arrow":""},on:{"done":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('FormSection',{staticClass:"step-1",scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        Do you have any pets?\n      ")]},proxy:true}],null,true)},[_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"hasPets","options":[
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          } ]},model:{value:(_vm.willMeta.has_pets),callback:function ($$v) {_vm.$set(_vm.willMeta, "has_pets", $$v)},expression:"willMeta.has_pets"}})],1),_vm._v(" "),(_vm.willMeta.has_pets)?_c('FormSection',{staticClass:"step-2",scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        My pets are...\n      ")]},proxy:true}],null,true)},[_vm._v(" "),_vm._l((_vm.pets),function(pet,index){return _c('MetaSlot',{key:index,attrs:{"meta":pet.meta},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var meta = ref.meta;
return [_c('DataCard',{attrs:{"title":meta.name,"description":meta.type,"edit":"","remove":"","type":"pet"},on:{"edit":function($event){return _vm.editPet(pet)},"remove":function($event){return _vm.removePet(pet)}}})]}}],null,true)})}),_vm._v(" "),_c('BaseButton',{staticClass:"btn-xl btn-white w-full add-pet",attrs:{"disabled":loading,"focusable":""},on:{"click":_vm.addPet}},[_vm._v("\n        + Add a pet\n      ")])],2):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }