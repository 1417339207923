<template>
  <ModuleStep :index="6" :prev-step="previous" :show-help="true">
    <Form
      v-slot="{ loading }"
      :hide-submit="!isComplete"
      submit-arrow
      @submit.prevent="onSubmit"
    >
      <FormSection>
        <template #heading>
          Are there any limits or exclusions you’d like to place on your
          healthcare decision makers?
        </template>
        <template v-if="['nsw'].includes(userState)">
          <p class="mb-4">
            In New South Wales if you don’t add any limitations or conditions
            your decision maker can generally make decisions related to:
          </p>
          <ul class="list-disc ml-6 mb-4">
            <li>your living arrangements</li>
            <li>
              the personal services you receive (such as nursing or meal
              delivery)
            </li>
            <li>
              the healthcare and medical treatment you receive (including
              palliative care and life-sustaining measures)
            </li>
          </ul>
          <p class="mb-4">
            You will be able to express general preferences and values regarding
            your care in a later question.
          </p>
        </template>
        <template v-else-if="['vic'].includes(userState)">
          <p class="mb-4">
            In Victoria if you don’t add any limitations or conditions, your
            decision maker can generally make decisions related to your medical
            treatment (including life-sustaining measures).
          </p>
          <p class="mb-4">
            You have the option of placing limitations on these functions here
            (e.g. they may only act while they are in Australia, or they may
            only act after consulting with a particular person).
          </p>
          <p class="mb-4">
            You will be able to express general preferences and values regarding
            your care in a later question.
          </p>
        </template>
        <template v-else-if="['qld'].includes(userState)">
          <p class="mb-4">
            In Queensland if you do not add any limitations or conditions your
            decision maker can generally make decisions related to:
          </p>
          <ul class="list-disc ml-6 mb-4">
            <li>
              personal affairs (such as your living arrangements, daily dress
              and diet)
            </li>
            <li>
              the support services you receive (such as nursing or meal
              delivery)
            </li>
            <li>your employment and education</li>
            <li>
              your health care (including medical treatments such as
              life-sustaining measures)
            </li>
          </ul>
          <p class="mb-4">
            You will be able to express general preferences and values regarding
            your care in a later question.
          </p>
        </template>
        <template v-else-if="['act'].includes(userState)">
          <p class="mb-4">
            In ACT if you don’t add any limitations or conditions your decision
            maker can generally make decisions related to:
          </p>
          <ul class="list-disc ml-6 mb-4">
            <li>
              your personal affairs (e.g. where you live, daily dress and diet,
              education, employment, and holidays)
            </li>
            <li>
              your health care (including medical treatment such as the
              provision of life-sustaining treatment and donations)
            </li>
          </ul>
          <p class="mb-4">
            If you do not want your attorney to be able to withdraw or withhold
            consent for medical treatment in any circumstances, you should also
            state that here.
          </p>
        </template>
        <template v-else-if="['wa'].includes(userState)">
          <p class="mb-4">
            In Western Australia if you don’t add any limitations or conditions
            your decision maker can generally make decisions related to:
          </p>
          <ul class="list-disc ml-6 mb-4">
            <li>your living arrangements</li>
            <li>your employment and education</li>
            <li>who you associate with</li>
            <li>
              your health care (including medical treatment decisions for the
              provision of life-sustaining measures)
            </li>
            <li>your participation in medical research</li>
          </ul>
        </template>
        <template v-else-if="['sa'].includes(userState)">
          <p class="mb-4">
            This is where you include any binding refusals of healthcare (e.g.
            whether there are any circumstances you don’t wish to receive
            life-sustaining treatments).
          </p>
          <p class="mb-4">
            If you fill out this section, you must state when and in what
            circumstances it will apply as your refusal(s) must be followed by
            your decision makers and health care providers.
          </p>
          <p class="mb-4">
            You will be able to express general preferences and values regarding
            your care in a later question.
          </p>
        </template>
        <template v-else-if="['tas'].includes(userState)">
          <p class="mb-4">
            In Tasmania if you don’t add any limitations or conditions, your
            decision maker can generally make decisions related to:
          </p>
          <ul class="list-disc ml-6 mb-4">
            <li>
              your personal affairs (e.g. where you live, who you associate
              with, and your employment)
            </li>
            <li>your health care (including life-sustaining measures)</li>
          </ul>
          <p class="mb-4">
            You will be able to express general preferences and values regarding
            your care in a later question.
          </p>
        </template>
        <template v-else-if="['nt'].includes(userState)">
          <p class="mb-4">
            In the Northern Territory if you don’t add any limitations or
            conditions your decision maker can generally make decisions related
            to:
          </p>
          <ul class="list-disc ml-6 mb-4">
            <li>your accommodation</li>
            <li>daily living issues (e.g. diet and dress)</li>
            <li>support services (such as nursing or meal services)</li>
            <li>who you associate with</li>
            <li>
              health care (including palliative care and life-sustaining
              measures)
            </li>
          </ul>
          <p class="mb-4">
            You will be able to express general preferences and values regarding
            your care in a later question.
          </p>
        </template>
        <LargeRadioButtons
          id="has_limitations"
          v-model="poaMedicalMeta.has_limitations"
          class="mb-12"
          :options="[
            {
              label: `Yes`,
              value: true,
            },
            {
              label: `No`,
              value: false,
            },
          ]"
          @change="onChange($event)"
        />
        <div v-if="poaMedicalMeta.has_limitations">
          <TextArea
            id="funeral_notes"
            v-model="poaMedicalMeta.limitations"
            :disabled="loading"
            maxlength="2500"
            placeholder="It is my instruction that..."
          />
        </div>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  queries as metaQueries,
  mutations as metaMutations,
} from '@/modules/apollo-queries/poa-meta';
import { queries as attorneysQueries } from '@/modules/apollo-queries/poa-attorneys';
import { prevStep, nextStep } from '@/modules/poa-flow/medical/index';
import Form from '~/components/Form';
import ModuleStep from '~/components/ModuleStep';
import FormSection from '~/components/FormSection';
import LargeRadioButtons from '~/components/LargeRadioButtons';
import TextArea from '~/components/TextArea';

import { user, will } from '~/mixins/apollo';

export default {
  name: 'MedicalPOALimits',
  components: {
    Form,
    ModuleStep,
    FormSection,
    LargeRadioButtons,
    TextArea,
  },
  mixins: [user, will],
  apollo: {
    attorneys: {
      ...attorneysQueries.getAttorneys({ power: 'MEDICAL' }),
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
    poaMedicalMeta: {
      ...metaQueries.poaMedicalMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
  },
  data() {
    return {
      attorneys: undefined,
      selectedPrimaryAttorneyPersonIDs: [],
      poaMedicalMeta: {
        has_limitations: undefined,
        limitations: undefined,
      },
    };
  },
  computed: {
    ...mapGetters(['poaId']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
        addressState: this.userState,
        poaMedicalMeta: this.poaMedicalMeta,
        numPrimaryAttorneysSelected: this.selectedPrimaryAttorneyPersonIDs
          .length,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
      });
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    isComplete() {
      return (
        this.poaMedicalMeta.has_limitations === false ||
        (this.poaMedicalMeta.has_limitations === true &&
          this.poaMedicalMeta.limitations?.length > 0)
      );
    },
  },
  watch: {
    attorneys(attorneys) {
      if (attorneys.length) {
        this.selectedPrimaryAttorneyPersonIDs = attorneys
          .filter((attorney) => attorney.role === 'PRIMARY')
          .map((attorney) => attorney.person.id);
      }
    },
  },
  methods: {
    onChange($event) {
      if ($event === false) {
        this.onSubmit();
      }
    },
    async onSubmit() {
      await this.$apollo.mutate(
        metaMutations.updatePoaMetas(this.poaId, [
          {
            key: 'has_limitations',
            value: this.poaMedicalMeta.has_limitations ? 'true' : 'false',
            category: 'MEDICAL',
          },
          {
            key: 'limitations',
            value: this.poaMedicalMeta.has_limitations
              ? this.poaMedicalMeta.limitations
              : null,
            category: 'MEDICAL',
          },
        ])
      );
      const path = this.next;
      await this.$router.push({ path });
    },
  },
  head() {
    return {
      title: 'Medical limitations',
    };
  },
};
</script>
