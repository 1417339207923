<template>
  <ModuleStep :index="5" prev-step="/guardians/your-pets">
    <Form
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      submit-arrow
      @done="submit"
    >
      <FormSection class="step-1">
        <template #heading>
          Would you like to appoint a guardian for your pets?
        </template>
        <LargeRadioButtons
          id="hasPetGuardians"
          v-model="willMeta.has_pet_guardians"
          :options="[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]"
        />
      </FormSection>
      <MetaSlot
        v-for="(pet, index) in pets"
        :key="index"
        v-slot="{ meta }"
        :meta="pet.meta"
      >
        <FormSection
          v-if="showForm(index)"
          class="step-2"
          tip="We’ll ask you to add or select people at relevant places in your Will. Their details can be edited on the People, Pets & Charities page."
        >
          <template #heading> Appoint a guardian for {{ meta.name }} </template>
          <PersonSelector
            :id="pet.id"
            v-model="selectedPetGuardians[index]"
            :loading="loading"
            :max="1"
            :people="filteredPeople[index]"
          />
        </FormSection>
      </MetaSlot>
    </Form>
  </ModuleStep>
</template>

<script>
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import LargeRadioButtons from '~/components/LargeRadioButtons';
import MetaSlot from '~/components/MetaSlot';
import ModuleStep from '~/components/ModuleStep';
import PersonSelector from '~/components/PersonSelector';

import { pets, people, user, will } from '~/mixins/apollo';
import { metaArrayToObject } from '~/utilities';

export default {
  name: 'GuardiansPets',
  components: {
    Form,
    FormSection,
    LargeRadioButtons,
    MetaSlot,
    ModuleStep,
    PersonSelector,
  },
  mixins: [pets, people, user, will],
  data() {
    return {
      selectedPetGuardians: [],
    };
  },
  computed: {
    isComplete() {
      const assignedPets = this.selectedPetGuardians.filter((arr) => {
        return arr.length;
      });

      return !!(
        this.willMeta.has_pet_guardians === false ||
        assignedPets.length === this.pets.length
      );
    },
    filteredPeople() {
      return this.pets.map((pet, index) => {
        return this.people.filter((person) => {
          const meta = metaArrayToObject(person.meta);
          const personIsAlreadySelected = this.selectedPetGuardians[
            index
          ].includes(person.id);
          return personIsAlreadySelected || meta.is_over_18;
        });
      });
    },
  },
  watch: {
    pets(pets) {
      if (pets.length && !this.selectedPetGuardians.length) {
        this.pets.forEach((pet) => {
          const person = this.people.find(
            (person) => pet.person && pet.person.id === person.id
          );

          this.selectedPetGuardians.push(person ? [person.id] : []);
        });
      }
    },
  },
  methods: {
    showForm(index) {
      return (
        this.willMeta.has_pet_guardians &&
        this.pets.length &&
        this.selectedPetGuardians[index]
      );
    },
    async submit() {
      await Promise.all(
        this.pets.map((pet, i) => {
          const selectedGuardian = this.selectedPetGuardians[i][0];

          if (this.willMeta.has_pet_guardians) {
            if (!pet.person || pet.person.id !== selectedGuardian) {
              this.addPetGuardian(pet, selectedGuardian);
            }
          } else if (pet.person) {
            this.removePetGuardian(pet);
          }
        })
      );

      this.$router.push({
        path: '/guardians/pet-care-fund',
      });
    },
  },
};
</script>
