<template>
  <div class="flex w-full" :class="classes">
    <div
      v-for="(option, index) in options"
      :key="index"
      class="group relative w-full mb-4 last-child:mb-0"
      :class="itemClasses"
    >
      <label
        class="btn btn-white w-full text-black text-center"
        :class="labelClasses[index]"
        :for="ids[index]"
      >
        <span v-if="option.icon" class="mr-3 text-teal-300">
          <BaseIcon :id="option.icon" />
        </span>
        <span v-text="option.label" />
      </label>
      <input
        :id="ids[index]"
        class="btn btn-focus absolute top-0 left-0 w-full h-full p-0"
        :name="id"
        type="radio"
        :value="option.value"
        @change="$emit('change', option.value)"
      />
    </div>
  </div>
</template>

<script>
import BaseIcon from '~/components/BaseIcon';

export default {
  name: 'LargeRadioButtons',
  components: {
    BaseIcon,
  },
  model: {
    event: 'change',
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    options: {
      // [{ label, value }, ...]
      type: Array,
      required: true,
    },
    value: {
      default: null,
      type: [Boolean, String],
    },
    horizontal: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    classes() {
      return {
        'md:flex-row': this.compact,
        'flex-row': this.horizontal,
        'flex-col': !this.horizontal,
      };
    },
    compact() {
      let compact = false;

      if (this.options.length === 2) {
        compact = this.options.every((option) => option.label.length < 15);
      }

      return compact;
    },
    itemClasses() {
      return {
        'md:mr-4 last-child:mr-0 md:mb-0': this.compact || this.horizontal,
      };
    },
    labelClasses() {
      return this.options.map((option) => {
        const selected = option.value === this.value;

        return {
          'btn-teal': selected,
          'group-hover:btn-white-hover': !selected,
          'justify-start': this.flex === 'col' && option.icon,
          'text-base': this.horizontal,
          'btn-lg': this.horizontal,
          'btn-xl': !this.horizontal,
          'md:text-left': !this.horizontal,
        };
      });
    },
    ids() {
      return this.options.map((option) => `${this.id}_${option.value}`);
    },
  },
};
</script>
