<template>
  <div
    class="flex items-baseline p-8 rounded font-medium text-base"
    :class="classes"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseAlert',
  props: {
    compact: {
      default: false,
      type: Boolean,
    },
    type: {
      type: String,
      required: true,
      validator: (s) => ['confirm', 'error', 'info', 'warn'].includes(s),
    },
  },
  computed: {
    classes() {
      return {
        'px-4 py-3': this.compact,
        'bg-teal-100 text-charcoal-300': this.type === 'confirm',
        'bg-red-200 text-red-400': this.type === 'error',
        'bg-blue-100 text-blue-300': this.type === 'info',
        'bg-orange-100 text-orange-400': this.type === 'warn',
        [this.type]: true,
      };
    },
  },
};
</script>
