<template>
  <div class="flex items-top mb-3 last-child:mb-0">
    <div class="flex-grow-0 flex-shrink-0 w-6 h-6 mr-2 text-teal-200">
      <svg
        class="w-full h-full mt-1 fill-current"
        viewBox="0 0 26 26"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 0C5.832 0 0 5.832 0 13s5.832 13 13 13 13-5.832 13-13S20.168 0 13 0zm1.625 5.688a1.625 1.625 0 110 3.249 1.625 1.625 0 010-3.25zm1.432 13.85c-1.477.581-1.909.774-2.858.774-.775 0-1.378-.189-1.808-.567-.849-.747-.706-1.826-.44-2.931l.8-2.83c.154-.583.467-1.773.029-2.19-.438-.418-1.458-.154-2.051.067l.214-.872c.856-.349 1.938-.775 2.862-.775 1.39 0 2.406.695 2.406 2.006 0 .123-.015.339-.043.65-.054.569-.07.531-.957 3.673-.1.345-.253 1.079-.253 1.434 0 1.367 1.59 1.029 2.313.688l-.214.872z"
        />
      </svg>
    </div>
    <button
      class="bg-transparent appearance-none focus:outline-none text-left"
      type="button"
      @click="expanded = !expanded"
    >
      <div class="flex items-center">
        <h6 class="mb-2 font-regular text-xl underline hover:no-underline">
          {{ qa.question }}
        </h6>
        <svg
          class="ml-2 stroke-current text-teal-200"
          :class="expanded ? 'rotate-180' : null"
          width="14"
          height="9"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M13 1L7 7 1 1" stroke-width="2" fill="none" />
        </svg>
      </div>
      <p v-if="expanded">
        {{ qa.answer }}
      </p>
    </button>
  </div>
</template>

<script>
export default {
  name: 'CheckoutFaqItem',
  props: {
    qa: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
};
</script>
