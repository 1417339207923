<template>
  <div
    class="hidden md:block fixed z-30 inset-x-0 bottom-0 text-lg text-center bg-teal-100 p-4"
  >
    <div class="flex flex-wrap lg:flex-no-wrap justify-center items-center">
      <span class="flex relative h-3 w-3">
        <span
          class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-200 opacity-75"
        ></span>
        <span
          class="relative inline-flex rounded-full h-3 w-3 bg-green-200"
        ></span>
      </span>
      <span class="text-xl font-medium ml-3">We&#39;re online</span>
      <span class="ml-8 text-left">
        <button
          aria-label="open live chat"
          type="button"
          class="transition transition-color duration-200 border-b border-green-300 rounded-none hover:border-transparent"
          @click="showChat"
        >
          Start a live chat
        </button>
        or do your will over the phone on
        <a
          href="tel:1800103310"
          class="border-b border-green-300 rounded-none hover:border-transparent"
        >
          1800 10 33 10
        </a>
        .
      </span>
    </div>
  </div>
</template>

<script>
import { showChat } from '@/modules/chat';

export default {
  methods: {
    showChat() {
      showChat();
    },
  },
};
</script>
