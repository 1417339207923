<template>
  <Module title="Get Started" :total-steps="7">
    <nuxt-child />
  </Module>
</template>

<script>
import detectIt from 'detect-it';
import { mapFields } from 'vuex-map-fields';
import cookies from 'js-cookie';

import Module from '~/components/Module';
import { user } from '~/mixins/apollo';
import { sanitizeSignUpRedirect } from '~/utilities/allowed-redirects';
import { saveAnonymousProfile } from '~/utilities';

export default {
  name: 'GetStarted',
  components: {
    Module,
  },
  mixins: [user],
  middleware: ['signup', 'notAuthenticated'],
  layout: 'module',
  computed: {
    ...mapFields('signup', [
      'meta.referral_charity',
      'meta.user_agent',
      'meta.device_type',
      'meta.has_partner',
      'coupon',
    ]),
  },
  mounted() {
    if (Object.keys(this.$route.query).length) {
      if (this.$route.query.charity) {
        this.referral_charity = this.$route.query.charity;
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '🎗 Referral Charity',
          props: {
            charity: this.referral_charity,
          },
        });
        cookies.set('referral_charity', this.referral_charity);
      }

      if (this.$route.query.coupon) {
        cookies.set('coupon', this.$route.query.coupon);
        saveAnonymousProfile({
          couponCode: this.$route.query.coupon,
        });
      }

      if (this.$route.query.hasPartner) {
        this.has_partner = true;
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '💍 Couples Get Started',
        });
        cookies.set('has_partner', true);
      }

      cookies.remove('signup_redirect');
      if (this.$route.query.redirect) {
        const where = sanitizeSignUpRedirect(
          String(this.$route.query.redirect)
        );

        if (where) {
          cookies.set('signup_redirect', where);
        }
      }

      this.$router.replace({ path: this.$route.path });
    }

    this.user_agent = navigator && navigator.userAgent;
    this.device_type = detectIt.deviceType;
  },
};
</script>
