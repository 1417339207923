<template>
  <div>
    <h4 v-if="label" class="mb-4 text-2xl">
      Charities and Not-for-Profits
    </h4>
    <div v-if="charities.length" class="relative">
      <MetaSlot
        v-for="(charityData, index) in charities"
        :key="index"
        v-slot="{ meta }"
        :meta="charityData.meta"
      >
        <LocalScope v-slot="{ id, selected }">
          <CharityCard
            :charity-key="charityData.id"
            :selected="isSelected[index]"
            :charity-data="meta"
            @onChange="onChange(charityData)"
          />
        </LocalScope>
      </MetaSlot>
    </div>
  </div>
</template>

<script>
import { LocalScope } from 'vue-local-scope';
import { mapActions } from 'vuex';
import { metaArrayToObject } from '~/utilities';
import { isSameCharity } from '~/utilities/charity';

import CharityCard from '~/components/CharityCard';
import MetaSlot from '~/components/MetaSlot';

import { charities, user } from '~/mixins/apollo';

export default {
  name: 'CharitySelector',
  components: {
    CharityCard,
    LocalScope,
    MetaSlot,
  },
  mixins: [charities, user],
  props: {
    label: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    max: {
      default: Infinity,
      type: Number,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selected: this.value,
    };
  },
  computed: {
    isSelected() {
      return this.charities.map((charity) => {
        return this.selected.includes(charity.id);
      });
    },
  },
  watch: {
    value(newValue) {
      if (!this.loading) {
        this.selected = newValue;
      }
    },
  },
  mounted() {
    this.$nuxt.$on('addCharities', (addedCharities) => {
      this.charities.forEach((charity) => {
        addedCharities.forEach((addedCharity) => {
          if (isSameCharity(metaArrayToObject(charity.meta), addedCharity)) {
            this.onChange(charity);
          }
        });
      });
    });
  },
  methods: {
    ...mapActions('charity', ['addCharity']),
    onChange(charity) {
      const index = this.selected.indexOf(charity.id);
      if (index > -1) {
        this.selected.splice(index, 1);
      } else if (this.selected.length < this.max) {
        this.selected.push(charity.id);
      } else if (this.max === 1) {
        this.selected = [charity.id];
      }
      this.$emit('input', this.selected);
    },
  },
};
</script>
