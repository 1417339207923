<template>
  <div class="p-6 md:p-8 xl:p-10 border-2 border-grey-300 rounded-t">
    <GenericPaymentMethod>
      <h2 class="text-2xl">Secure credit card payment</h2>
    </GenericPaymentMethod>
    <FormSection>
      <FormRow class="mt-2">
        <div class="flex items-center text-charcoal-100">
          <BaseGlyph id="lock" />
          <span class="ml-2">
            Your transaction is protected with bank level 256-bit encryption.
          </span>
        </div>
      </FormRow>
      <FormRow>
        <div class="w-full">
          <div class="flex flex-wrap xl:flex-no-wrap">
            <div class="w-full xl:mr-4">
              <label for="card-number" class="input-label"> Card Number </label>
              <div id="card-number" ref="cardNumber" />
            </div>
            <div class="flex w-full xl:w-auto">
              <div class="xl:flex-none w-1/2 xl:w-48 mr-4">
                <label for="card-expiry" class="input-label"> Expiry </label>
                <div id="card-expiry" ref="cardExpiry" />
              </div>
              <div class="xl:flex-none w-1/2 xl:w-28">
                <label for="card-cvc" class="input-label"> CVC </label>
                <div id="card-cvc" ref="cardCvc" />
              </div>
            </div>
          </div>
        </div>
      </FormRow>
      <FormRow>
        <div class="flex justify-end w-full mt-2">
          <img
            :src="require('~/assets/img/credit-cards/visa.png')"
            class="w-10 h-6 mr-2"
          />
          <img
            :src="require('~/assets/img/credit-cards/mastercard.png')"
            class="w-10 h-6 mr-2"
          />
          <img
            :src="require('~/assets/img/credit-cards/american-express.png')"
            class="w-10 h-6"
          />
        </div>
      </FormRow>
      <FormRow>
        <BaseAlert v-if="paymentError" class="w-full mt-2" type="error">
          {{ paymentError }}
        </BaseAlert>
      </FormRow>
    </FormSection>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import BaseAlert from '~/components/BaseAlert';
import BaseGlyph from '~/components/BaseGlyph';
import FormRow from '~/components/FormRow';
import FormSection from '~/components/FormSection';
import GenericPaymentMethod from '~/components/GenericPaymentMethod';

export default {
  name: 'PaymentStripe',
  components: {
    BaseAlert,
    BaseGlyph,
    FormRow,
    FormSection,
    GenericPaymentMethod,
  },
  data() {
    return {
      cardCvc: null,
      cardNumber: null,
      cardExpiry: null,
      elements: null,
      stripe: null,
      stripeClasses: {
        base: 'input py-6',
        focus: 'input-focus',
        invalid: 'input-error',
      },
      stripeStyles: {
        base: {
          fontFamily: 'Larsseit, Helvetica, Arial',
          fontSize: '20px',
          fontWeight: '400',
          fontSmoothing: 'antialiased',
          lineHeight: '1.6',
          '::-ms-clear': {
            display: 'none',
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters('checkout', ['paymentError']),
  },
  async mounted() {
    if (typeof window !== 'undefined') {
      this.stripe = await this.$stripe.import();
      this.createStripeElements();
    }
  },
  methods: {
    ...mapActions('checkout', ['setPaymentError']),
    async getStripeToken() {
      this.disabledStripeElements(true);
      const { token, error } = await this.stripe.createToken(this.cardNumber);
      if (error) {
        this.setPaymentError(error.message);
        this.disabledStripeElements(false);
      } else if (token) {
        return token;
      }
      return null;
    },
    createStripeElements() {
      this.elements = this.stripe.elements({
        fonts: [
          {
            family: 'Larsseit',
            src: `url('${
              window.location.origin
            }${require('~/static/fonts/Larsseit/Regular.ttf')}') format('truetype')`,
            weight: '400',
          },
        ],
      });

      this.cardNumber = this.elements.create('cardNumber', {
        classes: this.stripeClasses,
        style: this.stripeStyles,
        placeholder: '0000 0000 0000 0000',
        showIcon: true,
      });
      this.cardNumber.mount('#card-number');
      this.$emit('change', this.cardNumber);

      this.cardExpiry = this.elements.create('cardExpiry', {
        classes: this.stripeClasses,
        style: this.stripeStyles,
      });
      this.cardExpiry.mount('#card-expiry');

      this.cardCvc = this.elements.create('cardCvc', {
        classes: this.stripeClasses,
        style: this.stripeStyles,
      });
      this.cardCvc.mount('#card-cvc');
    },
    disabledStripeElements(disabled) {
      if (this.cardNumber) {
        this.cardNumber.update({ disabled });
      }

      if (this.cardExpiry) {
        this.cardExpiry.update({ disabled });
      }

      if (this.cardCvc) {
        this.cardCvc.update({ disabled });
      }
    },
  },
};
</script>
