<template>
  <ModuleStep :index="3" prev-step="/guardians/primary">
    <Form
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      submit-arrow
      @done="submit"
    >
      <FormSection class="step-1">
        <template #heading>
          Would you like to appoint a backup guardian for your children?
        </template>
        <p>
          A backup guardian will take parental responsibility of your children
          if your primary guardian is unable or unwilling to act.
        </p>
        <Tip>
          If you have someone in mind who could act if your primary guardian is
          unable to, it may be a good idea to add this extra layer of
          protection.
        </Tip>
        <LargeRadioButtons
          id="hasBackupGuardians"
          v-model="willMeta.has_backup_guardian"
          :options="[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]"
        />
      </FormSection>
      <FormSection
        v-if="willMeta.has_backup_guardian"
        class="step-2"
        tip="We’ll ask you to add or select people at relevant places in your Will. Their details can be edited on the People, Pets & Charities page."
      >
        <template #heading>
          Who would you like to appoint as backup guardian?
        </template>
        <Tip>
          Guardians must be over the age of 18.
        </Tip>
        <PersonSelector
          v-model="selectedBackupGuardian"
          :loading="loading"
          :max="1"
          :people="filteredPeople"
          type="guardian"
        />
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapActions } from 'vuex';

import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import LargeRadioButtons from '~/components/LargeRadioButtons';
import ModuleStep from '~/components/ModuleStep';
import PersonSelector from '~/components/PersonSelector';
import Tip from '~/components/Tip';

import { guardians, people, user, will } from '~/mixins/apollo';
import { metaArrayToObject } from '~/utilities';

export default {
  name: 'GuardiansBackup',
  components: {
    Form,
    FormSection,
    LargeRadioButtons,
    ModuleStep,
    PersonSelector,
    Tip,
  },
  mixins: [guardians, people, user, will],
  data() {
    return {
      selectedBackupGuardian: [],
    };
  },
  computed: {
    isComplete() {
      return !!(
        this.willMeta.has_backup_guardian === false ||
        this.selectedBackupGuardian.length
      );
    },
    filteredPeople() {
      return this.people.filter((person) => {
        const meta = metaArrayToObject(person.meta);
        const personIsAlreadySelected = this.selectedBackupGuardian.includes(
          person.id
        );
        return (
          personIsAlreadySelected ||
          (meta.is_over_18 && !this.isPrimaryGuardian(person))
        );
      });
    },
    variables() {
      return {
        id: this.willId,
        meta: this.willMeta.toArray(),
      };
    },
  },
  watch: {
    backupGuardians(guardians) {
      if (guardians && !this.selectedBackupGuardian.length) {
        this.selectedBackupGuardian = this.people
          .filter((person) => {
            return guardians.find(
              (guardian) => guardian.person.id === person.id
            );
          })
          .map((person) => person.id);
      }
    },
  },
  methods: {
    ...mapActions('person', ['addPerson']),
    async submit() {
      for (const person of this.people) {
        const isBackupGuardian = this.isBackupGuardian(person);
        const isSelected = this.selectedBackupGuardian.includes(person.id);

        if (this.willMeta.has_backup_guardian && isSelected) {
          if (!isBackupGuardian) {
            await this.addGuardian(person, 'backup');
          }
        } else if (isBackupGuardian) {
          await this.removeGuardian(person);
        }
      }

      this.$router.push({
        path: '/guardians/your-pets',
      });
    },
  },
};
</script>
