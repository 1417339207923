<template>
  <div>
    <template v-if="!['update', 'renew'].includes(checkoutAction)">
      <div v-if="finalSetupPrice !== null" class="pt-5">
        <h4 class="flex justify-between text-lg mb-6">
          <div class="flex items-center">
            <BaseIcon id="feather" />
            <span class="ml-4">Your Will</span>
          </div>
          <span v-text="`$${defaultSetupPrice}`" />
        </h4>
        <h4 v-if="addPartnersWill" class="flex justify-between text-lg mb-6">
          <div class="flex items-center">
            <BaseIcon id="feather" />
            <div class="flex flex-col items-start ml-4">
              <span>Your partner's Will</span>
              <span class="text-grey-600 text-base">
                Couple's discount (-50%)
              </span>
              <BaseButton
                class="text-grey-600 text-base underline hover:no-underline"
                @click="removeWill()"
              >
                Remove
              </BaseButton>
            </div>
          </div>
          <div class="flex flex-col">
            <span v-text="`$${defaultPartnerPrice}`" />
            <span
              class="text-grey-600 text-base line-through"
              v-text="`$${defaultSetupPrice}`"
            />
          </div>
        </h4>
        <div v-if="couponCode" class="mb-6">
          <span
            v-show="couponCode"
            v-text="`${couponCode} Discount Applied.`"
          />
          <BaseButton
            v-if="couponCode"
            class="text-teal-200 underline hover:no-underline"
            @click="$nuxt.$emit('applyCoupon', null)"
          >
            Remove
          </BaseButton>
        </div>
        <div
          class="py-5 flex justify-between border-t-2 text-xl border-grey-300"
        >
          <span>Total</span>
          <div>
            <span class="text-grey-600 text-base">AUD</span>
            <span>${{ finalSetupPrice }}</span>
          </div>
        </div>
      </div>
      <div v-if="finalSetupPrice === 0" class="py-5 border-t-2 border-grey-300">
        <p class="flex justify-between text-base">
          <span>
            A valid credit card is required for identity verification processes
          </span>
        </p>
      </div>
    </template>
    <template v-else-if="checkoutAction === 'renew'">
      <div class="py-5 border-grey-300">
        <h4 class="flex justify-between text-xl">
          <span>
            Unlock account
          </span>
          <span> ${{ defaultUnlockingPrice }} </span>
        </h4>
      </div>
      <div class="py-5 border-t-2 border-b-2 mb-8 border-grey-300">
        <h4 class="flex justify-between text-xl">
          <span>
            Total
          </span>
          <div>
            <span class="text-grey-600 text-base">AUD</span>
            <span>${{ defaultUnlockingPrice }}</span>
          </div>
        </h4>
      </div>
    </template>
    <template v-else>
      <div class="py-5 border-t-2 border-b-2 mb-8 border-grey-300">
        <h4 class="flex justify-between text-xl">
          <span v-if="checkoutAction === 'submit'">Unlimited updates</span>
          <span v-if="checkoutAction === 'update'">Renew my subscription</span>
          <span> ${{ defaultSubscriptionPrice }} / year </span>
        </h4>
      </div>
    </template>
    <div
      v-if="!['update', 'renew'].includes(checkoutAction)"
      class="py-5 border-t-2 border-grey-300"
    >
      <CouponInput :hidden="!!couponCode" :will-product="willProduct" />
      <div class="hidden lg:block mt-10 relative z-10 flex justify-center">
        <BaseWill />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { formatError } from '~/utilities';

import BaseIcon from '~/components/BaseIcon';
import CouponInput from '~/components/CouponInput';
import BaseButton from '~/components/BaseButton';
import BaseWill from '~/components/BaseWill';

import GET_PRICE_QUERY from '~/graphql/queries/GetPrice';

export default {
  name: 'CheckoutSummary',
  components: {
    BaseIcon,
    CouponInput,
    BaseButton,
    BaseWill,
  },
  props: {
    payPartnersWill: {
      type: Boolean,
    },
  },
  data() {
    return {
      addPartnersWill: false,
    };
  },
  computed: {
    ...mapGetters(['willId']),
    ...mapGetters('checkout', [
      'couponCode',
      'checkoutAction',
      'defaultSetupPrice',
      'defaultPartnerPrice',
      'defaultSubscriptionPrice',
      'defaultUnlockingPrice',
      'finalSetupPrice',
    ]),
    ...mapFields('signup', ['meta.has_partner']),
    classes() {
      return {
        'bg-teal-100': this.success,
        'bg-white': !this.success,
      };
    },
    renewalDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      return this.$formatDate(date, 'mmm d, yyyy');
    },
    willProduct() {
      return this.addPartnersWill ? 'WILL_X2' : 'WILL';
    },
  },
  watch: {
    has_partner(value) {
      this.addPartnersWill = value;
    },
    payPartnersWill(value) {
      this.addPartnersWill = value;
    },
    addPartnersWill() {
      this.updatePrice();
    },
  },
  methods: {
    ...mapActions('checkout', ['setCouponCode', 'setFinalSetupPrice']),
    removeWill() {
      this.addPartnersWill = false;
      this.$emit('remove:partnersWill');
      this.$nuxt.$emit('applyCoupon', this.modelValue);
    },
    async updatePrice() {
      try {
        const variables = {
          willId: this.willId,
          products: [this.willProduct],
        };

        // add code if exists
        if (this.couponCode) {
          variables.code = this.couponCode;
        }

        const { data } = await this.$apollo.query({
          query: GET_PRICE_QUERY,
          variables,
        });

        if (data.getPrice && data.getPrice.coupon) {
          const { coupon, setup } = data.getPrice;
          if (coupon.error) {
            this.error = coupon.error;
          } else if (!coupon.active) {
            this.error = 'Code expired';
          } else {
            this.setCouponCode(coupon.code);
            this.setFinalSetupPrice(parseInt(setup.finalPrice, 10) / 100);
          }
        } else if (data.getPrice && data.getPrice.invite) {
          const { invite, setup } = data.getPrice;
          this.setCouponCode(invite.code);
          this.setFinalSetupPrice(parseInt(setup.finalPrice, 10) / 100);
        } else if (data.getPrice && !this.couponCode) {
          const { setup } = data.getPrice;
          this.setCouponCode(null);
          this.setFinalSetupPrice(parseInt(setup.finalPrice, 10) / 100);
        } else {
          this.error = 'Invalid code';
        }
      } catch (err) {
        this.error = formatError(err.message);
      }
    },
  },
};
</script>
