<template>
  <ModuleStep :index="3" prev-step="/poa/about-yourself/address">
    <Form
      v-slot="{ loading }"
      auto-focus
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      submit-arrow
      @done="onDone"
    >
      <FormSection>
        <template #heading> What is your date of birth? </template>
        <Tip>
          Safewill is only available to people above the age of 18 years
        </Tip>
        <FormRow>
          <DateInput
            id="date_of_birth"
            v-model="willMeta.date_of_birth"
            :disabled="loading"
            rules="required|date|over18"
          />
        </FormRow>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import Form from '~/components/Form';
import FormRow from '~/components/FormRow';
import FormSection from '~/components/FormSection';
import ModuleStep from '~/components/ModuleStep';
import DateInput from '~/components/DateInput';
import Tip from '~/components/Tip';

import { user, will } from '~/mixins/apollo';
import { age } from '~/utilities';

export default {
  name: 'AboutYourselfName',
  components: {
    Form,
    FormRow,
    FormSection,
    ModuleStep,
    DateInput,
    Tip,
  },
  mixins: [user, will],
  computed: {
    isComplete() {
      return this.willMeta && this.willMeta.date_of_birth;
    },
  },
  methods: {
    onDone() {
      this.$nuxt.$emit('sendTrackingAttributes', {
        date_of_birth: `${this.willMeta.date_of_birth}`,
        age: age(this.willMeta.date_of_birth),
      });

      this.$router.push({
        path: '/poa/about-yourself/phone',
      });
    },
  },
};
</script>
