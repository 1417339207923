<template>
  <ModuleStep :index="1" prev-step="/dashboard" :show-help="true">
    <Form submit-arrow @submit="next">
      <FormSection>
        <template #heading>
          Executors
        </template>
        <p class="mb-5">
          <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
          An <GlossaryTooltip term="Executor">executor</GlossaryTooltip> is
          someone you nominate to carry out your Will. They’ll be responsible
          for following your instructions and performing the legal and financial
          actions required, including —
        </p>
        <ul class="unordered-list text-lg">
          <li>Identifying your assets, debts and possessions</li>
          <li>Distributing your estate and gifts</li>
          <li>Managing related accounting and tax obligations</li>
          <li>Legal and financial paperwork</li>
          <li>Keeping relevant people and organisations informed</li>
        </ul>
        <Tip>
          Carrying out your wishes is sometimes referred to as
          <GlossaryTooltip term="Estate Administration">
            Estate Administration
          </GlossaryTooltip>
        </Tip>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import GlossaryTooltip from '~/components/GlossaryTooltip';
import ModuleStep from '~/components/ModuleStep';
import Tip from '~/components/Tip';

import { will } from '~/mixins/apollo';

export default {
  name: 'ExecutorIntro',
  components: {
    Form,
    FormSection,
    GlossaryTooltip,
    ModuleStep,
    Tip,
  },
  mixins: [will],
  methods: {
    async next() {
      const executorOption = this.willMeta.executors_option;
      if (
        executorOption === 'professional' ||
        executorOption === 'friendsFamilyAndProfessional'
      ) {
        this.$router.push({
          path: '/executors/options',
        });
      } else {
        if (!executorOption) {
          this.willMeta.executors_option = 'friendsFamily';
          await this.updateWillMeta();
        }
        this.$router.push({
          path: '/executors/primary',
        });
      }
    },
  },
};
</script>
