var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleStep',{attrs:{"index":1,"prev-step":"/dashboard","show-help":true}},[_c('Form',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"hide-submit":!_vm.isComplete,"auto-submit":!_vm.willMeta.has_gifts,"submit-arrow":""},on:{"done":_vm.done}},[_c('FormSection',{staticClass:"step-1",scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        Would you like to leave a\n        "),_c('GlossaryTooltip',{attrs:{"term":"Gift"}},[_vm._v(" gift ")]),_vm._v("? 🎁\n      ")]},proxy:true}])},[_vm._v(" "),_c('p',{staticClass:"mb-8 sm:mb-12 text-2xl"},[_vm._v("\n        Gifts are a special part of your Will. You can gift anything you own,\n        whether it's a possession or an amount of money.\n      ")]),_vm._v(" "),_c('Tip',[_vm._v("\n        Gifts are specific items you'd like to give to specific people or\n        charities. Anything you don't gift will automatically go toward your\n        estate.\n      ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"has_gifts","options":[
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          } ]},model:{value:(_vm.willMeta.has_gifts),callback:function ($$v) {_vm.$set(_vm.willMeta, "has_gifts", $$v)},expression:"willMeta.has_gifts"}})],1),_vm._v(" "),(_vm.willMeta.has_gifts)?_c('FormSection',{staticClass:"step-2",scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        What would you like to gift?\n      ")]},proxy:true}],null,false,3409249770)},[_vm._v(" "),_c('Tip',[_vm._v("\n        If you would like to gift a single item among multiple beneficiaries,\n        you can enter the proportion of the item that each beneficiary should\n        receive as a separate gift. For example, if you would like your\n        “record collection” to be split equally between Person A and Person B,\n        you would leave a gift of 50% of your record collection to Person A,\n        and another gift of 50% of your record collection to Person B (Just\n        make sure that it adds to 100%)!\n      ")]),_vm._v(" "),_vm._l((_vm.gifts),function(gift,index){return _c('MetaSlot',{key:index,attrs:{"gift-meta":gift.meta,"person-meta":gift.person && gift.person.meta,"charity-meta":gift.charity && gift.charity.meta},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var giftMeta = ref.giftMeta;
        var personMeta = ref.personMeta;
        var charityMeta = ref.charityMeta;
return [_c('DataCard',{attrs:{"title":giftMeta.type === 'money'
              ? ("$" + (giftMeta.amount))
              : giftMeta.description,"description":personMeta.full_name || charityMeta.name,"icon":giftMeta.type === 'money' ? 'wallet' : 'gift',"edit":"","remove":"","type":"gift"},on:{"edit":function($event){return _vm.editGift(gift)},"remove":function($event){return _vm.removeGift(gift)}}},[(giftMeta.note)?_c('BaseAlert',{attrs:{"compact":"","type":"confirm"}},[_c('div',[_c('h6',[_vm._v("\n                You wrote:\n              ")]),_vm._v(" "),_c('p',{staticClass:"font-normal",domProps:{"textContent":_vm._s(giftMeta.note)}})])]):_vm._e()],1)]}}],null,true)})}),_vm._v(" "),_c('BaseButton',{staticClass:"btn-xl btn-white w-full add-gift",attrs:{"focusable":""},on:{"click":_vm.addGift}},[_vm._v("\n        + Add a gift\n      ")])],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }