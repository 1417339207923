<template>
  <div class="flex justify-start text-charcoal-200">
    <div
      class="flex justify-center items-center w-6 h-6 rounded-full mr-2 text-white"
      :class="valid ? 'bg-teal-200' : 'bg-grey-300'"
    >
      <BaseGlyph id="tick-small" />
    </div>
    <slot />
  </div>
</template>

<script>
import BaseGlyph from '~/components/BaseGlyph';

export default {
  name: 'PasswordValidatorItem',
  components: {
    BaseGlyph,
  },
  props: {
    valid: {
      default: true,
      type: Boolean,
    },
  },
};
</script>
