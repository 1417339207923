<template>
  <ModuleStep :index="4" :prev-step="prevStep">
    <Form
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      :auto-submit="!willMeta.has_pets"
      submit-arrow
      @done="submit"
    >
      <FormSection class="step-1">
        <template #heading>
          Do you have any pets?
        </template>
        <LargeRadioButtons
          id="hasPets"
          v-model="willMeta.has_pets"
          :options="[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]"
        />
      </FormSection>
      <FormSection v-if="willMeta.has_pets" class="step-2">
        <template #heading>
          My pets are...
        </template>
        <MetaSlot
          v-for="(pet, index) in pets"
          :key="index"
          v-slot="{ meta }"
          :meta="pet.meta"
        >
          <DataCard
            :title="meta.name"
            :description="meta.type"
            edit
            remove
            type="pet"
            @edit="editPet(pet)"
            @remove="removePet(pet)"
          />
        </MetaSlot>
        <BaseButton
          class="btn-xl btn-white w-full add-pet"
          :disabled="loading"
          focusable
          @click="addPet"
        >
          + Add a pet
        </BaseButton>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import BaseButton from '~/components/BaseButton';
import DataCard from '~/components/DataCard';
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import LargeRadioButtons from '~/components/LargeRadioButtons';
import MetaSlot from '~/components/MetaSlot';
import ModuleStep from '~/components/ModuleStep';

import { pets, user, will } from '~/mixins/apollo';

export default {
  name: 'GuardiansYourPets',
  components: {
    BaseButton,
    DataCard,
    Form,
    FormSection,
    LargeRadioButtons,
    MetaSlot,
    ModuleStep,
  },
  mixins: [pets, user, will],
  computed: {
    isComplete() {
      return !!(this.willMeta.has_pets === false || this.pets.length);
    },
    nextStep() {
      return this.willMeta.has_pets ? '/guardians/pet-guardians' : '/dashboard';
    },
    prevStep() {
      let to = '/guardians/your-children';

      if (this.willMeta.has_children) {
        if (this.willMeta.has_backup_guardian !== null) {
          to = '/guardians/backup';
        } else if (this.willMeta.has_primary_guardian !== null) {
          to = '/guardians/primary';
        }
      }

      return to;
    },
  },
  methods: {
    async submit() {
      if (this.willMeta.has_pets === false && this.pets.length) {
        await this.removeAllPets();

        this.willMeta.has_pet_guardians = null;
        this.willMeta.pet_care_fund = '';
        await this.updateWillMeta();
      }

      if (!this.willMeta.has_pets) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '✅ Module 2 (Guardians)',
        });
      }

      this.$router.push({
        path: this.willMeta.has_pets
          ? '/guardians/pet-guardians'
          : '/dashboard',
      });
    },
  },
};
</script>
