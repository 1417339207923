<template>
  <ModuleStep :index="2" prev-step="/estate">
    <Form
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      submit-arrow
      @done="done"
    >
      <FormSection>
        <template #heading>
          Would you consider leaving a portion of your estate to a charity or
          not-for-profit?
        </template>
        <p>
          Many of our users choose to leave part of their estate to charity, and
          a gift as small as 1% can create a lasting impact. Is there a cause
          you are passionate about?
        </p>
        <Tip>
          If you wish to leave a monetary gift to charity, you can do so in the
          Gifts section of your Will
        </Tip>
        <LargeRadioButtons
          id="hasPartner"
          v-model="willMeta.charity_in_estate"
          class="mb-8"
          :options="[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]"
        />
        <div v-if="willMeta.charity_in_estate">
          <h4 class="mb-4 text-2xl">
            Charities and Not-for-Profits
          </h4>
          <CharitySuggester
            v-if="showSuggestedCharities"
            v-model="selectedSuggestedCharities"
            :loading="loading"
            :selected-suggested-charities="selectedSuggestedCharities"
          />
          <CharitySelector
            v-model="selectedBeneficiaryIDs"
            :loading="loading"
          />
          <BaseButton
            class="btn-xl btn-white w-full add-charity"
            :disabled="loading"
            focusable
            @click="addCharity"
          >
            + Add a charity
          </BaseButton>
        </div>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapActions } from 'vuex';
import { metaArrayToObject } from '~/utilities';
import { isSameCharity } from '~/utilities/charity';

import BaseButton from '~/components/BaseButton';
import CharitySelector from '~/components/CharitySelector';
import CharitySuggester from '~/components/CharitySuggester';
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import ModuleStep from '~/components/ModuleStep';
import Tip from '~/components/Tip';
import LargeRadioButtons from '~/components/LargeRadioButtons';

import { beneficiaries, charities, people, user, will } from '~/mixins/apollo';

export default {
  name: 'EstateBeneficiaries',
  components: {
    BaseButton,
    CharitySelector,
    CharitySuggester,
    Form,
    FormSection,
    ModuleStep,
    LargeRadioButtons,
    Tip,
  },
  mixins: [beneficiaries, charities, people, user, will],
  data() {
    return {
      selectedBeneficiaryIDs: [],
      selectedSuggestedCharities: [],
    };
  },
  computed: {
    isComplete() {
      const madeSelection = this.willMeta.charity_in_estate !== undefined;
      const includeCharity = this.willMeta.charity_in_estate;
      const hasSelectedBeneficiaryIDs = this.selectedBeneficiaryIDs.length;
      const hasSelectedSuggestedBeneficiaries = !!(
        this.showSuggestedCharities && !!this.selectedSuggestedCharities.length
      );
      return (
        madeSelection &&
        (!includeCharity ||
          (includeCharity &&
            (hasSelectedBeneficiaryIDs || hasSelectedSuggestedBeneficiaries)))
      );
    },
    hasFamily() {
      return !!(this.partners.length || this.children.length);
    },
    showSuggestedCharities() {
      const hasReferral = !!this.willMeta.referral_charity;
      const hasAddedSuggestedCharity = this.charities.some((charity) => {
        const charityMeta = metaArrayToObject(charity.meta);
        return !!charityMeta.suggestionLocation;
      });
      return !hasReferral && !hasAddedSuggestedCharity;
    },
  },
  watch: {
    beneficiaries() {
      if (
        this.willMeta.estate_split === 'custom' &&
        this.beneficiaries.length &&
        !this.selectedBeneficiaryIDs.length
      ) {
        const people = this.people
          .filter((person) => {
            return this.beneficiaries.find(
              (beneficiary) =>
                beneficiary.person && beneficiary.person.id === person.id
            );
          })
          .map((person) => person.id);

        const charities = this.charities
          .filter((charity) => {
            return this.beneficiaries.find(
              (beneficiary) =>
                beneficiary.charity && beneficiary.charity.id === charity.id
            );
          })
          .map((charity) => charity.id);

        this.selectedBeneficiaryIDs = people.concat(charities);
      }
    },
  },
  mounted() {
    this.willMeta.estate_backup_index = 0;
    this.updateWillMeta();
  },
  methods: {
    ...mapActions('charity', ['addCharity']),
    async done() {
      const availableDistribution = 100;

      if (this.willMeta.charity_in_estate) {
        this.willMeta.estate_split = 'custom';
        this.updateWillMeta();

        if (this.selectedSuggestedCharities.length) {
          await Promise.all(
            this.selectedSuggestedCharities.map((charity) => {
              return this.addPartnerCharity(charity);
            })
          );
          await this.refetchCharities();
          this.charities.forEach((charity) => {
            const charityMeta = metaArrayToObject(charity.meta);
            const exists = this.selectedSuggestedCharities.find(
              (selectedSuggestedCharity) => {
                return isSameCharity(selectedSuggestedCharity, charityMeta);
              }
            );
            if (exists) {
              this.selectedBeneficiaryIDs.push(charity.id);
            }
          });
        }

        const existingPeople = this.beneficiaries.filter(
          (beneficiary) => beneficiary.person
        );
        const existingCharities = this.beneficiaries.filter((beneficiary) => {
          return this.selectedBeneficiaryIDs.find((id) => {
            return beneficiary.charity && beneficiary.charity.id === id;
          });
        });

        const total =
          this.selectedBeneficiaryIDs.length -
          existingCharities.length +
          existingPeople.length;
        existingPeople.map((beneficiary) => {
          this.updateBeneficiary(beneficiary, availableDistribution / total);
        });

        for (const charity of this.charities) {
          const beneficiary = this.getBeneficiaryByCharity(charity);
          const isSelected = this.selectedBeneficiaryIDs.includes(charity.id);

          if (isSelected) {
            if (!beneficiary) {
              await this.addBeneficiary(charity, availableDistribution / total);
            }
          } else if (beneficiary) {
            await this.removeBeneficiary(beneficiary);
          }
        }
      }

      this.$nuxt.$emit('sendTrackingAttributes', {
        has_charity_gift:
          this.willMeta.charity_in_gifts || this.willMeta.charity_in_estate,
      });

      const currentPrimaryTotal = this.beneficiaries.reduce(
        (total, beneficiary) => {
          return total + Number(beneficiary.distribution);
        },
        0
      );
      const mustRedistribute =
        this.willMeta.estate_split === 'custom' || currentPrimaryTotal !== 100;

      this.$router.push({
        path: mustRedistribute
          ? '/estate/distribution'
          : `/estate/backup-beneficiaries/${this.backupQueue[0].id}`,
      });
    },
  },
};
</script>
