<template>
  <ModuleStep :index="1" prev-step="/dashboard" :show-help="true">
    <Form
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      :auto-submit="!willMeta.has_assets"
      submit-arrow
      @done="done"
    >
      <FormSection>
        <template #heading>
          Add notes about your assets and debts
        </template>
        <p>
          These notes are instructive only and are non binding. You can divide
          your estate in the Estate section, or leave specific assets to
          particular people in the Gifts section.
        </p>
        <Tip>
          This section is optional and should only be used to help guide your
          executor. If there is any information about your assets and
          liabilities that you want your executor to know, you can add it here.
        </Tip>
        <LargeRadioButtons
          id="hasAssets"
          v-model="willMeta.has_assets"
          :options="[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]"
        />
      </FormSection>
      <FormSection v-if="willMeta.has_assets">
        <div>
          <EmptyState v-if="!selectedAssets.length">
            No assets added to your will yet.
          </EmptyState>
          <AssetInputs
            v-for="(asset, index) in selectedAssets"
            :id="`asset_${asset.id || index}`"
            :key="index"
            :disabled="loading"
            :description="asset.meta.description"
            :type="asset.meta.type"
            @description="asset.meta.description = $event"
            @type="asset.meta.type = $event"
            @add="addAssetInput"
            @remove="removeAssetInput(asset)"
          />
        </div>
        <div class="flex justify-end">
          <BaseButton
            class="btn-md btn-teal add-asset"
            focusable
            @click="addAssetInput"
          >
            + Add
          </BaseButton>
        </div>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import AssetInputs from '~/components/AssetInputs';
import BaseButton from '~/components/BaseButton';
import EmptyState from '~/components/EmptyState';
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import LargeRadioButtons from '~/components/LargeRadioButtons';
import ModuleStep from '~/components/ModuleStep';
import Tip from '~/components/Tip';

import { assets, user, will } from '~/mixins/apollo';
import { metaArrayToObject, objectToMetaArray } from '~/utilities';

export default {
  name: 'AssetsAdd',
  components: {
    AssetInputs,
    BaseButton,
    EmptyState,
    Form,
    FormSection,
    LargeRadioButtons,
    ModuleStep,
    Tip,
  },
  mixins: [assets, user, will],
  data() {
    return {
      defaultAsset: {},
      selectedAssets: [],
    };
  },
  computed: {
    isComplete() {
      return !!(
        this.willMeta.has_assets === false || this.selectedAssets.length
      );
    },
  },
  watch: {
    assets(assets) {
      if (assets.length && !this.selectedAssets.length) {
        this.selectedAssets = assets.map(({ id, meta }) => {
          return {
            id,
            meta: metaArrayToObject(meta),
          };
        });
      }
    },
  },
  methods: {
    addAssetInput() {
      this.selectedAssets.push({
        meta: {
          description: '',
          type: '',
        },
      });
    },
    async removeAssetInput(asset) {
      if (this.isAsset(asset)) {
        await this.removeAsset(asset);
      }

      this.selectedAssets.splice(this.selectedAssets.indexOf(asset), 1);
    },
    async done() {
      for (const asset of this.selectedAssets) {
        const isAsset = this.isAsset(asset);
        const meta = objectToMetaArray(asset.meta);

        if (this.willMeta.has_assets) {
          if (isAsset) {
            await this.updateAsset(asset, meta);
          } else {
            await this.addAsset(meta);
          }
        } else if (isAsset) {
          await this.removeAsset(asset);
        }
      }

      this.$router.push({
        path: '/assets/liabilities',
      });
    },
  },
};
</script>
