<template>
  <ValidationObserver ref="observer" tag="form" @submit.prevent="submit">
    <slot :loading="loading" />
    <FormRow v-if="!hideSubmit" :inline="submitInline">
      <div :class="{ 'w-full': !submitInline }">
        <p
          v-if="error"
          class="form-error mt-2 text-red-300"
          v-text="customError || error"
        />
        <p
          v-if="$nuxt.isOffline"
          class="form-error mt-2 text-red-300"
          v-text="`Unavailable until your Internet connection is restored.`"
        />
        <BaseButton
          class="btn-slate"
          :class="[classes.submit, submitClasses]"
          :disabled="disabled || $nuxt.isOffline"
          focusable
          :loading="loading"
          type="submit"
        >
          <div class="flex items-center">
            <span
              class="inline-block text-white"
              :class="classes.submitLabel"
              v-text="submitLabel"
            />
            <span v-if="submitArrow" class="ml-4">
              <BaseIcon id="arrow-right" />
            </span>
          </div>
        </BaseButton>
      </div>
    </FormRow>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

import { formatError } from '~/utilities';
import BaseButton from '~/components/BaseButton';
import FormRow from '~/components/FormRow';
import BaseIcon from '~/components/BaseIcon';

export default {
  name: 'Form',
  components: {
    BaseButton,
    BaseIcon,
    FormRow,
    ValidationObserver,
  },
  props: {
    autoFocus: {
      default: false,
      type: Boolean,
    },
    customError: {
      default: null,
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    hideSubmit: {
      default: false,
      type: Boolean,
    },
    autoSubmit: {
      default: false,
      type: Boolean,
    },
    mutation: {
      default: null,
      type: Object,
    },
    submitArrow: {
      default: false,
      type: Boolean,
    },
    submitCompact: {
      default: false,
      type: Boolean,
    },
    submitClasses: {
      default: '',
      type: String,
    },
    submitInline: {
      default: false,
      type: Boolean,
    },
    submitLabel: {
      default: 'Next',
      type: String,
    },
    submitWide: {
      default: false,
      type: Boolean,
    },
    variables: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      isDone: false,
      isLoading: false,
      error: null,
    };
  },
  computed: {
    apollo() {
      return this.mutation && this.variables;
    },
    classes() {
      return {
        submit: {
          'min-w-xs': this.submitArrow,
          'btn-lg': this.submitCompact,
          'btn-xl': !this.submitCompact,
          'w-full': this.submitWide,
          'btn-disabled': this.disabled,
          'mt-4': this.submitWide || this.error,
        },
        submitLabel: {
          'mr-auto': this.submitArrow,
          'text-grey-500': this.disabled,
        },
      };
    },
    loading() {
      return this.isDone || this.isLoading;
    },
  },
  watch: {
    hideSubmit(hideSubmit) {
      if (this.autoSubmit && !hideSubmit) {
        this.submit();
      }
    },
  },
  mounted() {
    this.$nuxt.$on('unlockForm', () => {
      this.isDone = false;
      this.isLoading = false;
    });

    this.$nuxt.$on('clearErrors', () => {
      this.$refs.observer && this.$refs.observer.reset();
    });

    if (this.autoFocus) {
      const first = this.$el.querySelector(
        'input[type=email],input[type=number],input[type=password],input[type=text]'
      );

      if (first) {
        first.focus();
      }
    }
  },
  methods: {
    async submit($event, mutate) {
      const isValid =
        this.$refs.observer && (await this.$refs.observer.validate());

      if (isValid) {
        this.isLoading = true;
        this.error = null;

        if (this.apollo) {
          await this.$apollo
            .mutate({
              mutation: this.mutation,
              variables: this.variables,
              update: (store, res) => {
                this.$emit('update', store, res);
                this.isDone = true;
                this.$emit('done', res);
              },
            })
            .then(({ errors }) => {
              if (errors) {
                this.isLoading = false;
                this.error = formatError(errors[0].message);
              }
            })
            .catch((e) => {
              this.isLoading = false;
              this.error = formatError(e.message);
            });
        } else {
          this.$emit('submit', $event);
          this.isLoading = true;
        }
      } else {
        this.error = 'Please complete all the required fields.';

        // Scroll to the first field with an error
        const parent = this.$refs.observer.$el.parentElement;
        const errorElement = parent.querySelector('.input-error');
        const parentIsScrollable = parent.classList.contains('overflow-y-auto');
        const scrollableElement = parentIsScrollable ? parent : window;

        if (errorElement && errorElement.closest('.text-base')) {
          const position = errorElement.closest('.text-base').offsetTop;
          const offsetPosition = parentIsScrollable ? parent.offsetTop : 0;

          scrollableElement.scrollTo({
            top: position - offsetPosition,
            behavior: 'smooth',
          });
        }
      }
    },
  },
};
</script>
