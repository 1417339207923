<template>
  <BaseCard
    class="mb-4 last-child:mb-0"
    :class="classes.card"
    :icon="icon"
    :logo="logo"
    :primary-text="title"
    :secondary-text="description"
  >
    <template #actions>
      <template v-if="!suggested">
        <Tooltip
          class="flex-grow md:flex-grow-0"
          :content="tooltip.edit"
          :disabled="edit"
        >
          <BaseButton
            class="btn btn-md btn-outline w-full md:w-auto"
            :disabled="!edit"
            @click="$emit('edit')"
          >
            Edit
          </BaseButton>
        </Tooltip>
        <Tooltip
          class="flex-grow md:flex-grow-0 ml-2"
          :content="tooltip.remove"
          :disabled="remove"
        >
          <BaseButton
            class="btn btn-md btn-outline w-full md:w-auto"
            :disabled="!remove"
            @click="$emit('remove')"
          >
            Remove
          </BaseButton>
        </Tooltip>
      </template>
      <Chip v-else label="Suggested" />
    </template>
    <slot />
  </BaseCard>
</template>

<script>
import { mapGetters } from 'vuex';

import BaseCard from '~/components/BaseCard';
import BaseButton from '~/components/BaseButton';
import Chip from '~/components/Chip';
import Tooltip from '~/components/Tooltip';

export default {
  name: 'DataCard',
  components: {
    BaseCard,
    BaseButton,
    Chip,
    Tooltip,
  },
  props: {
    description: {
      default: null,
      type: String,
    },
    edit: {
      default: false,
      type: Boolean,
    },
    icon: {
      default: null,
      type: String,
    },
    logo: {
      default: null,
      type: String,
    },
    remove: {
      default: false,
      type: Boolean,
    },
    suggested: {
      default: false,
      type: Boolean,
    },
    title: {
      default: null,
      type: String,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['willStatus']),
    classes() {
      return {
        card: {
          'bg-teal-200': this.selected,
        },
      };
    },
    tooltip() {
      return {
        edit:
          this.willStatus === 'APPROVED'
            ? `Unlock your Will to edit this ${this.type}.`
            : `This ${this.type} can’t be edited while your Will is being reviewed.`,
        remove: `This ${this.type} can’t be removed as they appear in your Will.`,
      };
    },
  },
};
</script>
