var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleStep',{attrs:{"index":3,"prev-step":"/dashboard","show-help":true}},[_c('Form',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"hide-submit":!_vm.isComplete,"auto-submit":!_vm.willMeta.has_liabilities,"submit-arrow":""},on:{"done":_vm.done},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('FormSection',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        Do you have any liabilities?\n      ")]},proxy:true}],null,true)},[_vm._v(" "),_c('p',[_vm._v("\n        Adding liabilities here will help your executor to settle the debts of\n        your estate, and distribute your remaining assets as instructed in\n        your Will.\n      ")]),_vm._v(" "),_c('Tip',[_vm._v("\n        You can add notes about any debts and liabilities in the next step.\n      ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"hasLiabilities","options":[
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          } ]},model:{value:(_vm.willMeta.has_liabilities),callback:function ($$v) {_vm.$set(_vm.willMeta, "has_liabilities", $$v)},expression:"willMeta.has_liabilities"}})],1),_vm._v(" "),(_vm.willMeta.has_liabilities)?_c('FormSection',[_c('div',[(!_vm.selectedLiabilities.length)?_c('EmptyState',[_vm._v("\n          No liabilities added to your will yet.\n        ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.selectedLiabilities),function(liability,index){return _c('LiabilityInputs',{key:index,attrs:{"id":("liability_" + (liability.id || index)),"disabled":loading,"description":liability.meta.description,"type":liability.meta.type},on:{"description":function($event){liability.meta.description = $event},"type":function($event){liability.meta.type = $event},"add":_vm.addLiabilityInput,"remove":function($event){return _vm.removeLiabilityInput(liability)}}})})],2),_vm._v(" "),_c('div',{staticClass:"flex justify-end"},[_c('BaseButton',{staticClass:"btn-md btn-teal add-liability",attrs:{"focusable":""},on:{"click":_vm.addLiabilityInput}},[_vm._v("\n          + Add\n        ")])],1)]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }