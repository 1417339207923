var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleStep',{attrs:{"index":2,"prev-step":"/estate"}},[_c('Form',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"hide-submit":!_vm.isComplete,"submit-arrow":""},on:{"done":_vm.done},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('FormSection',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        Would you consider leaving a portion of your estate to a charity or\n        not-for-profit?\n      ")]},proxy:true}],null,true)},[_vm._v(" "),_c('p',[_vm._v("\n        Many of our users choose to leave part of their estate to charity, and\n        a gift as small as 1% can create a lasting impact. Is there a cause\n        you are passionate about?\n      ")]),_vm._v(" "),_c('Tip',[_vm._v("\n        If you wish to leave a monetary gift to charity, you can do so in the\n        Gifts section of your Will\n      ")]),_vm._v(" "),_c('LargeRadioButtons',{staticClass:"mb-8",attrs:{"id":"hasPartner","options":[
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          } ]},model:{value:(_vm.willMeta.charity_in_estate),callback:function ($$v) {_vm.$set(_vm.willMeta, "charity_in_estate", $$v)},expression:"willMeta.charity_in_estate"}}),_vm._v(" "),(_vm.willMeta.charity_in_estate)?_c('div',[_c('h4',{staticClass:"mb-4 text-2xl"},[_vm._v("\n          Charities and Not-for-Profits\n        ")]),_vm._v(" "),(_vm.showSuggestedCharities)?_c('CharitySuggester',{attrs:{"loading":loading,"selected-suggested-charities":_vm.selectedSuggestedCharities},model:{value:(_vm.selectedSuggestedCharities),callback:function ($$v) {_vm.selectedSuggestedCharities=$$v},expression:"selectedSuggestedCharities"}}):_vm._e(),_vm._v(" "),_c('CharitySelector',{attrs:{"loading":loading},model:{value:(_vm.selectedBeneficiaryIDs),callback:function ($$v) {_vm.selectedBeneficiaryIDs=$$v},expression:"selectedBeneficiaryIDs"}}),_vm._v(" "),_c('BaseButton',{staticClass:"btn-xl btn-white w-full add-charity",attrs:{"disabled":loading,"focusable":""},on:{"click":_vm.addCharity}},[_vm._v("\n          + Add a charity\n        ")])],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }