<template>
  <BasePanel
    v-if="showUserPanel"
    class="user-panel"
    title="Edit details"
    @close="close"
  >
    <Form
      v-slot="{ loading }"
      auto-focus
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      submit-label="Save Details"
      @done="close"
    >
      <FormSection>
        <FormRow>
          <TextInput
            id="name_first"
            v-model="willMeta.name_first"
            :disabled="loading"
            label="First name"
            placeholder="Your first name"
            rules="required"
          />
        </FormRow>
        <FormRow>
          <TextInput
            id="name_middle"
            v-model="willMeta.name_middle"
            :disabled="loading"
            label="Middle name"
            placeholder="Your middle name (optional)"
          />
        </FormRow>
        <FormRow>
          <TextInput
            id="name_last"
            v-model="willMeta.name_last"
            :disabled="loading"
            label="Last name"
            placeholder="Your last name"
            rules="required"
          />
        </FormRow>
        <FormRow>
          <DateInput
            id="date_of_birth"
            v-model="willMeta.date_of_birth"
            :disabled="loading"
            label="Date of birth"
            rules="required|date|over18"
          />
        </FormRow>
        <FormRow>
          <TextInput
            id="address_street"
            v-model="willMeta.address_street"
            :disabled="loading"
            label="Street"
            placeholder="Street Address"
          />
        </FormRow>
        <FormRow>
          <TextInput
            id="address_suburb"
            v-model="willMeta.address_suburb"
            :disabled="loading"
            label="Suburb"
            placeholder="Suburb"
          />
        </FormRow>
        <FormRow>
          <SelectInput
            id="address_state"
            v-model="willMeta.address_state"
            class="w-full mb-2 md:w-1/2 md:mr-2 md:mb-0"
            :disabled="loading"
            placeholder="State"
            :options="australianStates"
            label="State"
            searchable
          />
          <TextInput
            id="address_postcode"
            v-model="willMeta.address_postcode"
            class="w-full md:w-1/2"
            :disabled="loading"
            label="Postcode"
            placeholder="Postcode"
          />
        </FormRow>
      </FormSection>
    </Form>
  </BasePanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import BasePanel from '~/components/BasePanel';
import Form from '~/components/Form';
import FormRow from '~/components/FormRow';
import FormSection from '~/components/FormSection';
import TextInput from '~/components/TextInput';
import DateInput from '~/components/DateInput';
import SelectInput from '~/components/SelectInput';

import { user, will } from '~/mixins/apollo';

export default {
  name: 'UserPanel',
  components: {
    BasePanel,
    Form,
    FormRow,
    FormSection,
    TextInput,
    DateInput,
    SelectInput,
  },
  mixins: [user, will],
  data() {
    return {};
  },
  computed: {
    ...mapGetters('user', ['showUserPanel']),
    ...mapGetters('ui', ['australianStates']),
  },
  methods: {
    ...mapActions('user', ['setShowUserPanel']),
    close() {
      this.setShowUserPanel(false);
    },
  },
};
</script>
