<template>
  <BaseModal v-if="showUnlockWillModal" @close="close">
    <div
      class="flex justify-center items-center w-10 h-10 rounded-full mb-4 bg-teal-300 text-white"
    >
      <BaseGlyph id="edit" />
    </div>
    <h4 class="max-w-xs mb-4 text-3xl text-teal-300">
      Updating your Will
    </h4>
    <div class="mb-4 text-xl">
      <p v-if="willStatus === 'FLAGGED'">
        Once you start updating your Will, you'll need to submit it for review
        again when you're ready. Your progress will be saved.
      </p>
      <p v-else>
        When you're done updating your Will, you'll need to submit it again for
        review. Until you print and sign the new one, the Will you've previously
        signed is still valid.
      </p>
    </div>
    <Form
      :mutation="VERSION_WILL_MUTATION"
      :variables="variables"
      submit-arrow
      submit-label="Start updating"
      @done="done"
    />
  </BaseModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import VERSION_WILL_MUTATION from '~/graphql/mutations/VersionWill';

import BaseGlyph from '~/components/BaseGlyph';
import BaseModal from '~/components/BaseModal';
import Form from '~/components/Form';

import { user, will } from '~/mixins/apollo';

export default {
  name: 'UnlockWillModal',
  components: {
    BaseGlyph,
    BaseModal,
    Form,
  },
  mixins: [user, will],
  data() {
    return {
      VERSION_WILL_MUTATION,
    };
  },
  computed: {
    ...mapGetters(['willStatus']),
    ...mapGetters('ui', ['showUnlockWillModal']),
    variables() {
      return {
        id: this.willId,
      };
    },
  },
  methods: {
    ...mapActions(['setWillId', 'setWillStatus']),
    ...mapActions('ui', ['setShowUnlockWillModal']),
    close() {
      this.setShowUnlockWillModal(false);
    },
    done({ data }) {
      if (data.versionWill.success) {
        this.setWillId(data.versionWill.will.id);
        this.setWillStatus(data.versionWill.will.status);
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '📝 Edit Will',
        });
      }

      this.close();
    },
  },
};
</script>
