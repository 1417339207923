<template>
  <ModuleStep :index="3" prev-step="/dashboard" :show-help="true">
    <Form
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      :auto-submit="!willMeta.has_liabilities"
      submit-arrow
      @done="done"
    >
      <FormSection>
        <template #heading>
          Do you have any liabilities?
        </template>
        <p>
          Adding liabilities here will help your executor to settle the debts of
          your estate, and distribute your remaining assets as instructed in
          your Will.
        </p>
        <Tip>
          You can add notes about any debts and liabilities in the next step.
        </Tip>
        <LargeRadioButtons
          id="hasLiabilities"
          v-model="willMeta.has_liabilities"
          :options="[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]"
        />
      </FormSection>
      <FormSection v-if="willMeta.has_liabilities">
        <div>
          <EmptyState v-if="!selectedLiabilities.length">
            No liabilities added to your will yet.
          </EmptyState>
          <LiabilityInputs
            v-for="(liability, index) in selectedLiabilities"
            :id="`liability_${liability.id || index}`"
            :key="index"
            :disabled="loading"
            :description="liability.meta.description"
            :type="liability.meta.type"
            @description="liability.meta.description = $event"
            @type="liability.meta.type = $event"
            @add="addLiabilityInput"
            @remove="removeLiabilityInput(liability)"
          />
        </div>
        <div class="flex justify-end">
          <BaseButton
            class="btn-md btn-teal add-liability"
            focusable
            @click="addLiabilityInput"
          >
            + Add
          </BaseButton>
        </div>
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import LiabilityInputs from '~/components/LiabilityInputs';
import BaseButton from '~/components/BaseButton';
import EmptyState from '~/components/EmptyState';
import Form from '~/components/Form';
import FormSection from '~/components/FormSection';
import LargeRadioButtons from '~/components/LargeRadioButtons';
import ModuleStep from '~/components/ModuleStep';
import Tip from '~/components/Tip';

import { liabilities, user, will } from '~/mixins/apollo';
import { metaArrayToObject, objectToMetaArray } from '~/utilities';

export default {
  name: 'LiabilitiesAdd',
  components: {
    LiabilityInputs,
    BaseButton,
    EmptyState,
    Form,
    FormSection,
    LargeRadioButtons,
    ModuleStep,
    Tip,
  },
  mixins: [liabilities, user, will],
  data() {
    return {
      defaultLiability: {},
      selectedLiabilities: [],
    };
  },
  computed: {
    isComplete() {
      return !!(
        this.willMeta.has_liabilities === false ||
        this.selectedLiabilities.length
      );
    },
  },
  watch: {
    liabilities(liabilities) {
      if (liabilities.length && !this.selectedLiabilities.length) {
        this.selectedLiabilities = liabilities.map(({ id, meta }) => {
          return {
            id,
            meta: metaArrayToObject(meta),
          };
        });
      }
    },
  },
  methods: {
    addLiabilityInput() {
      this.selectedLiabilities.push({
        meta: {
          description: '',
          type: '',
        },
      });
    },
    async removeLiabilityInput(liability) {
      if (this.isLiability(liability)) {
        await this.removeLiability(liability);
      }

      this.selectedLiabilities.splice(
        this.selectedLiabilities.indexOf(liability),
        1
      );
    },
    async done() {
      for (const liability of this.selectedLiabilities) {
        const isLiability = this.isLiability(liability);
        const meta = objectToMetaArray(liability.meta);

        if (this.willMeta.has_liabilities) {
          if (isLiability) {
            await this.updateLiability(liability, meta);
          } else {
            await this.addLiability(meta);
          }
        } else if (isLiability) {
          await this.removeLiability(liability);
        }
      }

      if (this.willMeta.has_liabilities || this.willMeta.has_assets) {
        this.$router.push({
          path: '/assets/notes',
        });
      } else {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '✅ Module 6 (Assets)',
        });
        this.$router.push({
          path: '/dashboard',
        });
      }
    },
  },
};
</script>
