<template>
  <ModuleStep :index="0" :prev-step="previous" :show-help="true">
    <Form
      v-slot="{ loading }"
      :hide-submit="!isComplete"
      submit-arrow
      @submit.prevent="onSubmit"
    >
      <FormSection>
        <template #heading>
          Would you like to appoint a financial decision maker?
        </template>
        <p class="mb-4">
          Appoint people you trust to act on your behalf as financial decision
          makers. This is known as creating an Enduring Power of Attorney under
          Australian Law.
        </p>
        <p class="mb-4">
          Your financial decision maker will be able to act on your behalf for
          any financial purpose, which may include:
        </p>
        <ul class="list-disc ml-6 mb-4">
          <li>Managing your bank accounts and investments</li>
          <li>Paying bills</li>
          <li>Submitting tax returns</li>
          <li>Buying and selling real estate</li>
          <li>Collecting rent</li>
        </ul>
        <p class="mb-4">
          Please note that your financial decision maker won’t be able to act on
          your behalf as director of a company, or trustee of a trust.
        </p>
        <Tip>
          We’ll ask you about any limits or exclusions you might want to add to
          their powers later.
        </Tip>
        <LargeRadioButtons
          id="has_attorney"
          v-model="poaFinancialMeta.has_attorney"
          :options="[
            {
              label: `Yes, I'd like to appoint financial decision makers`,
              value: true,
            },
            {
              label: `No, I don't want to appoint any financial decision makers`,
              value: false,
            },
          ]"
          @change="onSubmit()"
        />
      </FormSection>
    </Form>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';
import { queries, mutations } from '@/modules/apollo-queries/poa-meta';
import { prevStep, nextStep } from '@/modules/poa-flow/financial/index';
import Tip from '~/components/Tip';
import Form from '~/components/Form';
import ModuleStep from '~/components/ModuleStep';
import FormSection from '~/components/FormSection';
import LargeRadioButtons from '~/components/LargeRadioButtons';

export default {
  name: 'PoaFinancialAttorneyIndex',
  components: {
    Tip,
    Form,
    ModuleStep,
    FormSection,
    LargeRadioButtons,
  },
  apollo: {
    poaFinancialMeta: {
      ...queries.poaFinancialMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
  },
  data() {
    return {
      poaFinancialMeta: {
        has_attorney: undefined,
      },
    };
  },
  computed: {
    ...mapGetters(['poaId']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
        poaFinancialMeta: this.poaFinancialMeta,
      });
    },
    isComplete() {
      return (
        this.poaFinancialMeta &&
        (this.poaFinancialMeta.has_attorney === true ||
          this.poaFinancialMeta.has_attorney === false)
      );
    },
  },
  methods: {
    async onSubmit() {
      await this.$apollo.mutate(
        mutations.updatePoaMeta(this.poaId, {
          key: 'has_attorney',
          value: this.poaFinancialMeta.has_attorney ? 'true' : 'false',
          category: 'FINANCIAL',
        })
      );

      if (!this.poaFinancialMeta.has_attorney) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '✅ Module POA financial skipped',
        });
      }

      const path = this.next;
      this.$router.push({ path });
    },
  },
};
</script>
