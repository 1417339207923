<template>
  <AuthPanel>
    <template #heading> Update your <br />password </template>
    <template #form>
      <Form
        :mutation="isReset ? null : RESET_PASSWORD_MUTATION"
        :variables="variables"
        submit-arrow
        :submit-label="submitLabel"
        submit-wide
        @done="done"
        @submit="$router.push({ path: '/log-in' })"
      >
        <p v-if="isReset" class="text-2xl">
          Your password has successfully been reset.
        </p>
        <template v-else>
          <FormRow>
            <PasswordInput
              id="password"
              v-model="password"
              label="Choose a new password"
              placeholder="Password"
            />
          </FormRow>
          <FormRow>
            <PasswordValidator
              v-model="validPassword"
              :input="password"
              :exclude="[email]"
            />
          </FormRow>
        </template>
      </Form>
    </template>
  </AuthPanel>
</template>

<script>
import RESET_PASSWORD_MUTATION from '~/graphql/mutations/ResetPassword';

import AuthPanel from '~/components/AuthPanel';
import Form from '~/components/Form';
import FormRow from '~/components/FormRow';
import PasswordInput from '~/components/PasswordInput';
import PasswordValidator from '~/components/PasswordValidator';

export default {
  name: 'ResetPassword',
  middleware: ['notAuthenticated'],
  components: {
    AuthPanel,
    Form,
    FormRow,
    PasswordInput,
    PasswordValidator,
  },
  data() {
    return {
      RESET_PASSWORD_MUTATION,
      email: this.$route.query.e,
      token: this.$route.query.t,
      password: '',
      isReset: false,
    };
  },
  computed: {
    submitLabel() {
      return this.isReset ? 'Log In' : 'Save Password';
    },
    variables() {
      return {
        email: this.email,
        token: this.token,
        newPassword: this.password,
        confirmNewPassword: this.password,
      };
    },
  },
  methods: {
    done({ data }) {
      this.isReset = data.resetPassword.success;
      this.$nuxt.$emit('unlockForm');
    },
  },
};
</script>
