var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleStep',{attrs:{"index":5,"prev-step":"/guardians/your-pets"}},[_c('Form',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"hide-submit":!_vm.isComplete,"submit-arrow":""},on:{"done":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('FormSection',{staticClass:"step-1",scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        Would you like to appoint a guardian for your pets?\n      ")]},proxy:true}],null,true)},[_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"hasPetGuardians","options":[
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          } ]},model:{value:(_vm.willMeta.has_pet_guardians),callback:function ($$v) {_vm.$set(_vm.willMeta, "has_pet_guardians", $$v)},expression:"willMeta.has_pet_guardians"}})],1),_vm._v(" "),_vm._l((_vm.pets),function(pet,index){return _c('MetaSlot',{key:index,attrs:{"meta":pet.meta},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var meta = ref.meta;
return [(_vm.showForm(index))?_c('FormSection',{staticClass:"step-2",attrs:{"tip":"We’ll ask you to add or select people at relevant places in your Will. Their details can be edited on the People, Pets & Charities page."},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v(" Appoint a guardian for "+_vm._s(meta.name)+" ")]},proxy:true}],null,true)},[_vm._v(" "),_c('PersonSelector',{attrs:{"id":pet.id,"loading":loading,"max":1,"people":_vm.filteredPeople[index]},model:{value:(_vm.selectedPetGuardians[index]),callback:function ($$v) {_vm.$set(_vm.selectedPetGuardians, index, $$v)},expression:"selectedPetGuardians[index]"}})],1):_vm._e()]}}],null,true)})})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }