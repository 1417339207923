<template>
  <div class="flex flex-col md:max-w-4xl md:justify-around md:flex-row">
    <div class="relative w-full max-w-3xl">
      <div
        v-if="prevStep"
        class="lg:absolute lg:top-0 lg:right-100 lg:mt-2 lg:mr-12"
      >
        <Tooltip :content="tooltipContent">
          <BaseButton
            class="prev-step inline-flex w-auto text-slate mb-4 lg:mb-0"
            focusable
            @click="goBack"
          >
            <span class="lg:hidden opacity-50 text-lg text-slate-100 underline">
              Go back
            </span>
            <span class="hidden lg:inline">
              <BaseIcon id="arrow-left" />
            </span>
          </BaseButton>
        </Tooltip>
      </div>
      <slot />
    </div>
    <div v-if="showHelp" class="mt-24 ml-12 lg:ml-0 md:mt-0 w-full max-w-sm">
      <FeatureBlock
        class="py-2 max-w-sm fixed"
        heading="We’re here to help"
        icon="chat"
      >
        <p>
          The best way to contact our Customer Care team is
          <button
            type="button"
            class="link link-dark whitespace-no-wrap"
            @click="showChat"
          >
            via chat
          </button>
          , or you can call us on
          <a href="tel:1800103310" class="link link-dark whitespace-no-wrap">
            1800 10 33 10
          </a>
          - we are open 7 days a week.
        </p>
      </FeatureBlock>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { showChat } from '@/modules/chat';
import BaseButton from '~/components/BaseButton';
import BaseIcon from '~/components/BaseIcon';
import Tooltip from '~/components/Tooltip';
import FeatureBlock from '~/components/FeatureBlock';

export default {
  name: 'ModuleStep',
  components: {
    BaseButton,
    BaseIcon,
    Tooltip,
    FeatureBlock,
  },
  props: {
    prevStep: {
      default: null,
      type: String,
    },
    index: {
      type: Number,
      required: true,
    },
    showHelp: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    tooltipContent() {
      return this.prevStep === '/dashboard'
        ? 'Back to dashboard'
        : 'Back to previous question';
    },
  },
  mounted() {
    this.setCurrentStep(this.index);
  },
  methods: {
    ...mapActions('modules', ['setCurrentStep']),
    goBack() {
      this.$router.push({
        path: this.prevStep,
        query: this.$route.query,
      });
    },
    showChat() {
      showChat();
    },
  },
};
</script>
