<template>
  <div
    class="password-validator__item"
    :class="valid ? 'password-validator__item--valid' : null"
  >
    <BaseGlyph id="tick-small" />
    <slot />
  </div>
</template>

<script>
import BaseGlyph from '~/components/BaseGlyph';

export default {
  name: 'PasswordValidatorItem',
  components: {
    BaseGlyph,
  },
  props: {
    valid: {
      default: true,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss">
.password-validator__item {
  display: flex;
  align-items: center;
  margin-right: 1.75rem;
  &:not(:last-child) {
    margin-bottom: 0.75rem;
    @include mq('min-md') {
      margin-bottom: 1.25rem;
    }
  }
  svg {
    --background: rgb(var(--silver));
    fill: #fff;
    margin-right: 2rem;
    border: 4px solid var(--background);
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: var(--background);
  }
  &--valid svg {
    --background: var(--grass);
  }
}
</style>
