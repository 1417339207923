<template>
  <div class="fixed top-0 left-0 right-0 z-20 w-full">
    <GlobalHeader class="relative z-10" />
    <div
      v-scroll="onScroll"
      class="transition-shadow transition-500 transition-ease"
      :class="classes"
      :style="translateY ? styles.outer : null"
    >
      <div
        class="py-8 bg-white gutters flex items-center w-full h-full"
        :style="translateY ? styles.inner : null"
      >
        <div class="flex items-center h-full">
          <Tooltip
            class="mr-6 md:mr-8"
            content="Back to dashboard"
            :disabled="!userId"
            placement="bottom"
          >
            <nuxt-link
              to="/"
              class="inline-block h-10 md:h-12"
              style="max-width: 40px;"
            >
              <Logo class="h-12" />
            </nuxt-link>
          </Tooltip>
          <span
            v-if="title"
            class="mr-4 font-medium text-lg leading-none"
            v-text="title"
          />
        </div>
        <client-only>
          <div class="flex flex-grow items-center h-full">
            <slot />
          </div>
        </client-only>
      </div>
    </div>
  </div>
</template>

<script>
import anime from 'animejs';
import { mapGetters } from 'vuex';

import GlobalHeader from '~/components/GlobalHeader';
import Logo from '~/components/Logo';
import Tooltip from '~/components/Tooltip';

export default {
  name: 'TopBar',
  components: {
    GlobalHeader,
    Logo,
    Tooltip,
  },
  props: {
    title: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      paused: false,
      scrollTop: 0,
      shadow: false,
      translateY: 0,
    };
  },
  computed: {
    ...mapGetters(['userId']),
    classes() {
      return {
        shadow: this.scrollTop > 0,
      };
    },
    styles() {
      return {
        inner: {
          transform: `translate3d(0, ${this.translateY / 2}px, 0)`,
        },
        outer: {
          transform: `translate3d(0, -${this.translateY}px, 0)`,
        },
      };
    },
  },
  watch: {
    $route() {
      this.paused = true;
      this.shadow = false;

      anime({
        targets: this,
        translateY: 0,
        translateZ: 0,
        duration: this.translateY * 12,
        easing: 'easeInOutQuad',
        complete: () => {
          this.paused = false;
        },
      });
    },
  },
  methods: {
    onScroll(scrollTop) {
      switch (this.$mq) {
        case 'base':
        case 'sm':
          this.translateY = 0;
          break;
        default:
          if (!this.paused) {
            const max = this.$el.offsetHeight / 4;
            this.translateY = Math.min(Math.max(scrollTop / 2, 0), max);
          }
          break;
      }

      this.scrollTop = scrollTop;
    },
  },
};
</script>
