var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleStep',{attrs:{"index":5,"prev-step":"/about-yourself/address"}},[_c('Form',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"hide-submit":!_vm.isComplete,"auto-submit":_vm.isComplete && !_vm.willMeta.has_partner,"submit-arrow":""},on:{"done":_vm.done},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('FormSection',{staticClass:"step-1",scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v(" Do you have a partner? ")]},proxy:true}],null,true)},[_vm._v(" "),_c('Tip',[_vm._v("\n        You have a partner if you’re married or have\n        "),_c('GlossaryTooltip',{attrs:{"term":"De Facto/Domestic relationship"}},[_vm._v("\n          a de facto/domestic relationship.\n        ")])],1),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"hasPartner","options":[
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          } ]},model:{value:(_vm.willMeta.has_partner),callback:function ($$v) {_vm.$set(_vm.willMeta, "has_partner", $$v)},expression:"willMeta.has_partner"}}),_vm._v(" "),_c('Notice',{attrs:{"headline":"Partner discount","icon":"tag"}},[_c('p',[_vm._v("\n          Add your partner to get 50% off their Will. Once you’ve submitted\n          your Will, confirm your partner’s email address and invite them to\n          complete theirs when they’re ready.\n        ")])])],1),_vm._v(" "),(_vm.willMeta.has_partner)?_c('p',{staticClass:"text-lg mb-6"},[_vm._v("\n      Once you've written your Will, we'll send a discount code for your\n      partner to write theirs for 50% off.\n    ")]):_vm._e(),_vm._v(" "),(_vm.willMeta.has_partner)?_c('FormSection',{staticClass:"step-2",attrs:{"tip":"We’ll ask you to add or select people at relevant places in your will. Their details can be edited on the People, Pets & Charities page."},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v(" My partner is... ")]},proxy:true}],null,true)},[_vm._v(" "),_c('PersonSelector',{attrs:{"loading":loading,"max":1,"people":_vm.filteredPeople,"type":"partner"},model:{value:(_vm.selectedPartners),callback:function ($$v) {_vm.selectedPartners=$$v},expression:"selectedPartners"}})],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }