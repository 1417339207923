<template>
  <div class="border-b border-grey-300 last-child:border-b-0">
    <h4
      v-if="title"
      class="pt-7 px-8 pb-4 font-medium leading-tight"
      v-text="title"
    />
    <div class="px-8 pb-8 text-slate-100" :class="{ 'pt-7': !title }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: null,
      type: String,
    },
  },
};
</script>
